import React, { forwardRef } from "react";
import PropTypes from "prop-types";
import { styled } from "@stitches/react";
import token from "../../tokens/json/variables.json";

const Btn = styled("button", {
  all: "unset",
  boxSizing: "border-box",
  appearance: "none",
  border: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  fontSize: token.fontSize1,
  lineHeight: token.bodyShort01LineHeight,
  padding: `${token.spacing0} ${token.sizing4} ${token.spacing0} ${token.sizing4}`,
  borderRadius: token.borderRadius1,
  color: token.textTextOnColor,
  cursor: "pointer",
  fontFamily: token.fontFamiliesSourceSansPro,
  "&:focus": {
    // outline: `${token.spacing05} solid ${token.focusFocus}`,
    // outlineOffset: token.spacing05,
    // borderRadius: token.borderRadius15,
    border: `${token.borderWidth2} solid ${token.focusFocus}`,
  },
  variants: {
    color: {
      primary: {
        backgroundColor: token.buttonButtonPrimary,
        transition: "background-color 0.5s",
        "&:hover": {
          backgroundColor: token.buttonButtonPrimaryHover,
        },
        "&:active": {
          backgroundColor: token.buttonButtonPrimaryActive,
        },
      },
      secondary: {
        border: `${token.borderWidth1} solid ${token.buttonButtonSecondary}`,
        color: token.textTextBrand,
        backgroundColor: token.transparent,
        transition: "background-color 0.5s",
        "&:hover": {
          backgroundColor: token.buttonButtonPrimaryHover,
          color: token.textTextOnColor,
        },
        "&:active": {
          backgroundColor: token.buttonButtonPrimaryActive,
          color: token.textTextOnColor,
        },
      },
      danger: {
        backgroundColor: token.buttonButtonDangerPrimary,
        "&:hover": {
          backgroundColor: token.buttonButtonDangerHover,
        },
        "&:active": {
          backgroundColor: token.buttonButtonDangerActive,
        },
      },
      ghost: {
        backgroundColor: token.transparent,
        color: token.textTextPrimary,
        fontFamily: token.bodyShort01FontFamily,
        fontWeight: token.bodyShort01FontWeight,
        fontSize: token.bodyShort01FontSize,
        letterSpacing: token.bodyShort01LetterSpacing,
        lineHeight: token.bodyShort01LineHeight,
        textTransform: token.bodyShort01TextCase,
        textDecoration: token.bodyShort01TextDecoration,
        "&:hover": {
          color: token.textTextPrimary,
          backgroundColor: token.backgroundBackgroundHover,
        },
        "&:focus": {
          color: token.textTextPrimary,
        },
        "&:active": {
          backgroundColor: token.buttonButtonTertiaryActive,
        },
      },
      ghostDark: {
        backgroundColor: token.transparent,
        color: token.textTextOnColor,
        fontFamily: token.bodyShort01FontFamily,
        fontWeight: token.bodyShort01FontWeight,
        fontSize: token.bodyShort01FontSize,
        letterSpacing: token.bodyShort01LetterSpacing,
        lineHeight: token.bodyShort01LineHeight,
        textTransform: token.bodyShort01TextCase,
        textDecoration: token.bodyShort01TextDecoration,
        "&:hover": {
          color: token.textTextPrimary,
          backgroundColor: token.backgroundBackgroundHover,
        },
        "&:active": {
          color: token.textTextPrimary,
          backgroundColor: token.buttonButtonTertiaryActive,
        },
      },
      tertiary: {
        backgroundColor: token.transparent,
        color: token.textTextSecondary,
        fontFamily: token.bodyShort01FontFamily,
        fontWeight: token.bodyShort01FontWeight,
        fontSize: token.bodyShort01FontSize,
        letterSpacing: token.bodyShort01LetterSpacing,
        lineHeight: token.bodyShort01LineHeight,
        textTransform: token.bodyShort01TextCase,
        textDecoration: token.bodyShort01TextDecoration,
        border: `${token.borderWidth1} solid ${token.buttonButtonTertiary}`,
        "&:hover": {
          backgroundColor: token.buttonButtonTertiaryHover,
          color: token.textTextPrimary,
        },
        "&:active": {
          color: token.textTextPrimary,
          backgroundColor: token.buttonButtonTertiaryActive,
        },
        "&:focus": {
          backgroundColor: token.buttonButtonSecondary,
          color: token.textTextOnColor,
        },
      },
    },
    selected: {},
    size: {
      small: {
        height: token.sizing6,
      },
      medium: {
        height: token.sizing8,
      },
    },
    disabled: {
      true: {
        backgroundColor: token.buttonButtonDisabled,
        color: token.textTextOnColorDisabled,
        pointerEvents: "none",
        cursor: "not-allowed",
        "&:focus": {
          outline: "none",
        },
      },
    },
    hidden: {
      true: {
        display: "none",
      },
    },
  },
  defaultVariants: {
    color: "primary",
    size: "medium",
  },
  compoundVariants: [
    {
      color: "secondary",
      disabled: true,
      css: {
        color: token.textTextDisabled,
        border: `${token.borderWidth1} solid ${token.buttonButtonDisabled}`,
        backgroundColor: token.white,
      },
    },
    {
      color: "danger",
      disabled: true,
      css: {
        color: token.textTextOnColorDisabled,
        backgroundColor: token.buttonButtonDisabled,
      },
    },
    {
      color: "ghost",
      disabled: true,
      css: {
        color: token.textTextDisabled,
        backgroundColor: token.transparent,
      },
    },
    {
      color: "ghost",
      selected: true,
      css: {
        "&:focus": {
          color: token.white,
        },
        "&:hover": {
          color: token.textTextPrimary,
        },
      },
    },
    {
      color: "ghostDark",
      disabled: true,
      css: {
        backgroundColor: token.transparent,
        color: token.textTextOnColorDisabled,
      },
    },
    {
      color: "tertiary",
      disabled: true,
      css: {
        color: token.textTextDisabled,
        backgroundColor: token.transparent,
        border: `${token.borderWidth1}  solid ${token.borderBorderDisabled}`,
      },
    },
    {
      color: "ghost",
      selected: true,
      css: {
        fill: token.buttonButtonGhostSelected,
        backgroundColor: token.buttonButtonGhostSelected,
        color: token.textTextOnColor,
      },
    },
    {
      color: "ghostDark",
      selected: true,
      css: {
        backgroundColor: token.buttonButtonGhostSelected,
        color: token.textTextOnColor,
      },
    },
  ],
});

const Button = forwardRef((props, ref) => {
  return (
    <Btn ref={ref} {...props}>
      {props.children}
    </Btn>
  );
});

Button.propTypes = {
  /** Defines the theme color of the button. Options include:
   - "primary": For primary actions.
   - "secondary": For secondary actions.
   - "danger": Indicates a potentially destructive action.
   - "ghost": Transparent background for less prominent actions.
   - "ghostDark": For use on dark backgrounds.
   - "tertiary": Less common, used for tertiary actions. */
  color: PropTypes.oneOf([
    "primary",
    "secondary",
    "danger",
    "ghost",
    "ghostDark",
    "tertiary",
  ]),

  /**  Specifies the size of the button. Options:
   - "small": Smaller button size.
   - "medium": Standard button size. */
  size: PropTypes.oneOf(["small", "medium"]),

  /** Boolean indicating if the button is in a "selected" state. */
  selected: PropTypes.bool,

  /** When true, disables the button, making it non-interactive. */
  disabled: PropTypes.bool,

  /** Controls the visibility of the button. If true, the button is not displayed. */
  hidden: PropTypes.bool,

  /** Specifies a unique identifier for the button. Useful for referencing the button in JavaScript or for accessibility purposes. */
  id: PropTypes.string,

  /** Specifies the content inside the button. This prop is required. */
  children: PropTypes.node.isRequired,

  /** Function called when the button is clicked. Allows passing a custom event handler. */
  onClick: PropTypes.func,
};

export default Button;

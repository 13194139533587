import React from "react";
import PropTypes from "prop-types";
import { styled } from "@stitches/react";
import {
  SubMenuItemContainerStyles,
  SubMenuItemContentStyles,
  SubMenuLabelStyles,
} from "./SubMenuItem.styles";

const SubMenuItemContainer = styled("li", SubMenuItemContainerStyles);

const SubMenuItemContent = styled("div", SubMenuItemContentStyles);

const SubMenuLabel = styled("div", SubMenuLabelStyles);

const SubMenuItem = (props) => {
  const { label, selected, handleSelect, id, items, disabled, ...otherProps } =
    props;
  const handleSubmenuClick = (event) => {
    event.stopPropagation();
    handleSelect(event, selected);
  };

  return (
    <SubMenuItemContainer
      id={`test_${id}`}
      data-testid={`subMenuItem_${id}`}
      onClick={(event) => !disabled && handleSubmenuClick(event, id)}
      selected={selected === id}
      disabled={disabled}
      {...otherProps}
    >
      <SubMenuItemContent>
        <SubMenuLabel selected={selected === id}>{props.label}</SubMenuLabel>
      </SubMenuItemContent>
    </SubMenuItemContainer>
  );
};

SubMenuItem.propTypes = {
  label: PropTypes.string.isRequired,
  isCollapse: PropTypes.bool,
  selected: PropTypes.string,
  handleSelect: PropTypes.func,
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  disabled: PropTypes.bool,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      label: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      icon: PropTypes.node,
      disabled: PropTypes.bool,
      type: PropTypes.oneOf(["section_header", "sub_menu", "footer"]),
    })
  ),
};

export default SubMenuItem;

import tokens from "../../../tokens/json/variables.json";

export const NodeRootStyles = {
  background: tokens.layerLayer02,
  gap: tokens.sizing2,
  overflow: "hidden",
  textOverflow: "ellipsis",

  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  padding: `${tokens.spacing2} ${tokens.spacing4} ${tokens.spacing2} 0`,

  "&:hover": {
    background: tokens.layerLayerHover01,
  },
  "&:active": {
    background: tokens.backgroundBackgroundHover,
  },
  "&:focus": {
    border: `${tokens.borderWidth2} solid ${tokens.focusFocus}`,
  },

  variants: {
    selected: {
      true: {
        background: tokens.backgroundBackgroundSelectedHover,

        "&:hover": {
          background: tokens.backgroundBackgroundActive,
        },
      },
    },
    disabled: {
      true: {
        color: tokens.layerLayer02,
      },
    },
    active: {
      true: {
        background: tokens.backgroundBackgroundHover,
      },
    },
    borderBottom: {
      true: {
        borderBottom: `${tokens.borderWidth1} solid ${tokens.borderBorderSubtle00}`,
      },
    },
  },
  compoundVariants: [
    {
      selected: true,
      css: {
        background: tokens.backgroundBackgroundSelectedHover,

        "&:hover": {
          background: tokens.backgroundBackgroundActive,
        },
      },
    },
    {
      disabled: true,
      css: {
        color: tokens.layerLayer02,
        pointerEvents: "none",
      },
    },
  ],
};

export const NodeContentStyles = {
  width: "100%",
  variants: {
    disabled: {
      true: {
        color: tokens.textTextDisabled,
        pointerEvents: "none",
      },
    },
  },
};

export const NodeLabelStyles = {
  overflow: "hidden",
  textOverflow: "ellipsis",
  color: tokens.textTextPrimary,
  // typography
  fontFamily: tokens.bodyShort01FontFamily,
  fontWeight: tokens.bodyShort01FontWeight,
  lineHeight: tokens.bodyShort01LineHeight,
  fontSize: tokens.bodyShort01FontSize,
  letterSpacing: tokens.bodyShort01LetterSpacing,
  paragraphSpacing: tokens.bodyShort01ParagraphSpacing,
  textCase: tokens.bodyShort01TextCase,
  textDecoration: tokens.bodyShort01TextDecoration,
  variants: {
    disabled: {
      true: {
        color: tokens.textTextDisabled,
        pointerEvents: "none",
      },
    },
  },
};

export const NodeActionsContainerStyles = {
  display: "flex",
  gap: "8px",
  width: "24px",
  height: "24px",
  justifyContent: "center",
  alignItems: "center",
};

export const NodeIconButtonStyles = {
  all: "unset",
  cursor: "pointer",
  fill: tokens.iconIconSecondary,
  color: tokens.iconIconSecondary,
  height: tokens.sizing4,
  width: tokens.sizing4,
  variants: {
    disabled: {
      true: {
        color: tokens.iconIconDisabled,
        pointerEvents: "none",
      },
    },
  },
};

/* eslint-disable jsx-a11y/tabindex-no-positive */
import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { styled } from "@stitches/react";
import { ArrowDownIcon, ArrowUpIcon } from "../../icons";
import {
  ContainerStyles,
  InnerContainerStyles,
  LabelStyles,
  OptionStyles,
  OptionsContainerStyles,
} from "./TimePickerColumn.styles";

const Container = styled("div", ContainerStyles);
const Label = styled("div", LabelStyles);
const InnerContainer = styled("div", InnerContainerStyles);
const OptionsContainer = styled("div", OptionsContainerStyles);
const Option = styled("div", OptionStyles);

const REPEATING_OPTIONS = 4;

const TimePickerColumn = ({ selectedOption, ...props }) => {
  const isInfiniteScrollActive = props.options.length > 3;
  const ref = useRef(null);

  const [options, setOptions] = useState(props.options);
  const [selectedOptionIndex, setSelectedOptionIndex] = useState(
    props.options.indexOf(selectedOption) +
      (isInfiniteScrollActive ? REPEATING_OPTIONS : 0)
  );

  const onChange = (newOptions, index) => {
    setOptions(newOptions);
    setSelectedOptionIndex(index);
    props.onChange(newOptions[index]);
  };

  const onClickPrev = () => {
    // select the previous option
    if (isInfiniteScrollActive) {
      const newOptions = [options[props.options.length - 1], ...options];
      newOptions.splice(newOptions.length - 1, 1);
      onChange(newOptions, selectedOptionIndex);
    } else {
      const newIndex =
        selectedOptionIndex === 0
          ? options.length - 1
          : selectedOptionIndex - 1;

      onChange(options, newIndex);
    }
  };

  const onClickNext = () => {
    // select the next option
    if (isInfiniteScrollActive) {
      const newOptions = [...options, options[REPEATING_OPTIONS * 2]];
      newOptions.splice(0, 1);
      onChange(newOptions, selectedOptionIndex);
    } else {
      const newIndex =
        selectedOptionIndex === options.length - 1
          ? 0
          : selectedOptionIndex + 1;

      onChange(options, newIndex);
    }
  };

  const onClickItem = (index) => {
    if (isInfiniteScrollActive) {
      const skippedItemsLength = Math.abs(selectedOptionIndex - index);

      if (index - selectedOptionIndex < 0) {
        const newOptions = [
          ...options.slice(
            props.options.length - skippedItemsLength,
            props.options.length
          ),
          ...options,
        ];
        newOptions.splice(
          newOptions.length - skippedItemsLength,
          skippedItemsLength
        );
        onChange(newOptions, selectedOptionIndex);
      } else {
        const newOptions = [
          ...options,
          ...options.slice(
            REPEATING_OPTIONS * 2 - skippedItemsLength,
            REPEATING_OPTIONS * 2
          ),
        ];
        newOptions.splice(0, skippedItemsLength);
        onChange(newOptions, selectedOptionIndex);
      }
    } else {
      onChange(options, index);
    }
  };

  useEffect(() => {
    if (isInfiniteScrollActive) {
      setOptions([
        ...options.slice(-REPEATING_OPTIONS),
        ...options,
        ...options.slice(0, REPEATING_OPTIONS),
      ]);
    }
  }, []);

  useEffect(() => {
    // moving the container on selection
    const container = ref.current;

    container.style.top = `calc(50% - 40px - ${40 * selectedOptionIndex}px + ${
      options.length % 2 ? 0 : 20
    }px)`;
  }, [selectedOptionIndex]);

  return (
    <Container data-testid="column">
      <Label>{props.label}</Label>
      <InnerContainer tabIndex={1}>
        <Option style={{ zIndex: 2, cursor: "pointer" }} onClick={onClickPrev}>
          <ArrowUpIcon width="14px" height="14px" />
        </Option>
        <OptionsContainer ref={ref}>
          {options.map((option, optionIndex) => (
            <Option
              // eslint-disable-next-line react/no-array-index-key
              key={`${option}_${optionIndex}`}
              data-testid={`option_${props.label}_${option}`}
              onClick={() => onClickItem(optionIndex)}
              data-selected={optionIndex === selectedOptionIndex}
            >
              {option}
            </Option>
          ))}
        </OptionsContainer>
        <Option style={{ zIndex: 2, cursor: "pointer" }} onClick={onClickNext}>
          <ArrowDownIcon width="14px" height="14px" />
        </Option>
      </InnerContainer>
    </Container>
  );
};

TimePickerColumn.propTypes = {
  selectedOption: PropTypes.string,
  onChange: PropTypes.func,
  options: PropTypes.arrayOf(PropTypes.string),
  label: PropTypes.string,
};

export default TimePickerColumn;

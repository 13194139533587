import React, { useEffect, useState } from "react";
import { styled } from "@stitches/react";
import PropTypes from "prop-types";
import Tag from "../../Tag";
import Button from "../../Button/Button";
import { FiltersIcon } from "../../../icons";
import {
  ColumnFiltersRootStyles,
  FiltersContainerStyles,
  FilterIconButtonStyles,
  AppliedFiltersContainerStyles,
  SearchByWrapperStyle,
  SearchByTypographyStyle,
} from "./ColumnFilters.styles";
import ColumnSearch from "./ColumnSearch";

const ColumnFiltersRoot = styled("div", ColumnFiltersRootStyles);
const FilterContainer = styled("div", FiltersContainerStyles);
const FilterIconButton = styled("div", FilterIconButtonStyles);
const AppliedFiltersContainer = styled("div", AppliedFiltersContainerStyles);
const SearchByWrapper = styled("div", SearchByWrapperStyle);
const SearchByTypography = styled("div", SearchByTypographyStyle);

const ColumnFilters = ({
  allColumns,
  filters,
  setFilter,
  setAllFilters,
  toggleColumnSF,
  onFiltersChange,
}) => {
  const [columnFilters, setColumnFilters] = useState(new Map());
  useEffect(() => {
    const columnFitersMap = new Map();
    allColumns.forEach((column) => {
      if (
        ["includesValue", "includesSome"].includes(column?.filter) &&
        !columnFitersMap.has(column.id)
      )
        columnFitersMap.set(column.id, "filter");
    });
    setColumnFilters(columnFitersMap);
  }, []);

  useEffect(() => {
    if (typeof onFiltersChange === "function") onFiltersChange(filters);
  }, [filters]);

  return (
    <ColumnFiltersRoot data-testid="Table_Filters">
      {toggleColumnSF.filter && (
        <>
          <FilterContainer>
            <FilterIconButton>
              <FiltersIcon className="filterIcon" />
            </FilterIconButton>
            {allColumns?.map((column, index) => {
              return !column?.disableFilters &&
                ["includesValue", "includesSome"].includes(column?.filter) ? (
                <div
                  key={`Filter_${column.id || index}`}
                  id={`Filter_${column.id || index}`}
                >
                  {column.render("Filter")}
                </div>
              ) : null;
            })}
          </FilterContainer>
          <AppliedFiltersContainer
            visible={filters.some(
              (filter) =>
                filter?.value?.length > 0 && columnFilters.has(filter.id)
            )}
          >
            {filters?.map((column) => {
              return (
                Array.isArray(column?.value) &&
                columnFilters.has(column?.id) &&
                column?.value?.map((value, index) => (
                  <Tag
                    key={`AppliedFilter_${value || index}`}
                    data-testid={`AppliedFilter_${value || index}`}
                    size="medium"
                    label={value}
                    variant="info"
                    onClick={() => {
                      setFilter(
                        column?.id,
                        column?.value?.filter(
                          (filterValue) => filterValue !== value
                        )
                      );
                    }}
                    type="removable"
                  />
                ))
              );
            })}
            {filters.some(
              (filter) =>
                filter.value?.length > 0 && columnFilters.has(filter.id)
            ) && (
              <Button
                color="ghost"
                size="medium"
                data-testid="Clear_Filters"
                onClick={() => {
                  setAllFilters([]);
                }}
              >
                Clear Filters
              </Button>
            )}
          </AppliedFiltersContainer>
        </>
      )}
      {toggleColumnSF.search &&
        allColumns.some((columns) => columns?.filter === "text") && (
          <SearchByWrapper data-testid="table_column_search_wrapper">
            <SearchByTypography>Search by</SearchByTypography>
            <ColumnSearch
              allColumns={allColumns}
              filters={filters}
              setFilter={setFilter}
              setAllFilters={setAllFilters}
            />
          </SearchByWrapper>
        )}
    </ColumnFiltersRoot>
  );
};

ColumnFilters.propTypes = {
  allColumns: PropTypes.instanceOf(Array),
  filters: PropTypes.instanceOf(Array),
  setFilter: PropTypes.func,
  setAllFilters: PropTypes.func,
  toggleColumnSF: PropTypes.instanceOf(Object),
  onFiltersChange: PropTypes.func,
};

export default ColumnFilters;

import React from "react";
import { styled } from "@stitches/react";
import PropTypes from "prop-types";
import {
  ColumnFiltersRootStyles,
  SearchContainerStyles,
} from "./ColumnFilters.styles";
import DefaultColumnSearch from "./DefaultSearchFilter";

const ColumnFiltersRoot = styled("div", ColumnFiltersRootStyles);
const SearchContainer = styled("div", SearchContainerStyles);

const ColumnSearch = ({ allColumns }) => {
  return (
    <ColumnFiltersRoot data-testid="Table_Column_Search">
      <SearchContainer>
        {allColumns?.map((column, index) => {
          return !column?.disableFilters && column?.filter === "text" ? (
            <div
              key={`Search_${column.id || index}`}
              id={`Search_${column.id || index}`}
            >
              <DefaultColumnSearch column={column} />
            </div>
          ) : null;
        })}
      </SearchContainer>
    </ColumnFiltersRoot>
  );
};

ColumnSearch.propTypes = {
  allColumns: PropTypes.instanceOf(Array),
};

export default ColumnSearch;

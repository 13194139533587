import React, { useState, useEffect, useCallback } from "react";
import { styled } from "@stitches/react";
import PropTypes from "prop-types";
import Node from "./Node/Node";
import { TreeRootStyles } from "./Tree.styles";

const TreeRoot = styled("div", TreeRootStyles);

const Tree = ({
  nodeClickHandler,
  initialNodes,
  CustomNodeActions,
  borderBottom,
  CustomNodeExpandIcon,
  CustomNodeCollapseIcon,
  defaultSelectedNode,
  ...props
}) => {
  const [nodes, setNodes] = useState(initialNodes || []);
  const [expandedNodes, setExpandedNodes] = useState([]);
  const [selectedNode, setSelectedNode] = useState(defaultSelectedNode || null);

  useEffect(() => {
    setNodes(initialNodes);
  }, [initialNodes]);

  const handleNodeSelect = (node) => {
    setSelectedNode(node);
  };

  const handleDefaultNodeClick = useCallback(() => {
    handleNodeSelect(defaultSelectedNode);
    if (typeof nodeClickHandler === "function") {
      nodeClickHandler(defaultSelectedNode);
    }
  }, [defaultSelectedNode]);

  useEffect(() => {
    if (defaultSelectedNode) {
      handleDefaultNodeClick();
    }
  }, [defaultSelectedNode]);

  const handleToggleExpand = (nodeId) => {
    setExpandedNodes((prevExpandedNodes) => {
      if (prevExpandedNodes?.includes(nodeId)) {
        return prevExpandedNodes?.filter((id) => id !== nodeId);
      }
      return [...prevExpandedNodes, nodeId];
    });
  };

  return (
    <TreeRoot data-testid="Tree_Container" {...props}>
      {(nodes || [])?.map((node) => (
        <Node
          {...props}
          key={node?.id}
          node={node}
          depth={1}
          nodeClickHandler={nodeClickHandler}
          CustomNodeActions={CustomNodeActions}
          handleNodeSelect={handleNodeSelect}
          selectedNode={selectedNode}
          expandedNodes={expandedNodes}
          onToggleExpand={handleToggleExpand}
          borderBottom={borderBottom}
          CustomNodeCollapseIcon={CustomNodeCollapseIcon}
          CustomNodeExpandIcon={CustomNodeExpandIcon}
        />
      ))}
    </TreeRoot>
  );
};

Tree.propTypes = {
  initialNodes: PropTypes.instanceOf(Array),
  nodeClickHandler: PropTypes.func,
  CustomNodeActions: PropTypes.func,
  borderBottom: PropTypes.bool,
  CustomNodeCollapseIcon: PropTypes.instanceOf(Object),
  CustomNodeExpandIcon: PropTypes.instanceOf(Object),
  defaultSelectedNode: PropTypes.instanceOf(Object),
};

Tree.defaultProps = {};

export default Tree;

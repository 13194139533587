import * as React from "react";
const SvgAddUserIcon = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 16 16"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M5.6 8.5c1.768 0 3.2-1.455 3.2-3.25S7.367 2 5.6 2C3.833 2 2.4 3.455 2.4 5.25S3.833 8.5 5.6 8.5Zm0-5.281c1.103 0 2 .911 2 2.031s-.897 2.031-2 2.031-2-.911-2-2.031.897-2.031 2-2.031Zm1.268 6.5H4.332C1.94 9.719 0 11.689 0 14.119c0 .487.388.881.867.881h9.467a.873.873 0 0 0 .866-.881c0-2.43-1.94-4.4-4.332-4.4Zm-5.65 4.062c.167-1.597 1.5-2.844 3.114-2.844h2.535c1.616 0 2.928 1.248 3.115 2.844H1.219ZM15.4 7.078h-1.2V5.86a.605.605 0 0 0-.6-.609c-.332 0-.6.273-.6.61v1.218h-1.2c-.33 0-.6.274-.6.61 0 .335.269.609.6.609H13v1.219c0 .337.27.609.6.609.33 0 .6-.273.6-.61V8.298h1.2c.332 0 .6-.272.6-.61a.603.603 0 0 0-.6-.609Z"
      fill="inherit"
    />
  </svg>
);
export default SvgAddUserIcon;

import * as React from "react";
const SvgErrorFilledIcon = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 16 16"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#ErrorFilledIcon_svg__a)">
      <path fill="#fff" d="M6 3h4v10H6z" />
      <path
        d="M7.116.366a1.252 1.252 0 0 1 1.768 0l6.75 6.75a1.252 1.252 0 0 1 0 1.768l-6.75 6.75a1.252 1.252 0 0 1-1.768 0l-6.75-6.75a1.252 1.252 0 0 1 0-1.768l6.75-6.75ZM8 3.75c-.416 0-.87.334-.87.75v4c0 .416.454.75.87.75s.88-.334.88-.75v-4c0-.416-.464-.75-.88-.75Zm1 7.5a.999.999 0 1 0-2 0 .999.999 0 1 0 2 0Z"
        fill="inherit"
      />
    </g>
    <defs>
      <clipPath id="ErrorFilledIcon_svg__a">
        <path fill="#fff" d="M0 0h16v16H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgErrorFilledIcon;

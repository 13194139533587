import tokens from "../../tokens/json/variables.json";

export const ContainerStyles = {
  boxSizing: "border-box",
  display: "flex",
  flexDirection: "column",
  width: "50%",
  "&:first-child > div:nth-child(2)": {
    borderBottomLeftRadius: tokens.borderRadius1,
    borderLeft: 0,
    borderBottom: 0,
    "&::before": {
      borderBottomLeftRadius: tokens.borderRadius1,
    },
  },
  "&:nth-child(2) > div:nth-child(2)": {
    borderLeft: 0,
    borderBottom: 0,
    borderRight: 0,
  },
  "&:last-child > div:nth-child(2)": {
    borderBottomRightRadius: tokens.borderRadius1,
    borderRight: 0,
    borderBottom: 0,
    "&::before": {
      borderBottomRightRadius: tokens.borderRadius1,
    },
  },
};
export const InnerContainerStyles = {
  boxSizing: "border-box",
  position: "relative",
  height: 360,
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  border: `${tokens.borderWidth1} solid ${tokens.borderBorderSubtle01}`,
  overflow: "hidden",
  "&:focus::before": {
    display: "block",
  },
  "&::before": {
    pointerEvents: "none",
    display: "none",
    content: "",
    position: "absolute",
    top: 0,
    left: 0,
    width: "calc(100% - 2px)",
    height: "calc(100% - 2px)",
    border: `${tokens.borderWidth1} solid ${tokens.focusFocus}`,
    outline: "none",
    zIndex: 3,
  },
};

export const LabelStyles = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  margin: "0 auto",
  width: tokens.sizing10,
  height: tokens.sizing10,
  padding: tokens.spacing15,
  color: tokens.textTextSecondary,
  fontFamily: tokens.semiBoldBodyShort02FontFamily,
  fontWeight: tokens.semiBoldBodyShort02FontWeight,
  fontSize: tokens.semiBoldBodyShort02FontSize,
  lineHeight: tokens.semiBoldBodyShort02LineHeight,
  letterSpacing: tokens.semiBoldBodyShort02LetterSpacing,
  textDecoration: tokens.semiBoldBodyShort02TextDecoration,
};

export const OptionsContainerStyles = {
  position: "absolute",
  width: "100%",
  top: "50%",
};

export const OptionStyles = {
  width: "100%",
  height: tokens.sizing10,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  cursor: "pointer",
  backgroundColor: tokens.layerLayer02,
  color: tokens.textTextPrimary,
  fontFamily: tokens.semiBoldBodyShort02FontFamily,
  fontWeight: tokens.semiBoldBodyShort02FontWeight,
  fontSize: tokens.semiBoldBodyShort02FontSize,
  lineHeight: tokens.semiBoldBodyShort02LineHeight,
  letterSpacing: tokens.semiBoldBodyShort02LetterSpacing,
  textDecoration: tokens.semiBoldBodyShort02TextDecoration,
  "&[data-selected=true]": {
    position: "relative",
    color: "white",
    backgroundColor: tokens.backgroundBackgroundBrand,
    zIndex: 2,
  },
};

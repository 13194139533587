import * as React from "react";
const SvgEyeIcon = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 14 10"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M7 9.506a6.644 6.644 0 0 1-3.902-1.225A7.512 7.512 0 0 1 .499 5.08a7.313 7.313 0 0 1 2.599-3.273A6.644 6.644 0 0 1 7 .581c1.435 0 2.748.412 3.903 1.225A7.512 7.512 0 0 1 13.5 5.01a7.312 7.312 0 0 1-2.598 3.272A6.644 6.644 0 0 1 7 9.506Zm0-7.875a5.786 5.786 0 0 0-3.202.945 6.27 6.27 0 0 0-2.24 2.512A6.663 6.663 0 0 0 3.789 7.52a5.808 5.808 0 0 0 3.194.945 5.786 5.786 0 0 0 3.202-.945 6.11 6.11 0 0 0 2.223-2.511 6.427 6.427 0 0 0-2.223-2.433 5.808 5.808 0 0 0-3.193-.945H7Zm0 5.977a2.517 2.517 0 0 1-1.82-.744 2.49 2.49 0 0 1-.752-1.82c0-.709.254-1.321.744-1.82a2.49 2.49 0 0 1 1.82-.753c.708 0 1.32.254 1.82.744.498.49.752 1.111.752 1.82 0 .709-.254 1.321-.744 1.82A2.49 2.49 0 0 1 7 7.608Zm0-4.113c-.428 0-.796.149-1.093.446a1.453 1.453 0 0 0-.455 1.094c0 .438.148.796.446 1.094a1.5 1.5 0 0 0 1.094.455c.428 0 .796-.149 1.093-.446a1.5 1.5 0 0 0 .455-1.094c0-.429-.148-.796-.446-1.094A1.453 1.453 0 0 0 7 3.495Z"
      fill="#11161D"
    />
  </svg>
);
export default SvgEyeIcon;

import tokens from "../../../tokens/json/variables.json";

export const SectionContainerStyles = {
  paddingTop: tokens.sizing2,
  "&.nospacing": {
    paddingTop: "unset",
  },
};

export const SectionHeaderStyles = {
  paddingLeft: tokens.spacing3,
  paddingRight: tokens.spacing8,
  paddingBottom: tokens.spacing15,
  paddingTop: tokens.spacing15,
  fontFamily: tokens.semiBoldBodyShort01FontFamily,
  fontSize: tokens.semiBoldBodyShort01FontSize,
  fontWeight: tokens.semiBoldBodyShort01FontWeight,
  lineHeight: tokens.semiBoldBodyShort01LineHeight,
  letterSpacing: tokens.semiBoldBodyShort01LetterSpacing,
  color: tokens.textTextInverse,
  textTransform: "uppercase",
  height: tokens.sizing8,
};

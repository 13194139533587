import React from "react";
import PropTypes from "prop-types";
import { styled } from "@stitches/react";
import SvgUserIcon from "../../../icons/DealIcon";
import {
  ItemContainerStyles,
  ItemContentStyles,
  IconContainerStyles,
  LabelStyles,
} from "./Item.styles";

const ItemContainer = styled("div", ItemContainerStyles);

const ItemContent = styled("div", ItemContentStyles);

const IconContainer = styled("div", IconContainerStyles);

const Label = styled("div", LabelStyles);

const Item = (props) => {
  const {
    Icon,
    isCollapse,
    label,
    handleSelect,
    id = "test",
    selected,
    disabled,
    ...otherProps
  } = props;

  return (
    <ItemContainer
      id={id}
      onClick={(event) => !disabled && handleSelect(event, id)}
      selected={selected === id}
      data-testid={`item_${id}`}
      disabled={disabled}
      {...otherProps}
    >
      <ItemContent>
        <IconContainer>
          {typeof Icon === "object" ? Icon : <SvgUserIcon />}
        </IconContainer>
        {!isCollapse ? (
          <Label selected={selected === id}>{props.label}</Label>
        ) : null}
      </ItemContent>
    </ItemContainer>
  );
};

Item.propTypes = {
  label: PropTypes.string.isRequired,
  isCollapse: PropTypes.bool,
  Icon: PropTypes.node,
  handleSelect: PropTypes.func,
  selected: PropTypes.string,
  disabled: PropTypes.bool,
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default Item;

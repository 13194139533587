import * as React from "react";
const SvgAccountIcon = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 16 16"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#AccountIcon_svg__a)">
      <path
        d="M8 3.5a2.754 2.754 0 0 0-2.75 2.75A2.734 2.734 0 0 0 8 9c1.5 0 2.75-1.219 2.75-2.75C10.75 4.75 9.5 3.5 8 3.5Zm0 4c-.719 0-1.25-.531-1.25-1.25C6.75 5.562 7.281 5 8 5c.688 0 1.25.563 1.25 1.25 0 .719-.563 1.25-1.25 1.25ZM8 0C3.562 0 0 3.594 0 8c0 4.438 3.563 8 8 8 4.406 0 8-3.563 8-8 0-4.406-3.594-8-8-8Zm0 14.5a6.503 6.503 0 0 1-3.906-1.281A3.087 3.087 0 0 1 6.875 11.5h2.219c1.187 0 2.25.688 2.781 1.719A6.479 6.479 0 0 1 8 14.5Zm5-2.344C12.156 10.844 10.719 10 9.094 10H6.875c-1.625 0-3.063.844-3.906 2.156C2.03 11.031 1.5 9.594 1.5 8c0-3.563 2.906-6.5 6.5-6.5 3.563 0 6.5 2.938 6.5 6.5a6.445 6.445 0 0 1-1.5 4.156Z"
        fill="inherit"
      />
    </g>
    <defs>
      <clipPath id="AccountIcon_svg__a">
        <path fill="transparent" d="M0 0h16v16H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgAccountIcon;

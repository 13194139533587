import React from "react";
import { styled } from "@stitches/react";
import PropTypes from "prop-types";
import SvgUserIcon from "../../../icons/DealIcon";
import {
  FooterContainerStyles,
  FooterItemStyles,
  LabelStyles,
  IconStyles,
} from "./Footer.styles";

const FooterContainer = styled("div", FooterContainerStyles);
const FooterItem = styled("div", FooterItemStyles);
const Label = styled("div", LabelStyles);
const IconContainer = styled("div", IconStyles);

const Footer = (props) => {
  const { items, handleSelect, selected, isCollapse } = props;
  const footerItems = items?.map((item) => {
    const { id, label, icon } = item;
    return (
      <FooterItem
        key={id}
        id={id}
        selected={selected === id}
        onClick={(event) => handleSelect(event, item.id)}
      >
        <IconContainer>
          {typeof icon === "object" ? icon : <SvgUserIcon />}
        </IconContainer>
        {!isCollapse ? <Label selected={selected === id}>{label}</Label> : null}
      </FooterItem>
    );
  });
  return <FooterContainer>{footerItems}</FooterContainer>;
};

Footer.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      label: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      icon: PropTypes.node,
      disabled: PropTypes.bool,
      type: PropTypes.oneOf(["section_header", "sub_menu", "footer"]),
    })
  ),
  handleSelect: PropTypes.func,
  selected: PropTypes.string,
  isCollapse: PropTypes.bool,
};
export default Footer;

import { styled } from "@stitches/react";
import variables from "../../tokens/json/variables.json";

export const EmptyStateContainer = styled("div", {
  boxSizing: "border-box",
  margin: 0,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  padding: variables.spacing8,
  gap: variables.spacing8,
});

export const ImageContainer = styled("div", {
  margin: 0,
  padding: 0,
  display: "flex",
  alignItems: "center",
  flexDirection: "column",
});

export const TextContainer = styled("div", {
  margin: 0,
  padding: 0,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  gap: variables.spacing2,
});

export const Title = styled("div", {
  all: "unset",
  color: variables.textTextPrimary,
  fontFamily: variables.productiveHeading03FontFamily,
  fontSize: variables.productiveHeading03FontSize,
  fontWeight: variables.productiveHeading03FontWeight,
  lineHeight: variables.productiveHeading03LineHeight,
  letterSpacing: variables.productiveHeading03LetterSpacing,
  textAlign: "center",
});

export const Description = styled("div", {
  all: "unset",
  color: variables.textTextSecondary,
  fontFamily: variables.bodyLong02FontFamily,
  fontSize: variables.bodyLong02FontSize,
  fontWeight: variables.bodyLong02FontWeight,
  lineHeight: variables.bodyLong02LineHeight,
  letterSpacing: variables.bodyLong02LetterSpacing,
  textAlign: "center",
});

export const ActionsContainer = styled("div", {
  margin: 0,
  padding: 0,
  display: "flex",
  gap: variables.spacing4,
});

export const FootNoteContainer = styled("div", {
  margin: 0,
  padding: 0,
});

import variables from "../../../tokens/json/variables.json";

export const TableHeadStyles = {
  display: "block",
};

export const TableRowStyles = {};

export const TableHeaderStyles = {
  borderBottom: `${variables.borderWidth1} solid ${variables.borderBorderSubtle00}`,
  background: variables.layerLayer01,

  // spacing
  boxSizing: "border-box",
  display: "flex !important",
  flexDirection: "row",
  alignItems: "center",
  gap: variables.spacing15,

  color: variables.textTextPrimary,

  // typography
  fontFamily: variables.productiveHeading01FontFamily,
  fontWeight: variables.productiveHeading01FontWeight,
  lineHeight: variables.productiveHeading01LineHeight,
  fontSize: variables.productiveHeading01FontSize,
  letterSpacing: variables.productiveHeading01LetterSpacing,
  paragraphSpacing: variables.productiveHeading01ParagraphSpacing,
  textCase: variables.productiveHeading01TextCase,
  textDecoration: variables.productiveHeading01TextDecoration,

  trasition: "width 0.6s ease",

  "& .sortableIndicator": {
    display: "none",
    height: variables.sizing3,
    width: variables.sizing3,

    color: variables.iconIconPrimary,
    fill: variables.iconIconPrimary,
  },

  "& .sortIcon": {
    display: "block",
    height: variables.sizing3,
    width: variables.sizing3,
  },

  "& .customisationIcon": {
    display: "block",
    height: variables.sizing35,
    width: variables.sizing35,
  },

  "&:hover": {
    background: variables.layerLayerHover02,

    "& .sortableIndicator": {
      display: "contents",
    },
  },

  variants: {
    selected: {
      true: {
        "&:hover": {
          background: variables.backgroundBackgroundSelectedHover,
        },
      },
    },
    disabled: {
      true: {
        color: variables.textTextOnColorDisabled,
      },
    },
    canResize: {
      true: {
        resize: "horizontal",
        overflow: "auto",
      },
    },
    size: {
      compact: {
        height: variables.sizing8,
        padding: `${variables.spacing2} ${variables.spacing4}`,
      },
      extraSmall: {
        height: variables.sizing10,
        padding: `${variables.spacing2} ${variables.spacing4}`,
      },
      small: {
        height: variables.sizing12,
        padding: `${variables.spacing2} ${variables.spacing4}`,
      },
      medium: {
        height: variables.sizing16,
        padding: variables.spacing4,
      },
      large: {
        height: variables.sizing18, // check
        padding: variables.spacing4,
      },
      extraLarge: {
        height: variables.sizing20,
        padding: variables.spacing4,
      },
    },
    tableActions: {
      true: {
        display: "flex",
        justifyContent: "flex-end",
      },
    },
  },
};

export const ResizeHandleStyles = {
  padding: "0px 0.5px",
  width: "2px",
  height: "100%",
  resize: "horizontal",
  position: "absolute",
  right: 0,
  top: 0,
  cursor: "ew-resize !important",

  "&:hover": {
    background: variables.borderBorderStrong02,
  },

  variants: {
    isResizing: {
      true: {
        background: variables.borderBorderStrong02,
        boxShadow: "0px 0px 6px rgba(0,0,0,0.4)",
      },
    },
  },
};

export const CustomisationButtonStyles = {
  all: "unset",
  cursor: "pointer",

  variants: {
    hidden: {
      true: {
        visibility: "hidden",
      },
    },
  },
};

export const PopUpBodyStyles = {
  maxHeight: "230px",
};

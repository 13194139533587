import tokens from "../../tokens/json/variables.json";

export const BreadcrumbsSeparatorStyles = {
  display: "flex",
  userSelect: "none",
  marginLeft: 8,
  marginRight: 8,
  color: tokens.iconIconPrimary,
  fill: tokens.iconIconPrimary,
};

export const BreadcrumbsOlStyles = {
  display: "flex",
  flexWrap: "wrap",
  alignItems: "center",
  padding: 0,
  margin: 0,
  listStyle: "none",
};

export const CollapsedLiStyles = {
  color: tokens.linkLinkPrimary,
  "&:hover": {
    color: tokens.linkLinkPrimary,
  },
  "&:focus": {
    border: `${tokens.borderWidth1} solid ${tokens.focusFocus}`,
  },
  "&:active": {
    color: tokens.linkLinkPrimaryActive,
  },
  variants: {
    disabled: {
      true: {
        color: tokens.textTextDisabled,
      },
    },
  },
};

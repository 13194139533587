export const NestedListRootStyles = {
  display: "grid",
  gridAutoRows: "max-content",
  overflow: "auto",
};

export const SearchWrapperStyles = {
  marginBottom: "16px",
};

export const MoreItemsWrapperStyles = {
  display: "flex",
  justifyContent: "center",
  marginTop: "10px",
};

import tokens from "../../tokens/json/variables.json";

export const RadioGroupOuterContainerStyles = {
  boxSizing: "border-box",
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
};

export const LabelContainerStyles = {
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "flex-start",
  paddingBottom: tokens.spacing2,
};

export const RadioGroupLabelStyles = {
  fontFamily: tokens.labelFontFamily,
  fontSize: tokens.labelFontSize,
  lineHeight: tokens.labelLineHeight,
  fontWeight: tokens.labelFontWeight,
  letterSpacing: tokens.labelLetterSpacing,
  textTransform: tokens.labelTextCase,
  textDecoration: tokens.labelTextDecoration,
  paragraphSpacing: tokens.labelParagraphSpacing,
  color: tokens.textTextSecondary,

  variants: {
    disabled: {
      true: {
        color: tokens.textTextDisabled,
      },
    },
    required: {
      true: {
        "&::after": {
          content: " *",
          color: tokens.textTextError,
          fontFamily: tokens.labelFontFamily,
          fontSize: tokens.labelFontSize,
          fontWeight: tokens.labelFontWeight,
          lineHeight: tokens.labelLineHeight,
          letterSpacing: tokens.labelLetterSpacing,
          paragraphSpacing: tokens.labelParagraphSpacing,
        },
      },
    },
  },
};

export const RadioGroupItemStyles = {
  all: "unset",
  width: "max-content",
  height: "18px",
  borderRadius: tokens.borderRadiusFull,
  "&:focus": {
    color: tokens.iconIconBrand,
    boxShadow: "0 0 0 2px",
  },
  '&[data-state="checked"] *': {
    fill: tokens.iconIconBrand,
  },
  '&[data-state="unchecked"] *': {
    fill: tokens.iconIconSecondary,
  },
  "&[data-disabled] *": {
    fill: tokens.iconIconDisabled,
  },
  "&[readonly], &[readonly] ~ label": {
    pointerEvents: "none",
    svg: {
      fill: tokens.iconIconDisabled,
    },
  },
  variants: {
    error: {
      true: {
        "*": {
          fill: `${tokens.supportSupportError} !important`,
        },
      },
    },
  },
};

export const RadioGroupItemContainerStyles = {
  boxSizing: "border-box",
  display: "flex",
  flexDirection: "row",
  gap: tokens.spacing15,

  variants: {
    disabled: {
      true: {
        "*": {
          color: tokens.textTextDisabled,
          fill: tokens.iconIconDisabled,
        },
      },
    },
  },
};

export const RadioGroupContainerStyles = {
  boxSizing: "border-box",
  display: "flex",
  flexDirection: "row",
  gap: tokens.spacing4,

  variants: {
    direction: {
      vertical: {
        flexDirection: "column",
        gap: tokens.spacing3,
      },
    },
  },
};

export const CircleIConStyles = {
  width: tokens.sizing35,
  height: tokens.sizing35,
  padding: tokens.spacing05,
  fill: tokens.iconIconSecondary,
  boxSizing: "content-box",
};

export const CircleCheckedIconStyles = {
  width: tokens.sizing35,
  height: tokens.sizing35,
  padding: tokens.spacing05,
  fill: tokens.iconIconBrand,
  boxSizing: "content-box",
};

export const InfoIconStyles = {
  width: tokens.sizing3,
  height: tokens.sizing3,
  marginLeft: tokens.spacing1,
  fill: tokens.iconIconSecondary,
};

export const RadioGroupTextStyles = {
  color: tokens.textTextPrimary,
  fontFamily: tokens.bodyShort01FontFamily,
  fontWeight: tokens.bodyShort01FontWeight,
  fontSize: tokens.bodyShort01FontSize,
  letterSpacing: tokens.bodyShort01LetterSpacing,
  lineHeight: tokens.bodyShort01LineHeight,
  textTransform: tokens.bodyShort01TextCase,
  textDecoration: tokens.bodyShort01TextDecoration,
};

export const HelperTextStyles = {
  padding: `${tokens.spacing2} 0px 0px 0px`,
  color: tokens.textTextSecondary,
  fontFamily: tokens.labelFontFamily,
  fontWeight: tokens.labelFontWeight,
  lineHeight: tokens.labelLineHeight,
  fontSize: tokens.labelFontSize,
  letterSpacing: tokens.labelLetterSpacing,
  paragraphSpacing: tokens.labelParagraphSpacing,
  textCase: tokens.labelTextCase,
  textDecoration: tokens.labelTextDecoration,

  variants: {
    disabled: {
      true: {
        color: tokens.textTextDisabled,
      },
    },
    error: {
      true: {
        color: tokens.textTextError,
      },
    },
  },
};

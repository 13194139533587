import * as React from "react";
const SvgLogoutIcon = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 16 16"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M5.25 13.5H3A1.48 1.48 0 0 1 1.5 12V4A1.5 1.5 0 0 1 3 2.5h2.25A.74.74 0 0 0 6 1.75.76.76 0 0 0 5.25 1H3a2.98 2.98 0 0 0-3 3v8c0 1.688 1.313 3 3 3h2.25a.74.74 0 0 0 .75-.75.76.76 0 0 0-.75-.75Zm10.469-6.031-4.75-4.188a1.064 1.064 0 0 0-1.125-.187 1.027 1.027 0 0 0-.594.937v1.75H5.5c-.719 0-1.25.532-1.25 1.25v2c0 .688.531 1.25 1.25 1.25h3.75V12a1.027 1.027 0 0 0 1.031 1.031c.25 0 .5-.094.688-.281l4.75-4.156A.71.71 0 0 0 16 8.03a.71.71 0 0 0-.281-.562Zm-4.969 3.468V8.75h-5v-1.5h5V5.062L14.094 8l-3.344 2.938Z"
      fill="inherit"
    />
  </svg>
);
export default SvgLogoutIcon;

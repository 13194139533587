import * as React from "react";
const SvgCustomizationIcon = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 16 16"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M0 3.714C0 2.777.7 2 1.6 2h12.8c.875 0 1.6.777 1.6 1.714v8.572C16 13.25 15.275 14 14.4 14H1.6C.7 14 0 13.25 0 12.286V3.714Zm11.2 9h3.2c.2 0 .4-.187.4-.428V3.714c0-.214-.2-.428-.4-.428h-3.2v9.428ZM10 3.286H6v9.428h4V3.286Zm-5.2 9.428V3.286H1.6c-.225 0-.4.214-.4.428v8.572c0 .24.175.428.4.428h3.2Z"
      fill="inherit"
    />
  </svg>
);
export default SvgCustomizationIcon;

import tokens from "../../../tokens/json/variables.json";

export const SubMenuItemContainerStyles = {
  all: "unset",
  boxSizing: "border-box",
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  height: tokens.spacing8,
  cursor: "pointer",
  "&:hover": {
    backgroundColor: tokens.backgroundBackgroundInverseHover,
    color: tokens.white,
  },
  "&:active": {
    backgroundColor: tokens.backgroundBackgroundInverseActive,
    color: tokens.white,
  },
  "&:focus": {
    backgroundColor: tokens.backgroundBackgroundInverseSelected,
  },
  // "&.selected": {
  //   backgroundColor: tokens.backgroundBackgroundInverseSelected,
  //   color: tokens.textTextOnColor,
  // },
  variants: {
    disabled: {
      true: {
        textDecorationLine: "line-through",
        pointerEvents: "none",
      },
    },
    hidden: {
      true: {
        display: "none",
      },
    },
    selected: {
      true: {
        backgroundColor: tokens.backgroundBackgroundInverseSelected,
        color: tokens.white,
      },
    },
  },
};

export const SubMenuItemContentStyles = {
  all: "unset",
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  flex: "none",
  order: 0,
  flexGrow: 1,
  padding: `0 ${tokens.spacing8} 0 ${tokens.spacing14}`,
};

export const SubMenuLabelStyles = {
  all: "unset",
  fontFamily: tokens.bodyShort01FontFamily,
  fontStyle: "normal",
  fontWeight: tokens.bodyShort01FontWeight,
  fontSize: tokens.bodyShort01FontSize,
  lineHeight: tokens.bodyShort01LineHeight,
  letterSpacing: tokens.bodyShort01LetterSpacing,
  flex: "none",
  order: 1,
  flexGrow: 1,
  variants: {
    selected: {
      true: {
        fontFamily: tokens.semiBoldBodyShort01FontFamily,
        fontSize: tokens.semiBoldBodyShort01FontSize,
        fontWeight: tokens.semiBoldBodyShort01FontWeight,
        lineHeight: tokens.semiBoldBodyShort01LineHeight,
        letterSpacing: tokens.semiBoldBodyShort01LetterSpacing,
        textDecoration: tokens.semiBoldBodyShort01TextDecoration,
      },
    },
  },
};

import variables from "../../../tokens/json/variables.json";

export const ActionBarRootStyles = {
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "center",
  boxSizing: "border-box",
  height: "0px",

  background: variables.backgroundBackgroundInverse,
  borderRadius: variables.borderRadius1,

  color: variables.textTextOnColor,

  visibility: "hidden",
  opacity: "0",
  transition: "height 0.3s ease, visibility 0.6s, opacity 0.6s linear",

  variants: {
    visible: {
      true: {
        visibility: "visible",
        opacity: "1",
        padding: `${variables.spacing15} ${variables.spacing4}`,
        height: variables.sizing12,
      },
    },
  },
};

export const ActionBarLabelStyles = {
  // typography
  fontFamily: variables.semiBoldBodyShort01FontFamily,
  fontWeight: variables.semiBoldBodyShort01FontWeight,
  lineHeight: variables.semiBoldBodyShort01LineHeight,
  fontSize: variables.semiBoldBodyShort01FontSize,
  letterSpacing: variables.semiBoldBodyShort01LetterSpacing,
  paragraphSpacing: variables.semiBoldBodyShort01ParagraphSpacing,
  testCase: variables.semiBoldBodyShort01TextCase,
  textDecoration: variables.semiBoldBodyShort01TextDecoration,
};

export const ActionsContainerStyles = {
  display: "flex",
  alignItems: "center",
  gap: variables.spacing3,
  height: variables.sizing8,

  // typography
  fontFamily: variables.bodyShort01FontFamily,
  fontWeight: variables.bodyShort01FontWeight,
  lineHeight: variables.bodyShort01LineHeight,
  fontSize: variables.bodyShort01FontSize,
  letterSpacing: variables.bodyShort01LetterSpacing,
  paragraphSpacing: variables.bodyShort01ParagraphSpacing,
  testCase: variables.bodyShort01TextCase,
  textDecoration: variables.bodyShort01TextDecoration,
};

export const CancelButtonStyles = {
  padding: "0px 16px",
  alignItems: "center",
  cursor: "pointer",
};

import React, { useRef, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { styled } from "@stitches/react";
import token from "../../tokens/json/variables.json";
import CopyIcon from "../../icons/CopyIcon";
import EyeIcon from "../../icons/EyeIcon";
import EyeOffIcon from "../../icons/EyeOffIcon";
import Button from "../Button";

const TextFiledWrapper = styled("span", {
  all: "unser",
  position: "relative",
});

const ActionsWrapper = styled("span", {
  margin: 0,
  position: "absolute",
  right: 0,
  top: "50%",
  transform: "translateY(-50%)",
  display: "flex",
  padding: token.spacing1,
});

const ActionButton = styled(Button, {
  width: `${token.sizing6} !important`,
  height: `${token.sizing6} !important`,
  padding: "0px !important",
});

const TextField = styled("input", {
  height: token.sizing8,
  padding: `${token.borderWidth0} ${token.spacing4} ${token.borderWidth0} ${token.spacing4}`,
  color: token.textTextPrimary,
  border: "none",
  outline: `${token.borderWidth1} solid ${token.borderBorderSubtle01}`,
  borderRadius: token.borderRadius1,

  fontFamily: token.bodyShort01FontFamily,
  fontSize: token.bodyShort01FontSize,
  fontWeight: token.bodyShort01FontWeight,
  lineHeight: token.bodyShort01LineHeight,
  letterSpacing: token.bodyShort01LetterSpacing,
  paragraphSpacing: token.bodyShort01ParagraphSpacing,
  textDecoration: token.bodyShort01TextDecoration,
  textOverflow: "ellipsis",
  "&::placeholder": {
    color: token.textTextPlaceholder,
  },
  "&:active": {
    padding: `${token.borderWidth0} ${token.spacing4} ${token.borderWidth0} ${token.spacing4}`,
    color: token.textTextPrimary,
    outline: `${token.borderWidth2} solid ${token.focusFocus}`,
  },
  "&:focus": {
    padding: `${token.borderWidth0} ${token.spacing4} ${token.borderWidth0} ${token.spacing4}`,
    color: token.textTextPrimary,
    outline: `${token.borderWidth2} solid ${token.focusFocus}`,
  },
  "&[readOnly]": {
    color: token.textTextSecondary,
    background: token.fieldField01,
    "&:hover": {
      background: token.fieldFieldHover01,
    },
  },
  "&[disabled]": {
    color: token.textTextDisabled,
    outline: `${token.borderWidth1} solid ${token.borderBorderDisabled}`,
    pointerEvents: "none",
    cursor: "not-allowed",
    "&::placeholder": {
      color: token.textTextDisabled,
    },
  },
  variants: {
    error: {
      true: {
        color: token.textTextPrimary,
        outline: `${token.borderWidth2} solid ${token.supportSupportError}`,
      },
    },
  },

  compoundVariants: [
    {
      "&[disabled]": true,
      error: true,
      css: {
        color: token.textTextDisabled,
        outline: `${token.borderWidth1} solid ${token.borderBorderDisabled}`,
        pointerEvents: "none",
        cursor: "not-allowed",
        "&::placeholder": {
          color: token.textTextDisabled,
        },
      },
    },
  ],
});
const Label = styled("div", {
  color: token.textTextSecondary,
  fontFamily: token.labelFontFamily,
  fontSize: token.labelFontSize,
  fontWeight: token.labelFontWeight,
  lineHeight: token.labelLineHeight,
  letterSpacing: token.labelLetterSpacing,
  paragraphSpacing: token.labelParagraphSpacing,
  paddingBottom: token.sizing2,
  "&[disabled]": {
    color: token.textTextDisabled,
  },
  variants: {
    required: {
      true: {
        "&::after": {
          content: " *",
          color: token.textTextError,
          fontFamily: token.labelFontFamily,
          fontSize: token.labelFontSize,
          fontWeight: token.labelFontWeight,
          lineHeight: token.labelLineHeight,
          letterSpacing: token.labelLetterSpacing,
          paragraphSpacing: token.labelParagraphSpacing,
        },
      },
    },
  },
});
const HelperText = styled("div", {
  paddingTop: token.spacing1,
  color: token.textTextSecondary,
  fontFamily: token.labelFontFamily,
  fontSize: token.labelFontSize,
  fontWeight: token.labelFontWeight,
  lineHeight: token.labelLineHeight,
  letterSpacing: token.labelLetterSpacing,
  paragraphSpacing: token.labelParagraphSpacing,
  "&[disabled]": {
    color: token.textTextDisabled,
  },
  variants: {
    error: {
      true: {
        color: token.textTextError,
      },
    },
  },
  compoundVariants: [
    {
      "&[disabled]": true,
      error: true,
      css: {
        color: token.textTextDisabled,
      },
    },
  ],
});

const TextInput = (props) => {
  const {
    label,
    helperText,
    showActions = false,
    handleCopyCb,
    type,
    required,
    ...otherProps
  } = props;

  const handleCopy = (value = "") => {
    if (value) {
      navigator.clipboard
        .writeText(value)
        .then(() => {
          if (typeof handleCopyCb === "function") {
            handleCopyCb(true, value);
          }
        })
        .catch((err) => {
          if (typeof handleCopyCb === "function") handleCopyCb(false, err);
        });
    }
  };

  const firstChildRef = useRef(null);
  const secondChildRef = useRef(null);

  useEffect(() => {
    const secondChildWidth = secondChildRef?.current?.offsetWidth;
    if (secondChildWidth) {
      firstChildRef.current.style.paddingRight = `${secondChildWidth}px`;
    }
  }, []);

  const [showPassword, setShowPassword] = useState(false);

  const toggleShowPassword = () => {
    setShowPassword((prevState) => !prevState);
  };
  // eslint-disable-next-line
  const inputType = type === "password" ? (showPassword ? "text" : "password") : type;

  return (
    <>
      {label && (
        <Label
          required={required}
          {...otherProps}
          data-testid="textField-label"
        >
          {label}
        </Label>
      )}
      <TextFiledWrapper>
        <TextField
          ref={firstChildRef}
          type={inputType}
          {...otherProps}
          data-testid="textField-input"
        />
        {showActions && (
          <ActionsWrapper ref={secondChildRef} data-testid="textField-actions">
            {type === "password" &&
              (showPassword ? (
                <ActionButton
                  data-testid="textField-hidePassword"
                  color="ghost"
                  onClick={toggleShowPassword}
                >
                  <EyeOffIcon />
                </ActionButton>
              ) : (
                <ActionButton
                  data-testid="textField-showPassword"
                  color="ghost"
                  onClick={toggleShowPassword}
                >
                  <EyeIcon />
                </ActionButton>
              ))}
            <ActionButton
              data-testid="textField-copyContent"
              color="ghost"
              onClick={() => handleCopy(otherProps?.value)}
            >
              <CopyIcon />
            </ActionButton>
          </ActionsWrapper>
        )}
      </TextFiledWrapper>
      <HelperText {...otherProps} data-testid="textField-helper">
        {helperText}
      </HelperText>
    </>
  );
};

TextInput.propTypes = {
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  error: PropTypes.bool,
  readOnly: PropTypes.bool,
  showActions: PropTypes.bool,
  id: PropTypes.string,
  label: PropTypes.string,
  helperText: PropTypes.string,
  value: PropTypes.string,
  type: PropTypes.string,
  onChange: PropTypes.func,
  handleCopyCb: PropTypes.func,
};

export default TextInput;

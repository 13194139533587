import { useEffect, useCallback } from "react";

const useEscapeKey = (handleEscAction) => {
  const KEY_NAME_ESC = "Escape";
  const KEY_EVENT_TYPE = "keydown";

  const handleEsc = useCallback(
    (event) => {
      if (event?.key === KEY_NAME_ESC) {
        handleEscAction(event);
      }
    },
    [handleEscAction]
  );

  useEffect(() => {
    window.addEventListener(KEY_EVENT_TYPE, handleEsc);
    return () => {
      window.removeEventListener(KEY_EVENT_TYPE, handleEsc);
    };
  }, [handleEsc]);
};
export default useEscapeKey;

import React from "react";
import PropTypes from "prop-types";
import { styled } from "@stitches/react";
import { isFragment } from "react-is";
import ArrowRight from "../../icons/ArrowRightIcon";
import {
  BreadcrumbsSeparatorStyles,
  BreadcrumbsOlStyles,
} from "./BreadCrumbs.styles";
import BreadcrumbCollapsed from "./BreadCrumbCollapsed";

const BreadCrumbsContainer = styled("div", {});
const BreadcrumbsOl = styled("ol", BreadcrumbsOlStyles);
const BreadcrumbsSeparator = styled("li", BreadcrumbsSeparatorStyles);

function insertSeparators(items, separator) {
  return items.reduce((acc, current, index) => {
    if (index < items.length - 1) {
      // eslint-disable-next-line no-param-reassign
      acc = acc.concat(
        current,
        <BreadcrumbsSeparator
          aria-hidden
          // eslint-disable-next-line react/no-array-index-key
          key={`separator-${index}`}
        >
          {separator}
        </BreadcrumbsSeparator>
      );
    } else {
      acc.push(current);
    }

    return acc;
  }, []);
}
const BreadCrumbs = React.forwardRef(
  (
    {
      children,
      itemsAfterCollapse = 1,
      itemsBeforeCollapse = 1,
      maxItems = 8,
      separator = <ArrowRight />,
    },
    forwardedRef
  ) => {
    const [expanded, setExpanded] = React.useState(false);
    const listRef = React.useRef(null);
    const parentState = {
      expanded,
      itemsAfterCollapse,
      itemsBeforeCollapse,
      maxItems,
      separator,
    };
    const renderItemsBeforeAndAfter = (allItems) => {
      const handleClickExpand = () => {
        setExpanded(true);

        // The clicked element received the focus but gets removed from the DOM.
        // Let's keep the focus in the component after expanding.
        // Moving it to the <ol> or <nav> does not cause any announcement in NVDA.
        // By moving it to some link/button at least we have some announcement.
        const focusable = listRef.current.querySelector(
          "a,a[href],button,[tabindex]"
        );
        if (focusable) {
          focusable.focus();
        }
      };

      // This defends against someone passing weird input, to ensure that if all
      // items would be shown anyway, we just show all items without the EllipsisItem
      if (itemsBeforeCollapse + itemsAfterCollapse >= allItems.length) {
        if (process.env.NODE_ENV !== "production") {
          // eslint-disable-next-line no-console
          console.error(
            [
              "Common-UI: You have provided an invalid combination of props to the Breadcrumbs.",
              `itemsAfterCollapse={${itemsAfterCollapse}} + itemsBeforeCollapse={${itemsBeforeCollapse}} >= maxItems={${maxItems}}`,
            ].join("\n")
          );
        }
        return allItems;
      }

      return [
        ...allItems.slice(0, itemsBeforeCollapse),
        <BreadcrumbCollapsed
          aria-label="Show  Path"
          key="ellipsis"
          onClick={handleClickExpand}
        />,
        ...allItems.slice(
          allItems.length - itemsAfterCollapse,
          allItems.length
        ),
      ];
    };
    const allItems = React.Children.toArray(children)
      .filter((child) => {
        if (process.env.NODE_ENV !== "production") {
          if (isFragment(child)) {
            // eslint-disable-next-line no-console
            console.error(
              [
                "Common-UI: The Breadcrumbs component doesn't accept a Fragment as a child.",
                "Consider providing an array instead.",
              ].join("\n")
            );
          }
        }

        return React.isValidElement(child);
      })
      .map((child, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <li key={`child-${index}`}>{child}</li>
      ));

    return (
      <BreadCrumbsContainer
        ref={forwardedRef}
        data-testid="breadCrumbContainer"
      >
        <BreadcrumbsOl ref={listRef}>
          {insertSeparators(
            expanded || (maxItems && allItems.length <= maxItems)
              ? allItems
              : renderItemsBeforeAndAfter(allItems),
            separator,
            parentState
          )}
        </BreadcrumbsOl>
      </BreadCrumbsContainer>
    );
  }
);

BreadCrumbs.propTypes = {
  children: PropTypes.node,
  itemsAfterCollapse: PropTypes.number,
  itemsBeforeCollapse: PropTypes.number,
  maxItems: PropTypes.number,
  separator: PropTypes.node,
};

export default BreadCrumbs;

import * as React from "react";
const SvgCheckIcon = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 12 9"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="m4.25 6.434 6.18-6.18.82.82-7 7L.996 4.82l.82-.82L4.25 6.434Z"
      fill="inherit"
    />
  </svg>
);
export default SvgCheckIcon;

import tokens from "../../tokens/json/variables.json";

export const LabelContainerStyles = {
  cursor: "pointer",
  boxSizing: "border-box",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  gap: "2px",
  height: tokens.sizing10,
  color: tokens.textTextSecondary,
  borderBottom: `${tokens.borderWidth2} solid ${tokens.borderBorderSubtle01}`,
  fontFamily: tokens.bodyShort01FontFamily,
  fontSize: tokens.bodyShort01FontSize,
  fontWeight: tokens.bodyShort01FontWeight,
  letterSpacing: tokens.bodyShort01LetterSpacing,
  lineHeight: tokens.bodyShort01LineHeight,
  "&:focus": {
    color: tokens.textTextPrimary,
    borderBottom: `${tokens.borderWidth2} solid ${tokens.focusFocus}`,
  },
  "&:hover": {
    color: tokens.textTextPrimary,
    borderBottom: `${tokens.borderWidth2} solid ${tokens.borderBorderSubtle01}`,
  },
  variants: {
    selected: {
      true: {
        color: tokens.linkLinkPrimary,
        borderBottom: `${tokens.borderWidth2} solid ${tokens.borderBorderInteractive}`,
        fontFamily: tokens.semiBoldBodyShort01FontFamily,
        fontSize: tokens.semiBoldBodyShort01FontSize,
        fontWeight: tokens.semiBoldBodyShort01FontWeight,
        letterSpacing: tokens.semiBoldBodyShort01LetterSpacing,
        lineHeight: tokens.semiBoldBodyShort01LineHeight,
      },
    },
    disabled: {
      true: {
        cursor: "not-allowed",
        color: tokens.textTextDisabled,
        borderBottom: `${tokens.borderWidth2} solid ${tokens.borderBorderDisabled}`,
        "&:hover": {
          color: tokens.textTextDisabled,
          border: "none",
          borderBottom: `${tokens.borderWidth2} solid ${tokens.borderBorderDisabled}`,
        },
      },
    },
  },
};

export const LabelStyles = {
  display: "inline-flex",
  alignItems: "center",
  justifyContent: "center",
  height: "inherit",
  width: "inherit",
  paddingRight: tokens.spacing4,
  paddingLeft: tokens.spacing4,
  color: tokens.textTextSecondary,
  fontFamily: tokens.bodyShort01FontFamily,
  fontSize: tokens.bodyShort01FontSize,
  fontWeight: tokens.bodyShort01FontWeight,
  letterSpacing: tokens.bodyShort01LetterSpacing,
  lineHeight: tokens.bodyShort01LineHeight,
  "&:focus": {
    color: tokens.textTextPrimary,
  },
  "&:hover": {
    color: tokens.textTextPrimary,
  },
  variants: {
    selected: {
      true: {
        color: tokens.linkLinkPrimary,
        fontFamily: tokens.semiBoldBodyShort01FontFamily,
        fontSize: tokens.semiBoldBodyShort01FontSize,
        fontWeight: tokens.semiBoldBodyShort01FontWeight,
        letterSpacing: tokens.semiBoldBodyShort01LetterSpacing,
        lineHeight: tokens.semiBoldBodyShort01LineHeight,
      },
    },
    disabled: {
      true: {
        color: tokens.textTextDisabled,
        "&:hover": {
          color: tokens.textTextDisabled,
        },
      },
    },
  },
};

import * as React from "react";
const SvgSortUpDownIcon = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 16 16"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#SortUpDownIcon_svg__a)">
      <path
        d="M15.28 11.781a.75.75 0 0 0-1.06-1.06l-1.717 1.72V1.75a.748.748 0 0 0-.75-.75.748.748 0 0 0-.75.75v10.69l-1.719-1.719a.75.75 0 0 0-1.06 1.06l3 3a.747.747 0 0 0 1.06 0l3-3h-.003ZM4.28 1.22a.747.747 0 0 0-1.059 0l-3.003 3a.75.75 0 0 0 1.06 1.06l1.718-1.72V14.25c0 .416.335.75.75.75.416 0 .75-.334.75-.75V3.56l1.72 1.718a.75.75 0 0 0 1.06-1.06l-2.995-3Z"
        fill="inherit"
      />
    </g>
    <defs>
      <clipPath id="SortUpDownIcon_svg__a">
        <path fill="#fff" d="M0 0h16v16H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgSortUpDownIcon;

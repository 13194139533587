import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import * as CheckboxPrimitive from "@radix-ui/react-checkbox";
import { styled } from "@stitches/react";

import {
  SquareIcon,
  SquareCheckIcon,
  SquareMinusIcon,
  InfoIcon,
} from "../../icons";
import {
  LabelContainerStyles,
  HelperTextStyles,
  ContainerStyles,
  BaseIconStyles,
  InfoIconStyles,
  LabelStyles,
  TextStyles,
} from "./Checkbox.styles";
import Tooltip from "../Tooltip/Tooltip";

const OuterContainer = styled("div", {
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
});
const HelperText = styled("div", HelperTextStyles);
const StyledCheckbox = styled(CheckboxPrimitive.Root, ContainerStyles);
const BaseIcon = styled(SquareIcon, BaseIconStyles);
const StyledInfoIcon = styled(InfoIcon, InfoIconStyles);
const LabelContainer = styled("div", LabelContainerStyles);
const Label = styled("div", LabelStyles);
const Text = styled("div", TextStyles);

const Checkbox = ({
  indeterminate,
  helperText,
  required,
  onChange,
  checked,
  label,
  text,
  ...props
}) => {
  const [checkedState, setCheckedState] = useState(checked);

  useEffect(() => {
    if (indeterminate) {
      setCheckedState("indeterminate");
    } else if (checked) {
      setCheckedState(true);
    } else {
      setCheckedState(false);
    }
  }, [indeterminate, checked]);

  return (
    <OuterContainer>
      <LabelContainer>
        <Label required={Boolean(label) && required} disabled={props?.disabled}>
          {label}
        </Label>
        {props.info && (
          <Tooltip
            data-testid="tooltip"
            type="inline"
            content={props.info}
            align="center"
            side="right"
            style={{ display: "flex" }}
          >
            <StyledInfoIcon />
          </Tooltip>
        )}
      </LabelContainer>
      <StyledCheckbox
        checked={checkedState}
        onClick={(event) => event.stopPropagation()}
        data-testid="checkbox"
        onCheckedChange={props.readOnly ? null : onChange}
        {...props}
      >
        {!checkedState && <BaseIcon />}
        <CheckboxPrimitive.Indicator
          style={{ width: "14px", height: "14px", display: "flex" }}
        >
          {checkedState === true && (
            <SquareCheckIcon height="14px" width="14px" />
          )}
          {checkedState === "indeterminate" && (
            <SquareMinusIcon height="14px" width="14px" />
          )}
        </CheckboxPrimitive.Indicator>
        <Text required={!label && required}>{text}</Text>
      </StyledCheckbox>
      {helperText && (
        <HelperText
          data-testid="helperText"
          error={props?.error}
          disabled={props?.disabled}
        >
          {helperText}
        </HelperText>
      )}
    </OuterContainer>
  );
};

Checkbox.propTypes = {
  /** Text that appears next to the Checkbox. */
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  /** Text for an informational tooltip that appears when hovering over an information icon above the Checkbox. */
  info: PropTypes.string,
  /** The label of the Checkbox. This prop is optional and allows for flexible label for the Checkbox. */
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  /** Indicates if the Checkbox is in an error state. If true, the helper text will be displayed in an error state. */
  error: PropTypes.bool,
  /** Indicates if the Checkbox is in an checked or selected state. */
  checked: PropTypes.bool,
  /** The disabled state of the Checkbox. If true, the Checkbox will be disabled. */
  disabled: PropTypes.bool,
  /** Event handler for the onChange event of the Checkbox. */
  onChange: PropTypes.func,
  /** If true, displays an asterisk (*) in front of label to indicate that the checkbox is required in a form context.
   *  If true but label is not present then displays an asterisk (*) in front of text */
  required: PropTypes.bool,
  /** The readOnly state of the Checkbox. If true, the Checkbox will be readOnly. */
  readOnly: PropTypes.bool,
  /** Helper text for the Checkbox. Optional and provides additional information about the Checkbox. */
  helperText: PropTypes.string,
  /** Indicates if the Checkbox is in an unknown or indeterminate state. */
  indeterminate: PropTypes.bool,
};

export default Checkbox;

import * as React from "react";
const SvgWarningFilledIcon = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 16 16"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#WarningFilledIcon_svg__a)">
      <path fill="#000" d="M6 4h4v10H6z" />
      <path
        d="M8 1c.445 0 .854.234 1.079.619l6.75 11.5A1.248 1.248 0 0 1 14.75 15H1.25c-.447 0-.863-.24-1.085-.628a1.255 1.255 0 0 1 .007-1.253l6.75-11.5A1.248 1.248 0 0 1 8 1Zm0 3.5c-.416 0-.87.334-.87.75v4c0 .416.454.75.87.75s.88-.334.88-.75v-4c0-.416-.464-.75-.88-.75ZM9 12a.999.999 0 1 0-2 0 .999.999 0 1 0 2 0Z"
        fill="inherit"
      />
    </g>
    <defs>
      <clipPath id="WarningFilledIcon_svg__a">
        <path fill="#fff" d="M0 0h16v16H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgWarningFilledIcon;

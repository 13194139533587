import * as React from "react";
const SvgCircleCheckedIcon = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 16 16"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#CircleCheckedIcon_svg__a)">
      <path fill="#fff" d="M4 3h8v10H4z" />
      <path
        d="M8 16a8 8 0 0 0 8-8 8 8 0 0 0-8-8 8 8 0 0 0-8 8 8 8 0 0 0 8 8Zm3.531-9.469-4 4a.747.747 0 0 1-1.06 0l-2-2a.75.75 0 0 1 1.06-1.06L7 8.942l3.469-3.472a.75.75 0 0 1 1.06 1.06l.002.002Z"
        fill="inherit"
      />
    </g>
    <defs>
      <clipPath id="CircleCheckedIcon_svg__a">
        <path fill="#fff" d="M0 0h16v16H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgCircleCheckedIcon;

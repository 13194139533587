import tokens from "../../tokens/json/variables.json";

export const ContainerStyles = {
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  "&[data-inline=true]": {
    flexDirection: "row",
    alignItems: "center",
    columnGap: tokens.spacing15,

    "> div:first-child": {
      paddingBottom: 0,
    },
    "> div:nth-child(3)": {
      width: tokens.sizing8,
      paddingTop: 0,

      svg: {
        margin: "0 auto",
      },

      "&[data-large=true] svg": {
        width: tokens.sizing4,
        height: tokens.sizing4,
      },
    },
  },
};

export const LabelStyles = {
  paddingBottom: tokens.spacing15,
  color: tokens.textTextSecondary,
  fontFamily: tokens.labelFontFamily,
  fontSize: tokens.labelFontSize,
  fontWeight: tokens.fontWeightsSourceSansPro1,
  lineHeight: tokens.labelLineHeight,
  letterSpacing: tokens.labelLetterSpacing,
  paragraphSpacing: tokens.labelParagraphSpacing,
  maxWidth: "100%",
  whiteSpace: "nowrap",
  textOverflow: "ellipsis",
  overflow: "hidden",
};

export const BarStyles = {
  position: "relative",
  width: "100%",
  height: tokens.sizing1,
  backgroundColor: tokens.layerLayerHover02,
  borderRadius: tokens.borderRadiusFull,
  overflow: "hidden",
  "&[data-large=true]": {
    height: tokens.sizing2,
  },
};

export const BarInnerStyles = {
  position: "absolute",
  height: "100%",
  backgroundColor: tokens.layerLayerSelectedInverse,
  borderRadius: tokens.borderRadiusFull,
  transition: "0.5s ease",
  "&[data-type=info]": {
    backgroundColor: tokens.supportSupportInfo,
  },
  "&[data-type=success]": {
    backgroundColor: tokens.supportSupportSuccess,
  },
  "&[data-type=error]": {
    backgroundColor: tokens.supportSupportError,
  },
  "&[data-type=warning]": {
    backgroundColor: tokens.supportSupportWarning,
  },
};

export const StatusStyles = {
  padding: `${tokens.spacing1} 0px 0px 0px`,
  color: tokens.textTextPrimary,

  fontFamily: tokens.labelFontFamily,
  fontWeight: tokens.labelFontWeight,
  lineHeight: tokens.labelLineHeight,
  fontSize: tokens.labelFontSize,
  letterSpacing: tokens.labelLetterSpacing,
  paragraphSpacing: tokens.labelParagraphSpacing,
  textCase: tokens.labelTextCase,
  textDecoration: tokens.labelTextDecoration,

  svg: {
    width: tokens.sizing3,
    height: tokens.sizing3,
  },

  variants: {
    error: {
      true: {
        color: tokens.textTextError,
      },
    },
  },
};

import React from "react";
import PropTypes from "prop-types";
import Button from "../Button";
import Tooltip from "../Tooltip";
import {
  ActionsContainer,
  Description,
  EmptyStateContainer,
  FootNoteContainer,
  ImageContainer,
  TextContainer,
  Title,
} from "./EmptyState.styles";

const EmptyState = (props) => {
  const {
    showImage = false,
    image,
    showText,
    title,
    description,
    showActions,
    primaryButtonLabel = "Primary",
    onPrimaryAction,
    showSecondaryButton,
    secondaryButtonLabel = "Secondary",
    onSecondaryAction,
    children,
    primaryTooltipProps,
    secondaryTooltipProps,
    primaryButtonProps,
    secondaryButtonProps,
    ...rest
  } = props;
  return (
    <EmptyStateContainer {...rest}>
      {showImage &&
        (image ? (
          <ImageContainer data-testid="emptyState_imageContainer">
            {image}
          </ImageContainer>
        ) : null)}
      {showText && (
        <TextContainer data-testid="emptyState_textContainer">
          {title ? <Title data-testid="emptyState_title">{title}</Title> : null}
          {description ? (
            <Description data-testid="emptyState_desc">
              {description}
            </Description>
          ) : null}
        </TextContainer>
      )}
      {showActions && (
        <ActionsContainer data-testid="emptyState_actionsContainer">
          {showSecondaryButton && (
            <Tooltip {...secondaryTooltipProps}>
              <Button
                color="secondary"
                {...secondaryButtonProps}
                data-testid="emptyState_secondary_button"
                onClick={onSecondaryAction}
              >
                {secondaryButtonProps?.children || secondaryButtonLabel}
              </Button>
            </Tooltip>
          )}
          <Tooltip {...primaryTooltipProps}>
            <Button
              {...primaryButtonProps}
              data-testid="emptyState_primary_button"
              onClick={onPrimaryAction}
            >
              {primaryButtonProps?.children || primaryButtonLabel}
            </Button>
          </Tooltip>
        </ActionsContainer>
      )}
      {children ? (
        <FootNoteContainer data-testid="emptyState_footNoteContainer">
          {children}
        </FootNoteContainer>
      ) : null}
    </EmptyStateContainer>
  );
};

EmptyState.propTypes = {
  showImage: PropTypes.bool,
  image: PropTypes.node,
  showText: PropTypes.bool,
  title: PropTypes.string,
  description: PropTypes.string,
  showActions: PropTypes.bool,
  primaryButtonLabel: PropTypes.string,
  onPrimaryAction: PropTypes.func,
  showSecondaryButton: PropTypes.bool,
  secondaryButtonLabel: PropTypes.string,
  onSecondaryAction: PropTypes.func,
  children: PropTypes.node,
  primaryTooltipProps: PropTypes.oneOfType([PropTypes.object]),
  secondaryTooltipProps: PropTypes.oneOfType([PropTypes.object]),
  primaryButtonProps: PropTypes.oneOfType([PropTypes.object]),
  secondaryButtonProps: PropTypes.oneOfType([PropTypes.object]),
};
export default EmptyState;

import React, { useRef } from "react";
import PropTypes from "prop-types";
import { styled } from "@stitches/react";
import * as Portal from "@radix-ui/react-portal";
import CloseIcon from "../../icons/CloseIcon";
import {
  PopUpContainerStyles,
  PopUpHeaderStyles,
  PopUpTextContainerStyles,
  PopUpDescriptionStyles,
  PopUpTitleStyles,
  PortalRootStyles,
  CloseIconStyles,
  PopUpBodyStyles,
  PopUpFooterContainerStyles,
  PrimaryActionContainerStyles,
  PopUpTitleIconStyles,
} from "./PopUp.styles";
import Button from "../Button/Button";
import useIsOverflow from "../../customHooks/overFlow";
import useEscapeKey from "../../customHooks/useEscapeKey";

const StyledPortalRoot = styled(Portal.Root, PortalRootStyles);
const PopUpContainer = styled("div", PopUpContainerStyles);
const PopUpHeader = styled("div", PopUpHeaderStyles);
const PopUpTitleIcon = styled("div", PopUpTitleIconStyles);
const CloseIconContainer = styled("div", CloseIconStyles);
const PopUpTextContainer = styled("div", PopUpTextContainerStyles);
const PopUpDescription = styled("div", PopUpDescriptionStyles);
const PopUpTitle = styled("div", PopUpTitleStyles);
const PopUpBody = styled("div", PopUpBodyStyles);
const PopUpFooterContainer = styled("div", PopUpFooterContainerStyles);
const PrimaryActionContainer = styled("div", PrimaryActionContainerStyles);
const PopUp = (props) => {
  const {
    id,
    open,
    setOpen,
    size = "small",
    description,
    title,
    titleIcon,
    children,
    showFooter = false,
    onClose,
    cancelButtonLabel = "Button",
    cancelButtonProps = {},
    acceptButtonLabel = "Button",
    acceptButtonProps = {},
    secondaryButtonLabel = "Button",
    secondaryButtonProps = {},
    onAccept,
    onCancel,
    onSecondaryAction,
    showSecondaryButton = false,
    dimmer = false,
    onEscapeKeyAction,
  } = props;
  const ref = useRef();
  const isOverflow = useIsOverflow(ref);
  const handleClose = (event) => {
    event.preventDefault();
    if (setOpen) setOpen(false);
    if (onClose) onClose(event);
  };
  const handleEscAction = (event) => {
    event.preventDefault();
    if (onEscapeKeyAction) onEscapeKeyAction(event);
    else handleClose(event);
  };
  useEscapeKey(handleEscAction);

  return (
    <StyledPortalRoot data-testid="PopUp_root" visible={open} dimmer={dimmer}>
      <PopUpContainer id={`PopUp_${id}`} data-testid="PopUp" size={size}>
        <PopUpHeader>
          <PopUpTextContainer>
            {titleIcon && (
              <PopUpTitleIcon data-testid="PopUp_title_icon">
                {titleIcon}
              </PopUpTitleIcon>
            )}
            <PopUpTitle data-testid="PopUp_title">{title}</PopUpTitle>
            <CloseIconContainer
              id={`PopUp_${id}_closeIcon`}
              data-testid="PopUp_closeIcon"
              onClick={(event) => handleClose(event)}
            >
              <CloseIcon />
            </CloseIconContainer>
          </PopUpTextContainer>
          {description && (
            <PopUpDescription data-testid="PopUp_description">
              {description}
            </PopUpDescription>
          )}
        </PopUpHeader>
        {children && (
          <PopUpBody overflow={isOverflow} ref={ref}>
            {children}
          </PopUpBody>
        )}
        {showFooter && (
          <PopUpFooterContainer
            secondary={showSecondaryButton}
            overflow={isOverflow}
          >
            {showSecondaryButton && (
              <Button
                data-testid="PopUp_secondary_button"
                {...secondaryButtonProps}
                onClick={onSecondaryAction}
              >
                {secondaryButtonLabel}
              </Button>
            )}
            <PrimaryActionContainer>
              <Button
                data-testid="PopUp_cancel_button"
                {...cancelButtonProps}
                color="secondary"
                onClick={onCancel}
              >
                {cancelButtonLabel}
              </Button>
              <Button
                data-testid="PopUp_accept_button"
                {...acceptButtonProps}
                onClick={onAccept}
              >
                {acceptButtonLabel}
              </Button>
            </PrimaryActionContainer>
          </PopUpFooterContainer>
        )}
      </PopUpContainer>
    </StyledPortalRoot>
  );
};

PopUp.propTypes = {
  id: PropTypes.string,
  children: PropTypes.node,
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  dimmer: PropTypes.bool,
  size: PropTypes.oneOf(["extraSmall", "small", "medium", "large"]),
  description: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  title: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  titleIcon: PropTypes.node,
  showFooter: PropTypes.bool,
  onClose: PropTypes.func,
  cancelButtonLabel: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  cancelButtonProps: PropTypes.shape({
    color: PropTypes.string,
    size: PropTypes.string,
    disabled: PropTypes.bool,
    hidden: PropTypes.bool,
    id: PropTypes.string,
  }),
  acceptButtonLabel: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  acceptButtonProps: PropTypes.shape({
    color: PropTypes.string,
    size: PropTypes.string,
    disabled: PropTypes.bool,
    hidden: PropTypes.bool,
    id: PropTypes.string,
  }),
  secondaryButtonLabel: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  secondaryButtonProps: PropTypes.shape({
    color: PropTypes.string,
    size: PropTypes.string,
    disabled: PropTypes.bool,
    hidden: PropTypes.bool,
    id: PropTypes.string,
  }),
  onAccept: PropTypes.func,
  onCancel: PropTypes.func,
  onSecondaryAction: PropTypes.func,
  showSecondaryButton: PropTypes.bool,
  onEscapeKeyAction: PropTypes.func,
};

export default PopUp;

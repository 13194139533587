import React, { useMemo } from "react";
import PropTypes from "prop-types";
import Dropdown from "../../Dropdown";

const DefaultColumnFilter = ({
  column: { filterValue, setFilter, preFilteredRows, id, Header },
}) => {
  // Calculate the options for filtering
  // using the preFilteredRows
  const options = useMemo(() => {
    const uniqueOptions = new Set();
    preFilteredRows.forEach((row) => {
      const value = row.values[id];
      if (Array.isArray(value)) {
        value?.forEach((cellValue) => {
          if (cellValue !== null && cellValue !== undefined) {
            uniqueOptions.add(cellValue);
          }
        });
      } else if (value !== null && value !== undefined) {
        uniqueOptions.add(value);
      }
    });
    return [...uniqueOptions.values()];
  }, [id, preFilteredRows]);

  return (
    <Dropdown
      multiple
      filterEnabled
      inline
      entity={id}
      options={(options || []).map((i) => ({ id: i, label: i }))}
      value={filterValue || []}
      onValueChange={(vals) => {
        setFilter(vals);
      }}
      style={{ backgroundColor: "white" }}
      onClick={(e) => {
        e.stopPropagation();
      }}
      data-testid={`Filter_${id}`}
      customLabel={Header}
    />
  );
};

DefaultColumnFilter.propTypes = {
  column: PropTypes.shape({
    filterValue: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string,
      PropTypes.array,
      PropTypes.bool,
      PropTypes.object,
    ]),
    setFilter: PropTypes.func,
    preFilteredRows: PropTypes.instanceOf(Array),
    id: PropTypes.string,
    Header: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.func,
      PropTypes.node,
    ]),
  }),
};

export default DefaultColumnFilter;

import React from "react";
import PropTypes from "prop-types";
import { styled } from "@stitches/react";
import Search from "../../Search/Search";

const TextInputWrapper = styled("div", {
  display: "flex",
  alignItems: "center",
});

const DefaultColumnSearch = ({
  column: { filterValue, setFilter, Header, id },
}) => {
  return (
    <TextInputWrapper>
      <Search
        showSearchIcon={false}
        id={id}
        value={filterValue || ""}
        onChange={(e) => {
          setFilter(e.target.value ? [e.target.value] : "");
        }}
        data-testid={`${Header}_search`}
        placeholder={Header}
      />
    </TextInputWrapper>
  );
};

DefaultColumnSearch.propTypes = {
  column: PropTypes.shape({
    filterValue: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string,
      PropTypes.array,
      PropTypes.bool,
      PropTypes.object,
    ]),
    setFilter: PropTypes.func,
    id: PropTypes.string,
    Header: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.func,
      PropTypes.node,
    ]),
  }),
};

export default DefaultColumnSearch;

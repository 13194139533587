import React from "react";
import { styled } from "@stitches/react";
import { CollapsedLiStyles } from "./BreadCrumbs.styles";

const CollapsedLi = styled("li", CollapsedLiStyles);

const BreadcrumbCollapsed = (props) => {
  return (
    <CollapsedLi {...props} data-testid="breadCrumbCollapsed">
      ...
    </CollapsedLi>
  );
};

export default BreadcrumbCollapsed;

import * as React from "react";
const SvgTrashIcon = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 16 16"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M14.25 2.5a.76.76 0 0 1 .75.75.74.74 0 0 1-.75.75h-.375l-.75 10.156c-.094 1.063-.938 1.844-2 1.844H4.844c-1.063 0-1.907-.781-2-1.844L2.094 4H1.75A.722.722 0 0 1 1 3.25a.74.74 0 0 1 .75-.75h2.156L5.062.781A1.792 1.792 0 0 1 6.532 0h2.905c.594 0 1.157.313 1.47.781L12.061 2.5h2.188Zm-7.719-1a.263.263 0 0 0-.218.125l-.594.875h4.531l-.594-.875a.263.263 0 0 0-.219-.125H6.531ZM12.375 4H3.594l.75 10.063c.031.25.25.437.5.437h6.281c.25 0 .469-.188.5-.438L12.375 4Z"
      fill="inherit"
    />
  </svg>
);
export default SvgTrashIcon;

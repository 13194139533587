import variables from "../../../../tokens/json/variables.json";

export const PageSelectorRootStyles = {
  display: "flex",
  flexDirection: "row",
  justifyContent: "center",
  alignItems: "center",
  padding: "0px",
  gap: "6px",
};

export const PageGroupStyles = {
  display: "flex",
  flexDirection: "row",
  justifyContent: "center",
  alignItems: "center",
  padding: "0px",
  gap: "4px",
};

export const PageButtonStyles = {
  height: "32px",
  padding: "0px 16px",
  borderRadius: "4px",
  display: "flex",
  alignItems: "center",
  cursor: "pointer",

  // typography
  fontFamily: variables.bodyShort01FontFamily,
  fontWeight: variables.bodyShort01FontWeight,
  lineHeight: variables.bodyShort01LineHeight,
  fontSize: variables.bodyShort01FontSize,
  letterSpacing: variables.bodyShort01LetterSpacing,
  paragraphSpacing: variables.bodyShort01ParagraphSpacing,
  textCase: variables.bodyShort01TextCase,
  textDecoration: variables.bodyShort01TextDecoration,

  variants: {
    selected: {
      true: {
        color: variables.textTextOnColor,
        background: variables.buttonButtonGhostSelected,
      },
    },
  },
};

export const ArrowButtonStyles = {
  boxSizing: "border-box",
  padding: "0px 8px",
  height: "32px",
  width: "32px",
  borderRadius: "4px",
  display: "flex",
  alignItems: "center",
  cursor: "pointer",

  fill: variables.iconIconPrimary,
  color: variables.iconIconPrimary,

  "& .pageNavigatorIcon": {
    height: variables.sizing4,
    width: variables.sizing4,
  },

  variants: {
    disabled: {
      true: {
        cursor: "not-allowed",

        fill: variables.iconIconDisabled,
        color: variables.iconIconDisabled,
      },
    },
  },
};

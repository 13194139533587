import * as React from "react";
const SvgChevronLeftIcon = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 16 16"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="m7.656 12.125-3.781-4.25 3.781-4.25a.71.71 0 0 0 .188-.5c0-.187-.063-.406-.219-.531-.313-.281-.813-.25-1.063.031l-4.25 4.75c-.28.281-.28.688 0 1l4.22 4.75c.28.281.718.313 1.062.031.312-.218.343-.718.062-1.03Zm.657-4.75c-.282.281-.282.688 0 1l4.218 4.75c.281.281.719.313 1.063.031.281-.25.312-.75.031-1.03l-3.75-4.25 3.781-4.25a.71.71 0 0 0 .188-.5c0-.188-.063-.407-.219-.532a.774.774 0 0 0-1.063.031l-4.25 4.75Z"
      fill="inherit"
    />
  </svg>
);
export default SvgChevronLeftIcon;

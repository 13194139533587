import tokens from "../../tokens/json/variables.json";

export const InfoIconStyles = {
  width: tokens.sizing3,
  height: tokens.sizing3,
  marginLeft: tokens.spacing2,
  fill: tokens.iconIconSecondary,
};

export const LabelContainerStyles = {
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "flex-start",
  paddingBottom: tokens.spacing15,
};

export const LabelStyles = {
  color: tokens.textTextSecondary,
  fontFamily: tokens.labelFontFamily,
  fontSize: tokens.labelFontSize,
  fontWeight: tokens.labelFontWeight,
  lineHeight: tokens.labelLineHeight,
  letterSpacing: tokens.labelLetterSpacing,
  paragraphSpacing: tokens.labelParagraphSpacing,
  variants: {
    disabled: {
      true: {
        color: tokens.textTextDisabled,
      },
    },
    required: {
      true: {
        "&::after": {
          content: " *",
          color: tokens.textTextError,
          fontFamily: tokens.labelFontFamily,
          fontSize: tokens.labelFontSize,
          fontWeight: tokens.labelFontWeight,
          lineHeight: tokens.labelLineHeight,
          letterSpacing: tokens.labelLetterSpacing,
          paragraphSpacing: tokens.labelParagraphSpacing,
        },
      },
    },
  },
};

export const SliderContainerStyles = {
  display: "flex",
  alignItems: "center",
  gap: tokens.spacing15,
};

export const ValueStyles = {
  color: tokens.textTextPrimary,
  fontSize: tokens.bodyShort01FontSize,
  fontFamily: tokens.bodyShort01FontFamily,
  fontWeight: tokens.bodyShort01FontWeight,
  lineHeight: tokens.bodyShort01LineHeight,
  letterSpacing: tokens.bodyShort01LetterSpacing,
  textDecoration: tokens.bodyShort01TextDecoration,
  paragraphSpacing: tokens.bodyShort01ParagraphSpacing,
  variants: {
    disabled: {
      true: {
        color: tokens.textTextDisabled,
      },
    },
  },
};

export const RootStyles = {
  position: "relative",
  display: "flex",
  alignItems: "center",
  userSelect: "none",
  touchAction: "none",
  width: 200,
  height: 20,
};

export const TrackStyles = {
  backgroundColor: tokens.borderBorderSubtle02,
  position: "relative",
  flexGrow: 1,
  height: 2,
  "&:focus": {
    backgroundColor: "red",
  },
  variants: {
    disabled: {
      true: {
        backgroundColor: tokens.borderBorderDisabled,
        span: {
          backgroundColor: tokens.borderBorderDisabled,
        },
      },
    },
  },
};

export const RangeStyles = {
  position: "absolute",
  backgroundColor: tokens.backgroundBackgroundBrand,
  borderRadius: "9999px",
  height: "100%",
};

export const ThumbStyles = {
  boxSizing: "border-box",
  width: tokens.sizing2,
  height: tokens.sizing2,
  display: "none",
  border: "none",
  borderRadius: tokens.borderRadiusFull,
  backgroundColor: tokens.backgroundBackgroundBrand,
  "&:focus-visible": {
    outline: "none",
  },
  "&::after": {
    display: "block",
    content: "attr(data-value)",
    position: "absolute",
    bottom: "calc(100% + 9px)",
    left: "50%",
    transform: "translateX(-50%)",
    borderRadius: "2px",
    boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.2)",
    background: tokens.backgroundBackground,
    color: tokens.textTextPrimary,
    padding: "2px 16px",
    fontSize: tokens.bodyShort01FontSize,
    fontFamily: tokens.bodyShort01FontFamily,
    fontWeight: tokens.bodyShort01FontWeight,
    lineHeight: tokens.bodyShort01LineHeight,
    letterSpacing: tokens.bodyShort01LetterSpacing,
    textDecoration: tokens.bodyShort01TextDecoration,
    paragraphSpacing: tokens.bodyShort01ParagraphSpacing,
  },
  "&::before": {
    content: "",
    position: "absolute",
    bottom: "calc(100% + 3px)",
    left: "50%",
    transform: "translateX(-50%)",
    borderWidth: "3px 6px",
    borderStyle: "solid",
    borderColor: "white transparent transparent transparent",
    zIndex: 1,
  },
};

export const ThumbContainerStyles = {
  position: "relative",
  boxSizing: "border-box",
  width: tokens.sizing3,
  height: tokens.sizing3,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  border: `solid ${tokens.borderBorderInteractive}`,
  borderWidth: tokens.borderWidth1,
  borderRadius: tokens.borderRadiusFull,
  backgroundColor: tokens.backgroundBackground,
  "&:hover": { backgroundColor: tokens.backgroundBackgroundBrand },
  "&:focus-visible": {
    outline: "none",
  },
  "&:active": {
    backgroundColor: tokens.backgroundBackgroundBrand,
    border: "none",
    "div::after": {
      display: "block",
    },
  },
  "&:focus": {
    border: "none",
    "&:hover": { backgroundColor: tokens.backgroundBackground },
    "&::after": {
      content: "",
      boxSizing: "border-box",
      position: "absolute",
      width: tokens.sizing4,
      height: tokens.sizing4,
      border: `solid ${tokens.focusFocus}`,
      borderWidth: tokens.borderWidth2,
      borderRadius: tokens.borderRadiusFull,
      left: "50%",
      top: "50%",
      transform: "translate(-50%, -50%)",
    },
    div: {
      display: "block",
    },
  },
  "&:focus:active": {
    backgroundColor: tokens.backgroundBackgroundBrand,
    "&::after": {
      all: "unset",
      display: "none",
    },
  },
  "&[data-disabled]": {
    borderColor: tokens.borderBorderDisabled,
    pointerEvents: "none",
  },
  "&[readonly]": {
    display: "none",
  },
  variants: {
    double: {
      true: {
        "&:focus": {
          backgroundColor: tokens.backgroundBackground,
          border: `solid ${tokens.borderBorderInteractive}`,
          borderWidth: tokens.borderWidth1,
          "&::after": {
            all: "unset",
            display: "none",
          },
          div: {
            backgroundColor: tokens.backgroundBackground,
          },
          "&:hover": {
            backgroundColor: tokens.backgroundBackgroundBrand,
            div: {
              backgroundColor: tokens.backgroundBackgroundBrand,
            },
          },
          "&:active": {
            borderWidth: tokens.borderWidth1,
            div: {
              backgroundColor: tokens.backgroundBackgroundBrand,
            },
          },
        },
      },
    },
  },
};

export const HelperTextStyles = {
  padding: `${tokens.spacing1} 0px 0px 0px`,
  color: tokens.textTextSecondary,

  fontFamily: tokens.labelFontFamily,
  fontWeight: tokens.labelFontWeight,
  lineHeight: tokens.labelLineHeight,
  fontSize: tokens.labelFontSize,
  letterSpacing: tokens.labelLetterSpacing,
  paragraphSpacing: tokens.labelParagraphSpacing,
  textCase: tokens.labelTextCase,
  textDecoration: tokens.labelTextDecoration,

  variants: {
    disabled: {
      true: {
        color: tokens.textTextDisabled,
      },
    },
    error: {
      true: {
        color: tokens.textTextError,
      },
    },
  },
};

import tokens from "../../../tokens/json/variables.json";

export const NodeRootStyles = {
  borderBottom: `${tokens.borderWidth1} solid ${tokens.borderBorderSubtle00}`,
  background: tokens.layerLayer02,

  // spacing
  boxSizing: "border-box",
  display: "inline-flex",
  alignItems: "center",

  color: tokens.textTextOnTable,

  // typography
  fontFamily: tokens.bodyShort01FontFamily,
  fontWeight: tokens.bodyShort01FontWeight,
  lineHeight: tokens.bodyShort01LineHeight,
  fontSize: tokens.bodyShort01FontSize,
  letterSpacing: tokens.bodyShort01LetterSpacing,
  paragraphSpacing: tokens.bodyShort01ParagraphSpacing,
  textCase: tokens.bodyShort01TextCase,
  textDecoration: tokens.bodyShort01TextDecoration,

  "& .rowActions": {
    display: "inline-flex",
    visibility: "hidden",

    // Row Actions icon button fill color
    color: tokens.iconIconPrimary,
    fill: tokens.iconIconPrimary,
  },

  "&:hover": {
    background: tokens.layerLayerHover01,

    "& .rowActions": {
      visibility: "visible",
    },
  },

  variants: {
    selected: {
      true: {
        background: tokens.layerLayer01,

        "&:hover": {
          color: tokens.textTextPrimary,
          background: tokens.backgroundBackgroundSelectedHover,
          borderBottom: `${tokens.borderWidth1} solid ${tokens.borderBorderSubtleSelected01}`,
        },
      },
    },
    disabled: {
      true: {
        color: tokens.textTextOnColorDisabled,
        cursor: "not-allowed",
        pointerEvents: "none",

        "&:hover": {
          // background: "tranparent", doesn't work
        },
      },
    },
    size: {
      compact: {
        height: tokens.sizing8,
      },
      extraSmall: {
        height: tokens.sizing10,
      },
      small: {
        height: tokens.sizing12,
      },
      medium: {
        height: tokens.sizing16,
      },
      large: {
        height: tokens.sizing18,
      },
      extraLarge: {
        height: tokens.sizing20,
      },
    },
    isLastNode: {
      true: {
        border: "none",
      },
    },
  },
};

export const NodeLabelStyles = {
  padding: "8px 16px",
};

export const NodeActionsContainerStyles = {
  display: "flex",
  gap: "8px",
  marginLeft: "auto",
  padding: "8px 10px",
};

export const NodeExpandedAreaStyles = {
  paddingLeft: "46px",
  display: "grid",

  borderBottom: `${tokens.borderWidth1} solid ${tokens.borderBorderSubtle00}`,
  background: tokens.layerLayer02,
};

export const NodeIconButtonStyles = {
  all: "unset",
  cursor: "pointer",
  fill: tokens.iconIconSecondary,
  color: tokens.iconIconSecondary,
  padding: tokens.spacing4,
  height: tokens.sizing35,
  width: tokens.sizing35,
};

export const FallbackLabelStyles = {
  display: "inline-flex",
  alignItems: "center",
  gap: "6px",
  padding: "10px",
  color: tokens.textTextOnTable,

  // typography
  fontFamily: tokens.bodyShort01FontFamily,
  fontWeight: tokens.bodyShort01FontWeight,
  lineHeight: tokens.bodyShort01LineHeight,
  fontSize: tokens.bodyShort01FontSize,
  letterSpacing: tokens.bodyShort01LetterSpacing,
  paragraphSpacing: tokens.bodyShort01ParagraphSpacing,
  textCase: tokens.bodyShort01TextCase,
  textDecoration: tokens.bodyShort01TextDecoration,
};

export const FallbackContainerStyles = {
  marginLeft: "-46px",
  background: tokens.layerLayer02,
  color: tokens.buttonButtonPrimary,
  fill: tokens.buttonButtonPrimary,

  // spacing
  boxSizing: "border-box",
  display: "inline-flex",
  alignItems: "center",
  justifyContent: "center",

  variants: {
    size: {
      compact: {
        height: tokens.sizing8,
      },
      extraSmall: {
        height: tokens.sizing10,
      },
      small: {
        height: tokens.sizing12,
      },
      medium: {
        height: tokens.sizing16,
      },
      large: {
        height: tokens.sizing18,
      },
      extraLarge: {
        height: tokens.sizing20,
      },
    },
  },
};

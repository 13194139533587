import tokens from "../../../tokens/json/variables.json";

export const SubMenuTriggerStyles = {
  display: "block",
  boxSizing: "border-box",
  "&.subMenuCollapsed": {
    backgroundColor: tokens.layerLayerSelectedInverse,
    borderRadius: tokens.borderRadius2,
    color: tokens.textTextOnColor,
    fill: tokens.iconIconOnColor,
  },
  variants: {
    selected: {
      true: {
        backgroundColor: tokens.backgroundBackgroundInverseSelected,
        borderRadius: tokens.borderRadius2,
        color: tokens.textTextOnColor,
        fill: tokens.iconIconOnColor,
      },
    },
  },
};

export const LabelStyles = {
  fontFamily: tokens.bodyShort01FontFamily,
  fontStyle: "normal",
  fontWeight: tokens.bodyShort01FontWeight,
  fontSize: tokens.bodyShort01FontSize,
  lineHeight: tokens.bodyShort01LineHeight,
  letterSpacing: tokens.bodyShort01LetterSpacing,
  flexGrow: "2",
  "&.selected": {
    color: tokens.textTextOnColor,
  },
};

export const IconContainerStyles = {
  all: "unset",
  width: tokens.sizing4,
  height: tokens.sizing4,
  fill: tokens.iconIconInverse,
  "&.selected": {
    fill: tokens.iconIconOnColor,
    backgroundColor: tokens.backgroundBackgroundInverseSelected,
  },
  "&:hover": {
    fill: tokens.iconIconOnColor,
  },
};

export const SubMenuItemContainerStyles = {
  all: "unset",
  display: "flex",
  alignItems: "center",
  boxSizing: "border-box",
  gap: tokens.spacing4,
  height: tokens.sizing8,
  padding: `${tokens.spacing15} ${tokens.spacing2} ${tokens.spacing15} ${tokens.spacing4}`,
  color: tokens.gray40,
  fill: tokens.iconIconInverse,
  "&:hover": {
    backgroundColor: tokens.backgroundBackgroundInverseHover,
    color: tokens.textTextOnColor,
    fill: tokens.iconIconOnColor,
  },
  "&.selectedSubMenu": {
    borderRadius: tokens.borderRadius2,
    color: tokens.textTextOnColor,
    fill: tokens.iconIconOnColor,
  },
};

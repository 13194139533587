import * as React from "react";
const SvgSortUpIcon = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 16 16"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="m2.207 6.734 5.25-5.5a.746.746 0 0 1 1.086-.001l5.25 5.5a.751.751 0 0 1-1.086 1.035L8.75 3.622V14.25a.72.72 0 0 1-.722.722c-.386 0-.778-.306-.778-.722V3.622L3.294 7.769a.75.75 0 1 1-1.087-1.034Z"
      fill="inherit"
    />
  </svg>
);
export default SvgSortUpIcon;

import * as React from "react";
const SvgSortDownIcon = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 16 16"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="m13.794 9.269-5.25 5.5a.75.75 0 0 1-1.087-.001l-5.25-5.5c-.285-.327-.275-.774.025-1.062a.75.75 0 0 1 1.06.026l3.958 4.146V1.751c0-.414.336-.75.722-.75.386 0 .778.336.778.75v10.628l3.956-4.147a.75.75 0 0 1 1.06-.025c.303.287.312.734.028 1.062Z"
      fill="inherit"
    />
  </svg>
);
export default SvgSortDownIcon;

import * as React from "react";
const SvgCircleFilledIcon = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 14 14"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#CircleFilledIcon_svg__a)">
      <path
        d="M7 14a7 7 0 0 0 7-7 7 7 0 0 0-7-7 7 7 0 0 0-7 7 7 7 0 0 0 7 7Zm0-4.375a2.626 2.626 0 1 1 .001-5.251A2.626 2.626 0 0 1 7 9.625Z"
        fill="inherit"
      />
    </g>
    <defs>
      <clipPath id="CircleFilledIcon_svg__a">
        <path fill="#fff" d="M0 0h14v14H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgCircleFilledIcon;

import React, { useEffect, useMemo, useRef, useState } from "react";
import { styled } from "@stitches/react";
import PropTypes from "prop-types";
import {
  useTable,
  useFilters,
  useGlobalFilter,
  useSortBy,
  usePagination,
  useRowSelect,
  useFlexLayout,
  useResizeColumns,
  useColumnOrder,
} from "react-table";
import { useDimensions } from "../../customHooks";
import GlobalSearch from "./GlobalSearch/GlobalSearch";
import DefaultColumnFilter from "./ColumnFilters/DefaultColumnFilter";
import ActionBar from "./ActionBar/ActionBar";
import Header from "./Header/Header";
import Content from "./Content/Content";
import Paginator from "./Footer/Paginator";
import ColumnFilters from "./ColumnFilters/ColumnFilters";
import { SIZE_VARIENTS, MAX_PAGE_SIZE, PREDEFINED_COLUMNS } from "./Constants";
import RowSelectionBox from "./RowSelectionBox/RowSelectionBox";
import {
  RootStyles,
  TableStyles,
  TableActionsContainerStyles,
  SearchFilterWrapperStyle,
  ColumnSFStyle,
} from "./Table.styles";
import { ColumnSearchIcon, FiltersIcon } from "../../icons";
import EmptyDataFallback from "./Fallbacks/EmptyData";
import EmptyResultsFallback from "./Fallbacks/EmptyResults";
import Button from "../Button/Button";

const Root = styled("div", RootStyles);
const StyledTable = styled("table", TableStyles);
const TableActionsContainer = styled("div", TableActionsContainerStyles);
const ColumnSF = styled("div", ColumnSFStyle);
const SearchFilterWrapper = styled("div", SearchFilterWrapperStyle);

const tableHooks = (hooks) => {
  hooks?.visibleColumns?.push((inputColumns) => [
    {
      id: PREDEFINED_COLUMNS.ROW_SELECTION_COLUMN,
      /* eslint-disable-next-line react/prop-types */
      Header: ({ getToggleAllPageRowsSelectedProps }) => (
        <RowSelectionBox
          headerComponent
          {...getToggleAllPageRowsSelectedProps()} // eslint-disable-line react/prop-types
        />
      ),
      /* eslint-disable-next-line react/prop-types */
      Cell: ({ row }) => (
        <RowSelectionBox
          disabled={row?.original?.disabled} // eslint-disable-line react/prop-types
          {...row.getToggleRowSelectedProps()} // eslint-disable-line react/prop-types
        />
      ),
      width: "100px",
      disableResizing: true,
      disableFilters: true,
    },
    ...inputColumns,

    // Column customisation & Row Actions Column
    {
      id: PREDEFINED_COLUMNS.ROW_ACTIONS_COLUMN,
      disableResizing: true,
      disableFilters: true,
    },
  ]);
};

const Table = React.forwardRef(
  (
    {
      columns,
      data,
      identifier,
      label,
      CustomActions,
      RowActions,
      onRowClick,
      size,
      defaultHiddenColumns,
      fixedColumns,
      disableFilters,
      disableSearch,
      disableActions,
      disableRowSelection,
      disableRowActions,
      disableColumnCustomisation,
      defaultPageSize,
      rowsPerPageOptions,
      pagination,
      sortBy,
      autoResetFilters,
      selectedRowIndex,
      id,
      emptyStateWidth,
      savedFilters,
      onFiltersChange,
      savedGlobalFilter,
      onGlobalFilterChange,
      onPageSizeChange,
      emptyDataTitle,
      ...props
    },
    forwardedRef
  ) => {
    const initialPageSize = useMemo(
      () => (pagination ? defaultPageSize : data?.length || MAX_PAGE_SIZE),
      [pagination, defaultPageSize, data]
    );

    const initialHiddenColumns = useMemo(() => {
      const toBeHidden = [...defaultHiddenColumns];
      if (disableRowSelection) {
        toBeHidden.push(PREDEFINED_COLUMNS.ROW_SELECTION_COLUMN);
      }
      if (disableRowActions) {
        toBeHidden.push(PREDEFINED_COLUMNS.ROW_ACTIONS_COLUMN);
      }
      return [...new Set(toBeHidden)];
    }, [disableRowSelection, disableRowActions, defaultHiddenColumns]);

    const savedHiddenColumns = useMemo(() => {
      const persistedHiddenColumns = localStorage.getItem(
        `${id}_hiddenColumns`
      );
      if (persistedHiddenColumns) {
        let toBeHidden = JSON.parse(persistedHiddenColumns);
        if (disableRowSelection) {
          toBeHidden.push(PREDEFINED_COLUMNS.ROW_SELECTION_COLUMN);
        } else {
          toBeHidden = [...toBeHidden].filter(
            (columnId) => columnId !== PREDEFINED_COLUMNS.ROW_SELECTION_COLUMN
          );
        }
        if (disableRowActions) {
          toBeHidden.push(PREDEFINED_COLUMNS.ROW_ACTIONS_COLUMN);
        } else {
          toBeHidden = [...toBeHidden].filter(
            (columnId) => columnId !== PREDEFINED_COLUMNS.ROW_ACTIONS_COLUMN
          );
        }

        return [...new Set(toBeHidden)];
      }
      return null;
    }, [disableRowSelection, disableRowActions]);

    const initialColumnOrder = useMemo(() => {
      const initialOrder = [...columns].map((column) => {
        let columnId = column?.id;
        if (!columnId && typeof column?.accessor === "string") {
          columnId = column?.accessor;
        }
        return columnId;
      });
      return [
        PREDEFINED_COLUMNS.ROW_SELECTION_COLUMN,
        ...initialOrder,
        PREDEFINED_COLUMNS.ROW_ACTIONS_COLUMN,
      ];
    }, [columns]);

    const savedColumnOrder = useMemo(() => {
      const persistedColumnOrder = localStorage.getItem(`${id}_columnOrder`);
      if (persistedColumnOrder) {
        return JSON.parse(persistedColumnOrder);
      }
      return null;
    }, []);

    const {
      getTableProps,
      getTableBodyProps,
      headerGroups,
      prepareRow,
      setGlobalFilter,
      preGlobalFilteredRows,
      setFilter,
      setAllFilters,

      // pagination
      page,
      pageOptions,
      setPageSize,
      canPreviousPage,
      previousPage,
      canNextPage,
      nextPage,
      gotoPage,

      // selected rows
      selectedFlatRows,
      rows,
      toggleAllRowsSelected,

      state: {
        globalFilter,
        pageIndex,
        pageSize,
        filters,
        hiddenColumns,
        columnOrder,
      },

      // column customization
      allColumns,
      setHiddenColumns,
      setColumnOrder,
    } = useTable(
      {
        columns,
        data,

        // pagination
        initialState: {
          sortBy,
          pageIndex: 0,
          hiddenColumns: savedHiddenColumns || initialHiddenColumns,
          pageSize: initialPageSize,
          columnOrder: savedColumnOrder || initialColumnOrder,
          filters: savedFilters || [],
          globalFilter: savedGlobalFilter || "",
        },
        defaultCanFilter: false,
        defaultColumn: {
          Filter: DefaultColumnFilter,
          filter: "includesValue",
          defaultCanFilter: false,
        },
        autoResetFilters,
        autoResetGlobalFilter: false,
      },
      useFilters,
      useGlobalFilter,
      useSortBy,
      tableHooks,
      usePagination,
      useRowSelect,
      useFlexLayout,
      useResizeColumns,
      useColumnOrder
    );

    const selectedRowIds = selectedFlatRows.map(
      (d) => d?.original?.[identifier]
    );

    const tableTotal = useMemo(() => (rows || []).length, [rows]);

    const actionsRef = useRef(null);
    const { height, width } = useDimensions(actionsRef);

    const isEmpty = data?.length === 0;

    const [toggleColumnSF, setToggleColumnSF] = useState({
      search: false,
      filter: false,
    });

    useEffect(() => {
      if (typeof onFiltersChange === "function")
        setToggleColumnSF(() =>
          savedFilters.length
            ? {
                ...toggleColumnSF,
                filter: true,
                search: true,
              }
            : toggleColumnSF
        );
    }, [savedFilters]);

    /* resetting initial state values when dependent props are updated */
    useEffect(() => {
      setPageSize(initialPageSize);
    }, [initialPageSize]);

    useEffect(() => {
      setHiddenColumns(savedHiddenColumns || initialHiddenColumns);
    }, [columns, savedHiddenColumns, initialHiddenColumns]);

    useEffect(() => {
      setColumnOrder(savedColumnOrder || initialColumnOrder);
    }, [columns, savedColumnOrder, initialColumnOrder]);
    /* resetting initial state values when dependent props are updated */

    let tableBody = (
      <>
        <Content
          page={page}
          prepareRow={prepareRow}
          {...getTableBodyProps()}
          RowActions={RowActions}
          onRowClick={onRowClick}
          size={size}
          selectedRowIndex={selectedRowIndex}
          toggleAllRowsSelected={toggleAllRowsSelected}
        />
        {pagination && (
          <Paginator
            preGlobalFilteredRows={preGlobalFilteredRows}
            rowsPerPageOptions={rowsPerPageOptions}
            pageIndex={pageIndex}
            pageOptions={pageOptions}
            pageSize={pageSize}
            setPageSize={setPageSize}
            canPreviousPage={canPreviousPage}
            previousPage={previousPage}
            canNextPage={canNextPage}
            nextPage={nextPage}
            gotoPage={gotoPage}
            onPageSizeChange={onPageSizeChange}
          />
        )}
      </>
    );

    if (isEmpty) {
      // fallback when data is empty
      tableBody = (
        <EmptyDataFallback width={emptyStateWidth} title={emptyDataTitle} />
      );
    } else if ((page || []).length === 0) {
      // fallback when data is not empty but search & filter results are empty
      tableBody = <EmptyResultsFallback width={emptyStateWidth} />;
    }

    return (
      <Root {...props} ref={forwardedRef}>
        {!disableActions && (
          <TableActionsContainer ref={actionsRef}>
            <SearchFilterWrapper>
              {/* Global Filter Placement */}
              <GlobalSearch
                label={label}
                tableTotal={tableTotal}
                globalFilter={globalFilter}
                setGlobalFilter={setGlobalFilter}
                disableSearch={disableSearch}
                disableFilters={disableFilters}
                setToggleColumnSF={setToggleColumnSF}
                toggleColumnSF={toggleColumnSF}
                onGlobalFilterChange={onGlobalFilterChange}
              />
              {/* Global Filter Placement */}
              {/* Column Search and Filter Toggle Buttons */}
              {!disableFilters && (
                <ColumnSF data-testid="table_column_search_filter_buttons_container">
                  <Button
                    color="ghost"
                    id="table_column_filter_toggle_button"
                    data-testid="table_column_filter_toggle_button"
                    style={{ width: "32px", padding: "0" }}
                    selected={toggleColumnSF.filter}
                    onClick={() =>
                      setToggleColumnSF({
                        ...toggleColumnSF,
                        filter: !toggleColumnSF.filter,
                      })
                    }
                  >
                    <FiltersIcon />
                  </Button>
                  {allColumns.some((column) => column.filter === "text") ? (
                    <Button
                      color="ghost"
                      id="table_column_search_toggle_button"
                      data-testid="table_column_search_toggle_button"
                      style={{ width: "32px", padding: "0" }}
                      selected={toggleColumnSF.search}
                      onClick={() =>
                        setToggleColumnSF({
                          ...toggleColumnSF,
                          search: !toggleColumnSF.search,
                        })
                      }
                    >
                      <ColumnSearchIcon />
                    </Button>
                  ) : null}
                </ColumnSF>
              )}
              {/* Column Search and Filter Toggle Buttons */}
            </SearchFilterWrapper>

            {/* Column Filters Placement */}
            {!disableFilters && (
              <ColumnFilters
                allColumns={allColumns}
                filters={filters}
                setFilter={setFilter}
                setAllFilters={setAllFilters}
                toggleColumnSF={toggleColumnSF}
                onFiltersChange={onFiltersChange}
              />
            )}
            {/* Column Filters Placement */}

            {/* Action Bar: Selected Rows */}
            {!disableRowSelection && (
              <ActionBar
                selectedRowIds={selectedRowIds}
                CustomActions={CustomActions}
                toggleAllRowsSelected={toggleAllRowsSelected}
              />
            )}
            {/* Action Bar: Selected Rows */}
          </TableActionsContainer>
        )}

        <div
          id="tableWrapper"
          style={{
            width,
            overflowX: "scroll",
            scrollBehavior: "smooth",
            height: `calc(100% - ${height}px)`,
          }}
        >
          <StyledTable {...getTableProps()} style={{ height: "100%" }}>
            <Header
              headerGroups={headerGroups}
              size={size}
              allColumns={allColumns}
              hiddenColumns={hiddenColumns}
              setHiddenColumns={setHiddenColumns}
              defaultHiddenColumns={initialHiddenColumns}
              fixedColumns={fixedColumns}
              disableColumnCustomisation={disableColumnCustomisation}
              id={id}
              initialColumnOrder={initialColumnOrder}
              columnOrder={columnOrder}
              setColumnOrder={setColumnOrder}
            />
            {tableBody}
          </StyledTable>
        </div>
      </Root>
    );
  }
);

Table.propTypes = {
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      Header: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
      accessor: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
      id: PropTypes.string,
      Cell: PropTypes.func,
      disableFilters: PropTypes.bool,
      disableSortBy: PropTypes.bool,
      width: PropTypes.number,
    })
  ),
  data: PropTypes.instanceOf(Array),
  CustomActions: PropTypes.node,
  RowActions: PropTypes.node,
  onRowClick: PropTypes.func,
  size: PropTypes.oneOf(SIZE_VARIENTS),
  identifier: PropTypes.string,
  label: PropTypes.string,
  defaultHiddenColumns: PropTypes.instanceOf(Array),
  fixedColumns: PropTypes.instanceOf(Array),
  disableFilters: PropTypes.bool,
  disableSearch: PropTypes.bool,
  disableActions: PropTypes.bool,
  disableRowSelection: PropTypes.bool,
  disableRowActions: PropTypes.bool,
  disableColumnCustomisation: PropTypes.bool,
  defaultPageSize: PropTypes.number,
  rowsPerPageOptions: PropTypes.arrayOf(PropTypes.number),
  pagination: PropTypes.bool,
  sortBy: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      desc: PropTypes.bool,
    })
  ),
  autoResetFilters: PropTypes.bool,
  selectedRowIndex: PropTypes.number,
  id: PropTypes.string.isRequired,
  emptyStateWidth: PropTypes.string,
  /** To set default filters to the table */
  savedFilters: PropTypes.instanceOf(Array),
  /** callback function invoked when there is change in filters */
  onFiltersChange: PropTypes.func,
  /** To set default global filter to the table */
  savedGlobalFilter: PropTypes.string,
  /** callback function invoked when there is change in global filter */
  onGlobalFilterChange: PropTypes.func,
  onPageSizeChange: PropTypes.func,
  emptyDataTitle: PropTypes.string,
};

Table.defaultProps = {
  data: [],
  size: "small",
  identifier: "id",
  defaultHiddenColumns: [],
  fixedColumns: [],
  disableFilters: false,
  disableSearch: false,
  disableActions: false,
  disableRowSelection: false,
  disableRowActions: false,
  disableColumnCustomisation: false,
  defaultPageSize: 10,
  rowsPerPageOptions: [10, 15, 20, 50, 100],
  pagination: true,
  sortBy: [],
  autoResetFilters: true,
  emptyStateWidth: "351px",
};

export default Table;

import * as React from "react";
const SvgSquareMinusIcon = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 16 16"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M3 1c-1.103 0-2 .897-2 2v10c0 1.103.897 2 2 2h10c1.103 0 2-.897 2-2V3c0-1.103-.897-2-2-2H3Zm2.75 6.25h4.5c.416 0 .75.334.75.75s-.334.75-.75.75h-4.5A.748.748 0 0 1 5 8c0-.416.334-.75.75-.75Z"
      fill="inherit"
    />
  </svg>
);
export default SvgSquareMinusIcon;

import React from "react";
import { styled } from "@stitches/react";
import PropTypes from "prop-types";
import Dropdown from "../../Dropdown";
import PageSelector from "./PageSelector/PageSelector";

import {
  TableFootStyles,
  TableRowStyles,
  PaginatorRootStyles,
  EmptyPlaceHolderStyles,
} from "./Paginator.styles";

const StyledTableFoot = styled("tfoot", TableFootStyles);
const StyledTableRow = styled("tr", TableRowStyles);
const PaginatorRoot = styled("td", PaginatorRootStyles);
const EmptyPlaceHolder = styled("div", EmptyPlaceHolderStyles);

const Paginator = ({
  rowsPerPageOptions,
  pageIndex,
  pageOptions,
  pageSize,
  setPageSize,
  canPreviousPage,
  previousPage,
  canNextPage,
  nextPage,
  gotoPage,
  onPageSizeChange,
}) => {
  return (
    <StyledTableFoot data-testid="Table_Footer">
      <StyledTableRow>
        <PaginatorRoot>
          <EmptyPlaceHolder />
          <PageSelector
            pageIndex={pageIndex}
            pageOptions={pageOptions}
            canPreviousPage={canPreviousPage}
            previousPage={previousPage}
            canNextPage={canNextPage}
            nextPage={nextPage}
            gotoPage={gotoPage}
          />
          <Dropdown
            data-testid="Items_Per_Page"
            value={pageSize}
            onValueChange={(value) => {
              if (typeof onPageSizeChange === "function") {
                onPageSizeChange(value);
              }
              setPageSize(value);
            }}
            options={rowsPerPageOptions.map((option) => ({
              id: option,
              label: `${option} /page`,
            }))}
          />
        </PaginatorRoot>
      </StyledTableRow>
    </StyledTableFoot>
  );
};

Paginator.propTypes = {
  rowsPerPageOptions: PropTypes.arrayOf(PropTypes.number),
  pageIndex: PropTypes.number,
  pageOptions: PropTypes.instanceOf(Array),
  pageSize: PropTypes.number,
  setPageSize: PropTypes.func,
  canPreviousPage: PropTypes.bool,
  previousPage: PropTypes.func,
  canNextPage: PropTypes.bool,
  nextPage: PropTypes.func,
  gotoPage: PropTypes.func,
  onPageSizeChange: PropTypes.func,
};

export default Paginator;

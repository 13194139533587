import { styled } from "@stitches/react";
import token from "../../tokens/json/variables.json";

export const Label = styled("div", {
  color: token.textTextSecondary,
  fontFamily: token.labelFontFamily,
  fontSize: token.labelFontSize,
  fontWeight: token.labelFontWeight,
  lineHeight: token.labelLineHeight,
  letterSpacing: token.labelLetterSpacing,
  paragraphSpacing: token.labelParagraphSpacing,
  paddingBottom: token.sizing2,
  variants: {
    disabled: {
      true: {
        color: token.textTextDisabled,
      },
    },
  },
});

export const HelperText = styled("div", {
  paddingTop: token.spacing1,
  color: token.textTextSecondary,
  fontFamily: token.labelFontFamily,
  fontSize: token.labelFontSize,
  fontWeight: token.labelFontWeight,
  lineHeight: token.labelLineHeight,
  letterSpacing: token.labelLetterSpacing,
  paragraphSpacing: token.labelParagraphSpacing,
  variants: {
    disabled: {
      true: {
        color: token.textTextDisabled,
      },
    },
    error: {
      true: {
        color: token.textTextError,
      },
    },
  },

  compoundVariants: [
    {
      disabled: true,
      error: true,
      css: {
        color: token.textTextDisabled,
      },
    },
  ],
});

export const InputArea = styled("div", {
  boxSizing: "border-box",
  backgroundColor: `${token.white}`,
  paddingInline: `${token.spacing4}`,
  paddingBlock: `${token.spacing3}`,
  color: token.textTextPrimary,
  border: "none",
  outline: `${token.borderWidth1} solid ${token.borderBorderSubtle01}`,
  borderRadius: token.borderRadius1,
  resize: "both",
  overflow: "auto",
  display: "flex",
  flexWrap: "wrap",
  justifyContent: "flex-start",
  alignContent: "flex-start",
  gap: "4px",
  minHeight: token.sizing8,

  fontFamily: token.bodyShort01FontFamily,
  fontSize: token.bodyShort01FontSize,
  fontWeight: token.bodyShort01FontWeight,
  lineHeight: token.bodyShort01LineHeight,
  letterSpacing: token.bodyShort01LetterSpacing,
  paragraphSpacing: token.bodyShort01ParagraphSpacing,
  textDecoration: token.bodyShort01TextDecoration,
  "&::placeholder": {
    color: token.textTextPlaceholder,
  },
  "&:active": {
    color: token.textTextPrimary,
    outline: `${token.borderWidth2} solid ${token.focusFocus}`,
  },
  "&:focus": {
    color: token.textTextPrimary,
    outline: `${token.borderWidth2} solid ${token.focusFocus}`,
  },
  "&:focus-within": {
    color: token.textTextPrimary,
    outline: `${token.borderWidth2} solid ${token.focusFocus}`,
  },

  variants: {
    disabled: {
      true: {
        color: token.textTextDisabled,
        outline: `${token.borderWidth1} solid ${token.borderBorderDisabled}`,
        pointerEvents: "none",
        cursor: "not-allowed",
        "&::placeholder": {
          color: token.textTextDisabled,
        },
      },
    },
    error: {
      true: {
        color: token.textTextPrimary,
        outline: `${token.borderWidth2} solid ${token.supportSupportError}`,
      },
    },
    resizable: {
      false: {
        resize: "none",
      },
    },
    multiline: {
      false: {
        paddingBlock: "7px",
      },
      true: {
        minHeight: "42px",
      },
    },
  },

  compoundVariants: [
    {
      disabled: true,
      error: true,
      css: {
        color: token.textTextDisabled,
        outline: `${token.borderWidth1} solid ${token.borderBorderDisabled}`,
        pointerEvents: "none",
        cursor: "not-allowed",
        "&::placeholder": {
          color: token.textTextDisabled,
        },
      },
    },
  ],
});

export const TagsContainer = styled("div", {
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-start",
  flexWrap: "wrap",
  gap: token.spacing2,
});

export const CustomInput = styled("input", {
  border: "none",
  padding: 0,
  margin: 0,
  flexGrow: "1",
  height: "max-content",
  fontFamily: token.bodyShort01FontFamily,
  fontSize: token.bodyShort01FontSize,
  fontWeight: token.bodyShort01FontWeight,
  lineHeight: token.bodyShort01LineHeight,
  letterSpacing: token.bodyShort01LetterSpacing,
  pointerEvents: "none",
  "&::placeholder": {
    color: token.textTextPlaceholder,
  },

  "&:focus, &:active": {
    outline: "none",
  },
  variants: {
    disabled: {
      true: {
        display: "none",
      },
    },
  },
});

import * as React from "react";
const SvgSquareIcon = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 16 16"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M13 1a2 2 0 0 1 2 2v10c0 1.103-.897 2-2 2H3a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h10Zm0 1.5H3a.5.5 0 0 0-.5.5v10a.5.5 0 0 0 .5.5h10c.275 0 .5-.225.5-.5V3a.5.5 0 0 0-.5-.5Z"
      fill="inherit"
    />
  </svg>
);
export default SvgSquareIcon;

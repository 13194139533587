import variables from "../../../tokens/json/variables.json";

export const ColumnFiltersRootStyles = {
  display: "flex",
  flexDirection: "column",
  gap: variables.spacing4,
};

export const FiltersContainerStyles = {
  display: "flex",
  gap: variables.spacing2,
  alignItems: "center",
  flexWrap: "wrap",
  paddingLeft: "8px",
};

export const SearchContainerStyles = {
  display: "flex",
  gap: variables.spacing2,
  alignItems: "center",
  flexWrap: "wrap",
};

export const FilterIconButtonStyles = {
  height: variables.sizing5,
  width: variables.sizing5,
  paddingRight: "16px",

  fill: variables.textTextSecondary,
  color: variables.textTextSecondary,

  "& .filterIcon": {
    display: "block",
    height: variables.sizing5,
    width: variables.sizing5,
  },
};

export const AppliedFiltersContainerStyles = {
  display: "none",
  gap: variables.spacing2,
  alignItems: "center",
  flexWrap: "wrap",

  variants: {
    visible: {
      true: {
        display: "flex !important",
        maxHeight: "190px",
        minHeight: "32px",
        transition: "max-height 0.5s ease, min-height 0.5s ease",
      },
    },
  },
};

export const SearchByWrapperStyle = {
  display: "flex",
  gap: variables.spacing2,
  alignItems: "center",
  justifyContent: "flex-start",
  flexWrap: "wrap",
  paddingLeft: variables.spacing2,
};

export const SearchByTypographyStyle = {
  color: variables.textTextSecondary || "#455878",
  fontFamily: variables.semiBoldBodyShort01FontFamily,
  fontSize: variables.semiBoldBodyShort01FontSize,
  fontStyle: "normal",
  fontWeight: 600,
  lineHeight: variables.semiBoldBodyShort01LineHeight,
  letterSpacing: variables.semiBoldBodyShort01LetterSpacing,
};

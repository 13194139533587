import React, { useState, useEffect, useCallback } from "react";
import { styled } from "@stitches/react";
import PropTypes from "prop-types";
import NodeAction from "../NodeAction/NodeAction";
import { ArrowDownIcon, ArrowUpIcon } from "../../../icons";
import Spinner from "../Spinner/Spinner";
import {
  NodeRootStyles,
  NodeLabelStyles,
  NodeActionsContainerStyles,
  NodeExpandedAreaStyles,
  NodeIconButtonStyles,
  FallbackLabelStyles,
  FallbackContainerStyles,
} from "./Node.styles";

const NodeRoot = styled("div", NodeRootStyles);
const NodeLabel = styled("div", NodeLabelStyles);
const NodeActions = styled("div", NodeActionsContainerStyles);
const NodeExpandedArea = styled("div", NodeExpandedAreaStyles);
const NodeIconButton = styled("div", NodeIconButtonStyles);
const FallbackLabel = styled("div", FallbackLabelStyles);
export const FallbackContainer = styled("div", FallbackContainerStyles);

const Node = ({
  node,
  depth,
  size = "small",
  isLastNode,
  actions,
  fetchChildrenNodesByDepth,
}) => {
  const [expanded, setExpanded] = useState(false);
  const [children, setChildren] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [hasError, setHasError] = useState(false);

  useEffect(() => {
    if (node?.childrenData) {
      // Children data is available upfront, no need to fetch
      setChildren(node?.childrenData);
    } else if (
      node?.loadChildren &&
      children?.length === 0 &&
      expanded &&
      typeof fetchChildrenNodesByDepth === "function"
    ) {
      // Fetch children nodes using API endpoint based on node depth
      setHasError(false);
      setIsLoading(true);
      fetchChildrenNodesByDepth(node, depth)
        .then((childNodesData) => {
          setChildren(childNodesData);
        })
        .catch((error) => {
          setHasError(true);
          // eslint-disable-next-line no-console
          console.error("Error fetching child nodes:", error);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, [expanded, node, node.childrenData, children.length, depth]);

  const handleExpand = () => {
    setExpanded(!expanded);
  };

  const handleActionClick = useCallback(
    (event, action) => {
      event.stopPropagation();
      if (actions[action] && typeof actions[action].handler === "function") {
        actions[action].handler(node);
      }
    },
    [node]
  );

  return (
    <>
      <NodeRoot
        data-testid={`Node_${node?.id}`}
        size={size}
        isLastNode={isLastNode}
      >
        {(node?.loadChildren || children?.length > 0) && (
          <NodeIconButton
            data-testid={`Node_${expanded ? "Expanded" : "Collapsed"}`}
            onClick={handleExpand}
          >
            {expanded ? <ArrowUpIcon /> : <ArrowDownIcon />}
          </NodeIconButton>
        )}
        <NodeLabel data-testid="Node_Label">
          {node?.label} {node?.childrenCount ? `(${node?.childrenCount})` : ""}
        </NodeLabel>
        <NodeActions data-testid="Node_Actions">
          {Object.keys(actions)?.map((action) => (
            <NodeAction
              key={action}
              data-testid={`NodeAction_${action}`}
              node={node}
              action={action}
              {...actions[action]}
              onActionClick={handleActionClick}
            />
          ))}
        </NodeActions>
      </NodeRoot>
      {expanded && (
        <NodeExpandedArea data-testid={`Node_ExpandedArea_${node?.id}`}>
          {children?.map((child, index) => (
            <Node
              key={child.id}
              node={child}
              depth={depth + 1}
              isLastNode={index === (children || []).length - 1}
              actions={actions}
              fetchChildrenNodesByDepth={fetchChildrenNodesByDepth}
            />
          ))}
          {isLoading && (
            <FallbackContainer size={size}>
              <Spinner />
            </FallbackContainer>
          )}
          {!isLoading && hasError && (
            <FallbackLabel>Failed to load more details...</FallbackLabel>
          )}
        </NodeExpandedArea>
      )}
    </>
  );
};

Node.propTypes = {
  node: PropTypes.instanceOf(Object),
  depth: PropTypes.number,
  size: PropTypes.oneOf([
    "compact",
    "extraSmall",
    "small",
    "medium",
    "large",
    "extraLarge",
  ]),
  isLastNode: PropTypes.bool,
  actions: PropTypes.instanceOf(Object),
  fetchChildrenNodesByDepth: PropTypes.func,
};

Node.defaultProps = {
  depth: 0,
  isLastNode: false,
  actions: {},
};

export default Node;

/* eslint-disable no-dupe-keys */
import tokens from "../../../tokens/json/variables.json";

export const FooterContainerStyles = {
  flexGrow: "2",
  all: "unset",
  boxSizing: "border-box",
  display: "flex",
  alignItems: "center",
  color: tokens.gray40,
  padding: "0 8px 8px 8px",
  height: "auto",
  flexDirection: "column",
  border: `1px solid ${tokens.layerLayerSelectedInverse}`,
  boxShadow:
    "0px -2px 2px rgba(0, 0, 0, 0.1), 0px -2px 4px rgba(0, 0, 0, 0.1), 0px -4px 8px rgba(0, 0, 0, 0.1)",
};

export const FooterItemStyles = {
  all: "unset",
  boxSizing: "border-box",
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  gap: tokens.spacing4,
  flex: "none",
  height: tokens.spacing8,
  width: "-moz-available",
  width: "-webkit-fill-available",
  padding: `${tokens.spacing15} ${tokens.spacing2} ${tokens.spacing15} ${tokens.spacing4}`,
  "&:hover": {
    backgroundColor: tokens.backgroundBackgroundInverseHover,
    color: tokens.white,
  },
  "&:active": {
    backgroundColor: tokens.backgroundBackgroundBrand,
    color: tokens.textTextOnColor,
  },
  "&:focus": {
    backgroundColor: tokens.backgroundBackgroundBrand,
    // boxShadow: "0 0 0 3px black",
  },
  variants: {
    disabled: {
      true: {
        pointerEvents: "none",
      },
    },
    hidden: {
      true: {
        display: "none",
      },
    },
    selected: {
      backgroundColor: tokens.backgroundBackgroundInverseSelected,
      color: tokens.textTextOnColor,
    },
  },
};

export const LabelStyles = {
  all: "unset",
  fontFamily: tokens.bodyShort01FontFamily,
  fontStyle: "normal",
  fontWeight: tokens.bodyShort01FontWeight,
  fontSize: tokens.bodyShort01FontSize,
  lineHeight: tokens.bodyShort01LineHeight,
  letterSpacing: tokens.bodyShort01LetterSpacing,
  flex: "none",
  order: 1,
  flexGrow: 1,
  variants: {
    selected: {
      true: {
        fontFamily: tokens.semiBoldBodyShort01FontFamily,
        fontSize: tokens.semiBoldBodyShort01FontSize,
        fontWeight: tokens.semiBoldBodyShort01FontWeight,
        lineHeight: tokens.semiBoldBodyShort01LineHeight,
        letterSpacing: tokens.semiBoldBodyShort01LetterSpacing,
        textDecoration: tokens.semiBoldBodyShort01TextDecoration,
      },
    },
  },
};

export const IconStyles = {
  all: "unset",
  width: tokens.spacing4,
  height: tokens.spacing4,
};

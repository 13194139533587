import * as React from "react";
const SvgTimeIcon = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 14 14"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M6.344 3.281c0-.355.273-.656.656-.656.355 0 .656.3.656.656v3.39L9.98 8.204c.301.219.383.63.165.93a.628.628 0 0 1-.903.164l-2.625-1.75A.673.673 0 0 1 6.344 7V3.281ZM7 0c3.855 0 7 3.145 7 7 0 3.883-3.145 7-7 7-3.883 0-7-3.117-7-7 0-3.855 3.117-7 7-7ZM1.312 7A5.683 5.683 0 0 0 7 12.688c3.117 0 5.688-2.543 5.688-5.688 0-3.117-2.57-5.688-5.688-5.688-3.145 0-5.688 2.57-5.688 5.688Z"
      fill="inherit"
    />
  </svg>
);
export default SvgTimeIcon;

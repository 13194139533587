import React from "react";
import { styled } from "@stitches/react";
import { LoaderIcon } from "../../../icons";
import { LoaderStyles } from "./Spinner.styles";

const SpinningLoader = styled(LoaderIcon, LoaderStyles);

const Spinner = () => {
  return <SpinningLoader />;
};

export default Spinner;

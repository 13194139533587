import React, { useMemo } from "react";
import { styled } from "@stitches/react";
import PropTypes from "prop-types";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { PREDEFINED_COLUMNS } from "../../Constants";
import ColumnVisibilityToggle from "./ColumnToggleField";
import { ToggleFieldsContainerStyles } from "./ColumnToggleField.styles";

const ToggleFieldsContainer = styled("div", ToggleFieldsContainerStyles);

const ColumnToggleFieldContainer = ({
  columns,
  handleColumnToggle,
  columnsToBeHidden,
  fixedColumns,
  columnsToBeReOrdered,
  setColumnsToBeReOrdered,
}) => {
  const handleDragEnd = (result) => {
    if (!result.destination) return;

    setColumnsToBeReOrdered((prevOrder) => {
      const reorderedColumns = [...prevOrder];
      const [movedColumn] = reorderedColumns.splice(result.source.index, 1);
      reorderedColumns.splice(result.destination.index, 0, movedColumn);
      return reorderedColumns;
    });
  };

  const orderedColumns = useMemo(() => {
    return [...columns].sort((a, b) => {
      const indexA = columnsToBeReOrdered?.indexOf(a.id);
      const indexB = columnsToBeReOrdered?.indexOf(b.id);

      if (indexA === -1) return 1; // Place items not found in orderArray at the end.
      if (indexB === -1) return -1; // Place items not found in orderArray at the end.

      return indexA - indexB;
    });
  }, [columnsToBeReOrdered, columns]);

  return (
    <DragDropContext onDragEnd={handleDragEnd}>
      <Droppable droppableId="column-reorder">
        {(droppableProvided) => (
          <ToggleFieldsContainer
            data-testid="columnVisibilityToggles"
            {...droppableProvided.droppableProps}
            ref={droppableProvided.innerRef}
          >
            {orderedColumns.map((column, index) => {
              const checked = !(columnsToBeHidden || []).includes(column?.id);
              const disabled = (fixedColumns || []).includes(column?.id);
              return (
                <Draggable
                  key={column.id}
                  draggableId={column.id}
                  index={index}
                >
                  {(draggableProvided, snapshot) => (
                    <ColumnVisibilityToggle
                      checked={checked}
                      disabled={disabled}
                      column={column}
                      handleColumnToggle={handleColumnToggle}
                      isDragging={snapshot.isDragging}
                      isHidden={Object.values(PREDEFINED_COLUMNS).includes(
                        column.id
                      )}
                      ref={draggableProvided.innerRef}
                      {...draggableProvided.draggableProps}
                      {...draggableProvided.dragHandleProps}
                    />
                  )}
                </Draggable>
              );
            })}
          </ToggleFieldsContainer>
        )}
      </Droppable>
    </DragDropContext>
  );
};

ColumnToggleFieldContainer.propTypes = {
  columns: PropTypes.instanceOf(Array),
  handleColumnToggle: PropTypes.func,
  columnsToBeHidden: PropTypes.instanceOf(Array),
  fixedColumns: PropTypes.instanceOf(Array),
  columnsToBeReOrdered: PropTypes.instanceOf(Array),
  setColumnsToBeReOrdered: PropTypes.func,
};

export default ColumnToggleFieldContainer;

import { keyframes } from "@stitches/react";
import tokens from "../../tokens/json/variables.json";

// animations
const slideDownAndFade = keyframes({
  "0%": { opacity: 0, transform: "translateY(-10px)" },
  "100%": { opacity: 1, transform: "translateY(0)" },
});
const slideUpAndFade = keyframes({
  "0%": { opacity: 0, transform: "translateY(10px)" },
  "100%": { opacity: 1, transform: "translateY(0)" },
});

export const InputStyles = {
  borderColor: tokens.borderBorderSubtle01,
  paddingRight: `${tokens.spacing8} !important`,
  "&::-webkit-calendar-picker-indicator": {
    opacity: 0,
  },
};

export const CalendarIconContainerStyles = {
  boxSizing: "border-box",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  width: 24,
  height: 24,
  position: "absolute",
  right: 4,
  top: 4,
  padding: "5px 5.87px",
  backgroundColor: tokens.backgroundBackground,
  borderRadius: tokens.borderRadius1,
  cursor: "pointer",
  transition: "0.3s ease",
  svg: {
    fill: tokens.iconIconPrimary,
    transition: "0.3s ease",
  },
  "&[data-active=true]": {
    backgroundColor: tokens.buttonButtonGhostSelected,
    svg: {
      fill: tokens.backgroundBackground,
    },
  },
  "&[data-disabled=true]": {
    opacity: 0.5,
    pointerEvents: "none",
  },
};

export const CalendarContainerStyles = {
  maxWidth: "298px",
  boxSizing: "border-box",
  position: "absolute",
  marginTop: tokens.spacing1,
  display: "flex",
  flexDirection: "column",
  padding: tokens.spacing2,
  borderRadius: tokens.borderRadius1,
  backgroundColor: tokens.layerLayer02,
  outline: `${tokens.borderBorderSubtle01} solid ${tokens.borderWidth1}`,
  boxShadow: `${tokens.menuShadowX}px ${tokens.menuShadowY}px ${tokens.menuShadowBlur}px ${tokens.menuShadowSpread}px ${tokens.menuShadowColor}`,

  "@media (prefers-reduced-motion: no-preference)": {
    animationDuration: "300ms",
    animationTimingFunction: "cubic-bezier(0.16, 1, 0.3, 1)",
    willChange: "transform, opacity",
    animationName: slideDownAndFade,
  },
};

export const CalendarHeaderStyles = {
  width: 280,
  boxSizing: "border-box",
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  padding: `${tokens.spacing1} 0px`,
};

export const ArrowButtonStyles = {
  width: tokens.sizing8,
  height: tokens.sizing8,
  padding: 0,
};

export const YearMonthButtonStyles = {
  all: "unset",
  display: "flex",
  alignItems: "center",
  padding: tokens.spacing15,
  cursor: "pointer",
  gap: tokens.spacing1,
};

export const ArrowIconStyles = {
  width: tokens.sizing3,
  height: tokens.sizing3,
  fill: tokens.iconIconSecondary,
  transition: "0.3s ease",
  "&[data-open=true]": {
    transform: "rotate(180deg)",
  },
};

export const YearMonthTextStyles = {
  color: tokens.textTextPrimary,
  fontFamily: tokens.semiBoldBodyShort02FontFamily,
  fontWeight: tokens.semiBoldBodyShort02FontWeight,
  fontSize: tokens.semiBoldBodyShort02FontSize,
  lineHeight: tokens.semiBoldBodyShort02LineHeight,
  letterSpacing: tokens.semiBoldBodyShort02LetterSpacing,
  textDecoration: tokens.semiBoldBodyShort02TextDecoration,
};

export const WeekDaysContainerStyles = {
  display: "flex",
  alignItems: "center",
  div: {
    // each week day
    boxSizing: "border-box",
    width: 40,
    height: 40,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: tokens.textTextSecondary,
    fontFamily: tokens.bodyShort02FontFamily,
    fontWeight: tokens.bodyShort02FontWeight,
    fontSize: tokens.bodyShort02FontSize,
    lineHeight: tokens.bodyShort02LineHeight,
    letterSpacing: tokens.bodyShort02LetterSpacing,
    textDecoration: tokens.bodyShort02TextDecoration,
  },
};

export const TodayContainerStyles = {
  boxSizing: "border-box",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  paddingTop: tokens.spacing3,
  paddingBottom: tokens.spacing3,
  boxShadow: `0 ${tokens.borderWidth1} 0 ${tokens.borderBorderSubtle00} inset`,
  columnGap: tokens.spacing4,
};

export const TodayTextStyles = {
  cursor: "pointer",
  position: "relative",
  display: "flex",
  alignItems: "center",
  height: tokens.sizing8,
  color: tokens.textTextSecondary,
  fontFamily: tokens.bodyShort01FontFamily,
  fontWeight: tokens.bodyShort01FontWeight,
  fontSize: tokens.bodyShort01FontSize,
  lineHeight: tokens.bodyShort01LineHeight,
  letterSpacing: tokens.bodyShort01LetterSpacing,
  textDecoration: tokens.bodyShort01TextDecoration,
  "&:after": {
    position: "absolute",
    content: "",
    width: 4,
    height: 4,
    top: "50%",
    right: "calc(100% + 4px)",
    backgroundColor: tokens.iconIconBrand,
    transform: "translateY(-50%)",
  },
};

export const TimeTextContainerStyles = {
  boxSizing: "border-box",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  boxShadow: `0 ${tokens.borderWidth1} 0 ${tokens.borderBorderSubtle00} inset`,
  paddingTop: tokens.spacing2,
};

export const TimeTextStyles = {
  boxSizing: "border-box",
  height: tokens.sizing8,
  display: "flex",
  alignItems: "center",
  cursor: "pointer",
  color: tokens.textTextPrimary,
  fontFamily: tokens.semiBoldBodyShort02FontFamily,
  fontWeight: tokens.semiBoldBodyShort02FontWeight,
  fontSize: tokens.semiBoldBodyShort02FontSize,
  lineHeight: tokens.semiBoldBodyShort02LineHeight,
  letterSpacing: tokens.semiBoldBodyShort02LetterSpacing,
  textDecoration: tokens.semiBoldBodyShort02TextDecoration,
  padding: tokens.spacing1,
  columnGap: tokens.spacing1,
};

export const TimeButtonStyles = {
  width: 24,
  padding: 0,

  svg: {
    width: tokens.sizing35,
    height: tokens.sizing35,
    fill: tokens.iconIconPrimary,
  },
};

export const DaysContainerStyles = {
  display: "grid",
  gridTemplateColumns: "repeat(7, 1fr)",
  button: {
    // each day
    all: "unset",
    position: "relative",
    boxSizing: "border-box",
    width: tokens.sizing10,
    height: tokens.sizing10,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: tokens.textTextPrimary,
    fontFamily: tokens.bodyShort02FontFamily,
    fontWeight: tokens.bodyShort02FontWeight,
    fontSize: tokens.bodyShort02FontSize,
    lineHeight: tokens.bodyShort02LineHeight,
    backgroundColor: tokens.backgroundBackground,
    letterSpacing: tokens.bodyShort02LetterSpacing,
    textDecoration: tokens.bodyShort02TextDecoration,
    "&:hover": {
      backgroundColor: tokens.buttonButtonTertiaryHover,
    },
    "&:focus": {
      backgroundColor: tokens.backgroundBackground,
      border: `${tokens.borderWidth2} solid ${tokens.focusFocus}`,
    },
    "&:active": {
      backgroundColor: tokens.backgroundBackgroundSelected,
      border: "unset",
    },
    "&:disabled": {
      pointerEvents: "none",
      color: tokens.textTextDisabled,
    },
    "&[data-dayoutside=true]": {
      color: tokens.textTextSecondary,
    },
    "&[data-dayselected=true]": {
      backgroundColor: tokens.buttonButtonPrimary,
      color: tokens.textTextOnColor,
    },
    "&[data-dayinrange=true]": {
      backgroundColor: tokens.backgroundBackgroundSelected,
    },
    "&[data-daytoday=true]": {
      border: `${tokens.borderWidth1} solid ${tokens.borderBorderSubtle02}`,
      "&::after": {
        position: "absolute",
        content: "",
        width: 4,
        height: 4,
        bottom: 5,
        left: "50%",
        backgroundColor: tokens.iconIconBrand,
        transform: "translateX(-50%)",
      },
      "&:disabled": {
        border: `${tokens.borderWidth1} solid ${tokens.borderBorderDisabled}`,
        color: tokens.textTextDisabled,
        "&::after": {
          backgroundColor: tokens.iconIconDisabled,
        },
      },
      "&[data-dayselected=true]": {
        border: `${tokens.borderWidth1} solid ${tokens.borderBorderDisabled}`,
        "&::after": {
          backgroundColor: tokens.iconIconOnColor,
        },
      },
    },
  },
};

export const YearsContainerStyles = {
  paddingRight: tokens.spacing2,
  paddingLeft: tokens.spacing3,
  rowGap: tokens.spacing1,
  backgroundColor: tokens.layerLayer02,
  maxHeight: "360px",
  overflowY: "scroll",
  "&::-webkit-scrollbar": {
    width: "4px",
  },
  "&::-webkit-scrollbar-thumb": {
    background: tokens.layerAccentLayerAccent01,
    borderRadius: tokens.borderRadius05,
  },
};

export const CollapsibleCardStyles = {
  minWidth: "unset",
  border: "none",
  width: "100%",
  padding: "10px 16px",
  borderBottom: `1px solid ${tokens.borderBorderSubtle01}`,
  borderRadius: tokens.borderRadius0,
  "&:hover": {
    backgroundColor: "unset",
  },
  "> div[data-testid*=card_title_]": {
    flexDirection: "row-reverse",
    justifyContent: "space-between",
  },
  "> div[data-testid*=card_title_] > div:nth-child(2) > div": {
    fontSize: tokens.semiBoldBodyShort01FontSize,
    lineHeight: tokens.semiBoldBodyShort01LineHeight,
  },
  "> div[class*=expanded-true]": {
    display: "grid",
    gridTemplateRows: "repeat(3, min-content)",
    gridAutoFlow: "column",
    padding: 0,
  },
  button: {
    // each month of a year
    all: "unset",
    position: "relative",
    boxSizing: "border-box",
    width: tokens.sizing14,
    height: tokens.sizing10,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
    color: tokens.textTextPrimary,
    fontFamily: tokens.bodyShort02FontFamily,
    fontWeight: tokens.bodyShort02FontWeight,
    fontSize: tokens.bodyShort02FontSize,
    lineHeight: tokens.bodyShort02LineHeight,
    backgroundColor: tokens.backgroundBackground,
    letterSpacing: tokens.bodyShort02LetterSpacing,
    textDecoration: tokens.bodyShort02TextDecoration,
    "&:hover": {
      backgroundColor: tokens.buttonButtonTertiaryHover,
    },
    "&:focus": {
      backgroundColor: tokens.backgroundBackground,
      border: `2px solid ${tokens.focusFocus}`,
    },
    "&:active": {
      backgroundColor: tokens.backgroundBackgroundSelected,
      border: "unset",
    },
    "&:disabled": {
      pointerEvents: "none",
      color: tokens.textTextDisabled,
    },
    "&[data-monthselected=true]": {
      backgroundColor: tokens.buttonButtonPrimary,
      color: tokens.textTextOnColor,
    },
  },
};

export const TimeModalContainerStyles = {
  boxSizing: "border-box",
  position: "absolute",
  display: "flex",
  flexDirection: "row",
  width: tokens.sizing72,
  bottom: "40px", // time
  borderRadius: tokens.borderRadius1,
  backgroundColor: tokens.layerLayer02,
  border: `${tokens.borderWidth1} solid ${tokens.borderBorderSubtle00}`,
  boxShadow: `${tokens.menuShadowX}px ${tokens.menuShadowY}px ${tokens.menuShadowBlur}px ${tokens.menuShadowSpread}px ${tokens.menuShadowColor}`,
  zIndex: 10,

  "@media (prefers-reduced-motion: no-preference)": {
    animationDuration: "300ms",
    animationTimingFunction: "cubic-bezier(0.16, 1, 0.3, 1)",
    willChange: "transform, opacity",
    animationName: slideUpAndFade,
  },
  "&[data-todayenabled=true]": {
    bottom: "calc(56px + 8px + 40px)", // today + margin + time
  },
  "&[data-position=down]": {
    bottom: "unset",
    top: `calc(100% - 56px - ${tokens.spacing2} - ${tokens.spacing2} + ${tokens.spacing1})`,
    animationName: slideDownAndFade,
  },
};

export const EscapeBackgroundStyles = {
  position: "fixed",
  top: 0,
  left: 0,
  width: "100%",
  height: "100%",
  zIndex: 9,
};

export const LabelStyles = {
  color: tokens.textTextSecondary,
  fontFamily: tokens.labelFontFamily,
  fontSize: tokens.labelFontSize,
  fontWeight: tokens.labelFontWeight,
  lineHeight: tokens.labelLineHeight,
  letterSpacing: tokens.labelLetterSpacing,
  paragraphSpacing: tokens.labelParagraphSpacing,
  paddingBottom: tokens.sizing2,
  "&[disabled]": {
    color: tokens.textTextDisabled,
  },
  variants: {
    required: {
      true: {
        "&::after": {
          content: " *",
          color: tokens.textTextError,
          fontFamily: tokens.labelFontFamily,
          fontSize: tokens.labelFontSize,
          fontWeight: tokens.labelFontWeight,
          lineHeight: tokens.labelLineHeight,
          letterSpacing: tokens.labelLetterSpacing,
          paragraphSpacing: tokens.labelParagraphSpacing,
        },
      },
    },
  },
};

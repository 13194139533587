import * as React from "react";
const SvgEyeOffIcon = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 16 15"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M10.764 11.61c-.38.15-.81.26-1.28.34-.49.08-.99.13-1.5.13-1.64 0-3.14-.46-4.47-1.38a8.025 8.025 0 0 1-2.95-3.69c.22-.66.54-1.23.94-1.79.38-.53.84-1.04 1.38-1.52l-2.1-2.09.84-.86 12.75 12.75-.78.88-2.83-2.78v.01Zm-7.07-7.07c-.38.29-.75.66-1.1 1.11-.38.48-.65.94-.81 1.36.55 1.13 1.4 2.1 2.52 2.8 1.12.7 2.4 1.06 3.82 1.06.36 0 .72-.02 1.07-.07.26-.03.48-.08.64-.13l-.91-.91c-.12.04-.25.08-.41.11-.18.03-.36.04-.52.04-.8 0-1.5-.28-2.07-.84-.57-.56-.86-1.26-.86-2.09 0-.17.01-.34.04-.52.03-.15.06-.29.11-.41l-1.51-1.51h-.01Zm8.11 5.26.09-.07c.54-.39 1.01-.84 1.41-1.32.4-.48.69-.98.88-1.46a6.862 6.862 0 0 0-2.46-2.81 6.503 6.503 0 0 0-3.56-1.06c-.46 0-.93.04-1.42.13-.48.09-.86.19-1.13.31l-.06.03-.91-.93.13-.06c.39-.18.9-.34 1.51-.47.61-.13 1.21-.2 1.8-.2 1.6 0 3.09.46 4.42 1.38 1.33.92 2.31 2.15 2.93 3.69a7.63 7.63 0 0 1-1.13 2.05c-.46.59-1 1.12-1.6 1.57l-.07.05-.81-.81-.02-.02Zm-2.18-2.18.02-.06c.28-.75.14-1.36-.42-1.86-.38-.33-.77-.5-1.19-.5-.2 0-.41.04-.63.12l-.06.02-.87-.87.1-.07c.2-.13.42-.22.66-.28.24-.06.49-.09.74-.09.81 0 1.51.29 2.08.85.57.57.85 1.27.85 2.08 0 .25-.03.51-.09.75-.06.25-.15.47-.27.65l-.07.11-.87-.87.02.02Z"
      fill="#000"
    />
  </svg>
);
export default SvgEyeOffIcon;

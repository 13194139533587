import React from "react";
import PropTypes from "prop-types";

import { styled } from "@stitches/react";
import * as ReactRadioGroup from "@radix-ui/react-radio-group";

import Tooltip from "../Tooltip/Tooltip";

import { CircleFilledIcon, CircleIcon, InfoIcon } from "../../icons";
import {
  RadioGroupOuterContainerStyles,
  RadioGroupItemContainerStyles,
  RadioGroupContainerStyles,
  CircleCheckedIconStyles,
  RadioGroupLabelStyles,
  RadioGroupItemStyles,
  RadioGroupTextStyles,
  CircleIConStyles,
  HelperTextStyles,
  InfoIconStyles,
  LabelContainerStyles,
} from "./RadioGroup.styles";

const RadioGroupOuterContainer = styled("div", RadioGroupOuterContainerStyles);
const LabelContainer = styled("div", LabelContainerStyles);
const RadioGroupLabel = styled("div", RadioGroupLabelStyles);
const RadioGroupContainer = styled(
  ReactRadioGroup.Root,
  RadioGroupContainerStyles
);
const HelperText = styled("div", HelperTextStyles);

const StyledInfoIcon = styled(InfoIcon, InfoIconStyles);
const RadioGroupIcon = styled(CircleIcon, CircleIConStyles);
const RadioGroupCheckedIcon = styled(CircleFilledIcon, CircleCheckedIconStyles);

const Item = styled(ReactRadioGroup.Item, RadioGroupItemStyles);
const RadioGroupText = styled("label", RadioGroupTextStyles);
const Flex = styled("div", RadioGroupItemContainerStyles);

const RadioGroup = ({
  checkedValue,
  helperText,
  onChange,
  options,
  label,
  ...props
}) => {
  return (
    <RadioGroupOuterContainer>
      <LabelContainer>
        <RadioGroupLabel
          data-testid="RadioGroup-label"
          disabled={props?.disabled}
          required={Boolean(label) && props?.required}
        >
          {label}
        </RadioGroupLabel>
        {props.info && (
          <Tooltip
            data-testid="tooltip"
            type="inline"
            content={props.info}
            align="center"
            side="right"
            style={{ display: "flex" }}
          >
            <StyledInfoIcon />
          </Tooltip>
        )}
      </LabelContainer>
      <RadioGroupContainer
        data-testid="RadioGroup-inner-container"
        onValueChange={!props?.readOnly && onChange}
        value={checkedValue}
        {...props}
      >
        {options.map((option) => {
          const isReadOnly = props?.readOnly || option?.readOnly;
          const isDisabled = props?.disabled || option.disabled;

          return (
            <Flex key={option.id} disabled={isDisabled}>
              <Item
                id={option.id}
                error={options.length === 1 && props?.error}
                value={option.value}
                disabled={isReadOnly || isDisabled}
                readOnly={isReadOnly}
                data-testid={`option_${option.id}`}
              >
                {checkedValue !== option.value && <RadioGroupIcon />}
                {checkedValue === option.value && (
                  <ReactRadioGroup.Indicator>
                    <RadioGroupCheckedIcon />
                  </ReactRadioGroup.Indicator>
                )}
              </Item>
              <RadioGroupText htmlFor={option.id} data-testid="RadioGroup-text">
                {option.value}
              </RadioGroupText>
            </Flex>
          );
        })}
      </RadioGroupContainer>
      {helperText && (
        <HelperText
          data-testid="helperText"
          error={props?.error}
          disabled={props?.disabled}
        >
          {helperText}
        </HelperText>
      )}
    </RadioGroupOuterContainer>
  );
};

RadioGroup.propTypes = {
  /** Information to be displayed in a tooltip. */
  info: PropTypes.string,
  /** Indicates if the RadioGroup is in an error state. If true, the helper text will be displayed in an error state. */
  error: PropTypes.bool,
  /** The label of the RadioGroup. This prop is optional and allows for flexible label for the RadioGroup. */
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  /** An array of options for the RadioGroup. Required and provides flexible options for the RadioGroup. */
  options: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      disabled: PropTypes.bool,
    })
  ),
  /** Indicates if the RadioGroup is required. If true, the RadioGroup will be required. */
  required: PropTypes.bool,
  /** The disabled state of the RadioGroup. If true, the RadioGroup will be disabled. */
  disabled: PropTypes.bool,
  /** The readOnly state of the RadioGroup. If true, the RadioGroup will be readOnly. */
  readOnly: PropTypes.bool,
  /** Event handler for the onChange event of the RadioGroup. Optional and allows for a flexible onChange event. */
  onChange: PropTypes.func,
  /** Direction of the RadioGroup layout. Can be "horizontal" or "vertical". */
  direction: PropTypes.oneOf(["horizontal", "vertical"]),
  /** Helper text for the RadioGroup. Optional and provides additional information about the RadioGroup. */
  helperText: PropTypes.string,
  /** The value that is currently checked in the RadioGroup. Optional and allows setting the initial checked value. */
  checkedValue: PropTypes.string,
};

RadioGroup.defaultProps = {
  direction: "horizontal",
};

export default RadioGroup;

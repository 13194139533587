import * as React from "react";
const SvgColumnSearchIcon = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 16 16"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#ColumnSearchIcon_svg__a)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.379 8.065c.574-.78.918-1.767.918-2.823C13.297 2.626 11.14.47 8.523.47 5.884.469 3.75 2.626 3.75 5.242a4.77 4.77 0 0 0 4.773 4.774c1.033 0 2.02-.322 2.8-.918l2.915 2.914a.708.708 0 0 0 1.033 0 .708.708 0 0 0 0-1.033l-2.892-2.914Zm-1.01-1.17a3.277 3.277 0 0 1-2.846 1.652 3.287 3.287 0 0 1-2.868-1.652 3.274 3.274 0 0 1 0-3.305 3.335 3.335 0 0 1 2.868-1.652c1.17 0 2.25.642 2.846 1.652a3.274 3.274 0 0 1 0 3.305ZM1.25 9a.74.74 0 0 0-.75.75c0 .438.313.75.75.75h2.5a.74.74 0 0 0 .75-.75.76.76 0 0 0-.75-.75h-2.5Zm0 2.5a.74.74 0 0 0-.75.75c0 .438.313.75.75.75h5.5a.74.74 0 0 0 .75-.75.76.76 0 0 0-.75-.75h-5.5Zm0 4h8.5a.74.74 0 0 0 .75-.75.76.76 0 0 0-.75-.75h-8.5a.74.74 0 0 0-.75.75c0 .438.313.75.75.75Z"
        fill="inherit"
      />
    </g>
    <defs>
      <clipPath id="ColumnSearchIcon_svg__a">
        <path fill="#fff" d="M0 0h16v16H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgColumnSearchIcon;

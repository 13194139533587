import React from "react";
import PropTypes from "prop-types";
import { styled } from "@stitches/react";

import { ErrorFilledIcon } from "../../icons";
import Tooltip from "../Tooltip/Tooltip";
import {
  BarInnerStyles,
  BarStyles,
  ContainerStyles,
  LabelStyles,
  StatusStyles,
} from "./ProgressBar.styles";

const Container = styled("div", ContainerStyles);
const Status = styled("div", StatusStyles);
const Label = styled("div", LabelStyles);
const Bar = styled("div", BarStyles);
const BarInner = styled("div", BarInnerStyles);

const ProgressBar = ({ status, label, id, ...props }) => {
  return (
    <Container
      data-inline={props.inline}
      style={{ width: props.width }}
      data-testid={`${id}_progressbar`}
    >
      {label && <Label data-testid={`${id}_label`}>{label}</Label>}
      <Bar data-large={props?.large}>
        <BarInner
          style={{ width: `${props.progress}%` }}
          data-type={props.type}
        />
      </Bar>
      {status && (
        <Status error={props?.type === "error"} data-large={props?.large}>
          {props.inline && props.type === "error" ? (
            <Tooltip
              data-testid={`${id}_tooltip`}
              type="inline"
              content={status}
              align="center"
              side="top"
              style={{ display: "flex" }}
            >
              <ErrorFilledIcon />
            </Tooltip>
          ) : (
            status
          )}
        </Status>
      )}
    </Container>
  );
};

ProgressBar.propTypes = {
  progress: PropTypes.number,
  status: PropTypes.string,
  inline: PropTypes.bool,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  large: PropTypes.bool,
  type: PropTypes.oneOf(["info", "success", "error", "warning"]),
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

ProgressBar.defaultProps = {
  inline: false,
  large: false,
  width: 200,
};

export default ProgressBar;

import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { styled } from "@stitches/react";
import { VectorIcon } from "../../icons";
import Loader from "../Loader";
import {
  groupDescriptionStyle,
  groupHeadingStyle,
  groupHeadlineStyle,
  groupIconStyle,
  rootStyle,
  sectionCommentStyle,
  sectionDescStyle,
  sectionHeadingStyle,
  sectionIconStyle,
  sectionLinkStyle,
  sectionsStyle,
  sectionContentStyle,
  sectionStyle,
  sectionTitleStyle,
} from "./CMSPanel.styles";

const RootStyle = styled("div", rootStyle);
const GroupHeadlineStyle = styled("a", groupHeadlineStyle);
const GroupIconStyle = styled("img", groupIconStyle);
const GroupHeadingStyle = styled("div", groupHeadingStyle);
const GroupDescriptionStyle = styled("div", groupDescriptionStyle);
const SectionsStyle = styled("div", sectionsStyle);
const SectionStyle = styled("a", sectionStyle);
const SectionHeadingStyle = styled("div", sectionHeadingStyle);
const SectionIconStyle = styled("img", sectionIconStyle);
const SectionContentStyle = styled("div", sectionContentStyle);
const SectionTitleStyle = styled("div", sectionTitleStyle);
const SectionDescStyle = styled("div", sectionDescStyle);
const SectionLinkStyle = styled("div", sectionLinkStyle);
const SectionCommentStyle = styled("div", sectionCommentStyle);

const environmentToURL = {
  dev: "https://docs.mt-accounts-dev.vsscloud.tv",
  staging: "https://docs.matisse.coldsnow.net",
  master: "https://docs.account.synamedia.com",
};

const CMSPanel = ({
  slug,
  type,
  border,
  lineSeparator,
  environment,
  preview,
  loaderType,
  overrideHeadingStyle,
  ...rest
}) => {
  const [CMSPanelData, setCMSPanelData] = useState(null);
  const [credentials, setCredentials] = useState(null);
  const [loading, setLoading] = useState(true);

  const fetchGraphQL = async () => {
    const query = `{
      resourcePanelGroupCollection(where: {slug: "${slug}"} ${
      preview ? ", preview: true" : ""
    }) {
        items {
          heading
          description
          icon {
            title
            url
          }
          resourcePanelsCollection(limit: 10) {
            items {
              slug
              heading
              description
              title
              comment
              icon {
                url
                title
              }
              link {
                url
                displayText
              }
            }
          }
        }
      }
    }`;
    const response = await fetch(
      `https://graphql.contentful.com/content/v1/spaces/${credentials.space}/environments/${environment}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${
            preview ? credentials.previewAccessToken : credentials.accessToken
          }`,
        },
        body: JSON.stringify({ query }),
      }
    );
    if (response?.status !== 200) {
      // eslint-disable-next-line no-console
      console.error(`${query} failed with status code: ${response?.status}`);
      throw Error;
    }

    const responseData = await response?.json();
    if (responseData.errors?.length > 0) {
      // eslint-disable-next-line no-console
      console.error(
        `${query} failed with errors: ${JSON.stringify(responseData.errors)}`
      );
      throw Error;
    }

    return responseData;
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          `${environmentToURL[environment]}/api/credentials`,
          {
            method: "GET",
          }
        );
        const credentialsResponse = await response.json();
        setCredentials(credentialsResponse);
      } catch (error) {
        if (process.env.NODE_ENV !== "production") {
          // eslint-disable-next-line no-console
          console.error(
            `Error getting environment credentials: ${error.message}`
          );
        }
      }
    };

    fetchData();
  }, [environment]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (credentials) {
          const entry = await fetchGraphQL();
          const item = entry.data.resourcePanelGroupCollection.items[0];
          setCMSPanelData(item);
          setLoading(false);
        }
      } catch (error) {
        if (process.env.NODE_ENV !== "production") {
          // eslint-disable-next-line no-console
          console.error(`Error fetching entry from Contentful: ${error}`);
        }
      }
    };

    fetchData();
  }, [credentials]);

  return loading ? (
    <Loader type={loaderType} />
  ) : (
    CMSPanelData && (
      <RootStyle type={type} border={border} {...rest}>
        <GroupHeadlineStyle target="_blank">
          {CMSPanelData.icon && (
            <GroupIconStyle
              type={type}
              src={CMSPanelData.icon.url}
              alt={CMSPanelData.icon.title}
            />
          )}

          {CMSPanelData.heading && (
            <GroupHeadingStyle
              type={type}
              {...(overrideHeadingStyle && { style: overrideHeadingStyle })}
            >
              {CMSPanelData.heading}
            </GroupHeadingStyle>
          )}
        </GroupHeadlineStyle>

        {CMSPanelData.description && (
          <GroupDescriptionStyle>
            {CMSPanelData.description}
          </GroupDescriptionStyle>
        )}

        <SectionsStyle type={type}>
          {CMSPanelData.resourcePanelsCollection.items.map((data) => (
            <React.Fragment key={data.slug}>
              <SectionStyle
                data-testid={`SectionStyle_id_${data.slug}`}
                type={type}
                lineSeparator={lineSeparator}
                {...(data.link && { href: data.link.url, target: "_blank" })}
              >
                {data.heading && (
                  <SectionHeadingStyle type={type}>
                    {data.heading}
                    {data.link && type === "layered" && (
                      <SectionLinkStyle type={type}>
                        <VectorIcon />
                      </SectionLinkStyle>
                    )}
                  </SectionHeadingStyle>
                )}

                {data.icon && (
                  <SectionIconStyle
                    type={type}
                    src={data.icon.url}
                    alt={data.icon.title}
                  />
                )}
                <SectionContentStyle>
                  {data.title && (
                    <SectionTitleStyle type={type}>
                      {data.title}
                      {data.link && type === "layered" && (
                        <SectionLinkStyle type={type}>
                          <VectorIcon />
                        </SectionLinkStyle>
                      )}
                    </SectionTitleStyle>
                  )}

                  {data.description && (
                    <SectionDescStyle type={type}>
                      {data.description}
                      {data.link && type === "layered" && (
                        <SectionLinkStyle type={type} href={data.link.url}>
                          <VectorIcon />
                        </SectionLinkStyle>
                      )}
                    </SectionDescStyle>
                  )}
                </SectionContentStyle>
                {data.link && type === "cards" && (
                  <SectionLinkStyle type={type}>
                    {data.link.displayText}
                  </SectionLinkStyle>
                )}

                {data.comment && (
                  <SectionCommentStyle>{data.comment}</SectionCommentStyle>
                )}
              </SectionStyle>
            </React.Fragment>
          ))}
        </SectionsStyle>
      </RootStyle>
    )
  );
};

CMSPanel.propTypes = {
  slug: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  environment: PropTypes.oneOf(["dev", "staging", "master"]),
  preview: PropTypes.bool,
  type: PropTypes.oneOf(["cards", "layered"]),
  border: PropTypes.bool,
  lineSeparator: PropTypes.bool,
  overrideHeadingStyle: PropTypes.oneOfType([PropTypes.object]),
  loaderType: PropTypes.oneOf([
    "Audio",
    "BallTriangle",
    "Bars",
    "Circles",
    "Grid",
    "Hearts",
    "Oval",
    "Puff",
    "Rings",
    "TailSpin",
    "ThreeDots",
    "Watch",
    "RevolvingDot",
    "Triangle",
    "Plane",
    "MutatingDots",
    "CradleLoader",
  ]),
};

CMSPanel.defaultProps = {
  environment: "master",
  preview: false,
  type: "layered",
  border: true,
  lineSeparator: false,
  loaderType: "ThreeDots",
};

export default CMSPanel;

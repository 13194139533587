import * as React from "react";
const SvgDealIcon = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 16 16"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M3.5 4.5c0-.531.438-1 1-1 .531 0 1 .469 1 1 0 .563-.469 1-1 1-.563 0-1-.438-1-1ZM7.156 1c.532 0 1.032.219 1.407.594l5.5 5.5a2.018 2.018 0 0 1 0 2.843l-4.157 4.157a2.018 2.018 0 0 1-2.844 0l-5.5-5.5A1.959 1.959 0 0 1 1 7.188V2.5A1.5 1.5 0 0 1 2.5 1h4.656ZM2.625 7.531l5.5 5.5a.476.476 0 0 0 .719 0L13 8.875a.476.476 0 0 0 0-.719l-5.5-5.5a.502.502 0 0 0-.344-.156H2.5v4.688c0 .125.031.25.125.343Z"
      fill="inherit"
    />
  </svg>
);
export default SvgDealIcon;

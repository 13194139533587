import React from "react";
import PropTypes from "prop-types";
import EmptyState from "../../EmptyState";
import NoDataSvgArt from "./NoDataSvgArt";

const EmptyDataFallback = ({ width, title }) => {
  return (
    <EmptyState
      data-testid="emptyDataFallback"
      image={<NoDataSvgArt height="256px" width="263px" />}
      style={{
        background: "white",
        width,
        margin: "auto",
      }}
      title={title || "No data found"}
      showImage
      showText
    />
  );
};

EmptyDataFallback.propTypes = {
  width: PropTypes.string,
  title: PropTypes.string,
};

export default EmptyDataFallback;

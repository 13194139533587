export const SIZE_VARIENTS = [
  "compact",
  "extraSmall",
  "small",
  "medium",
  "large",
  "extraLarge",
];

export const MAX_PAGE_SIZE = 999999;

export const PREDEFINED_COLUMNS = {
  ROW_SELECTION_COLUMN: "tableSelection",
  ROW_ACTIONS_COLUMN: "tableActions",
};

import tokens from "../../tokens/json/variables.json";

export const ContainerStyles = {
  all: "unset",
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  gap: tokens.spacing15,

  '&[data-state="checked"]': {
    color: tokens.iconIconBrand,
    fill: tokens.iconIconBrand,
  },

  '&[data-state="indeterminate"]': {
    color: tokens.iconIconBrand,
    fill: tokens.iconIconBrand,
  },

  "&[data-disabled] *": {
    fill: tokens.iconIconDisabled,
    color: tokens.iconIconDisabled,
  },

  "&:focus-within > div:first-child": {
    boxShadow: "0 0 0 2px",
  },

  variants: {
    readOnly: {
      true: {
        pointerEvents: "none",
        svg: {
          fill: tokens.iconIconDisabled,
        },
      },
    },

    error: {
      true: {
        svg: {
          fill: tokens.textTextError,
        },
      },
    },
  },
};

export const BaseIconStyles = {
  width: tokens.sizing35,
  height: tokens.sizing35,
  fill: tokens.iconIconSecondary,
};

export const InfoIconStyles = {
  width: tokens.sizing3,
  height: tokens.sizing3,
  marginLeft: tokens.spacing1,
  fill: tokens.iconIconSecondary,
};

export const LabelContainerStyles = {
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "flex-start",
  paddingBottom: tokens.spacing2,
};

export const LabelStyles = {
  color: tokens.textTextSecondary,
  fontFamily: tokens.labelFontFamily,
  fontSize: tokens.labelFontSize,
  fontWeight: tokens.labelFontWeight,
  lineHeight: tokens.labelLineHeight,
  letterSpacing: tokens.labelLetterSpacing,
  paragraphSpacing: tokens.labelParagraphSpacing,

  variants: {
    disabled: {
      true: {
        color: tokens.textTextDisabled,
      },
    },
    required: {
      true: {
        "&::after": {
          content: " *",
          color: tokens.textTextError,
          fontFamily: tokens.labelFontFamily,
          fontSize: tokens.labelFontSize,
          fontWeight: tokens.labelFontWeight,
          lineHeight: tokens.labelLineHeight,
          letterSpacing: tokens.labelLetterSpacing,
          paragraphSpacing: tokens.labelParagraphSpacing,
        },
      },
    },
  },
};

export const TextStyles = {
  color: tokens.textTextPrimary,
  fontFamily: tokens.bodyShort01FontFamily,
  fontWeight: tokens.bodyShort01FontWeight,
  fontSize: tokens.bodyShort01FontSize,
  letterSpacing: tokens.bodyShort01LetterSpacing,
  lineHeight: tokens.bodyShort01LineHeight,
  textTransform: tokens.bodyShort01TextCase,
  textDecoration: tokens.bodyShort01TextDecoration,
  variants: {
    required: {
      true: {
        "&::after": {
          content: " *",
          color: tokens.textTextError,
          fontFamily: tokens.labelFontFamily,
          fontSize: tokens.labelFontSize,
          fontWeight: tokens.labelFontWeight,
          lineHeight: tokens.labelLineHeight,
          letterSpacing: tokens.labelLetterSpacing,
          paragraphSpacing: tokens.labelParagraphSpacing,
        },
      },
    },
  },
};

export const HelperTextStyles = {
  padding: `${tokens.spacing1} 0px 0px 0px`,
  color: tokens.textTextSecondary,

  fontFamily: tokens.labelFontFamily,
  fontWeight: tokens.labelFontWeight,
  lineHeight: tokens.labelLineHeight,
  fontSize: tokens.labelFontSize,
  letterSpacing: tokens.labelLetterSpacing,
  paragraphSpacing: tokens.labelParagraphSpacing,
  textCase: tokens.labelTextCase,
  textDecoration: tokens.labelTextDecoration,

  variants: {
    disabled: {
      true: {
        color: tokens.textTextDisabled,
      },
    },
    error: {
      true: {
        color: tokens.textTextError,
      },
    },
  },
};

import React from "react";
import PropTypes from "prop-types";
import { styled } from "@stitches/react";
import { TabsContainerStyles } from "./Tabs.styles";

const TabsContainer = styled("div", TabsContainerStyles);
const Tabs = (props) => {
  const { selected, onClick, children } = props;
  return (
    <TabsContainer data-testid="tabs">
      {children &&
        Array.isArray(children) &&
        children.map((childElement) => {
          return React.cloneElement(childElement, {
            key: childElement.props.id,
            id: childElement.props.id,
            selected: selected === childElement?.props?.id,
            disabled: childElement?.props?.disabled,
            label: childElement?.props?.label,
            onClick: (event, id) =>
              !childElement?.props?.disabled &&
              onClick(event, childElement?.props?.id || id),
          });
        })}
    </TabsContainer>
  );
};

Tabs.propTypes = {
  children: PropTypes.node,
  onClick: PropTypes.func,
  selected: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default Tabs;

import tokens from "../../tokens/json/variables.json";

export const MenuStyles = {
  all: "unset",
  listStyle: "none",
  boxSizing: "border-box",
  display: "flex",
  flexDirection: "column",
  width: tokens.sizing64,
  height: "100vh",
  backgroundColor: tokens.headerHeaderBackground,
  userSelect: "none",
  zIndex: 1,
  transition: "width 0.6s ease",

  '&[data-iscollapsed="true"]': {
    width: tokens.sizing16,
  },
};

export const ListStyles = {
  all: "unset",
  display: "flex",
  flexDirection: "column",
  justifyContent: "flex-start",
  padding: "8px",
  overflow: "scroll",
  flexGrow: "6",
  gap: tokens.spacing1,
  variants: {
    isCollapsed: {
      true: {
        margin: "8px",
        padding: "0",
        width: tokens.sizing12,
      },
    },
  },
};

export const CollapseItemStyles = {
  boxSizing: "border-box",
  width: tokens.sizing16,
  all: "unset",
  display: "flex",
  paddingTop: tokens.spacing2,
  paddingLeft: "8px",
  flexDirection: "column",
  alignItems: "flex-start",
  justifyContent: "flex-start",
  border: `1px solid ${tokens.layerLayerSelectedInverse}`,
  boxShadow:
    "0px 2px 2px rgba(0, 0, 0, 0.1), 0px 2px 4px rgba(0, 0, 0, 0.1), 0px 4px 8px rgba(0, 0, 0, 0.1)",
};

export const CollapseIconContainerStyles = {
  all: "unset",
  width: tokens.sizing12,
  height: tokens.sizing12,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  borderRadius: "4px",
  courser: "pointer",
};

export const CollapseIconStyles = {
  all: "unset",
  width: tokens.sizing5,
  height: tokens.sizing5,
  color: tokens.headerHeaderIcon,
};

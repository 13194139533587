import * as React from "react";
const SvgUserIcon = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 16 16"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M8.906 9.719H6.47A4.46 4.46 0 0 0 2 14.188c0 .457.355.812.813.812h9.75a.818.818 0 0 0 .812-.813A4.475 4.475 0 0 0 8.906 9.72ZM3.22 13.78a3.267 3.267 0 0 1 3.25-2.844h2.437a3.261 3.261 0 0 1 3.225 2.844H3.219ZM7.688 8.5a3.256 3.256 0 0 0 3.25-3.25c0-1.777-1.473-3.25-3.25-3.25a3.256 3.256 0 0 0-3.25 3.25 3.24 3.24 0 0 0 3.25 3.25Zm0-5.281c1.117 0 2.03.914 2.03 2.031a2.021 2.021 0 0 1-2.03 2.031A2.005 2.005 0 0 1 5.655 5.25c0-1.117.889-2.031 2.032-2.031Z"
      fill="inherit"
    />
  </svg>
);
export default SvgUserIcon;

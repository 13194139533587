import tokens from "../../tokens/json/variables.json";

export const ContainerStyles = {
  boxSizing: "border-box",
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  rowGap: tokens.spacing3,

  button: {
    position: "relative",
  },
};

export const LabelContainerStyles = {
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  "&[data-disabled=true] > div": {
    color: tokens.textTextDisabled,
  },
};

export const LabelStyles = {
  paddingBottom: tokens.spacing15,
  color: tokens.textTextSecondary,
  fontFamily: tokens.bodyShort01FontFamily,
  fontWeight: tokens.bodyShort01FontWeight,
  fontSize: tokens.bodyShort01FontSize,
  letterSpacing: tokens.bodyShort01LetterSpacing,
  lineHeight: tokens.bodyShort01LineHeight,
  textTransform: tokens.bodyShort01TextCase,
  textDecoration: tokens.bodyShort01TextDecoration,
};

export const HelperTextStyles = {
  color: tokens.textTextSecondary,
  fontFamily: tokens.labelFontFamily,
  fontWeight: tokens.labelFontWeight,
  fontSize: tokens.labelFontSize,
  letterSpacing: tokens.labelLetterSpacing,
  lineHeight: tokens.labelLineHeight,
  textTransform: tokens.labelTextCase,
  textDecoration: tokens.labelTextDecoration,
};

export const DragContainerStyles = {
  boxSizing: "border-box",
  width: "100%",
  position: "relative",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  rowGap: tokens.spacing1,
  justifyContent: "center",
  backgroundColor: tokens.layerLayer01,
  border: `${tokens.borderWidth1} dashed ${tokens.borderBorderSubtle02}`,
  borderRadius: tokens.borderRadius1,
  padding: tokens.spacing6,

  "&:hover": {
    borderStyle: "solid",
    borderColor: tokens.focusFocus,
    "&:last-child span": {
      textDecoration: "underline",
    },
  },

  "&[data-active=true]": {
    borderStyle: "solid",
    borderColor: tokens.focusFocus,
    backgroundColor: tokens.backgroundBackgroundHover,
  },
};

export const DragTextStyles = {
  color: tokens.textTextSecondary,
  fontFamily: tokens.bodyShort01FontFamily,
  fontWeight: tokens.bodyShort01FontWeight,
  fontSize: tokens.bodyShort01FontSize,
  letterSpacing: tokens.bodyShort01LetterSpacing,
  lineHeight: tokens.bodyShort01LineHeight,
  textTransform: tokens.bodyShort01TextCase,
  textDecoration: tokens.bodyShort01TextDecoration,

  span: {
    color: tokens.linkLinkPrimary,
    fontFamily: tokens.semiBoldBodyShort01FontFamily,
    fontWeight: tokens.semiBoldBodyShort01FontWeight,
    fontSize: tokens.semiBoldBodyShort01FontSize,
    letterSpacing: tokens.semiBoldBodyShort01LetterSpacing,
    lineHeight: tokens.semiBoldBodyShort01LineHeight,
    textTransform: tokens.semiBoldBodyShort01TextCase,
    textDecoration: tokens.semiBoldBodyShort01TextDecoration,
  },
};

export const DragInputStyles = {
  position: "absolute",
  width: "100%",
  height: "100%",
  cursor: "pointer",
  fontSize: 0,
  opacity: 0,

  "&::-webkit-file-upload-button": {
    cursor: "pointer",
  },
};

export const ProgressListStyles = {
  width: "100%",
  display: "flex",
  flexDirection: "column",
  rowGap: tokens.spacing3,
  paddingTop: tokens.spacing2,
};

export const ProgressContainerStyles = {
  boxSizing: "border-box",
  width: "100%",
  display: "flex",
  alignItems: "center",
  padding: `${tokens.spacing15} ${tokens.spacing15} ${tokens.spacing1} ${tokens.spacing3}`,
  columnGap: tokens.spacing15,
  backgroundColor: tokens.layerLayer03,
  borderRadius: tokens.borderRadius1,

  button: {
    marginLeft: "auto",
  },
};

import * as React from "react";
const SvgInfoFilledIcon = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 16 16"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#InfoFilledIcon_svg__a)">
      <path fill="#fff" d="M6 2h5v11H6z" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0ZM6.25 6.812c0-.345.298-.625.667-.625H8.75c.369 0 .667.28.667.625v4.375h.666c.37 0 .667.28.667.626 0 .345-.298.624-.667.624H6.917c-.37 0-.667-.279-.667-.624 0-.346.298-.626.667-.626h.666v-3.75h-.666c-.37 0-.667-.279-.667-.625Zm1.9-1.75a1.25 1.25 0 1 0 0-2.5 1.25 1.25 0 0 0 0 2.5Z"
        fill="inherit"
      />
    </g>
    <defs>
      <clipPath id="InfoFilledIcon_svg__a">
        <path fill="#fff" d="M0 0h16v16H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgInfoFilledIcon;

import * as React from "react";
const SvgLoaderIcon = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 16 16"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#LoaderIcon_svg__a)">
      <path
        d="M9.5 1.5a1.5 1.5 0 1 0-3 0 1.5 1.5 0 0 0 3 0Zm0 13a1.5 1.5 0 1 0-3 0 1.5 1.5 0 0 0 3 0Zm-8-5a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3ZM16 8a1.5 1.5 0 1 0-3 0 1.5 1.5 0 0 0 3 0ZM4.466 13.656a1.5 1.5 0 1 0-2.122-2.122 1.5 1.5 0 0 0 2.122 2.122Zm0-9.194a1.503 1.503 0 0 0 0-2.121 1.498 1.498 0 0 0-2.122.003 1.5 1.5 0 1 0 2.122 2.122v-.003Zm7.068 9.194a1.5 1.5 0 1 0 2.122-2.122 1.5 1.5 0 0 0-2.122 2.122Z"
        fill="inherit"
      />
    </g>
    <defs>
      <clipPath id="LoaderIcon_svg__a">
        <path fill="transparent" d="M0 0h16v16H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgLoaderIcon;

import React from "react";
import PropTypes from "prop-types";
import EmptyState from "../../EmptyState";
import NoResultsSvgArt from "./NoResultsSvgArt";

const EmptyResultsFallback = ({ width }) => {
  return (
    <EmptyState
      data-testid="emptyResultsFallback"
      description="Modify the filter options or search terms to find what you're looking for."
      image={<NoResultsSvgArt height="256px" width="263px" />}
      style={{
        background: "white",
        width,
        margin: "auto",
      }}
      title="No results found"
      showImage
      showText
    />
  );
};

EmptyResultsFallback.propTypes = {
  width: PropTypes.string,
};

export default EmptyResultsFallback;

import * as React from "react";

const NoDataSvgArt = (props) => (
  <svg
    width="274"
    height="256"
    viewBox="0 0 274 256"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M201.851 194.263L64.2996 229.684C59.6337 230.88 54.684 230.176 50.5363 227.727C46.3886 225.278 43.3817 221.284 42.1753 216.621L0.57616 55.0778C-0.619744 50.4119 0.0839781 45.4622 2.53295 41.3145C4.98192 37.1668 8.97614 34.1599 13.6393 32.9535L141.608 0L174.362 14.6629L214.914 172.138C216.11 176.804 215.406 181.754 212.957 185.902C210.508 190.049 206.514 193.056 201.851 194.263Z"
      fill="#F1F3F7"
    />
    <path
      d="M14.9435 38.0183C11.6228 38.8775 8.77839 41.0187 7.03442 43.9724C5.29046 46.9261 4.78932 50.4509 5.64096 53.7736L47.2401 215.316C48.0992 218.637 50.2405 221.481 53.1941 223.225C56.1478 224.969 59.6726 225.471 62.9953 224.619L200.547 189.198C203.867 188.339 206.712 186.197 208.456 183.244C210.2 180.29 210.701 176.765 209.849 173.443L169.926 18.4072L141.141 5.52106L14.9435 38.0183Z"
      fill="white"
    />
    <path
      d="M174.013 15.4963L152.725 20.9782C151.171 21.3784 149.521 21.1448 148.139 20.3289C146.758 19.513 145.756 18.1815 145.356 16.6274L141.307 0.904486C141.29 0.835651 141.292 0.763213 141.313 0.695481C141.335 0.627749 141.375 0.567464 141.429 0.521539C141.483 0.475614 141.55 0.445908 141.62 0.435829C141.69 0.425749 141.762 0.435703 141.827 0.46455L174.072 14.7844C174.144 14.8163 174.204 14.8697 174.244 14.9373C174.284 15.0048 174.302 15.0831 174.295 15.1614C174.289 15.2396 174.258 15.3139 174.207 15.3739C174.157 15.4339 174.089 15.4767 174.013 15.4963Z"
      fill="#D8DEE9"
    />
    <path
      d="M162.813 153.45L100.188 169.577C99.8033 169.676 99.4028 169.698 99.0095 169.643C98.6161 169.587 98.2375 169.454 97.8954 169.252C97.5533 169.05 97.2543 168.783 97.0155 168.465C96.7767 168.148 96.6029 167.786 96.5038 167.402C96.4047 167.017 96.3824 166.616 96.4381 166.223C96.4938 165.83 96.6264 165.451 96.8284 165.109C97.0304 164.767 97.2978 164.468 97.6154 164.229C97.9329 163.99 98.2944 163.816 98.6791 163.717L161.304 147.591C161.689 147.492 162.09 147.469 162.483 147.525C162.876 147.581 163.255 147.713 163.597 147.915C163.939 148.117 164.238 148.385 164.477 148.702C164.716 149.02 164.89 149.381 164.989 149.766C165.088 150.151 165.11 150.551 165.054 150.945C164.999 151.338 164.866 151.717 164.664 152.059C164.462 152.401 164.195 152.7 163.877 152.939C163.559 153.177 163.198 153.351 162.813 153.45Z"
      fill="#CCCCCC"
    />
    <path
      d="M177.445 160.226L102.734 179.465C101.957 179.665 101.133 179.549 100.442 179.141C99.7509 178.733 99.2503 178.067 99.0502 177.29C98.8501 176.513 98.9669 175.688 99.3748 174.997C99.7828 174.306 100.449 173.806 101.226 173.606L175.936 154.367C176.713 154.167 177.537 154.285 178.227 154.693C178.918 155.101 179.418 155.766 179.618 156.543C179.818 157.319 179.701 158.143 179.294 158.834C178.887 159.525 178.222 160.026 177.445 160.226Z"
      fill="#CCCCCC"
    />
    <path
      d="M74.628 189C80.5337 189 85.3212 184.212 85.3212 178.307C85.3212 172.401 80.5337 167.614 74.628 167.614C68.7223 167.614 63.9348 172.401 63.9348 178.307C63.9348 184.212 68.7223 189 74.628 189Z"
      fill="#B8C3D6"
    />
    <path
      d="M156.154 125.466L72.9819 146.875C70.5782 147.491 68.0284 147.129 65.8918 145.867C63.7552 144.605 62.2064 142.547 61.5851 140.145L44.1553 72.434C43.5394 70.0303 43.9022 67.4805 45.1639 65.3439C46.4257 63.2073 48.4835 61.6585 50.8858 61.0372L134.058 39.6273C136.462 39.0115 139.012 39.3742 141.148 40.636C143.285 41.8978 144.834 43.9555 145.455 46.3579L162.885 114.069C163.501 116.472 163.138 119.022 161.876 121.159C160.614 123.295 158.557 124.844 156.154 125.466Z"
      fill="white"
    />
    <path
      d="M156.154 125.466L72.9819 146.875C70.5782 147.491 68.0284 147.129 65.8918 145.867C63.7552 144.605 62.2064 142.547 61.5851 140.145L44.1553 72.434C43.5394 70.0303 43.9022 67.4805 45.1639 65.3439C46.4257 63.2073 48.4835 61.6585 50.8858 61.0372L134.058 39.6273C136.462 39.0115 139.012 39.3742 141.148 40.636C143.285 41.8978 144.834 43.9555 145.455 46.3579L162.885 114.069C163.501 116.472 163.138 119.022 161.876 121.159C160.614 123.295 158.557 124.844 156.154 125.466ZM51.1603 62.1035C49.0406 62.6517 47.225 64.0183 46.1116 65.9036C44.9983 67.7888 44.6782 70.0386 45.2216 72.1595L62.6515 139.87C63.1997 141.99 64.5663 143.806 66.4515 144.919C68.3367 146.032 70.5865 146.353 72.7075 145.809L155.88 124.399C158 123.851 159.815 122.484 160.929 120.599C162.042 118.714 162.362 116.464 161.819 114.343L144.389 46.6323C143.841 44.5127 142.474 42.697 140.589 41.5836C138.703 40.4703 136.454 40.1502 134.333 40.6936L51.1603 62.1035Z"
      fill="#B8C3D6"
    />
    <path
      d="M129.839 62.3944L87.8627 73.1997C87.4957 73.3021 87.1035 73.2614 86.7654 73.0858C86.4273 72.9101 86.1685 72.6127 86.0413 72.2535C85.9821 72.0659 85.9617 71.8682 85.9814 71.6725C86.0011 71.4767 86.0606 71.287 86.156 71.115C86.2515 70.943 86.381 70.7922 86.5366 70.6719C86.6923 70.5516 86.8708 70.4642 87.0613 70.4151L129.79 59.416C131.516 60.2906 131.063 62.0793 129.839 62.3944Z"
      fill="#D8DEE9"
    />
    <path
      d="M131.734 69.7573L89.7579 80.5626C89.391 80.665 88.9988 80.6243 88.6607 80.4487C88.3225 80.273 88.0637 79.9756 87.9365 79.6164C87.8773 79.4288 87.857 79.2311 87.8767 79.0354C87.8964 78.8396 87.9558 78.65 88.0513 78.4779C88.1467 78.3059 88.2762 78.1551 88.4319 78.0348C88.5875 77.9145 88.7661 77.8271 88.9566 77.7781L131.686 66.7789C133.412 67.6535 132.958 69.4422 131.734 69.7573Z"
      fill="#D8DEE9"
    />
    <path
      d="M81.7629 87.0906L68.9522 90.3883C68.5338 90.4956 68.09 90.4324 67.7181 90.2128C67.3462 89.9932 67.0766 89.635 66.9685 89.2168L63.077 74.0991C62.9697 73.6808 63.0328 73.2369 63.2525 72.865C63.4721 72.4931 63.8303 72.2235 64.2485 72.1154L77.0591 68.8177C77.4775 68.7106 77.9213 68.7738 78.2932 68.9934C78.6651 69.2131 78.9347 69.5712 79.0429 69.9893L82.9344 85.1069C83.0416 85.5253 82.9785 85.9692 82.7589 86.3411C82.5393 86.713 82.1811 86.9825 81.7629 87.0906Z"
      fill="#B8C3D6"
    />
    <path
      d="M135.493 84.8642L71.4296 101.355C71.0626 101.458 70.6704 101.417 70.3323 101.241C69.9942 101.066 69.7354 100.768 69.6082 100.409C69.549 100.221 69.5286 100.024 69.5483 99.8279C69.568 99.6322 69.6274 99.4425 69.7229 99.2705C69.8183 99.0984 69.9479 98.9477 70.1035 98.8273C70.2592 98.707 70.4377 98.6196 70.6282 98.5706L135.445 81.8857C137.171 82.7603 136.717 84.5492 135.493 84.8642Z"
      fill="#D8DEE9"
    />
    <path
      d="M137.389 92.2293L73.3253 108.72C72.9584 108.823 72.5662 108.782 72.2281 108.606C71.89 108.431 71.6311 108.133 71.5039 107.774C71.4447 107.586 71.4243 107.389 71.4441 107.193C71.4638 106.997 71.5232 106.808 71.6186 106.636C71.7141 106.464 71.8436 106.313 71.9993 106.192C72.1549 106.072 72.3334 105.985 72.524 105.936L137.341 89.2509C139.067 90.1254 138.612 91.9142 137.389 92.2293Z"
      fill="#D8DEE9"
    />
    <path
      d="M139.283 99.5894L75.2201 116.08C74.8531 116.183 74.4609 116.142 74.1228 115.966C73.7847 115.791 73.5259 115.493 73.3987 115.134C73.3395 114.947 73.3191 114.749 73.3388 114.553C73.3586 114.357 73.418 114.168 73.5134 113.996C73.6089 113.824 73.7384 113.673 73.894 113.553C74.0497 113.432 74.2282 113.345 74.4187 113.296L139.235 96.611C140.961 97.4855 140.507 99.2744 139.283 99.5894Z"
      fill="#D8DEE9"
    />
    <path
      d="M141.179 106.954L77.1159 123.445C76.7489 123.547 76.3567 123.507 76.0186 123.331C75.6805 123.155 75.4216 122.858 75.2944 122.499C75.2352 122.311 75.2149 122.113 75.2346 121.918C75.2543 121.722 75.3137 121.532 75.4092 121.36C75.5046 121.188 75.6341 121.037 75.7898 120.917C75.9454 120.797 76.124 120.709 76.3145 120.66L141.131 103.975C142.857 104.85 142.403 106.639 141.179 106.954Z"
      fill="#D8DEE9"
    />
    <path
      d="M143.074 114.315L79.0106 130.806C78.6437 130.908 78.2515 130.867 77.9134 130.692C77.5752 130.516 77.3164 130.219 77.1892 129.86C77.13 129.672 77.1096 129.474 77.1294 129.278C77.1491 129.083 77.2085 128.893 77.304 128.721C77.3994 128.549 77.5289 128.398 77.6845 128.278C77.8402 128.158 78.0187 128.07 78.2093 128.021L143.026 111.336C144.752 112.211 144.298 114 143.074 114.315Z"
      fill="#D8DEE9"
    />
    <path
      d="M75.4171 182.055C75.1842 182.116 74.9381 182.099 74.7157 182.007L74.7019 182.001L71.7889 180.766C71.6537 180.709 71.5311 180.626 71.4281 180.521C71.3252 180.416 71.2438 180.292 71.1888 180.156C71.1338 180.02 71.1062 179.875 71.1075 179.728C71.1088 179.581 71.139 179.436 71.1965 179.301C71.2539 179.166 71.3374 179.043 71.4422 178.94C71.5471 178.838 71.6711 178.756 71.8073 178.702C71.9435 178.647 72.0892 178.619 72.236 178.621C72.3828 178.623 72.5278 178.653 72.6628 178.711L74.5504 179.514L77.0239 173.695C77.0813 173.56 77.1647 173.438 77.2693 173.335C77.3739 173.232 77.4978 173.151 77.6337 173.096C77.7697 173.041 77.9151 173.014 78.0617 173.015C78.2083 173.017 78.3532 173.047 78.4881 173.104L78.4736 173.142L78.4891 173.104C78.7613 173.22 78.9764 173.44 79.0871 173.714C79.1978 173.989 79.195 174.296 79.0794 174.569L76.1693 181.41C76.1019 181.568 75.999 181.708 75.8686 181.82C75.7383 181.931 75.584 182.011 75.4177 182.054L75.4171 182.055Z"
      fill="white"
    />
    <path
      d="M254.61 255.822H112.571C107.755 255.817 103.137 253.901 99.7308 250.495C96.3248 247.089 94.409 242.471 94.4036 237.655V70.8418C94.409 66.0251 96.3248 61.4072 99.7308 58.0013C103.137 54.5954 107.755 52.6795 112.571 52.6741H244.715L272.778 75.0418V237.655C272.772 242.471 270.856 247.089 267.451 250.495C264.045 253.901 259.427 255.817 254.61 255.822Z"
      fill="#D8DEE9"
    />
    <path
      d="M112.571 57.9042C109.141 57.908 105.853 59.2724 103.427 61.6978C101.002 64.1232 99.6374 67.4117 99.6335 70.8418V237.655C99.6374 241.085 101.002 244.373 103.427 246.799C105.853 249.224 109.141 250.588 112.571 250.592H254.61C258.04 250.588 261.328 249.224 263.754 246.799C266.179 244.373 267.544 241.085 267.548 237.655V77.5614L242.886 57.9042H112.571Z"
      fill="white"
    />
    <path
      d="M226.983 106.409H162.315C161.513 106.409 160.743 106.09 160.176 105.523C159.609 104.955 159.29 104.186 159.29 103.383C159.29 102.581 159.609 101.812 160.176 101.244C160.743 100.677 161.513 100.358 162.315 100.358H226.983C227.786 100.358 228.555 100.677 229.123 101.244C229.69 101.812 230.009 102.581 230.009 103.383C230.009 104.186 229.69 104.955 229.123 105.523C228.555 106.09 227.786 106.409 226.983 106.409Z"
      fill="#B8C3D6"
    />
    <path
      d="M239.463 116.62H162.315C161.514 116.619 160.745 116.299 160.179 115.732C159.612 115.165 159.294 114.396 159.294 113.594C159.294 112.793 159.612 112.024 160.179 111.456C160.745 110.889 161.514 110.57 162.315 110.569H239.463C239.861 110.568 240.255 110.646 240.622 110.798C240.99 110.95 241.324 111.173 241.605 111.454C241.887 111.735 242.11 112.068 242.262 112.436C242.414 112.803 242.493 113.197 242.493 113.594C242.493 113.992 242.414 114.386 242.262 114.753C242.11 115.12 241.887 115.454 241.605 115.735C241.324 116.016 240.99 116.239 240.622 116.391C240.255 116.542 239.861 116.62 239.463 116.62Z"
      fill="#B8C3D6"
    />
    <path
      d="M226.984 146.116H162.318C161.515 146.116 160.746 146.434 160.179 147.002C159.611 147.569 159.292 148.339 159.292 149.141C159.292 149.943 159.611 150.713 160.179 151.28C160.746 151.847 161.515 152.166 162.318 152.166H226.984C227.786 152.166 228.556 151.847 229.123 151.28C229.69 150.713 230.009 149.943 230.009 149.141C230.009 148.339 229.69 147.569 229.123 147.002C228.556 146.434 227.786 146.116 226.984 146.116Z"
      fill="#B8C3D6"
    />
    <path
      d="M239.464 156.328H162.318C161.515 156.328 160.746 156.647 160.179 157.214C159.611 157.782 159.292 158.551 159.292 159.353C159.292 160.156 159.611 160.925 160.179 161.493C160.746 162.06 161.515 162.379 162.318 162.379H239.464C240.267 162.379 241.036 162.06 241.604 161.493C242.171 160.925 242.49 160.156 242.49 159.353C242.49 158.551 242.171 157.782 241.604 157.214C241.036 156.647 240.267 156.328 239.464 156.328Z"
      fill="#B8C3D6"
    />
    <path
      d="M226.983 197.928H162.315C161.513 197.928 160.743 197.609 160.176 197.042C159.609 196.474 159.29 195.705 159.29 194.902C159.29 194.1 159.609 193.33 160.176 192.763C160.743 192.196 161.513 191.877 162.315 191.877H226.983C227.786 191.877 228.555 192.196 229.123 192.763C229.69 193.33 230.009 194.1 230.009 194.902C230.009 195.705 229.69 196.474 229.123 197.042C228.555 197.609 227.786 197.928 226.983 197.928Z"
      fill="#B8C3D6"
    />
    <path
      d="M239.463 208.138H162.315C161.514 208.137 160.745 207.818 160.179 207.251C159.612 206.684 159.294 205.915 159.294 205.113C159.294 204.311 159.612 203.542 160.179 202.975C160.745 202.408 161.514 202.089 162.315 202.088H239.463C239.861 202.087 240.255 202.165 240.622 202.317C240.99 202.469 241.324 202.691 241.605 202.972C241.887 203.253 242.11 203.587 242.262 203.954C242.414 204.322 242.493 204.715 242.493 205.113C242.493 205.511 242.414 205.904 242.262 206.272C242.11 206.639 241.887 206.973 241.605 207.254C241.324 207.535 240.99 207.758 240.622 207.909C240.255 208.061 239.861 208.139 239.463 208.138Z"
      fill="#B8C3D6"
    />
    <path
      d="M135.386 119.182C141.291 119.182 146.079 114.395 146.079 108.489C146.079 102.583 141.291 97.7957 135.386 97.7957C129.48 97.7957 124.693 102.583 124.693 108.489C124.693 114.395 129.48 119.182 135.386 119.182Z"
      fill="#B8C3D6"
    />
    <path
      d="M134.326 112.568C134.086 112.568 133.852 112.491 133.659 112.346L133.647 112.337L131.134 110.415C131.017 110.326 130.919 110.215 130.846 110.088C130.772 109.96 130.724 109.82 130.705 109.675C130.685 109.529 130.695 109.381 130.733 109.239C130.77 109.097 130.836 108.964 130.925 108.848C131.015 108.731 131.126 108.633 131.253 108.56C131.381 108.487 131.521 108.439 131.667 108.42C131.812 108.401 131.96 108.411 132.102 108.449C132.244 108.487 132.377 108.553 132.493 108.642L134.121 109.891L137.967 104.872C138.056 104.756 138.168 104.658 138.295 104.585C138.421 104.512 138.562 104.464 138.707 104.445C138.852 104.426 139 104.435 139.142 104.473C139.283 104.511 139.416 104.577 139.532 104.666L139.509 104.699L139.533 104.666C139.768 104.846 139.922 105.112 139.96 105.406C139.999 105.699 139.92 105.996 139.74 106.231L135.215 112.131C135.111 112.267 134.976 112.377 134.822 112.453C134.668 112.528 134.499 112.567 134.327 112.567L134.326 112.568Z"
      fill="white"
    />
    <path
      d="M147.178 154.247C147.179 155.652 146.902 157.042 146.365 158.34C145.828 159.638 145.041 160.817 144.048 161.81C143.055 162.804 141.877 163.592 140.579 164.129C139.282 164.667 137.891 164.944 136.487 164.944C136.395 164.946 136.303 164.942 136.211 164.933C134.109 164.879 132.069 164.206 130.347 162.999C128.625 161.791 127.297 160.103 126.53 158.144C125.763 156.186 125.59 154.045 126.033 151.989C126.477 149.933 127.517 148.054 129.023 146.586C130.53 145.118 132.436 144.127 134.502 143.737C136.569 143.347 138.705 143.575 140.643 144.393C142.581 145.211 144.234 146.582 145.397 148.334C146.559 150.087 147.179 152.144 147.178 154.247Z"
      fill="#B8C3D6"
    />
    <path
      d="M135.386 210.701C141.291 210.701 146.079 205.913 146.079 200.008C146.079 194.102 141.291 189.314 135.386 189.314C129.48 189.314 124.693 194.102 124.693 200.008C124.693 205.913 129.48 210.701 135.386 210.701Z"
      fill="#B8C3D6"
    />
    <path
      d="M272.232 75.7617H250.249C248.644 75.7617 247.105 75.1242 245.97 73.9894C244.836 72.8547 244.198 71.3156 244.198 69.7108V53.4749C244.198 53.4038 244.218 53.3342 244.256 53.274C244.294 53.2138 244.348 53.1654 244.412 53.1345C244.476 53.1035 244.547 53.0912 244.618 53.099C244.689 53.1068 244.756 53.1343 244.811 53.1785L272.466 75.087C272.528 75.1358 272.573 75.2025 272.595 75.2779C272.617 75.3533 272.614 75.4336 272.589 75.5078C272.563 75.582 272.515 75.6462 272.451 75.6917C272.387 75.7372 272.31 75.7617 272.232 75.7617Z"
      fill="#B8C3D6"
    />
    <path
      d="M147.178 154.247C147.178 155.652 146.902 157.042 146.365 158.34C145.828 159.638 145.041 160.817 144.048 161.81C143.055 162.804 141.876 163.592 140.579 164.129C139.282 164.667 137.891 164.944 136.487 164.944C136.395 164.946 136.303 164.942 136.211 164.933C134.83 161.487 134.297 157.76 134.658 154.065C135.02 150.371 136.264 146.817 138.287 143.704C140.774 144.131 143.031 145.423 144.658 147.352C146.285 149.281 147.177 151.723 147.178 154.247Z"
      fill="#91A3C0"
    />
    <path
      d="M159.29 149.144C159.291 148.341 159.611 147.572 160.178 147.004C160.746 146.437 161.515 146.117 162.318 146.116H178.432C179.373 148.03 180.042 150.067 180.419 152.166H162.318C161.516 152.166 160.746 151.848 160.179 151.281C159.611 150.714 159.291 149.946 159.29 149.144Z"
      fill="#91A3C0"
    />
    <path
      d="M180.794 156.328C180.795 158.372 180.525 160.406 179.99 162.379H162.318C161.515 162.379 160.746 162.06 160.179 161.493C159.611 160.925 159.292 160.156 159.292 159.353C159.292 158.551 159.611 157.782 160.179 157.214C160.746 156.647 161.515 156.328 162.318 156.328H180.794Z"
      fill="#91A3C0"
    />
    <path
      d="M220.665 193.642C220.208 194.39 219.474 194.926 218.622 195.133C217.771 195.339 216.872 195.198 216.124 194.742L177.584 171.234C177.214 171.008 176.892 170.712 176.636 170.361C176.38 170.011 176.196 169.613 176.094 169.192C175.992 168.77 175.974 168.333 176.041 167.904C176.108 167.475 176.258 167.064 176.484 166.694C176.71 166.324 177.007 166.001 177.357 165.746C177.707 165.49 178.105 165.306 178.526 165.204C178.948 165.102 179.386 165.083 179.814 165.15C180.243 165.217 180.654 165.368 181.024 165.594L219.565 189.102C220.313 189.558 220.849 190.293 221.055 191.144C221.261 191.996 221.121 192.894 220.665 193.642Z"
      fill="#91A3C0"
    />
    <path
      d="M182.124 170.134C179.403 174.596 175.419 178.151 170.677 180.349C165.935 182.547 160.648 183.289 155.484 182.482C150.32 181.675 145.512 179.355 141.667 175.815C137.821 172.276 135.112 167.675 133.881 162.595C132.651 157.516 132.954 152.185 134.753 147.278C136.552 142.371 139.765 138.107 143.987 135.027C148.209 131.946 153.25 130.186 158.472 129.97C163.694 129.754 168.863 131.091 173.325 133.813C179.302 137.468 183.585 143.345 185.235 150.154C186.885 156.963 185.766 164.149 182.124 170.134ZM142.644 146.053C140.603 149.399 139.6 153.276 139.762 157.192C139.924 161.109 141.244 164.889 143.554 168.056C145.865 171.222 149.062 173.633 152.743 174.982C156.423 176.331 160.421 176.558 164.231 175.635C168.04 174.713 171.491 172.681 174.146 169.797C176.801 166.913 178.541 163.306 179.146 159.433C179.751 155.56 179.194 151.595 177.546 148.039C175.897 144.482 173.231 141.494 169.885 139.453C165.396 136.722 160.007 135.883 154.9 137.12C149.793 138.357 145.385 141.57 142.644 146.053Z"
      fill="#91A3C0"
    />
  </svg>
);
export default NoDataSvgArt;

import React from "react";
import { styled } from "@stitches/react";
import PropTypes from "prop-types";
import { SIZE_VARIENTS } from "../Constants";
import {
  TableRowStyles,
  TableDataStyles,
  CellContentStyles,
} from "./Row.styles";

const StyledTableRow = styled("tr", TableRowStyles);
const StyledTableData = styled("td", TableDataStyles);
const CellContent = styled("div", CellContentStyles);

const Row = React.forwardRef(
  (
    { row, size, isSelected, RowActions, selectedRowIndex, ...props },
    forwardedRef
  ) => {
    return (
      <StyledTableRow
        size={size}
        selected={row?.isSelected || selectedRowIndex === row?.index}
        disabled={row?.original?.disabled}
        {...props}
        ref={forwardedRef}
      >
        {row?.cells?.map((cell) => {
          const isActionsColumn = cell?.column?.id === "tableActions";
          return (
            <StyledTableData size={size} {...cell.getCellProps()}>
              <CellContent
                size={size}
                className={isActionsColumn ? "rowActions" : ""}
                rowActions={isActionsColumn}
                data-testid="rowActions"
              >
                {isActionsColumn && RowActions ? (
                  <RowActions data-testid="Row_Actions" row={cell?.row} />
                ) : (
                  cell.render("Cell")
                )}
              </CellContent>
            </StyledTableData>
          );
        })}
      </StyledTableRow>
    );
  }
);

Row.propTypes = {
  row: PropTypes.instanceOf(Object),
  size: PropTypes.oneOf(SIZE_VARIENTS),
  isSelected: PropTypes.bool,
  RowActions: PropTypes.node,
  selectedRowIndex: PropTypes.number,
};

export default Row;

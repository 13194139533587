import React, { useEffect, useState, useRef } from "react";
import PropTypes from "prop-types";
import { styled } from "@stitches/react";
import { useDimensions } from "../../customHooks";
import {
  CardContainerStyles,
  CardDescriptionStyles,
  CardTitleStyles,
  CardHeaderContainerStyles,
  IconContainerStyles,
  CardContentContainerStyles,
  CardBodyContainerStyles,
  SelectIconContainerStyles,
} from "./Card.styles";
import RightArrow from "../../icons/ArrowRightIcon";
import DownArrow from "../../icons/ArrowDownIcon";
import Square from "../../icons/SquareIcon";
import Circle from "../../icons/CircleIcon";
import SquareFilled from "../../icons/SquareCheckIcon";
import CircleFilled from "../../icons/CircleCheckedIcon";

const CardContainer = styled("div", CardContainerStyles);
const CardHeaderContainer = styled("div", CardHeaderContainerStyles);
const IconContainer = styled("div", IconContainerStyles);
const CardContentContainer = styled("div", CardContentContainerStyles);
const CardTitle = styled("div", CardTitleStyles);
const CardDescription = styled("div", CardDescriptionStyles);
const CardBodyContainer = styled("div", CardBodyContainerStyles);
const SelectIconContainer = styled("div", SelectIconContainerStyles);

// Component implementation
const Card = (props) => {
  const {
    id,
    children,
    type,
    title,
    description,
    expanded,
    checked,
    onClick,
    onToggleChecked,
    onToggleExpand,
    initialHeight,
    ...rest
  } = props;
  const cardRef = useRef(null);
  const bodyRef = useRef(null);
  const [collapse, setCollapse] = useState(expanded || true);
  const [selected, setSelected] = useState(checked || false);
  const isExpandable = type === "expandable";
  const isSingleSelect = type === "singleSelect";
  const isMultiSelect = type === "multiSelect";
  const isClickable = type === "clickable";

  const isTitle = !!title;
  const { height } = useDimensions(bodyRef);

  const handleToggle = (event) => {
    event.preventDefault();
    if (isClickable) onClick(event);
    if (isExpandable) setCollapse(!collapse);
    if (isExpandable && onToggleExpand) onToggleExpand(event, !collapse);
    if ((isSingleSelect || isMultiSelect) && onToggleChecked) {
      setSelected(!selected);
      onToggleChecked(event, !selected);
    }
  };

  useEffect(() => {
    setCollapse(expanded);
  }, [expanded]);

  useEffect(() => {
    setSelected(checked);
  }, [checked]);
  const cardInitialHeight = title && description ? 84 : initialHeight;
  const isChildrenVisible = !isExpandable || (isExpandable && collapse);
  const cardHeight = `${
    cardInitialHeight + height + (isChildrenVisible ? 16 : 0)
  }px`;

  return (
    <CardContainer
      ref={cardRef}
      type={type}
      data-testid={`card_${id}`}
      expanded={isExpandable && collapse}
      selected={isSingleSelect || isMultiSelect ? selected : false}
      style={{ height: isExpandable ? cardHeight : "auto" }}
      title={isTitle}
      {...rest}
    >
      {(title || description) && (
        <CardHeaderContainer
          onClick={handleToggle}
          data-testid={`card_title_${id}`}
          type={type}
        >
          {isExpandable && (
            <IconContainer data-testid="toggle_icon">
              {collapse ? <DownArrow /> : <RightArrow />}
            </IconContainer>
          )}
          <CardContentContainer>
            {title && <CardTitle>{title}</CardTitle>}
            {description && <CardDescription>{description}</CardDescription>}
          </CardContentContainer>
          {(isSingleSelect || isMultiSelect) && (
            <SelectIconContainer
              selected={selected}
              data-testid={
                isSingleSelect ? "single_Select_Card" : "multi_Select_Card"
              }
            >
              {isSingleSelect && (selected ? <CircleFilled /> : <Circle />)}
              {isMultiSelect && (selected ? <SquareFilled /> : <Square />)}
            </SelectIconContainer>
          )}
        </CardHeaderContainer>
      )}
      {children && (
        <CardBodyContainer
          ref={bodyRef}
          type={type}
          expanded={isExpandable ? collapse : true}
        >
          {" "}
          {children}
        </CardBodyContainer>
      )}
    </CardContainer>
  );
};

Card.propTypes = {
  /** An optional string that specifies a unique identifier for the link. This can be useful for
  referencing the link in JavaScript, for styling, or for accessibility enhancements. */
  id: PropTypes.string,
  /** The type of the card. It can be one of the following: default, clickable, expandable, singleSelect, multiSelect. */
  type: PropTypes.oneOf([
    "default",
    "clickable",
    "expandable",
    "singleSelect",
    "multiSelect",
  ]),
  /** The content to be rendered inside the card. This prop is required and allows for flexible
  /** card content, including text, icons, or other elements. */
  children: PropTypes.node,
  /** The title of the card. This prop is optional and allows for flexible card content, including text, icons, or other elements. */
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  /** The description of the card. This prop is an optional string and allows for flexible card description. */
  description: PropTypes.string,
  /** A boolean property indicating if the card is in an expanded state. It suggests that the card's content may be currently visible or hidden. */
  expanded: PropTypes.bool,
  /** A function that is called when the card is expanded or collapsed. It is called with the event and the new expanded state as arguments. */
  onToggleExpand: PropTypes.func,
  /** A function that is called when the card is checked or unchecked. It is called with the event and the new checked state as arguments. */
  onToggleChecked: PropTypes.func,
  /** The initial height of the card. This prop is optional and allows for flexible card height. */
  initialHeight: PropTypes.number,
  /** A boolean property indicating if the card is checked. */
  checked: PropTypes.bool,
  /** A function that is called when the card is clicked. It is called with the event as an argument. */
  onClick: PropTypes.func,
};

Card.defaultProps = {
  initialHeight: 54,
};

export default Card;

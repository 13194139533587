import token from "../../tokens/json/variables.json";

export const ToggleContainerStyles = {
  padding: token.spacing05,
  display: "flex",
  alignItems: "center",
  border: `${token.borderWidth2} solid transparent`,
  "&:focus-within": {
    borderRadius: token.borderRadiusFull,
    border: `${token.borderWidth2} solid ${token.focusFocus}`,
  },
  variants: {
    disableStyles: {
      true: {
        "&:focus-within": {
          border: `${token.borderWidth2} solid transparent`,
        },
      },
    },
    readOnly: {
      true: {
        "&:focus-within": {
          border: `${token.borderWidth2} solid transparent`,
        },
      },
    },
    error: {
      true: {
        "&:focus-within": {
          border: `${token.borderWidth2} solid transparent`,
        },
      },
    },
  },
};

export const SwitchRootStyles = {
  display: "flex",
  height: token.sizing4,
  width: token.sizing8,
  backgroundColor: token.white,
  alignItems: "center",
  border: `${token.iconIconSecondary} solid ${token.borderWidth1}`,
  borderRadius: token.borderRadiusFull,
  padding: "0",
  '&[data-state="checked"]': {
    backgroundColor: token.iconIconBrand,
    border: "none",
  },
  variants: {
    disableStyles: {
      true: {
        '&[data-state="checked"]': { backgroundColor: token.iconIconDisabled },
        borderColor: token.iconIconDisabled,
      },
    },
    readOnly: {
      true: {
        '&[data-state="checked"]': { backgroundColor: token.iconIconDisabled },
        borderColor: token.iconIconDisabled,
      },
    },
    error: {
      true: {
        borderColor: token.supportSupportError,
        '&[data-state="checked"]': {
          backgroundColor: token.supportSupportError,
        },
      },
    },
  },
};

export const SwitchThumbStyles = {
  display: "block",
  width: token.sizing3,
  height: token.sizing3,
  backgroundColor: token.iconIconSecondary,
  borderRadius: token.borderRadiusFull,
  transform: "translateX(1px)",
  '&[data-state="checked"]': {
    transform: "translateX(17px)",
    backgroundColor: token.iconIconOnColor,
  },
  variants: {
    disableStyles: {
      true: {
        '&[data-state="unchecked"]': {
          backgroundColor: token.iconIconDisabled,
        },
      },
    },
    readOnly: {
      true: {
        '&[data-state="unchecked"]': {
          backgroundColor: token.iconIconDisabled,
        },
      },
    },
    error: {
      true: {
        '&[data-state="checked"]': {
          backgroundColor: token.iconIconOnColor,
        },
        '&[data-state="unchecked"]': {
          backgroundColor: token.supportSupportError,
        },
      },
    },
  },
};

export const FlexCenteredStyles = {
  display: "flex",
  columnGap: token.spacing05,
  alignItems: "center",
};
export const FlexStyles = {
  display: "flex",
  columnGap: token.spacing05,
};

export const LabelStyles = {
  color: token.textTextSecondary,
  fontFamily: token.labelFontFamily,
  fontSize: token.labelFontSize,
  fontWeight: token.labelFontWeight,
  lineHeight: token.labelLineHeight,
  letterSpacing: token.labelLetterSpacing,
  paragraphSpacing: token.labelParagraphSpacing,
  paddingBottom: token.sizing2,
  textCase: token.labelTextCase,
  textDecoration: token.labelTextDecoration,
  cursor: "default",
  variants: {
    disableStyles: {
      true: {
        color: token.textTextDisabled,
      },
    },
  },
};

export const ToggleLabelStyles = {
  color: token.textTextPrimary,
  fontFamily: token.bodyShort01FontFamily,
  fontWeight: token.bodyShort01FontWeight,
  lineHeight: token.bodyShort01LineHeight,
  letterSpacing: token.bodyShort01LetterSpacing,
  fontSize: token.bodyShort01FontSize,
  paragraphSpacing: token.bodyShort01ParagraphSpacing,
  textCase: token.bodyShort01TextCase,
  textDecoration: token.bodyShort01TextDecoration,
  paddingLeft: token.spacing1,
  alignItems: "center",
  cursor: "default",
  variants: {
    disableStyles: {
      true: {
        color: token.textTextDisabled,
      },
    },
    readOnly: {
      true: {
        color: token.textTextPrimary,
      },
    },
  },
};

export const HelperTextStyles = {
  paddingTop: token.spacing1,
  color: token.textTextSecondary,
  fontFamily: token.labelFontFamily,
  fontSize: token.labelFontSize,
  fontWeight: token.labelFontWeight,
  lineHeight: token.labelLineHeight,
  letterSpacing: token.labelLetterSpacing,
  paragraphSpacing: token.labelParagraphSpacing,
  textCase: token.labelTextCase,
  textDecoration: token.labelTextDecoration,
  cursor: "default",
  variants: {
    disableStyles: {
      true: {
        color: token.textTextDisabled,
      },
    },
    error: {
      true: {
        color: token.textTextError,
      },
    },
  },
};

export const RequiredStyles = {
  color: token.textTextError,
  fontFamily: token.labelFontFamily,
  fontSize: token.labelFontSize,
  fontWeight: token.labelFontWeight,
  lineHeight: token.labelLineHeight,
  letterSpacing: token.labelLetterSpacing,
  paragraphSpacing: token.labelParagraphSpacing,
  textCase: token.labelTextCase,
  textDecoration: token.labelTextDecoration,
  cursor: "default",
};

export const IconStyles = {
  fontSize: token.sizing3,
  padding: token.spacing05,
  color: token.iconIconSecondary,
  variants: {
    disableStyles: {
      true: {
        color: token.textTextDisabled,
      },
    },
  },
};

import * as React from "react";
const SvgVectorIcon = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 16 16"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#VectorIcon_svg__a)">
      <path
        d="M15.25 0H11c-.406 0-.781.25-.938.656a1.01 1.01 0 0 0 .22 1.094l1.28 1.281-6.03 6.031a.964.964 0 0 0 0 1.407.99.99 0 0 0 .718.281c.25 0 .5-.094.688-.281l6.03-6.031 1.282 1.28a.99.99 0 0 0 .719.282c.125 0 .25 0 .375-.063.406-.156.656-.53.656-.937V.75a.76.76 0 0 0-.75-.75Zm-3 10a.74.74 0 0 0-.75.75v3.5c0 .156-.125.25-.25.25h-9.5c-.156 0-.25-.094-.25-.25v-9.5c0-.125.094-.25.25-.25h3.5A.74.74 0 0 0 6 3.75.76.76 0 0 0 5.25 3h-3.5C.781 3 0 3.813 0 4.75v9.5C0 15.219.781 16 1.75 16h9.5c.938 0 1.75-.781 1.75-1.75v-3.5a.76.76 0 0 0-.75-.75Z"
        fill="inherit"
      />
    </g>
    <defs>
      <clipPath id="VectorIcon_svg__a">
        <path fill="transparent" d="M0 0h16v16H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgVectorIcon;

import React, { useState } from "react";
import PropTypes from "prop-types";

import { styled } from "@stitches/react";

import FileProgress from "./FileProgress";
import Button from "../Button";
import {
  ContainerStyles,
  DragContainerStyles,
  DragInputStyles,
  DragTextStyles,
  HelperTextStyles,
  LabelContainerStyles,
  LabelStyles,
  ProgressListStyles,
} from "./FileUploader.styles";

import uploadImage from "../../assets/art/upload-cloud.png";

const Container = styled("div", ContainerStyles);
const LabelContainer = styled("div", LabelContainerStyles);
const Label = styled("div", LabelStyles);
const HelperText = styled("div", HelperTextStyles);
const DragContainer = styled("div", DragContainerStyles);
const DragInput = styled("input", DragInputStyles);
const DragText = styled("div", DragTextStyles);
const ProgressList = styled("div", ProgressListStyles);

const FileUploader = ({ files, ...props }) => {
  const [dragActive, setDragActive] = useState(false);

  const handleDrag = (e) => {
    e.preventDefault();
    e.stopPropagation();

    if (e.type === "dragenter" || e.type === "dragover") {
      setDragActive(true);
    } else if (e.type === "dragleave") {
      setDragActive(false);
    }
  };

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      props.onChange([...e.dataTransfer.files]);
    }
  };

  const handleChange = (e) => {
    e.preventDefault();

    if (e.target.files && e.target.files[0]) {
      props.onChange([...e.target.files]);
    }
  };

  return (
    <Container data-testid="file-uploader">
      {(props?.label || props?.helperText) && (
        <LabelContainer data-disabled={props.disabled}>
          <Label>{props?.label}</Label>
          <HelperText>{props?.helperText}</HelperText>
        </LabelContainer>
      )}
      {props.isButton ? (
        <Button
          color="primary"
          disabled={props.disabled}
          onClick={(e) => e.stopPropagation()}
          data-testid="file-uploader-button"
        >
          <DragInput
            type="file"
            onChange={handleChange}
            multiple={props.multiple}
          />
          Browse Files
        </Button>
      ) : (
        <DragContainer
          data-active={dragActive}
          onDragEnter={handleDrag}
          onDragLeave={handleDrag}
          onDragOver={handleDrag}
          onDrop={handleDrop}
        >
          <DragInput
            type="file"
            onChange={handleChange}
            multiple={props.multiple}
            data-testid="dnd-input"
          />
          <img width={56} height={45} src={uploadImage} alt="Upload" />
          <DragText>
            Drag and drop files or <span>browse files</span>
          </DragText>
        </DragContainer>
      )}
      {props?.children?.length > 0 && Array.isArray(props.children) ? (
        <ProgressList>
          {props.children.map((childElement) => {
            return React.cloneElement(childElement, {
              key: childElement.props.id,
            });
          })}
        </ProgressList>
      ) : (
        <ProgressList data-testid="file-uploader-files">
          {files.map((file) => (
            <FileProgress key={file.name} id={file.name} label={file.name} />
          ))}
        </ProgressList>
      )}
    </Container>
  );
};

const FILE_FORMAT = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  name: PropTypes.string,
};
FileUploader.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  files: PropTypes.arrayOf(PropTypes.shape(FILE_FORMAT)),
  children: PropTypes.node,
  isButton: PropTypes.bool,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  multiple: PropTypes.bool,
  helperText: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

FileUploader.defaultProps = {
  isButton: false,
  disabled: false,
  multiple: false,
  files: [],
};

export default FileUploader;

import React from "react";
import { styled } from "@stitches/react";
import PropTypes from "prop-types";
import Item from "../Item";
import SubMenu from "../SubMenu/SubMenu";

const SectionItemContainer = styled("div", {
  all: "unset",
});

const SectionItem = ({
  sectionItems,
  isCollapse,
  handleSelect,
  selected,
  setCollapse,
  ...otherProps
}) => {
  return (
    <SectionItemContainer {...otherProps}>
      {sectionItems &&
        sectionItems.map((item, index) => {
          if (item?.items) {
            return (
              <SubMenu
                id={item?.id}
                key={item?.id}
                subMenuItems={item.items}
                label={item.label}
                collapse={isCollapse}
                selected={selected}
                handleSelect={handleSelect}
                setCollapse={setCollapse}
                disabled={item.disabled}
                Icon={item?.icon}
                expanded={
                  // !isCollapse &&
                  item?.items?.some((childItem) => childItem?.id === selected)
                }
              />
            );
          }
          return (
            <Item
              key={`SectionItem_${item.id}}`}
              id={item?.id}
              label={item.label}
              Icon={item?.icon}
              isCollapse={isCollapse}
              tabIndex={index}
              handleSelect={(event) => handleSelect(event, item.id)}
              selected={selected}
              hidden={item?.hidden || false}
              disabled={item?.disabled || false}
            />
          );
        })}
    </SectionItemContainer>
  );
};

SectionItem.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  sectionItems: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      label: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      icon: PropTypes.node,
      disabled: PropTypes.bool,
      type: PropTypes.oneOf(["section_header", "sub_menu", "footer"]),
    })
  ),
  children: PropTypes.node,
  handleSelect: PropTypes.func,
  selected: PropTypes.string,
  isCollapse: PropTypes.bool,
  setCollapse: PropTypes.func,
};

export default SectionItem;

import React, { useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";
import * as DropdownMenuPrimitive from "@radix-ui/react-dropdown-menu";
import { styled } from "@stitches/react";
import Button from "../Button";
import { useDebounce } from "../../customHooks";
import {
  ArrowDownIcon,
  CheckIcon,
  SquareIcon,
  SquareCheckIcon,
  SquareMinusIcon,
} from "../../icons";
import {
  DropdownTriggerStyles,
  DropdownLabelStyles,
  DropdownHelperTextStyles,
  DropdownTextTriggerStyles,
  DropdownTriggerTextStyles,
  DropdownIconStyles,
  DropdownContentStyles,
  DropdownGroupStyles,
  DropdownItemStyles,
  DropdownItemLabelStyles,
  DropdownItemIconStyles,
  DropdownItemTextStyles,
  DropdownItemIndicatorStyles,
  DropdownItemIndicatorIconStyles,
  DropdownCheckboxItemStyles,
  DropdownCheckboxItemIndicatorStyles,
  DropdownTagContainerStyles,
  SearchFieldContainerStyles,
  ClearButtonStyles,
  TooltipContentStyles,
} from "./Dropdown.styles";
import Tooltip from "../Tooltip/Tooltip";
import Search from "../Search/Search";
import Tag from "../Tag/Tag";

const DROPDOWN_TYPES = {
  SELECT: "select",
  MENU: "menu",
};

const StyledDropdownTrigger = styled(
  DropdownMenuPrimitive.Trigger,
  DropdownTextTriggerStyles
);
const StyledDropdownMenuTriggerText = styled("div", DropdownTriggerTextStyles);
const StyledDropdownMenuTriggerIcon = styled(ArrowDownIcon, DropdownIconStyles);

const StyledContent = styled(
  DropdownMenuPrimitive.Content,
  DropdownContentStyles
);

const StyledDropdownMenuRadioGroup = styled(
  DropdownMenuPrimitive.RadioGroup,
  DropdownGroupStyles
);
const StyledRadioItem = styled(
  DropdownMenuPrimitive.RadioItem,
  DropdownItemStyles
);
const StyledDropdownMenuOptionText = styled("div", DropdownItemTextStyles);
const DropdownItemIndicatorIcon = styled(
  CheckIcon,
  DropdownItemIndicatorIconStyles
);
const DropdownMenuRadioItemIndicator = styled(
  DropdownMenuPrimitive.ItemIndicator,
  DropdownItemIndicatorStyles
);

// Menu Checkbox Group
const StyledDropdownMenuCheckboxGroup = styled(
  DropdownMenuPrimitive.Group,
  DropdownGroupStyles
);

const StyledDropdownMenuCheckboxItem = styled(
  DropdownMenuPrimitive.CheckboxItem,
  DropdownCheckboxItemStyles
);

const DropdownMenuCheckboxItemIndicator = styled(
  "div",
  DropdownCheckboxItemIndicatorStyles
);

const DropDownLabelTagContainer = styled("div", DropdownTagContainerStyles);

const StyledSeeAllOptions = styled("div");

// Export these for further customization
export const DropdownMenuRoot = DropdownMenuPrimitive.Root;
export const DropdownMenuLabel = styled("div", DropdownLabelStyles);
export const DropdownMenuHelperText = styled("div", DropdownHelperTextStyles);
export const DropdownMenuTrigger = styled(
  DropdownMenuPrimitive.Trigger,
  DropdownTriggerStyles
);
export const DropdownMenuTextTrigger = StyledDropdownTrigger;
export const DropdownMenuTriggerText = StyledDropdownMenuTriggerText;
export const DropdownMenuTriggerIcon = StyledDropdownMenuTriggerIcon;
export const DropdownMenuContent = React.forwardRef(
  ({ children, portal, contentWrapperProps, ...props }, forwardedRef) => {
    const content = (
      <StyledContent
        {...props}
        ref={forwardedRef}
        onClick={(e) => {
          e.stopPropagation();
        }}
        {...contentWrapperProps}
      >
        {children}
      </StyledContent>
    );
    if (!portal) {
      return content;
    }
    return (
      <DropdownMenuPrimitive.Portal>{content}</DropdownMenuPrimitive.Portal>
    );
  }
);

DropdownMenuContent.propTypes = {
  children: PropTypes.node.isRequired,
  portal: PropTypes.bool,
  contentWrapperProps: PropTypes.instanceOf(Object),
};

export const DropdownMenuRadioGroup = StyledDropdownMenuRadioGroup;
export const DropdownMenuRadioItem = React.forwardRef(
  ({ children, ...props }, forwardedRef) => {
    return (
      <StyledRadioItem {...props} ref={forwardedRef}>
        {children}
        {props?.selected && (
          <DropdownMenuRadioItemIndicator>
            <DropdownItemIndicatorIcon />
          </DropdownMenuRadioItemIndicator>
        )}
      </StyledRadioItem>
    );
  }
);

DropdownMenuRadioItem.propTypes = {
  children: PropTypes.node.isRequired,
  selected: PropTypes.bool,
};

// Checkbox
export const DropdownMenuCheckboxGroup = StyledDropdownMenuCheckboxGroup;
export const DropdownMenuCheckboxItem = React.forwardRef(
  ({ children, ...props }, forwardedRef) => {
    return (
      <StyledDropdownMenuCheckboxItem
        {...props}
        ref={forwardedRef}
        onSelect={(event) => {
          event.preventDefault();
        }}
      >
        <DropdownMenuCheckboxItemIndicator
          checked={props?.checked}
          disabled={props?.disabled}
        >
          {props?.checked === "indeterminate" && (
            <DropdownMenuSquareMinusIcon />
          )}
          {props?.checked === true && <DropdownMenuSquareCheckedIcon />}
          {props?.checked === false && <DropdownMenuSquareIcon />}
        </DropdownMenuCheckboxItemIndicator>
        {children}
      </StyledDropdownMenuCheckboxItem>
    );
  }
);

DropdownMenuCheckboxItem.propTypes = {
  children: PropTypes.node.isRequired,
  checked: PropTypes.bool,
  disabled: PropTypes.bool,
};
export const DropdownMenuSquareIcon = styled(SquareIcon, {
  fill: "inherit",
  width: "14px",
  height: "14px",
});
export const DropdownMenuSquareCheckedIcon = styled(SquareCheckIcon, {
  fill: "inherit",
  width: "14px",
  height: "14px",
});

export const DropdownMenuSquareMinusIcon = styled(SquareMinusIcon, {
  fill: "inherit",
  width: "14px",
  height: "14px",
});

export const DropdownMenuItemLabel = styled("div", DropdownItemLabelStyles);
export const DropdownMenuItemText = StyledDropdownMenuOptionText;
export const DropdownMenuItemIcon = styled("div", DropdownItemIconStyles);
const SearchFieldContainer = styled("div", SearchFieldContainerStyles);
export const SearchField = React.forwardRef(
  ({ searchText, setSearchText, ...props }, forwardedRef) => {
    return (
      <SearchFieldContainer ref={forwardedRef}>
        <Search
          {...props}
          className="searchInput"
          data-testid="dropDownSearch"
          tabIndex={-1}
          placeholder="Search"
          value={searchText}
          onChange={(e) => {
            setSearchText(e?.target?.value);
          }}
          onKeyDown={(e) => {
            // To prevent input losing focus
            // https://github.com/radix-ui/primitives/discussions/787
            e.stopPropagation();
          }}
        />
      </SearchFieldContainer>
    );
  }
);
SearchField.propTypes = {
  searchText: PropTypes.string,
  setSearchText: PropTypes.func,
};

const TagTooltipContent = styled("div", TooltipContentStyles);

const Dropdown = ({
  open,
  onOpenChange,
  value,
  onValueChange,
  options,
  placeholder,
  label,
  error,
  helperText,
  multiple,
  entity,
  disabled,
  required,
  width,
  side,
  align,
  type,
  children,
  headerComponent,
  customLabel,
  tooltipProps,
  filterEnabled = false,
  showSelected = false,
  showAllSelected = false,
  portal,
  contentWrapperProps,
  ...props
}) => {
  const [searchText, setSearchText] = useState("");
  const [filteredOptions, setFilteredOptions] = useState([...options]);
  const [isSelectAllChecked, setIsSelectAllChecked] = useState(false);

  // DeBounce Function
  useDebounce(
    () => {
      if (searchText === "") {
        setFilteredOptions([...options]);
        return;
      }
      setFilteredOptions(
        (options || []).filter((option) =>
          option?.label?.toLowerCase()?.includes(searchText?.toLowerCase())
        )
      );
    },
    [options, searchText],
    400
  );

  const handleCheckedChange = (itemId, checked) => {
    if (checked && !value?.includes(itemId)) {
      onValueChange([...value, itemId]);
    } else {
      onValueChange([...value].filter((id) => id !== itemId));
    }
  };

  const handleSelectAll = (checked) => {
    if (checked) {
      const selectedList = new Set([
        ...value,
        ...(filteredOptions || []).map((option) => {
          return option.id;
        }),
      ]);

      onValueChange(Array.from(selectedList));
    } else {
      onValueChange([]);
    }
  };

  const selectedItemLabels = useMemo(() => {
    if (type !== DROPDOWN_TYPES.SELECT) {
      return false;
    }

    if (multiple) {
      if (showSelected) {
        const selectedValues = (options || []).filter((option) =>
          (value || []).includes(option?.id)
        );

        if (selectedValues?.length === 0) {
          return `${(value || []).length} ${entity || ""} selected`;
        }

        const tagsWidth =
          Math.max(...selectedValues.map((el) => el?.label?.length)) * 6 + 40;

        const visibleTagCount = Math.trunc(
          Number(
            (Number(width.replace(/[^0-9]/g, "")) - 36 - 22 - 30) /
              Number(tagsWidth)
          )
        );

        return (
          <DropDownLabelTagContainer
            style={{ flexWrap: showAllSelected ? "wrap" : "nowrap" }}
            key={selectedValues?.length}
            onPointerDown={(e) => {
              // Following line is to try preventing bubbling
              e?.stopPropagation();
            }}
          >
            {selectedValues
              ?.slice(
                0,
                showAllSelected ? selectedValues.length : visibleTagCount
              )
              ?.map((tag) => (
                <Tag
                  disabled={disabled}
                  key={tag?.id}
                  type="removable"
                  style={{ cursor: "default" }}
                  data-testid={`${tag?.id}_tag`}
                  onClick={() => {
                    handleCheckedChange(tag?.id, false);
                  }}
                  {...tag}
                />
              ))}
            {!showAllSelected && selectedValues.length > visibleTagCount && (
              <Tooltip
                portal={portal}
                content={
                  <TagTooltipContent>
                    {selectedValues
                      ?.slice(visibleTagCount, selectedValues.length)
                      ?.map((tag) => (
                        <Tag
                          disabled={disabled}
                          key={tag?.id}
                          type="default"
                          style={{ cursor: "default", width: "fit-content" }}
                          data-testid={`${tag?.id}_tag`}
                          {...tag}
                        />
                      ))}
                  </TagTooltipContent>
                }
              >
                <StyledSeeAllOptions
                  onPointerDown={(e) => {
                    // Following line is to try preventing bubbling
                    e?.stopPropagation();
                  }}
                >
                  <Tag
                    disabled={disabled}
                    key="see-more"
                    type="default"
                    label={`+${selectedValues.length - visibleTagCount}`}
                    data-testid="Tag_SeeMore"
                    id="SeeMore"
                  />
                </StyledSeeAllOptions>
              </Tooltip>
            )}
          </DropDownLabelTagContainer>
        );
      }

      return `${(value || []).length} ${entity || ""} selected`;
    }
    return (options || []).find((option) => option?.id === value)?.label;
  }, [value, options, type, multiple, entity, showAllSelected, disabled]);

  let displayLabel = "";
  if (customLabel) {
    displayLabel = customLabel;
  } else if (selectedItemLabels) {
    displayLabel = selectedItemLabels || value;
  } else if (placeholder) {
    displayLabel = placeholder;
  }

  useEffect(() => {
    setIsSelectAllChecked(false);
    if (filterEnabled && multiple && filteredOptions?.length) {
      const allSelected = filteredOptions.every((result) =>
        value?.some((item) => item === result?.id)
      );
      const someSelected = filteredOptions.some((result) =>
        value?.some((item) => item === result?.id)
      );

      if (allSelected) {
        setIsSelectAllChecked(true);
      } else if (someSelected) {
        setIsSelectAllChecked("indeterminate");
      } else {
        setIsSelectAllChecked(false);
      }
    }
  }, [searchText, value, filteredOptions]);

  const DropdownTrigger = children ? (
    <DropdownMenuTrigger
      data-testid="dropdownCustomTrigger"
      style={{ width }}
      {...props}
    >
      {children}
    </DropdownMenuTrigger>
  ) : (
    <div style={{ display: "grid" }}>
      {label && (
        <DropdownMenuLabel
          data-testid="dropdownLabel"
          disabled={disabled}
          required={required}
        >
          {label}
        </DropdownMenuLabel>
      )}
      <DropdownMenuTextTrigger
        disabled={disabled}
        data-testid="dropdownTrigger"
        multiline={showAllSelected}
        style={{ width }}
        headerComponent={headerComponent}
        error={error}
        {...props}
      >
        <DropdownMenuTriggerText>{displayLabel}</DropdownMenuTriggerText>
        <DropdownMenuTriggerIcon />
      </DropdownMenuTextTrigger>
      {helperText && (
        <DropdownMenuHelperText
          data-testid="dropdownHelperText"
          error={error}
          disabled={disabled}
        >
          {helperText}
        </DropdownMenuHelperText>
      )}
    </div>
  );

  return (
    <DropdownMenuRoot open={open} onOpenChange={onOpenChange} modal={false}>
      {DropdownTrigger}
      <DropdownMenuContent
        style={{ width }}
        sideOffset={5}
        side={side}
        align={align}
        loop
        type={type}
        portal={portal}
        contentWrapperProps={contentWrapperProps}
      >
        {/* to  be replaced with search Component */}
        {filterEnabled && (
          <SearchField
            searchText={searchText}
            setSearchText={setSearchText}
            key="dropDownSearch"
          />
        )}
        {filterEnabled && multiple && (
          <DropdownMenuCheckboxItem
            key="select_all"
            data-testid="dropdownFilter_SelectAll"
            actions={filterEnabled}
            checked={isSelectAllChecked}
            onCheckedChange={(checked) => {
              handleSelectAll(checked);
            }}
          >
            <DropdownMenuItemLabel>
              <DropdownMenuItemText data-testid="dropDownSelectAll">
                Select All
              </DropdownMenuItemText>
              {/* to  be updated  once  button gets  updated according  to new  figma */}
              <Button
                size="small"
                color="ghost"
                data-testid="dropDownClear"
                onClick={(e) => {
                  e.stopPropagation();
                  onValueChange([]);
                }}
                style={ClearButtonStyles}
              >
                Clear
              </Button>
            </DropdownMenuItemLabel>
          </DropdownMenuCheckboxItem>
        )}
        {multiple && (
          <DropdownMenuCheckboxGroup>
            {(filteredOptions || []).map((option) => (
              <Tooltip
                key={option?.id}
                data-testid={`tooltip_${option?.id}`}
                side="right"
                content={option?.info}
                portal={portal}
                {...tooltipProps}
              >
                <DropdownMenuCheckboxItem
                  key={option?.id}
                  data-testid={`dropdownItem_${option?.id}`}
                  disabled={option?.disabled}
                  checked={value?.includes(option.id)}
                  onCheckedChange={(checked) =>
                    handleCheckedChange(option?.id, checked)
                  }
                >
                  <DropdownMenuItemLabel>
                    {option?.icon && (
                      <DropdownMenuItemIcon>
                        {option?.icon}
                      </DropdownMenuItemIcon>
                    )}
                    <DropdownMenuItemText>
                      {option?.label || option?.id}
                    </DropdownMenuItemText>
                  </DropdownMenuItemLabel>
                </DropdownMenuCheckboxItem>
              </Tooltip>
            ))}
          </DropdownMenuCheckboxGroup>
        )}
        {!multiple && (
          <DropdownMenuRadioGroup value={value} onValueChange={onValueChange}>
            {(filteredOptions || []).map((option) => (
              <Tooltip
                key={option?.id}
                side="right"
                content={option?.info}
                {...tooltipProps}
                portal={portal}
              >
                <DropdownMenuRadioItem
                  key={option?.id}
                  value={option?.id}
                  data-testid={`dropdownItem_${option?.id}`}
                  disabled={option?.disabled}
                  selected={
                    type !== DROPDOWN_TYPES.MENU && option?.id === value
                  }
                  type={type}
                >
                  <DropdownMenuItemLabel>
                    {option?.icon && (
                      <DropdownMenuItemIcon>
                        {option?.icon}
                      </DropdownMenuItemIcon>
                    )}
                    <DropdownMenuItemText>
                      {option?.label || option?.id}
                    </DropdownMenuItemText>
                  </DropdownMenuItemLabel>
                </DropdownMenuRadioItem>
              </Tooltip>
            ))}
          </DropdownMenuRadioGroup>
        )}
      </DropdownMenuContent>
    </DropdownMenuRoot>
  );
};

Dropdown.propTypes = {
  open: PropTypes.bool,
  onOpenChange: PropTypes.func,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.array,
  ]),
  onValueChange: PropTypes.func,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      label: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      icon: PropTypes.node,
      disabled: PropTypes.bool,
      info: PropTypes.string,
    })
  ),
  placeholder: PropTypes.string,
  label: PropTypes.string,
  error: PropTypes.bool,
  helperText: PropTypes.string,
  multiple: PropTypes.bool,
  entity: PropTypes.string,
  disabled: PropTypes.bool,
  inline: PropTypes.bool,
  required: PropTypes.bool,
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  side: PropTypes.oneOf(["top", "right", "bottom", "left"]),
  align: PropTypes.oneOf(["start", "center", "end"]),
  type: PropTypes.oneOf(Object.values(DROPDOWN_TYPES)),
  headerComponent: PropTypes.bool,
  customLabel: PropTypes.string,
  children: PropTypes.node,
  tooltipProps: PropTypes.shape({
    type: PropTypes.string,
    content: PropTypes.string,
    align: PropTypes.string,
    side: PropTypes.string,
    defaultOpen: PropTypes.bool,
    id: PropTypes.string,
  }),
  filterEnabled: PropTypes.bool,
  showSelected: PropTypes.bool,
  showAllSelected: PropTypes.bool,
  portal: PropTypes.bool,
  contentWrapperProps: PropTypes.instanceOf(Object),
};

Dropdown.defaultProps = {
  placeholder: "Select",
  side: "bottom",
  align: "start",
  type: DROPDOWN_TYPES.SELECT,
  headerComponent: false,
  showSelected: false,
  showAllSelected: false,
  options: [],
  portal: true,
};

export default Dropdown;

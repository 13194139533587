import variables from "../../../tokens/json/variables.json";

export const TableRowStyles = {
  borderBottom: `${variables.borderWidth1} solid ${variables.borderBorderSubtle00}`,
  background: variables.layerLayer02,

  // spacing
  boxSizing: "border-box",
  display: "flex",
  flexDirection: "row",
  alignItems: "center",

  color: variables.textTextOnTable,

  // typography
  fontFamily: variables.bodyShort01FontFamily,
  fontWeight: variables.bodyShort01FontWeight,
  lineHeight: variables.bodyShort01LineHeight,
  fontSize: variables.bodyShort01FontSize,
  letterSpacing: variables.bodyShort01LetterSpacing,
  paragraphSpacing: variables.bodyShort01ParagraphSpacing,
  textCase: variables.bodyShort01TextCase,
  textDecoration: variables.bodyShort01TextDecoration,

  "& .rowActions": {
    display: "inline-flex",
    visibility: "hidden",

    // Row Actions icon button fill color
    color: variables.iconIconPrimary,
    fill: variables.iconIconPrimary,
  },

  "&:hover": {
    background: variables.layerLayerHover01,

    "& .rowActions": {
      visibility: "visible",
    },
  },

  variants: {
    selected: {
      true: {
        background: variables.layerLayer01,

        "&:hover": {
          color: variables.textTextPrimary,
          background: variables.backgroundBackgroundSelectedHover,
          borderBottom: `${variables.borderWidth1} solid ${variables.borderBorderSubtleSelected01}`,
        },
      },
    },
    disabled: {
      true: {
        color: variables.textTextOnColorDisabled,
        cursor: "not-allowed",
        pointerEvents: "none",

        "&:hover": {
          // background: "tranparent", doesn't work
        },
      },
    },
    size: {
      compact: {
        height: variables.sizing8,
      },
      extraSmall: {
        height: variables.sizing10,
      },
      small: {
        height: variables.sizing12,
      },
      medium: {
        height: variables.sizing16,
      },
      large: {
        height: variables.sizing18,
      },
      extraLarge: {
        height: variables.sizing20,
      },
    },
  },
};

export const TableDataStyles = {
  height: "100%",
  display: "flex",
  flexDirection: "row",
  alignItems: "center",

  variants: {
    size: {
      compact: {
        padding: `${variables.spacing2} ${variables.spacing4}`,
      },
      extraSmall: {
        padding: `${variables.spacing2} ${variables.spacing4}`,
      },
      small: {
        padding: `${variables.spacing2} ${variables.spacing4}`,
      },
      medium: {
        padding: `${variables.spacing25} ${variables.spacing4}`,
      },
      large: {
        padding: variables.spacing4,
      },
      extraLarge: {
        padding: variables.spacing4,
      },
    },
  },
};

export const CellContentStyles = {
  width: "100%",
  textOverflow: "ellipsis",
  overflow: "hidden",
  variants: {
    size: {
      compact: {
        whiteSpace: "nowrap",
      },
      extraSmall: {
        whiteSpace: "nowrap",
      },
      small: {
        whiteSpace: "nowrap",
      },
      medium: {
        display: "-webkit-box",
        "-webkit-line-clamp": "2",
        "-webkit-box-orient": "vertical",
      },
      large: {
        display: "-webkit-box",
        "-webkit-line-clamp": "2",
        "-webkit-box-orient": "vertical",
      },
      extraLarge: {
        display: "-webkit-box",
        "-webkit-line-clamp": "2",
        "-webkit-box-orient": "vertical",
      },
    },
    rowActions: {
      true: {
        display: "flex",
        justifyContent: "flex-end",
        gap: variables.spacing2,
      },
    },
  },
};

import React, { useState, useEffect, useCallback } from "react";
import { styled } from "@stitches/react";
import PropTypes from "prop-types";
import tokens from "../../../tokens/json/variables.json";
import { ArrowDownIcon, ArrowRightIcon } from "../../../icons";
import {
  NodeRootStyles,
  NodeLabelStyles,
  NodeActionsContainerStyles,
  NodeIconButtonStyles,
  NodeContentStyles,
} from "./Node.styles";

const NodeRoot = styled("div", NodeRootStyles);
const NodeLabel = styled("div", NodeLabelStyles);
const NodeActions = styled("div", NodeActionsContainerStyles);
const NodeIconButton = styled("div", NodeIconButtonStyles);
const NodeContent = styled("div", NodeContentStyles);

const Node = ({
  node,
  depth,
  nodeClickHandler,
  CustomNodeActions,
  handleNodeSelect,
  selectedNode,
  expandedNodes,
  onToggleExpand,
  active,
  borderBottom,
  CustomNodeCollapseIcon,
  CustomNodeExpandIcon,
  ...props
}) => {
  const [expanded, setExpanded] = useState(false);
  const [children, setChildren] = useState([]);

  useEffect(() => {
    if (node?.children) {
      // Children data is available upfront, no need to fetch
      setChildren(node?.children);
    }
  }, [expanded, node, node?.children, children?.length, depth]);

  const handleExpand = () => {
    setExpanded(!expanded);
    onToggleExpand(node?.id);
  };

  const CustomSlot = () => node?.customSlot;
  const isSelected = selectedNode?.id === node?.id;
  const isExpanded = expandedNodes.includes(node?.id);

  const handleNodeClick = useCallback(
    (event) => {
      handleNodeSelect(node);
      event.stopPropagation();
      if (typeof nodeClickHandler === "function") {
        nodeClickHandler(node);
      }
    },
    [node]
  );

  const handleOnKeyDown = (event) => {
    if (event?.key === "Enter" || event.key === " ") {
      event.preventDefault();
      handleExpand();
    }
  };

  return (
    <>
      <NodeRoot
        data-testid={`Node_${node?.id}`}
        style={
          children?.length > 0
            ? { paddingLeft: `${depth * parseInt(tokens?.sizing4, 10)}px` }
            : {
                paddingLeft: `${
                  parseInt(tokens?.sizing10, 10) +
                  (depth - 1) * parseInt(tokens?.sizing4, 10)
                }px`,
              }
        }
        borderBottom={borderBottom}
        selected={isSelected}
        disabled={node?.disabled}
        active={active || isExpanded}
        onClick={(event) => handleNodeClick(event)}
        /* eslint-disable jsx-a11y/tabindex-no-positive */
        tabIndex={1}
        onKeyDown={(event) => handleOnKeyDown(event)}
      >
        {children?.length > 0 && (
          <NodeIconButton
            data-testid={`Node_${expanded ? "Expanded" : "Collapsed"}_${
              node?.id
            }`}
            onClick={handleExpand}
            disabled={node?.disabled}
          >
            {expanded
              ? CustomNodeExpandIcon || <ArrowDownIcon />
              : CustomNodeCollapseIcon || <ArrowRightIcon />}
          </NodeIconButton>
        )}
        <NodeContent disabled={node?.disabled}>
          {node?.customSlot ? (
            <CustomSlot
              style={
                node?.disabled && {
                  color: `${tokens.textTextDisabled}`,
                }
              }
              node={node}
            />
          ) : (
            <NodeLabel data-testid="Node_Label" disabled={node?.disabled}>
              {node?.label}
            </NodeLabel>
          )}
        </NodeContent>
        <NodeActions data-testid="Node_Actions">
          {CustomNodeActions && <CustomNodeActions node={node} />}
        </NodeActions>
      </NodeRoot>

      {expanded &&
        children?.map((childNode) => (
          <Node
            key={childNode?.id}
            node={childNode}
            depth={depth + 1}
            nodeClickHandler={nodeClickHandler}
            CustomNodeActions={CustomNodeActions}
            handleNodeSelect={handleNodeSelect}
            selectedNode={selectedNode}
            expandedNodes={expandedNodes}
            onToggleExpand={onToggleExpand}
            active
            borderBottom={borderBottom}
            CustomNodeCollapseIcon={CustomNodeCollapseIcon}
            CustomNodeExpandIcon={CustomNodeExpandIcon}
            {...props}
          />
        ))}
    </>
  );
};

Node.propTypes = {
  node: PropTypes.instanceOf(Object),
  depth: PropTypes.number,
  nodeClickHandler: PropTypes.func,
  CustomNodeActions: PropTypes.func,
  handleNodeSelect: PropTypes.func,
  selectedNode: PropTypes.instanceOf(Object),
  expandedNodes: PropTypes.instanceOf(Array),
  onToggleExpand: PropTypes.func,
  active: PropTypes.bool,
  borderBottom: PropTypes.bool,
  CustomNodeCollapseIcon: PropTypes.instanceOf(Object),
  CustomNodeExpandIcon: PropTypes.instanceOf(Object),
};

Node.defaultProps = {
  depth: 1,
};

export default Node;

import * as React from "react";
const SvgArrowDownIcon = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 16 16"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M13.25 6.813 8.5 11.28a.716.716 0 0 1-.5.219.849.849 0 0 1-.531-.188l-4.75-4.5c-.313-.28-.313-.75-.031-1.062.28-.313.75-.313 1.062-.031l4.25 4 4.219-4c.312-.282.781-.282 1.062.031.281.313.281.781-.031 1.063Z"
      fill="inherit"
    />
  </svg>
);
export default SvgArrowDownIcon;

import * as React from "react";
const SvgSquareCheckIcon = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 16 16"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M3 1c-1.103 0-2 .897-2 2v10c0 1.103.897 2 2 2h10c1.103 0 2-.897 2-2V3c0-1.103-.897-2-2-2H3Zm8.531 5.531-4 4a.747.747 0 0 1-1.06 0l-2-2a.75.75 0 0 1 1.06-1.06L7 8.942l3.469-3.472a.75.75 0 0 1 1.06 1.06l.002.002Z"
      fill="inherit"
    />
  </svg>
);
export default SvgSquareCheckIcon;

import React, { useEffect, useState } from "react";
import { styled } from "@stitches/react";
import PropTypes from "prop-types";
import {
  SortUpIcon,
  SortDownIcon,
  SortUpDownIcon,
  CustomizationIcon,
} from "../../../icons";
import { SIZE_VARIENTS, PREDEFINED_COLUMNS } from "../Constants";
import PopUp from "../../PopUp";
import {
  TableHeadStyles,
  TableRowStyles,
  TableHeaderStyles,
  ResizeHandleStyles,
  CustomisationButtonStyles,
  PopUpBodyStyles,
} from "./Header.styles";
import ColumnToggleFieldContainer from "./ColumnToggleField/ColumnToggleFieldContainer";

const StyledTableHead = styled("thead", TableHeadStyles);
const StyledTableRow = styled("tr", TableRowStyles);
const StyledTableHeader = styled("th", TableHeaderStyles);
const ResizeHandle = styled("div", ResizeHandleStyles);
const ColumnCustomisationButton = styled("button", CustomisationButtonStyles);
const PopUpBody = styled("div", PopUpBodyStyles);

const Header = React.forwardRef(
  (
    {
      headerGroups,
      size,
      allColumns,
      hiddenColumns,
      setHiddenColumns,
      defaultHiddenColumns,
      fixedColumns,
      disableColumnCustomisation,
      id,
      initialColumnOrder,
      columnOrder,
      setColumnOrder,
      ...props
    },
    forwardedRef
  ) => {
    const [openPopup, setOpenPopup] = useState(false);
    const [columnsToBeHidden, setColumnsToBeHidden] = useState([]);
    const [columnsToBeReOrdered, setColumnsToBeReOrdered] = useState([]);

    useEffect(() => {
      localStorage.setItem(
        `${id}_hiddenColumns`,
        JSON.stringify(hiddenColumns)
      );
      setColumnsToBeHidden([...hiddenColumns]);
    }, [hiddenColumns]);

    useEffect(() => {
      localStorage.setItem(`${id}_columnOrder`, JSON.stringify(columnOrder));
      setColumnsToBeReOrdered([...columnOrder]);
    }, [columnOrder]);

    const handleClose = () => {
      setOpenPopup(false);
    };

    const handleOpen = () => {
      if (disableColumnCustomisation) return;
      setOpenPopup(true);
    };

    const handleColumnToggle = (columnId, checked) => {
      if (
        checked ||
        (columnsToBeHidden || []).find(
          (hiddenColumnId) => hiddenColumnId === columnId
        )
      ) {
        setColumnsToBeHidden(
          [...columnsToBeHidden].filter(
            (hiddenColumnId) => hiddenColumnId !== columnId
          )
        );
      } else {
        setColumnsToBeHidden([...columnsToBeHidden, columnId]);
      }
    };

    const handleSave = () => {
      setHiddenColumns(columnsToBeHidden);
      setColumnOrder(columnsToBeReOrdered);
      handleClose();
    };

    const handleReset = () => {
      setColumnsToBeHidden([...defaultHiddenColumns]);
      setColumnsToBeReOrdered(initialColumnOrder);
    };

    return (
      <StyledTableHead
        size={size}
        {...props}
        ref={forwardedRef}
        data-testid="Table_Headers"
      >
        <PopUp
          id="columnCustomisation"
          description="Select the columns from the below options."
          onAccept={handleSave}
          onCancel={handleClose}
          onClose={handleClose}
          onSecondaryAction={handleReset}
          secondaryButtonLabel="Reset"
          acceptButtonLabel="Save"
          cancelButtonLabel="Cancel"
          secondaryButtonProps={{ color: "ghost", size: "small" }}
          open={openPopup}
          setOpen={setOpenPopup}
          showFooter
          showSecondaryButton
          size="extraSmall"
          title="Customise Columns"
        >
          <PopUpBody>
            <ColumnToggleFieldContainer
              columns={allColumns}
              handleColumnToggle={handleColumnToggle}
              columnsToBeHidden={columnsToBeHidden}
              fixedColumns={fixedColumns}
              columnsToBeReOrdered={columnsToBeReOrdered}
              setColumnsToBeReOrdered={setColumnsToBeReOrdered}
            />
          </PopUpBody>
        </PopUp>
        {headerGroups?.map((headerGroup) => (
          <StyledTableRow {...headerGroup.getHeaderGroupProps()}>
            {headerGroup?.headers?.map((column) => {
              const isTableActionsHeader =
                column?.id === PREDEFINED_COLUMNS.ROW_ACTIONS_COLUMN;
              return (
                <StyledTableHeader
                  size={size}
                  // canResize={column.canResize}
                  tableActions={isTableActionsHeader}
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                >
                  {isTableActionsHeader ? (
                    <ColumnCustomisationButton
                      onClick={handleOpen}
                      data-testid="columnCustomisationButton"
                      hidden={disableColumnCustomisation}
                    >
                      <CustomizationIcon className="customisationIcon" />
                    </ColumnCustomisationButton>
                  ) : (
                    column.render("Header")
                  )}

                  {/* Sortable Indicator */}
                  {column.canSort && !column.isSorted && (
                    <span className="sortableIndicator">
                      <SortUpDownIcon className="sortIcon" />
                    </span>
                  )}
                  {/* Active Sort Indicator */}
                  {column.isSorted && (
                    <span
                      data-testid={
                        column.isSortedDesc ? "Sort_Desc" : "Sort_Asc"
                      }
                    >
                      {column.isSortedDesc ? (
                        <SortDownIcon className="sortIcon" />
                      ) : (
                        <SortUpIcon className="sortIcon" />
                      )}
                    </span>
                  )}

                  {/* Column Risize Handle Placement */}
                  {column.canResize && (
                    <ResizeHandle
                      {...column.getResizerProps()}
                      className="resizeHandle"
                      isResizing={column.isResizing}
                      onClick={(e) => {
                        e.stopPropagation();
                      }}
                    />
                  )}
                  {/* Column Risize Handle Placement */}

                  {/* Filter Placement */}
                  {/* <div>{column.canFilter ? column?.render("Filter") : null}</div> */}
                  {/* Filter Placement */}
                </StyledTableHeader>
              );
            })}
          </StyledTableRow>
        ))}
      </StyledTableHead>
    );
  }
);

Header.propTypes = {
  headerGroups: PropTypes.instanceOf(Array),
  size: PropTypes.oneOf(SIZE_VARIENTS),
  allColumns: PropTypes.instanceOf(Array),
  hiddenColumns: PropTypes.instanceOf(Array),
  setHiddenColumns: PropTypes.func,
  defaultHiddenColumns: PropTypes.instanceOf(Array),
  fixedColumns: PropTypes.instanceOf(Array),
  disableColumnCustomisation: PropTypes.bool,
  id: PropTypes.string,
  initialColumnOrder: PropTypes.instanceOf(Array),
  columnOrder: PropTypes.instanceOf(Array),
  setColumnOrder: PropTypes.func,
};

export default Header;

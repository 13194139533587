import tokens from "../../tokens/json/variables.json";

export const rootStyle = {
  variants: {
    type: {
      layered: {
        display: "flex",
        flexDirection: "column",
        gap: tokens.spacing6,
        width: "454px",
      },
    },
    border: {
      true: {
        backgroundColor: tokens.layerLayer02,
        border: `${tokens.borderWidth1} solid ${tokens.borderBorderSubtle00}`,
        borderRadius: tokens.borderRadius1,
        padding: tokens.spacing8,
      },
    },
  },
};

export const groupHeadlineStyle = {
  display: "flex",
  alignItems: "center",
  flexDirection: "row",
  gap: tokens.spacing4,
};

export const groupIconStyle = {
  height: tokens.sizing7,
  width: "23.333px",
};

export const groupHeadingStyle = {
  fontFamily: tokens.fontFamiliesPoppins,
  fontSize: tokens.fontSize4,
  fontStyle: "normal",
  fontWeight: tokens.fontWeightsPoppins3,
  lineHeight: tokens.lineHeights5,
  letterSpacing: tokens.letterSpacing0,
  variants: {
    type: {
      cards: {
        fontWeight: tokens.fontWeightsPoppins2,
        fontSize: tokens.fontSize3,
        lineHeight: tokens.lineHeights4,
        letterSpacing: tokens.letterSpacing0,
        paddingBottom: tokens.spacing6,
      },
    },
  },
};

export const groupDescriptionStyle = {
  fontFamily: tokens.fontFamiliesSourceSansPro,
  fontSize: tokens.fontSize2,
  fontStyle: "normal",
  fontWeight: tokens.fontWeightsSourceSansPro1,
  lineHeight: tokens.lineHeights1,
  letterSpacing: tokens.letterSpacing0,
};

export const sectionsStyle = {
  display: "flex",
  flexWrap: "wrap",
  variants: {
    type: {
      cards: {
        gap: tokens.spacing8,
        flexDirection: "row",
      },
      layered: {
        gap: tokens.spacing5,
        flexDirection: "column",
      },
    },
  },
};

export const sectionStyle = {
  display: "flex",
  alignItems: "flex-start",
  backgroundColor: tokens.layerLayer02,
  textDecoration: tokens.link01TextCase,
  variants: {
    type: {
      cards: {
        flexWrap: "wrap",
        alignItems: "normal",
        height: "226px",
        width: "436px",
        padding: "24px 32px 32px 24px",
        border: `${tokens.borderWidth1} solid ${tokens.borderBorderSubtle00}`,
        borderRadius: tokens.borderRadius1,
      },
      layered: {
        width: "100%",
        alignItems: "center",
        gap: "26px",
      },
    },
    lineSeparator: {
      true: {
        "&:not(:last-child)": {
          borderBottom: `${tokens.borderWidth1} solid #b8c3d6`,
          borderColor: tokens.gray20,
          paddingBottom: tokens.spacing5,
        },
        "&:last-child > div:nth-child(2)": {
          borderBottom: 0,
        },
      },
    },
  },
};

export const sectionContentStyle = {
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  gap: tokens.spacing2,
};

export const sectionHeadingStyle = {
  fontFamily: tokens.fontFamiliesPoppins,
  color: tokens.textTextPrimary,
  fontWeight: tokens.fontWeightsPoppins2,
  alignItems: "center",
  display: "flex",
  variants: {
    type: {
      cards: {
        fontSize: tokens.fontSize5,
      },
      layered: {
        fontFamily: tokens.fontFamiliesSourceSansPro,
        fontSize: tokens.fontSize2,
        fontStyle: "normal",
        fontWeight: tokens.fontWeightsSourceSansPro0,
        lineHeight: tokens.lineHeights1,
        letterSpacing: tokens.letterSpacing0,
      },
    },
  },
};

export const sectionTitleStyle = {
  fontFamily: tokens.fontFamiliesSourceSansPro,
  fontSize: tokens.fontSize1,
  color: tokens.textTextPrimary,
  fontWeight: tokens.fontWeightsSourceSansPro1,
  variants: {
    type: {
      cards: {
        lineHeight: tokens.lineHeights0,
      },
    },
  },
};

export const sectionDescStyle = {
  fontFamily: tokens.fontFamiliesSourceSansPro,
  textDecoration: "none",
  color: tokens.textTextPrimary,
  fontSize: tokens.fontSize1,
  fontStyle: "normal",
  fontWeight: tokens.fontWeightsSourceSansPro0,
  lineHeight: tokens.lineHeights1,
  letterSpacing: tokens.letterSpacing0,
  variants: {
    type: {
      cards: {
        fontSize: tokens.fontSize1,
      },
    },
  },
};

export const sectionLinkStyle = {
  color: tokens.linkLinkPrimary,
  variants: {
    type: {
      layered: {
        display: "inline",
        paddingLeft: tokens.spacing2,
      },
      cards: {
        fontFamily: tokens.fontFamiliesSourceSansPro,
        fontSize: tokens.fontSize1,
        fontWeight: tokens.fontWeightsSourceSansPro0,
        lineHeight: tokens.lineHeights0,
        letterSpacing: "0.01em",
        textDecoration: tokens.link01TextDecoration,
      },
    },
  },
  "&:hover": {
    color: tokens.linkLinkPrimaryHover,
  },
};

export const sectionCommentStyle = {
  fontFamily: tokens.fontFamiliesSourceSansPro,
  fontSize: tokens.fontSize0,
  fontWeight: tokens.fontWeightsSourceSansPro1,
  lineHeight: tokens.lineHeights0,
  letterSpacing: "0.01em",
  marginLeft: "auto",
  color: tokens.gray90,
};

export const sectionIconStyle = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  variants: {
    type: {
      cards: {
        boxShadow:
          "0px 0px 2px rgba(49, 63, 86, 0.15), 2px 2px 10px rgba(49, 63, 86, 0.1)",
        width: tokens.sizing10,
        height: tokens.sizing10,
        borderRadius: "50%",
        overflow: "hidden",
      },
      layered: {
        flexDirection: "column",
      },
    },
  },
};

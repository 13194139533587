import * as React from "react";

const DummySvgArt = (props) => (
  <svg
    width={256}
    height={256}
    viewBox="0 0 256 256"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect width={256} height={256} rx={128} fill="#F1F3F7" />
    <g clipPath="url(#d)">
      <path
        d="M136 142c0-5.525 4.475-10 10-10h36c5.525 0 10 4.475 10 10v36c0 5.525-4.475 10-10 10h-36c-5.525 0-10-4.475-10-10v-36Z"
        fill="#91A3C0"
      />
      <path
        opacity={0.4}
        d="M136 64c2.8 0 5.425 1.475 6.85 3.885l24 39.99c1.5 2.475 1.525 5.55.1 8.075-1.4 2.5-4.075 3.825-6.95 3.825h-48c-2.875 0-5.55-1.325-6.95-3.825-1.425-2.525-1.4-5.6.1-8.075l24-39.99C130.575 65.475 133.2 64 136 64Zm-72 96c0-17.675 14.328-32 32-32 17.675 0 32 14.325 32 32s-14.325 32-32 32c-17.672 0-32-14.325-32-32Z"
        fill="#B8C3D6"
      />
    </g>
    <defs>
      <clipPath id="d">
        <path fill="#fff" d="M64 64h128v128H64z" />
      </clipPath>
    </defs>
  </svg>
);
export default DummySvgArt;

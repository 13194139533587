import * as React from "react";
const SvgArrowUpIcon = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 16 16"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="m2.719 9.219 4.75-4.469c.156-.156.343-.25.531-.25.156 0 .344.094.5.219l4.75 4.468c.313.282.313.75.031 1.063-.281.313-.75.313-1.062.031l-4.25-4-4.219 4c-.313.281-.781.281-1.063-.031a.71.71 0 0 1 .032-1.031Z"
      fill="inherit"
    />
  </svg>
);
export default SvgArrowUpIcon;

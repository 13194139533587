import * as React from "react";
const SvgArrowRightIcon = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 16 16"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="m6.781 2.75 4.47 4.75c.124.156.218.344.218.5 0 .188-.094.375-.219.531l-4.469 4.75c-.28.313-.75.313-1.062.032-.313-.282-.313-.75-.031-1.063l4-4.25-4-4.219c-.282-.312-.282-.78.03-1.062.313-.281.782-.281 1.063.031Z"
      fill="inherit"
    />
  </svg>
);
export default SvgArrowRightIcon;

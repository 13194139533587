import { keyframes } from "@stitches/react";
import variables from "../../tokens/json/variables.json";

// animations
const slideUpAndFade = keyframes({
  "0%": { opacity: 0, transform: "translateY(2px)" },
  "100%": { opacity: 1, transform: "translateY(0)" },
});

const slideRightAndFade = keyframes({
  "0%": { opacity: 0, transform: "translateX(-2px)" },
  "100%": { opacity: 1, transform: "translateX(0)" },
});

const slideDownAndFade = keyframes({
  "0%": { opacity: 0, transform: "translateY(-2px)" },
  "100%": { opacity: 1, transform: "translateY(0)" },
});

const slideLeftAndFade = keyframes({
  "0%": { opacity: 0, transform: "translateX(2px)" },
  "100%": { opacity: 1, transform: "translateX(0)" },
});

export const DropdownTriggerStyles = {
  all: "unset",
};

export const DropdownTextTriggerStyles = {
  all: "unset",
  boxSizing: "border-box",
  display: "grid",
  gridTemplateColumns: "1fr 22px",
  alignItems: "center",
  width: "fit-content",
  minWidth: "min-content",

  height: variables.spacing8,
  paddingRight: variables.spacing4,
  paddingLeft: variables.spacing4,
  gap: variables.spacing2,
  borderRadius: variables.borderRadius1,

  variants: {
    headerComponent: {
      true: {
        background: variables.headerHeaderBackground,
        border: `${variables.borderWidth1} solid ${variables.headerHeaderBorder}`,

        // DropdownTriggerTextStyles
        color: variables.headerHeaderText,
        // DropdownIconStyles
        fill: variables.headerHeaderIcon,

        "&:hover": {
          background: variables.headerHeaderBackgroundHover,
          fill: variables.headerHeaderIconOnColor,
        },

        "&:focus": {
          border: `${variables.borderWidth1} solid ${variables.headerHeaderFocus}`,
        },

        '&[data-state="open"]': {
          border: `${variables.borderWidth1} solid ${variables.headerHeaderBorderSelected}`,
          fill: variables.headerHeaderIconOnColor,
        },

        "&[data-disabled]": {
          border: `${variables.borderWidth1} solid ${variables.borderBorderOnColorDisabled}`,
          // DropdownTriggerTextStyles
          color: variables.textTextOnColorDisabled,
          // DropdownIconStyles
          fill: variables.iconIconOnColorDisabled,
        },
      },
      false: {
        border: `${variables.borderWidth1} solid ${variables.borderBorderSubtle01}`,

        // DropdownTriggerTextStyles
        color: variables.textTextPrimary,
        // DropdownIconStyles
        fill: variables.iconIconPrimary,

        "&:hover": {
          background: variables.layerLayerHover01,
        },

        "&:focus": {
          border: `${variables.borderWidth2} solid ${variables.focusFocus}`,
        },

        '&[data-state="open"]': {
          border: `${variables.borderWidth1} solid ${variables.borderBorderSubtleSelected01}`,
        },

        "&[data-disabled]": {
          border: `${variables.borderWidth1} solid ${variables.borderBorderDisabled}`,
          // DropdownTriggerTextStyles
          color: variables.textTextDisabled,
          // DropdownIconStyles
          fill: variables.iconIconDisabled,
        },
      },
    },
    inline: {
      true: {
        border: "none",
        "&:hover": {
          border: `${variables.borderWidth1} solid ${variables.borderBorderSubtle01}`,
        },
        "&[data-disabled]": {
          border: "none",
        },
      },
    },
    error: {
      true: {
        border: `${variables.borderWidth2} solid ${variables.supportSupportError}`,
      },
    },
    multiline: {
      true: {
        paddingTop: variables.spacing15,
        paddingBottom: variables.spacing15,
        height: "max-content",
      },
    },
  },
};

export const DropdownTriggerTextStyles = {
  // for max-width
  overflow: "hidden",
  whiteSpace: "nowrap",
  textOverflow: "ellipsis",

  fontFamily: variables.bodyShort01FontFamily,
  fontWeight: variables.bodyShort01FontWeight,
  lineHeight: variables.bodyShort01LineHeight,
  fontSize: variables.bodyShort01FontSize,
  letterSpacing: variables.bodyShort01LetterSpacing,
  paragraphSpacing: variables.bodyShort01ParagraphSpacing,
  testCase: variables.bodyShort01TextCase,
  textDecoration: variables.bodyShort01TextDecoration,

  color: "inherit",
};

export const DropdownIconStyles = {
  boxSizing: "content-box",

  fill: "inherit",
  paddingLeft: variables.spacing2,

  width: "14px",
  height: "14px",
};

export const DropdownLabelStyles = {
  padding: `0px 0px ${variables.spacing2} 0px`,
  color: variables.textTextSecondary,

  fontFamily: variables.labelFontFamily,
  fontWeight: variables.labelFontWeight,
  lineHeight: variables.labelLineHeight,
  fontSize: variables.labelFontSize,
  letterSpacing: variables.labelLetterSpacing,
  paragraphSpacing: variables.labelParagraphSpacing,
  textCase: variables.labelTextCase,
  textDecoration: variables.labelTextDecoration,

  variants: {
    disabled: {
      true: {
        color: variables.textTextDisabled,
      },
    },
    required: {
      true: {
        "&::after": {
          content: " *",
          color: variables.textTextError,
          fontFamily: variables.labelFontFamily,
          fontSize: variables.labelFontSize,
          fontWeight: variables.labelFontWeight,
          lineHeight: variables.labelLineHeight,
          letterSpacing: variables.labelLetterSpacing,
          paragraphSpacing: variables.labelParagraphSpacing,
        },
      },
    },
  },
};

export const DropdownHelperTextStyles = {
  padding: `${variables.spacing1} 0px 0px 0px`,
  color: variables.textTextSecondary,

  fontFamily: variables.labelFontFamily,
  fontWeight: variables.labelFontWeight,
  lineHeight: variables.labelLineHeight,
  fontSize: variables.labelFontSize,
  letterSpacing: variables.labelLetterSpacing,
  paragraphSpacing: variables.labelParagraphSpacing,
  textCase: variables.labelTextCase,
  textDecoration: variables.labelTextDecoration,

  variants: {
    disabled: {
      true: {
        color: variables.textTextDisabled,
      },
    },
    error: {
      true: {
        color: variables.textTextError,
      },
    },
  },
};

export const DropdownContentStyles = {
  boxShadow: "0px 0px 20px rgba(0, 0, 0, 0.1)",
  backgroundColor: variables.layerLayer02,

  border: `${variables.borderWidth1} solid ${variables.borderBorderSubtle02}`,
  borderRadius: variables.borderRadius1,

  variants: {
    type: {
      select: {
        padding: `${variables.spacing2} 0px`,
      },
      menu: {
        padding: `${variables.spacing4}`,
      },
    },
  },

  "@media (prefers-reduced-motion: no-preference)": {
    animationDuration: "400ms",
    animationTimingFunction: "cubic-bezier(0.16, 1, 0.3, 1)",
    willChange: "transform, opacity",
    '&[data-state="open"]': {
      '&[data-side="top"]': { animationName: slideDownAndFade },
      '&[data-side="right"]': { animationName: slideLeftAndFade },
      '&[data-side="bottom"]': { animationName: slideUpAndFade },
      '&[data-side="left"]': { animationName: slideRightAndFade },
    },
  },
};

export const DropdownGroupStyles = {
  display: "grid",
  gap: variables.spacing0,
  maxHeight: "30vh",
  overflowY: "auto",
  scrollBehavior: "smooth",

  "&::-webkit-scrollbar": {
    width: "4px",
  },
  "&::-webkit-scrollbar-track": {
    width: "4px",
  },
  "&::-webkit-scrollbar-thumb": {
    width: "4px",
    backgroundColor: variables.layerAccentLayerAccent01,
    borderRadius: variables.borderRadius05,
  },
};

export const DropdownItemStyles = {
  display: "flex",
  justifyContent: "space-between",
  gap: variables.spacing2,
  alignItems: "center",
  paddingLeft: variables.spacing4,
  paddingRight: variables.spacing4,
  paddingBlock: variables.spacing15,
  boxSizing: "border-box",

  minHeight: variables.spacing8,

  // DropdownItemTextStyles
  color: variables.textTextPrimary,

  variants: {
    type: {
      menu: {
        borderRadius: variables.borderRadius1,
      },
    },
  },

  "&:hover": {
    backgroundColor: variables.layerLayerHover01,
  },

  "&:focus": {
    backgroundColor: variables.layerLayerHover01,
  },

  "&:focus-visible": {
    outline: "none",
  },

  "&[data-disabled]": {
    // DropdownItemTextStyles
    color: variables.textTextDisabled,
  },
};

export const DropdownCheckboxItemStyles = {
  display: "grid",
  gridTemplateColumns: `14px 1fr`,
  gap: variables.spacing2,
  alignItems: "center",
  paddingLeft: variables.spacing4,
  paddingRight: variables.spacing4,
  boxSizing: "border-box",
  paddingBlock: variables.spacing15,

  minHeight: variables.spacing8,

  // DropdownItemTextStyles
  color: variables.textTextPrimary,

  "&:hover": {
    backgroundColor: variables.layerLayerHover01,
  },

  "&:focus": {
    backgroundColor: variables.layerLayerHover01,
  },

  "&:focus-visible": {
    outline: "none",
  },

  "&[data-disabled]": {
    // DropdownItemTextStyles
    color: variables.textTextDisabled,
  },
  variants: {
    actions: {
      true: {
        borderBottom: `1px solid  ${variables.borderBorderSubtle00}`,
        marginBottom: variables.spacing1,
      },
    },
  },
};

export const DropdownItemLabelStyles = {
  display: "flex",
  // gridTemplateColumns: "auto 1fr",
  gap: variables.spacing4,
  alignItems: "center",
};

export const DropdownItemIconStyles = {
  fill: variables.gray80,
  display: "flex",
  alignItems: "center",
};

export const DropdownItemTextStyles = {
  color: "inherit",

  fontFamily: variables.bodyShort01FontFamily,
  fontWeight: variables.bodyShort01FontWeight,
  lineHeight: variables.bodyShort01LineHeight,
  fontSize: variables.bodyShort01FontSize,
  letterSpacing: variables.bodyShort01LetterSpacing,
  paragraphSpacing: variables.bodyShort01ParagraphSpacing,
  testCase: variables.bodyShort01TextCase,
  textDecoration: variables.bodyShort01TextDecoration,
};

export const DropdownItemIndicatorStyles = {
  fill: variables.iconIconPrimary,
  paddingLeft: variables.spacing2,

  width: "22px",
  height: variables.sizing35,
  display: "inline-flex",
};

export const DropdownItemIndicatorIconStyles = {
  width: variables.sizing35,
  height: variables.sizing35,
  fill: "inherit",
};

export const DropdownCheckboxItemIndicatorStyles = {
  width: variables.sizing35,
  height: variables.sizing35,
  fill: variables.iconIconPrimary,
  display: "inline-flex",

  variants: {
    checked: {
      true: {
        fill: variables.iconIconBrand,
      },
      indeterminate: {
        fill: variables.iconIconBrand,
      },
    },
    disabled: {
      true: {
        fill: variables.iconIconDisabled,
      },
    },
  },
};

export const DropdownTagContainerStyles = {
  display: "flex",
  columnGap: variables.spacing2,
  rowGap: variables.spacing15,
  width: "fit-content",
};

export const SearchFieldContainerStyles = {
  display: "grid",
  padding: `0 ${variables.spacing2}`,
  marginBottom: variables.spacing1,
  boxSizing: "border-box",

  "& .searchInput": {
    width: "100%",
  },
};

export const ClearButtonStyles = {
  marginLeft: "auto",
  marginRight: `-${variables.spacing4}`,
};

export const TooltipContentStyles = {
  padding: "14px 0px",
  display: "flex",
  flexDirection: "column",
  gap: variables.spacing15,
};

import React from "react";
import { styled } from "@stitches/react";
import PropTypes from "prop-types";
import {
  ColumnVisibilityToggleStyles,
  ColumnToggleFieldStyles,
} from "./ColumnToggleField.styles";

const ColumnToggleLabel = styled("label", ColumnVisibilityToggleStyles);
const ColumnToggleField = styled("div", ColumnToggleFieldStyles);

const ColumnVisibilityToggle = React.forwardRef(
  (
    {
      column,
      checked,
      disabled,
      isDragging,
      isHidden,
      handleColumnToggle,
      ...props
    },
    forwardedRef
  ) => {
    return (
      <ColumnToggleField
        key={column?.id}
        data-testid={column?.id}
        ref={forwardedRef}
        isDragging={isDragging}
        isHidden={isHidden}
        {...props}
      >
        {/* To Be replaced with common-ui checkbox */}
        <input
          style={{ margin: "0px" }}
          type="checkbox"
          // role="checkbox"
          checked={checked}
          disabled={disabled}
          data-state={checked ? "checked" : "unchecked"}
          onChange={(e) => {
            if (disabled) return;
            handleColumnToggle(column?.id, e.target.checked);
          }}
        />{" "}
        <ColumnToggleLabel
          onClick={() => {
            if (disabled) return;
            handleColumnToggle(column?.id);
          }}
        >
          {typeof column?.Header === "string" ? column?.Header : column?.id}
        </ColumnToggleLabel>
      </ColumnToggleField>
    );
  }
);

ColumnVisibilityToggle.propTypes = {
  column: PropTypes.instanceOf(Object),
  checked: PropTypes.bool,
  disabled: PropTypes.bool,
  isDragging: PropTypes.bool,
  isHidden: PropTypes.bool,
  handleColumnToggle: PropTypes.func,
};

export default ColumnVisibilityToggle;

import * as React from "react";
const SvgCloseIcon = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 16 16"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M13.719 12.449c.337.373.337.934 0 1.27-.375.375-.938.375-1.275 0L8.019 9.271l-4.463 4.448c-.375.375-.937.375-1.275 0-.375-.336-.375-.897 0-1.27L6.744 8 2.28 3.551c-.375-.373-.375-.934 0-1.27a.823.823 0 0 1 1.238 0l4.5 4.485 4.462-4.448a.823.823 0 0 1 1.238 0c.375.336.375.897 0 1.27L9.256 8l4.463 4.449Z"
      fill="inherit"
    />
  </svg>
);
export default SvgCloseIcon;

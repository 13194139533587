import * as React from "react";
const SvgApplicationsIcon = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 16 16"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M1 2.25C1 1.56 1.56 1 2.25 1h1.5C4.44 1 5 1.56 5 2.25v1.5C5 4.44 4.44 5 3.75 5h-1.5C1.56 5 1 4.44 1 3.75v-1.5Zm0 5C1 6.56 1.56 6 2.25 6h1.5C4.44 6 5 6.56 5 7.25v1.5C5 9.44 4.44 10 3.75 10h-1.5C1.56 10 1 9.44 1 8.75v-1.5Zm4 5v1.5C5 14.44 4.44 15 3.75 15h-1.5C1.56 15 1 14.44 1 13.75v-1.5c0-.69.56-1.25 1.25-1.25h1.5c.69 0 1.25.56 1.25 1.25Zm1-10C6 1.56 6.56 1 7.25 1h1.5C9.44 1 10 1.56 10 2.25v1.5C10 4.44 9.44 5 8.75 5h-1.5C6.56 5 6 4.44 6 3.75v-1.5Zm4 5v1.5C10 9.44 9.44 10 8.75 10h-1.5C6.56 10 6 9.44 6 8.75v-1.5C6 6.56 6.56 6 7.25 6h1.5C9.44 6 10 6.56 10 7.25Zm-4 5c0-.69.56-1.25 1.25-1.25h1.5c.69 0 1.25.56 1.25 1.25v1.5c0 .69-.56 1.25-1.25 1.25h-1.5C6.56 15 6 14.44 6 13.75v-1.5Zm9-10v1.5C15 4.44 14.44 5 13.75 5h-1.5C11.56 5 11 4.44 11 3.75v-1.5c0-.69.56-1.25 1.25-1.25h1.5c.69 0 1.25.56 1.25 1.25Zm-4 5c0-.69.56-1.25 1.25-1.25h1.5c.69 0 1.25.56 1.25 1.25v1.5c0 .69-.56 1.25-1.25 1.25h-1.5C11.56 10 11 9.44 11 8.75v-1.5Zm4 5v1.5c0 .69-.56 1.25-1.25 1.25h-1.5c-.69 0-1.25-.56-1.25-1.25v-1.5c0-.69.56-1.25 1.25-1.25h1.5c.69 0 1.25.56 1.25 1.25Z"
      fill="inherit"
    />
  </svg>
);
export default SvgApplicationsIcon;

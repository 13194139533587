import React from "react";
import ReactLoader from "react-loader-spinner";
import PropTypes from "prop-types";
import styles from "./Loader.module.css";

const Loader = ({ id, type, width, height, color }) => {
  return (
    <div data-testid={`loader_${id}`} className={styles.container}>
      <div
        className={styles.loader}
        style={{ marginLeft: -width / 2, marginTop: -height / 2 }}
      >
        <ReactLoader type={type} color={color} height={height} width={width} />
      </div>
    </div>
  );
};

Loader.propTypes = {
  id: PropTypes.string,
  type: PropTypes.oneOf([
    "Audio",
    "BallTriangle",
    "Bars",
    "Circles",
    "Grid",
    "Hearts",
    "Oval",
    "Puff",
    "Rings",
    "TailSpin",
    "ThreeDots",
    "Watch",
    "RevolvingDot",
    "Triangle",
    "Plane",
    "MutatingDots",
    "CradleLoader",
  ]),
  width: PropTypes.number,
  height: PropTypes.number,
  color: PropTypes.string,
};

Loader.defaultProps = {
  type: "ThreeDots",
  width: 100,
  height: 100,
  color: "#004ecc",
};

export default Loader;

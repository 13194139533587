import React from "react";
import PropTypes from "prop-types";
import { styled } from "@stitches/react";
import token from "../../tokens/json/variables.json";

const LinkButton = styled("a", {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  fontSize: token.link02FontSize,
  lineHeight: token.link02LineHeight,
  color: token.linkLinkPrimary,
  cursor: "pointer",
  fontFamily: token.link02FontFamily,
  fontWeight: token.link02FontWeight,
  letterSpacing: token.link01LetterSpacing,
  paragraphSpacing: token.link01ParagraphSpacing,
  textCase: token.link01TextCase,
  textDecoration: "none",
  "&:hover": {
    textDecoration: "none",
    color: token.linkLinkPrimaryHover,
  },
  "&:active": {
    color: token.linkLinkPrimaryActive,
  },
  "&:visited": {
    color: token.textTextPrimary,
    "&:hover": {
      color: token.linkLinkPrimaryHover,
    },
    "&:active": {
      color: token.linkLinkPrimaryActive,
    },
  },
  "&:focus": {
    border: `${token.borderWidth1} solid ${token.focusFocus}`,
  },
  variants: {
    disabled: {
      true: {
        color: token.textTextDisabled,
        pointerEvents: "none",
        cursor: "not-allowed",
        "&:visited": {
          color: token.textTextDisabled,
        },
      },
    },
    hidden: {
      true: {
        display: "none",
      },
    },
    size: {
      small: {
        fontSize: token.link01FontSize,
        lineHeight: token.link01LineHeight,
      },
      medium: {
        fontSize: token.link02FontSize,
        lineHeight: token.link02LineHeight,
      },
    },
  },
  defaultVariants: {
    size: "medium",
  },
});

const Link = (props) => {
  return <LinkButton {...props}>{props.children}</LinkButton>;
};

Link.propTypes = {
  /** Specifies the size of the Link. Options:
  - "small": Smaller Link size.
  - "medium": Standard Link size. (default) */
  size: PropTypes.oneOf(["small", "medium"]),

  /** The URL or path the link component points to. This is a standard anchor tag attribute
  which determines the link's destination. */
  href: PropTypes.string,

  /** When true, the link is disabled. This prevents users from being able to interact with
  the link. Disabled links are typically styled differently to indicate their inactive state. */
  disabled: PropTypes.bool,

  /** Controls the visibility of the link on the page. If true, the link will not be rendered
    or visible to the user. This can be useful for conditionally showing links based on certain criteria. */
  hidden: PropTypes.bool,

  /** An optional string that specifies a unique identifier for the link. This can be useful for
    referencing the link in JavaScript, for styling, or for accessibility enhancements. */
  id: PropTypes.string,

  /** The content to be rendered inside the link. This prop is required and allows for flexible
   link content, including text, icons, or other elements. It must be provided for the link
   to display anything. */
  children: PropTypes.node.isRequired,
};

export default Link;

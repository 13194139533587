import tokens from "../../tokens/json/variables.json";

export const SearchFieldStyles = {
  height: tokens.sizing8,
  padding: `${tokens.spacing0} ${tokens.spacing8} ${tokens.spacing0} 30px`,
  color: tokens.textTextPrimary,
  border: `${tokens.borderWidth1} solid ${tokens.borderBorderSubtle01}`,
  borderRadius: tokens.borderRadius1,
  boxSizing: "border-box",
  fontFamily: tokens.bodyShort01FontFamily,
  fontSize: tokens.bodyShort01FontSize,
  fontWeight: tokens.bodyShort01FontWeight,
  lineHeight: tokens.bodyShort01LineHeight,
  letterSpacing: tokens.bodyShort01LetterSpacing,
  paragraphSpacing: tokens.bodyShort01ParagraphSpacing,
  textDecoration: tokens.bodyShort01TextDecoration,
  textOverflow: "ellipsis",
  "&::placeholder": {
    color: tokens.textTextPlaceholder,
  },
  "&:active": {
    padding: `${tokens.spacing0} ${tokens.spacing8} ${tokens.spacing0} 30px`,
    color: tokens.textTextPrimary,
    border: `${tokens.borderWidth2} solid ${tokens.focusFocus}`,
    "& + button": {
      display: "flex",
    },
  },
  "&:focus": {
    padding: `${tokens.spacing0} ${tokens.spacing8} ${tokens.spacing0} 30px`,
    color: tokens.textTextPrimary,
    border: `${tokens.borderWidth2} solid ${tokens.focusFocus}`,
  },
  "&[data-showicon=false]": {
    padding: `${tokens.spacing0} ${tokens.spacing8} ${tokens.spacing0} 8px`,
  },

  variants: {
    disabled: {
      true: {
        color: tokens.textTextDisabled,
        border: `${tokens.borderWidth1} solid ${tokens.borderBorderSubtle01}`,
        pointerEvents: "none",
        cursor: "not-allowed",
        "&::placeholder": {
          color: tokens.textTextDisabled,
        },
      },
    },
    error: {
      true: {
        color: tokens.textTextPrimary,
        border: `${tokens.borderWidth2} solid ${tokens.supportSupportError}`,
      },
    },
  },
};

export const SearchContainerStyles = {
  display: "grid",
  position: "relative",
  backgroundColor: tokens.layerLayer02,
};

export const SearchIconContainerStyles = {
  position: "absolute",
  display: "flex",
  alignItems: "center",
  fill: tokens.iconIconSecondary,
  color: tokens.iconIconSecondary,
  height: "100%",
  paddingLeft: tokens.spacing2,
  variants: {
    disabled: {
      true: {
        color: tokens.iconIconDisabled,
      },
    },
  },
};

export const SearchIconStyles = {
  width: tokens.sizing35,
  height: tokens.sizing35,
};

export const ClearButtonStyles = {
  margin: 0,
  position: "absolute",
  width: tokens.sizing8,
  padding: 0,
  top: 0,
  right: 0,
  display: "none",
  "&.visibleStyles": {
    display: "flex",
  },
  variants: {
    disabled: {
      true: {
        color: tokens.iconIconDisabled,
      },
    },
  },
};

export const ClearIconStyles = {
  color: tokens.iconIconPrimary,
  height: tokens.sizing35,
  width: tokens.sizing35,
};

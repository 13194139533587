import React from "react";
import { styled } from "@stitches/react";
import PropTypes from "prop-types";
import Button from "../../Button/Button";
import useHover from "../../../customHooks/useHover";
import { NodeIconButtonStyles } from "./NodeAction.styles";

const NodeIconButton = styled(Button, NodeIconButtonStyles);

const NodeAction = ({
  node,
  visible,
  isVisible,
  icon,
  hoverIcon,
  action,
  onActionClick,
}) => {
  const [ref, isHovered] = useHover();

  if (visible === false || (isVisible && !isVisible(node))) {
    return null; // Hide the action if visible is set to false or isVisible condition fails
  }

  return (
    <div ref={ref}>
      <NodeIconButton
        color="ghost"
        size="medium"
        onClick={(e) => onActionClick(e, action)}
      >
        {isHovered && hoverIcon ? hoverIcon : icon}
      </NodeIconButton>
    </div>
  );
};

NodeAction.propTypes = {
  node: PropTypes.instanceOf(Object),
  visible: PropTypes.bool,
  isVisible: PropTypes.func,
  icon: PropTypes.node,
  hoverIcon: PropTypes.node,
  action: PropTypes.string,
  onActionClick: PropTypes.func,
};

export default NodeAction;

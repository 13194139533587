import React, { useState, useEffect, useRef } from "react";
import { styled } from "@stitches/react";
import PropTypes from "prop-types";
import Node, { FallbackContainer } from "./Node/Node";
import {
  NestedListRootStyles,
  SearchWrapperStyles,
  MoreItemsWrapperStyles,
} from "./NestedList.styles";
import Search from "../Search/Search";
import Spinner from "./Spinner/Spinner";
import Button from "../Button/Button";
import EmptyState from "../EmptyState";
import DummySvgArt from "../../assets/art/DummySvgArt";

const NestedListRoot = styled("div", NestedListRootStyles);
const SearchWrapper = styled("div", SearchWrapperStyles);
const MoreItemsWrapper = styled("div", MoreItemsWrapperStyles);
const EmptyStateDescription = styled("div", {});

function processData(input) {
  const processedData = [];
  input?.forEach((item) => {
    const existingItem = processedData?.find(
      (outputItem) => outputItem.id === item.id
    );
    if (existingItem) {
      existingItem.childrenData = [
        ...(existingItem?.childrenData || []),
        ...(item?.childrenData || []),
      ];
      if (item && item.childrenCount !== undefined) {
        existingItem.childrenCount += item.childrenCount;
      }
    } else {
      processedData.push({ ...item });
    }
  });
  return processedData;
}

const NestedList = ({
  initialNodes,
  actions,
  fetchRootNodesByPage,
  fetchChildrenNodesByDepth,
  searchEnabled = false,
  ...props
}) => {
  const [nodes, setNodes] = useState(initialNodes || []);
  const [page, setPage] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const containerRef = useRef(null);
  const [searchText, setSearchText] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [hasMoreItems, setHasMoreItems] = useState(false);
  const [searchCount, setSearchCount] = useState(0);
  const shouldStopPageIncrementRef = useRef(false);

  const onAsync = (pageNumber, searchValue) => {
    setIsLoading(true);
    fetchRootNodesByPage(searchValue ? searchCount : pageNumber, searchValue)
      .then((rootNodesData) => {
        if (searchValue) {
          setHasMoreItems(rootNodesData?.hasMoreData);
          if (rootNodesData?.hasMoreData) {
            setSearchCount((prev) => prev + 1);
          }
          setNodes((prevNodes) => {
            const prev = Array.isArray(prevNodes) ? [...prevNodes] : [];
            return processData([...prev, ...(rootNodesData?.items ?? [])]);
          });
        } else {
          shouldStopPageIncrementRef.current = rootNodesData?.length === 0;
          setNodes((prevNodes) => [...prevNodes, ...rootNodesData]);
        }
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.error("Error fetching root nodes:", error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    if (
      (!initialNodes || initialNodes?.length === 0) &&
      typeof fetchRootNodesByPage === "function" &&
      !searchText?.length
    ) {
      // Fetch initial root nodes from API if not passed as prop
      onAsync(page);
    } else if (searchText === "") {
      setNodes(initialNodes);
    }
  }, [page, initialNodes]);

  const handleScroll = () => {
    const container = containerRef.current;
    if (shouldStopPageIncrementRef.current) return;
    if (
      container.scrollTop + container.clientHeight + 1 >=
        container.scrollHeight &&
      !isLoading
    ) {
      setPage((prevPage) => prevPage + 1);
    }
  };

  const searchNodes = (query) => {
    function searchRecursively(newNode) {
      let match = false;
      if (
        newNode?.id?.toString().toLowerCase().includes(query) ||
        newNode?.label?.toLowerCase().includes(query)
      ) {
        match = true;
      }

      if (newNode?.childrenData) {
        const matchingChildren = newNode?.childrenData?.filter((child) =>
          searchRecursively(child)
        );

        if (matchingChildren?.length > 0) {
          match = true;
          // eslint-disable-next-line no-param-reassign
          newNode.childrenData = matchingChildren;
          // eslint-disable-next-line no-param-reassign
          newNode.childrenCount = matchingChildren?.length;
        }
      }
      return match;
    }

    const results = JSON.parse(JSON.stringify(initialNodes))?.filter((node) => {
      return searchRecursively(node);
    });
    return results;
  };

  useEffect(() => {
    shouldStopPageIncrementRef.current = searchQuery !== "";
  }, [searchQuery]);

  const handleOnChange = (value) => {
    setPage(0);
    setNodes([]);
    setSearchText(value);
    setHasMoreItems(false);
    setSearchCount(0);
    if (value === "") {
      if (
        (!initialNodes || initialNodes?.length === 0) &&
        typeof fetchRootNodesByPage === "function"
      ) {
        onAsync(0, value);
        setSearchQuery(value);
      } else {
        setSearchQuery(value);

        setNodes(initialNodes);
      }
    }
  };

  const handleOnKeyPress = (value) => {
    if (
      (!initialNodes || initialNodes?.length === 0) &&
      typeof fetchRootNodesByPage === "function"
    ) {
      setSearchQuery(value);
      onAsync(0, value);
    } else {
      setSearchQuery(value);

      setNodes(searchNodes(value.toLowerCase()));
    }
  };

  useEffect(() => {
    const container = containerRef.current;
    container.addEventListener("scroll", handleScroll);
    return () => {
      container.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleOnMoreItems = () => {
    onAsync(searchCount + 1, searchQuery);
  };

  return (
    <>
      {searchEnabled && (
        <SearchWrapper>
          <Search
            value={searchText}
            placeholder="Enter Search Term and Press Enter"
            onChange={(e) => handleOnChange(e.target.value)}
            onKeyPress={(e) => {
              const inputValue = e.target.value;
              if (e.charCode === 13 && inputValue?.length) {
                handleOnKeyPress(inputValue);
              }
            }}
            data-testid="nestedListSearch"
          />
        </SearchWrapper>
      )}
      <NestedListRoot
        data-testid="NestedList_Container"
        ref={containerRef}
        {...props}
      >
        {nodes?.length || isLoading ? (
          <>
            {(nodes || [])?.map((node) => (
              <Node
                key={node.id}
                node={node}
                depth={0}
                actions={actions}
                fetchChildrenNodesByDepth={fetchChildrenNodesByDepth}
                size="small"
              />
            ))}
            {isLoading && (
              <FallbackContainer size="small">
                <Spinner />
              </FallbackContainer>
            )}
            {hasMoreItems && searchText && (
              <MoreItemsWrapper>
                <Button color="ghost" size="medium" onClick={handleOnMoreItems}>
                  more items
                </Button>
              </MoreItemsWrapper>
            )}
          </>
        ) : (
          <>
            {" "}
            {searchQuery === searchText && (
              <EmptyState
                data-testid="emptyState_container"
                description={
                  searchQuery?.length ? (
                    <EmptyStateDescription>
                      <EmptyStateDescription>
                        No results were found
                      </EmptyStateDescription>
                      Please check your spelling and try again
                    </EmptyStateDescription>
                  ) : (
                    "No Data found"
                  )
                }
                image={<DummySvgArt height="100px" width="100px" />}
                showImage
                showText
                style={{
                  background: "white",
                }}
              />
            )}
          </>
        )}
      </NestedListRoot>
    </>
  );
};

NestedList.propTypes = {
  initialNodes: PropTypes.instanceOf(Array),
  actions: PropTypes.instanceOf(Object),
  fetchRootNodesByPage: PropTypes.func,
  fetchChildrenNodesByDepth: PropTypes.func,
  searchEnabled: PropTypes.bool,
};

NestedList.defaultProps = {
  actions: {},
};

export default NestedList;

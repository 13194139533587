import variables from "../../../tokens/json/variables.json";

export const TableBodyStyles = {
  display: "block",
  overflow: "scroll",
  transition: "all 0.6s ease",

  variants: {
    size: {
      compact: {
        height: `calc(100% - ${variables.sizing8})`,
      },
      extraSmall: {
        height: `calc(100% - ${variables.sizing10})`,
      },
      small: {
        height: `calc(100% - ${variables.sizing12})`,
      },
      medium: {
        height: `calc(100% - ${variables.sizing16})`,
      },
      large: {
        height: `calc(100% - ${variables.sizing18})`,
      },
      extraLarge: {
        height: `calc(100% - ${variables.sizing20})`,
      },
    },
  },

  "&::-webkit-scrollbar": {
    width: "4px",
  },
  "&::-webkit-scrollbar-thumb": {
    background: variables.gray20,
    borderRadius: variables.borderRadius05,
  },
};

import React from "react";
import { styled } from "@stitches/react";
import PropTypes from "prop-types";
import { ArrowLeftIcon, ArrowRightIcon, EllipsisIcon } from "../../../../icons";
import {
  PageSelectorRootStyles,
  PageGroupStyles,
  PageButtonStyles,
  ArrowButtonStyles,
} from "./PageSelector.styles";

const PageSelectorRoot = styled("div", PageSelectorRootStyles);
const PageGroup = styled("div", PageGroupStyles);
const PageButton = styled("div", PageButtonStyles);
const ArrowButton = styled("div", ArrowButtonStyles);

const PageSelector = ({
  pageIndex,
  pageOptions,
  canPreviousPage,
  previousPage,
  canNextPage,
  nextPage,
  gotoPage,
}) => {
  const middleCount = 5;
  const pageCount = pageOptions?.length || 0;
  const currentPage = pageIndex;
  let firstPage = 1;
  if (currentPage > middleCount - 1) {
    firstPage = Math.min(currentPage - 1, pageCount - middleCount);
  }
  let numItems = Math.min(pageCount, middleCount);
  const showPrevSkipped = firstPage > 1;
  const showNextSkipped =
    pageCount > middleCount + 2 && firstPage < pageCount - middleCount;

  numItems -= !!showPrevSkipped + !!showNextSkipped;

  const elements = new Array(numItems)
    .fill(0)
    .map((_, index) => {
      const pageValue = firstPage + index;
      if (pageValue >= pageCount - 1) {
        return null;
      }
      return (
        <PageButton
          key={pageValue}
          tabIndex={0}
          selected={currentPage === pageValue}
          onClick={() => {
            gotoPage(Number(pageValue));
          }}
        >
          {pageValue + 1}
        </PageButton>
      );
    })
    .filter(Boolean);

  return (
    <PageSelectorRoot>
      <ArrowButton
        tabIndex={0}
        data-testid="Previous_Page"
        onClick={() => previousPage()}
        disabled={!canPreviousPage}
      >
        <ArrowLeftIcon className="pageNavigatorIcon" />
      </ArrowButton>
      <PageGroup>
        <PageButton
          key={0}
          tabIndex={0}
          selected={currentPage === 0}
          onClick={() => {
            gotoPage(Number(0));
          }}
        >
          {1}
        </PageButton>
        {!!showPrevSkipped && <EllipsisIcon />}
        {elements}
        {!!showNextSkipped && <EllipsisIcon />}
        {pageCount > 1 && (
          <PageButton
            key={pageCount - 1}
            tabIndex={0}
            selected={currentPage === pageCount - 1}
            onClick={() => {
              gotoPage(Number(pageCount - 1));
            }}
          >
            {pageCount}
          </PageButton>
        )}
      </PageGroup>
      <ArrowButton
        tabIndex={0}
        data-testid="Next_Page"
        onClick={() => nextPage()}
        disabled={!canNextPage}
      >
        <ArrowRightIcon className="pageNavigatorIcon" />
      </ArrowButton>
    </PageSelectorRoot>
  );
};

PageSelector.propTypes = {
  pageIndex: PropTypes.number,
  pageOptions: PropTypes.instanceOf(Array),
  canPreviousPage: PropTypes.bool,
  previousPage: PropTypes.func,
  canNextPage: PropTypes.bool,
  nextPage: PropTypes.func,
  gotoPage: PropTypes.func,
};

export default PageSelector;

import * as React from "react";
const SvgCircleAddIcon = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 14 14"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M6.344 9.406v-1.75h-1.75A.632.632 0 0 1 3.937 7c0-.355.274-.656.657-.656h1.75v-1.75c0-.356.273-.657.656-.657.355 0 .656.301.656.657v1.75h1.75c.356 0 .656.3.656.656 0 .383-.3.656-.656.656h-1.75v1.75c0 .383-.3.656-.656.656a.632.632 0 0 1-.656-.656ZM14 7c0 3.883-3.145 7-7 7-3.883 0-7-3.117-7-7 0-3.855 3.117-7 7-7 3.855 0 7 3.145 7 7ZM7 1.312c-3.145 0-5.688 2.57-5.688 5.688A5.683 5.683 0 0 0 7 12.688c3.117 0 5.688-2.543 5.688-5.688 0-3.117-2.57-5.688-5.688-5.688Z"
      fill="inherit"
    />
  </svg>
);
export default SvgCircleAddIcon;

import * as React from "react";
const SvgEditIcon = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 16 16"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="m1.812 10.125 9.5-9.5a2.018 2.018 0 0 1 2.844 0l1.218 1.219c.094.093.188.218.25.312a1.994 1.994 0 0 1-.25 2.531l-9.5 9.5c-.03.032-.093.063-.124.126a3.32 3.32 0 0 1-1.032.562L.937 15.969a.638.638 0 0 1-.719-.188.708.708 0 0 1-.218-.719l1.125-3.78c.125-.438.375-.845.687-1.157Zm.75 1.594-.719 2.437 2.438-.719c.187-.062.375-.156.531-.312l7.156-7.156L10 4.03l-7.125 7.157c-.032 0-.032.03-.063.062-.125.125-.187.281-.25.469Z"
      fill="inherit"
    />
  </svg>
);
export default SvgEditIcon;

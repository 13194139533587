import * as React from "react";
const SvgCalendarIcon = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 14 14"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M2.747 14a1.88 1.88 0 0 1-1.872-1.878V3.585a1.88 1.88 0 0 1 1.872-1.878h.68V.597c0-.332.264-.597.596-.597.331 0 .595.265.595.598v1.11h4.764V.597c0-.333.264-.598.595-.598.332 0 .596.265.596.598v1.11h.68a1.88 1.88 0 0 1 1.872 1.877v8.537A1.88 1.88 0 0 1 11.254 14H2.747Zm-.681-1.878c0 .376.306.683.68.683h8.508c.374 0 .68-.307.68-.683V5.463H2.066v6.659Zm9.868-7.854v-.683a.684.684 0 0 0-.68-.683H2.747a.684.684 0 0 0-.681.683v.683h9.868Z"
      fill="inherit"
    />
  </svg>
);
export default SvgCalendarIcon;

import { keyframes } from "@stitches/react";
// import variables from "../../tokens/json/variables.json";

// animations
const spin = keyframes({
  "0%": { transform: "rotate(0deg)" },
  "100%": { transform: "rotate(360deg)" },
});

export const LoaderStyles = {
  animationName: spin,
  animationDuration: "1.5s",
  animationTimingFunction: "linear",
  animationIterationCount: "infinite",
};

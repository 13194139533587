import * as React from "react";
const SvgChevronRightIcon = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 16 16"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="m8.437 3.625 3.781 4.25-3.781 4.25a.831.831 0 0 0-.188.5c0 .219.063.438.22.563a.774.774 0 0 0 1.062-.032l4.218-4.75c.25-.312.25-.718 0-1l-4.218-4.75a.747.747 0 0 0-1.063-.062c-.281.25-.312.75-.031 1.031Zm-.656 4.75c.28-.281.28-.687 0-1l-4.25-4.75c-.25-.281-.75-.312-1.032-.031a.71.71 0 0 0-.062 1.031l3.781 4.25-3.781 4.25a.831.831 0 0 0-.188.5c0 .219.063.438.22.563a.774.774 0 0 0 1.062-.032l4.25-4.78Z"
      fill="inherit"
    />
  </svg>
);
export default SvgChevronRightIcon;

import * as React from "react";
const SvgHelpIcon = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 16 16"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#HelpIcon_svg__a)">
      <path
        d="M8 0C3.562 0 0 3.594 0 8c0 4.438 3.563 8 8 8 4.406 0 8-3.563 8-8 0-4.406-3.594-8-8-8Zm0 14.5A6.495 6.495 0 0 1 1.5 8c0-3.563 2.906-6.5 6.5-6.5 3.563 0 6.5 2.938 6.5 6.5 0 3.594-2.938 6.5-6.5 6.5Zm0-4c-.563 0-1 .438-1 1 0 .563.406 1 1 1 .531 0 1-.438 1-1 0-.563-.469-1-1-1ZM9.031 4H7.437A2.174 2.174 0 0 0 5.25 6.188c0 .406.344.75.75.75a.76.76 0 0 0 .75-.75c0-.375.281-.688.656-.688H9c.406 0 .75.313.75.688a.67.67 0 0 1-.344.593L7.625 7.844a.77.77 0 0 0-.375.656V9c0 .406.344.75.75.75A.76.76 0 0 0 8.75 9v-.063l1.406-.874a2.23 2.23 0 0 0 1.063-1.876C11.25 4.97 10.28 4 9.03 4Z"
        fill="inherit"
      />
    </g>
    <defs>
      <clipPath id="HelpIcon_svg__a">
        <path fill="transparent" d="M0 0h16v16H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgHelpIcon;

import * as React from "react";
const SvgEllipsisIcon = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 16 16"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M11.77 8c0-.828.723-1.5 1.615-1.5C14.276 6.5 15 7.172 15 8s-.724 1.5-1.615 1.5c-.892 0-1.616-.672-1.616-1.5ZM6.384 8c0-.828.723-1.5 1.615-1.5s1.615.672 1.615 1.5S8.892 9.5 8 9.5 6.385 8.828 6.385 8ZM4.23 8c0 .828-.723 1.5-1.616 1.5C1.723 9.5 1 8.828 1 8s.723-1.5 1.615-1.5c.893 0 1.616.672 1.616 1.5Z"
      fill="inherit"
    />
  </svg>
);
export default SvgEllipsisIcon;

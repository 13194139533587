import variables from "../../../tokens/json/variables.json";

export const GlobalSearchRootStyles = {
  display: "flex",
  alignItems: "center",
  gap: "16px",
};

export const TableTitleStyles = {
  // typography
  fontFamily: variables.productiveHeading03FontFamily,
  fontWeight: variables.productiveHeading03FontWeight,
  lineHeight: variables.productiveHeading03LineHeight,
  fontSize: variables.productiveHeading03FontSize,
  letterSpacing: variables.productiveHeading03LetterSpacing,
  paragraphSpacing: variables.productiveHeading03ParagraphSpacing,
  textCase: variables.productiveHeading03TextCase,
  textDecoration: variables.productiveHeading03TextDecoration,
};

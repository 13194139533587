/* eslint-disable no-dupe-keys */
import { keyframes } from "@stitches/react";
import variables from "../../tokens/json/variables.json";

const slideIn = keyframes({
  from: { transform: "$$transformValue" },
  to: { transform: "translate3d(0,0,0)" },
});

const slideOut = keyframes({
  from: { transform: "translate3d(0,0,0)" },
  to: { transform: "$$transformValue" },
});

// const fadeIn = keyframes({
//   from: { opacity: "0" },
//   to: { opacity: "1" },
// });

// const fadeOut = keyframes({
//   from: { opacity: "1" },
//   to: { opacity: "0" },
// });

export const PanelStyles = {
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",

  backgroundColor: variables.layerLayer02,
  boxShadow: "-4px 0px 10px rgba(0, 0, 0, 0.1);",
  position: "fixed",
  top: 0,
  bottom: 0,
  width: "fit-content",

  // Among other things, prevents text alignment inconsistencies when dialog can't be centered in the viewport evenly.
  // Affects animated and non-animated dialogs alike.
  willChange: "transform",

  // '&:focus': {
  //   outline: 'none',
  // },

  '&[data-state="open"]': {
    animation: `${slideIn} 500ms cubic-bezier(0.22, 1, 0.36, 1)`,
  },

  '&[data-state="closed"]': {
    animation: `${slideOut} 500ms cubic-bezier(0.22, 1, 0.36, 1)`,
  },

  variants: {
    side: {
      top: {
        $$transformValue: "translate3d(0,-100%,0)",
        width: "100%",
        height: 300,
        bottom: "auto",
      },
      right: {
        $$transformValue: "translate3d(100%,0,0)",
        right: 0,
      },
      bottom: {
        $$transformValue: "translate3d(0,100%,0)",
        width: "100%",
        height: 300,
        bottom: 0,
        top: "auto",
      },
      left: {
        $$transformValue: "translate3d(-100%,0,0)",
        left: 0,
      },
    },
    type: {
      white: {
        backgroundColor: variables.layerLayer02,
      },
      gray: {
        backgroundColor: variables.layerLayer01,
      },
    },
  },

  defaultVariants: {
    side: "right",
  },
};

export const CloseButtonStyles = {
  position: "absolute",
  top: variables.sizing6,
  right: variables.sizing6,
};

export const IconButtonStyles = {
  padding: 0,
  width: variables.sizing6,
  height: variables.sizing6,
  color: variables.iconIconPrimary,
};

export const HeaderStyles = {
  padding: `${variables.spacing6} ${variables.spacing6} 0 ${variables.spacing6}`,
  display: "flex",
  gap: variables.spacing3,
};

export const HeaderContentStyles = {
  display: "grid",
  gap: variables.spacing3,
};

export const TitleStyles = {
  all: "unset",
  color: variables.textTextPrimary,

  fontFamily: variables.semiBoldBodyShort02FontFamily,
  fontWeight: variables.semiBoldBodyShort02FontWeight,
  lineHeight: variables.semiBoldBodyShort02LineHeight,
  fontSize: variables.semiBoldBodyShort02FontSize,
  letterSpacing: variables.semiBoldBodyShort02LetterSpacing,
  paragraphSpacing: variables.semiBoldBodyShort02ParagraphSpacing,
  textCase: variables.semiBoldBodyShort02TextCase,
  textDecoration: variables.semiBoldBodyShort02TextDecoration,
};

export const DescriptionStyles = {
  all: "unset",
  color: variables.textTextPrimary,

  fontFamily: variables.bodyLong01FontFamily,
  fontWeight: variables.bodyLong01FontWeight,
  lineHeight: variables.bodyLong01LineHeight,
  fontSize: variables.bodyLong01FontSize,
  letterSpacing: variables.bodyLong01LetterSpacing,
  paragraphSpacing: variables.bodyLong01ParagraphSpacing,
  textCase: variables.bodyLong01TextCase,
  textDecoration: variables.bodyLong01TextDecoration,
};

export const BodyStyles = {
  padding: variables.spacing6,
  flex: 1,
  // height: '600px',
  overflow: "auto",
};

export const FooterStyles = {
  display: "flex",
  flexDirection: "row",
  justifyContent: "flex-end",
  alignItems: "center",
  padding: variables.spacing6,
  width: "-moz-available",
  width: "-webkit-fill-available",
  variants: {
    secondary: {
      true: {
        justifyContent: "space-between",
      },
    },
    enabledShadow: {
      true: {
        boxShadow:
          "0px -2px 8px rgba(0, 0, 0, 0.05), 0px -1px 4px rgba(0, 0, 0, 0.05), 0px -1px 2px rgba(0, 0, 0, 0.05)",
      },
    },
  },
};

export const PrimaryActionContainerStyles = {
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  gap: variables.spacing4,
};

export const OverlayStyles = {
  position: "fixed",
  top: 0,
  right: 0,
  bottom: 0,
  left: 0,

  backgroundColor: variables.backgroundBackgroundInverse,
  opacity: 0.3,

  // '&[data-state="open"]': {
  //   animation: `${fadeIn} 150ms cubic-bezier(0.22, 1, 0.36, 1)`,
  // },

  // '&[data-state="closed"]': {
  //   animation: `${fadeOut} 150ms cubic-bezier(0.22, 1, 0.36, 1)`,
  // },
};

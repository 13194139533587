import React, { useEffect, useState } from "react";
import { styled } from "@stitches/react";
import { useAsyncDebounce } from "react-table";
import PropTypes from "prop-types";
import Search from "../../Search/Search";
import {
  GlobalSearchRootStyles,
  TableTitleStyles,
} from "./GlobalSearch.styles";

const GlobalSearchRoot = styled("div", GlobalSearchRootStyles);
const TableTitle = styled("div", TableTitleStyles);

const GlobalSearch = ({
  label,
  tableTotal,
  globalFilter,
  setGlobalFilter,
  disableSearch,
  onGlobalFilterChange,
}) => {
  const [value, setValue] = useState(globalFilter);

  useEffect(() => {
    setValue(globalFilter);
  }, [globalFilter]);

  const onChange = useAsyncDebounce((debouncedValue) => {
    if (typeof onGlobalFilterChange === "function")
      onGlobalFilterChange(debouncedValue);
    setGlobalFilter(debouncedValue || undefined);
  }, 300);

  return (
    <GlobalSearchRoot>
      {label ? (
        <TableTitle data-testid="Table_Title">{`${
          tableTotal || 0
        } ${label}`}</TableTitle>
      ) : null}
      {!disableSearch && (
        <div>
          <Search
            data-testid="Table_Search"
            value={value || ""}
            onChange={(e) => {
              setValue(e?.target?.value);
              onChange(e?.target?.value);
            }}
            placeholder="Search"
          />
        </div>
      )}
    </GlobalSearchRoot>
  );
};

GlobalSearch.propTypes = {
  label: PropTypes.string,
  tableTotal: PropTypes.number,
  globalFilter: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.bool,
  ]),
  setGlobalFilter: PropTypes.func,
  disableSearch: PropTypes.bool,
  onGlobalFilterChange: PropTypes.func,
};

export default GlobalSearch;

import React, { useEffect, useState } from "react";
import { styled } from "@stitches/react";
import PropTypes from "prop-types";
import {
  SearchContainerStyles,
  SearchIconContainerStyles,
  SearchIconStyles,
  SearchFieldStyles,
  ClearIconStyles,
  ClearButtonStyles,
} from "./Search.styles";
import SearchIcon from "../../icons/SearchIcon";
import ClearIcon from "../../icons/CloseIcon";
import Button from "../Button/Button";

const TextContainer = styled("div", SearchContainerStyles);
const SearchIconContainer = styled("div", SearchIconContainerStyles);
const SearchSVG = styled(SearchIcon, SearchIconStyles);
const ClearSVG = styled(ClearIcon, ClearIconStyles);
const TextField = styled("input", SearchFieldStyles);
const StyledButton = styled(Button, ClearButtonStyles);

const Search = React.forwardRef(
  (
    {
      value,
      onChange,
      disabled,
      placeholder,
      onKeyDown,
      showSearchIcon,
      ...props
    },
    forwardedRef
  ) => {
    const [searchText, setSearchText] = useState(value || "");

    useEffect(() => {
      setSearchText(value);
    }, [value]);

    const handleClear = (e) => {
      e.stopPropagation();
      setSearchText("");
      if (onChange) {
        onChange({ ...e, target: { ...e?.target, value: "" } });
      }
    };

    const handleChange = (e) => {
      e.stopPropagation();
      setSearchText(e?.target?.value);
      if (onChange) {
        onChange(e);
      }
    };

    const handleKeyDown = (e) => {
      e.stopPropagation();
      if (onKeyDown) {
        onKeyDown(e);
      }
    };

    return (
      <TextContainer ref={forwardedRef} disabled={disabled}>
        {showSearchIcon && (
          <SearchIconContainer disabled={disabled}>
            <SearchSVG />
          </SearchIconContainer>
        )}
        <TextField
          data-showicon={showSearchIcon}
          tabIndex={-1}
          placeholder={placeholder || "Search"}
          value={searchText}
          onChange={handleChange}
          onKeyDown={(e) => {
            handleKeyDown(e);
          }}
          disabled={disabled}
          {...props}
        />
        <StyledButton
          size="medium"
          color="ghost"
          data-testid="searchClear"
          onClick={handleClear}
          disabled={disabled}
          className={searchText && "visibleStyles"}
        >
          <ClearSVG />
        </StyledButton>
      </TextContainer>
    );
  }
);

Search.defaultProps = {
  showSearchIcon: true,
};

Search.propTypes = {
  /** The current value of the search input. */
  value: PropTypes.string,
  /** Callback function invoked when the value of the search input changes. */
  onChange: PropTypes.func,
  /** Disables the search input when true. */
  disabled: PropTypes.bool,
  /** Callback function invoked when a key is pressed. */
  onKeyDown: PropTypes.func,
  /** The placeholder text for the search input. */
  placeholder: PropTypes.string,
  /** Controls the visibility of the search icon. */
  showSearchIcon: PropTypes.bool,
};

export default Search;

import React from "react";
import PropTypes from "prop-types";
import { styled } from "@stitches/react";
import * as ReactSlider from "@radix-ui/react-slider";
import {
  LabelStyles,
  RangeStyles,
  RootStyles,
  ThumbStyles,
  TrackStyles,
  ValueStyles,
  HelperTextStyles,
  ThumbContainerStyles,
  SliderContainerStyles,
  LabelContainerStyles,
  InfoIconStyles,
} from "./Slider.styles";
import { InfoIcon } from "../../icons";
import Tooltip from "../Tooltip/Tooltip";

const Container = styled("div", {
  display: "flex",
  flexDirection: "column",
});
const Label = styled("div", LabelStyles);
const Value = styled("div", ValueStyles);
const SliderRoot = styled(ReactSlider.Root, RootStyles);
const SliderTrack = styled(ReactSlider.Track, TrackStyles);
const SliderRange = styled(ReactSlider.Range, RangeStyles);
const HelperText = styled("div", HelperTextStyles);
const SliderThumbRoot = ReactSlider.Thumb;
const SliderThumb = styled("div", ThumbStyles);
const ThumbContainer = styled("div", ThumbContainerStyles);
const StyledInfoIcon = styled(InfoIcon, InfoIconStyles);
const LabelContainer = styled("div", LabelContainerStyles);
const SliderContainer = styled("div", SliderContainerStyles);

const Slider = ({
  helperText,
  readOnly,
  onChange,
  required,
  error,
  label,
  value,
  ...props
}) => {
  const sliderValue = value || props?.defaultValue;
  const double = sliderValue.length > 1;

  return (
    <Container>
      <LabelContainer data-testid="slider-label-container">
        <Label required={Boolean(label) && required} disabled={props?.disabled}>
          {label}
        </Label>
        {props.info && (
          <Tooltip
            data-testid="tooltip"
            type="inline"
            content={props.info}
            align="center"
            side="right"
            style={{ display: "flex" }}
          >
            <StyledInfoIcon />
          </Tooltip>
        )}
      </LabelContainer>
      <SliderContainer>
        <Value disabled={props?.disabled}>{props.min}</Value>
        <SliderRoot
          onValueChange={readOnly ? null : onChange}
          value={sliderValue}
          disabled={props?.disabled || readOnly}
          data-testid="slider-root"
          {...props}
        >
          <SliderTrack disabled={props?.disabled}>
            <SliderRange />
          </SliderTrack>
          <SliderThumbRoot asChild readOnly={readOnly} double={double}>
            <ThumbContainer>
              <SliderThumb data-value={sliderValue[0]} />
            </ThumbContainer>
          </SliderThumbRoot>
          {double && (
            <SliderThumbRoot asChild readOnly={readOnly} double={double}>
              <ThumbContainer>
                <SliderThumb data-value={sliderValue[1]} />
              </ThumbContainer>
            </SliderThumbRoot>
          )}
        </SliderRoot>
        <Value disabled={props?.disabled}>{props.max}</Value>
      </SliderContainer>
      {helperText && (
        <HelperText
          data-testid="helperText"
          error={error}
          disabled={props?.disabled}
        >
          {helperText}
        </HelperText>
      )}
    </Container>
  );
};

Slider.propTypes = {
  min: PropTypes.number,
  max: PropTypes.number,
  step: PropTypes.number,
  label: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  info: PropTypes.string,
  error: PropTypes.bool,
  value: PropTypes.arrayOf(PropTypes.number),
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  readOnly: PropTypes.bool,
  helperText: PropTypes.string,
  defaultValue: PropTypes.arrayOf(PropTypes.number),
  minStepsBetweenThumbs: PropTypes.number,
};

Slider.defaultProps = {
  minStepsBetweenThumbs: 1,
  defaultValue: [],
};

export default Slider;

import React, { useState } from "react";
import { styled } from "@stitches/react";
import PropTypes from "prop-types";
import SvgDealIcon from "../../icons/DealIcon";
import SvgChevronLeftIcon from "../../icons/ChevronLeftIcon";
import SvgChevronRightIcon from "../../icons/ChevronRightIcon";
import Item from "./Item"; // Menu Item in figma
import Section from "./Section/Section"; // Group, under group we have "Menu Item" and "Sub Menu"
import Footer from "./Footer";
import tokens from "../../tokens/json/variables.json";
import {
  MenuStyles,
  ListStyles,
  CollapseItemStyles,
  CollapseIconContainerStyles,
  CollapseIconStyles,
} from "./SideMenu.styles";

const SECTION_HEADER = "section_header";

const Menu = styled("div", MenuStyles);

const List = styled("div", ListStyles);

// style for collapse Container and Icon
const CollapseItem = styled("div", CollapseItemStyles);

const CollapseIconContainer = styled("button", CollapseIconContainerStyles);

const CollapseIcon = styled("span", CollapseIconStyles);

const SideMenu = (props) => {
  const { selectedId, menuData, onSelectionChange, onCollapseChange, ...rest } =
    props;
  const [collapse, setCollapse] = useState(false);
  const [selected, setSelected] = useState(selectedId || "home");
  const handleCollapse = (event) => {
    onCollapseChange(event, !collapse);
    if (collapse) {
      setCollapse(false);
    } else {
      setCollapse(true);
    }
  };
  const handleSelect = (event, id) => {
    event.preventDefault();
    setCollapse(false);
    setSelected(id);
    onSelectionChange(event, id);
  };
  const footerItems = menuData?.filter((item) => item?.type === "footer");
  const menuItems = menuData?.filter((item) => item?.type !== "footer");
  const Content = menuItems?.map((item, index) => {
    if (
      Object.prototype.hasOwnProperty.call(item, "type") &&
      item.type === SECTION_HEADER &&
      Object.prototype.hasOwnProperty.call(item, "items") &&
      Array.isArray(item.items) &&
      item.items.length
    ) {
      // make a section container
      return (
        <Section
          data-testid={`section_${index}`}
          sectionItems={item.items}
          id={item.id}
          key={item?.id}
          isCollapse={collapse}
          handleSelect={handleSelect}
          selected={selected}
          setCollapse={setCollapse}
        >
          {item.label}
        </Section>
      );
    }
    return (
      <Item
        key={`Item_${item?.id}}`}
        id={item.id}
        label={item.label}
        Icon={item?.icon || <SvgDealIcon />}
        isCollapse={collapse}
        tabIndex={index}
        handleSelect={handleSelect}
        selected={selected}
        disabled={item?.disabled || false}
      />
    );
  });
  return (
    <Menu data-iscollapsed={collapse} data-testid="sideMenu" {...rest}>
      {/* move menu to Menu folder */}
      <CollapseItem>
        <CollapseIconContainer
          onClick={handleCollapse}
          data-testid="sideMenuCollapse"
          collapse={collapse.toString()}
        >
          <CollapseIcon>
            {collapse ? (
              <SvgChevronRightIcon
                height={tokens.sizing5}
                width={tokens.sizing5}
              />
            ) : (
              <SvgChevronLeftIcon
                height={tokens.sizing5}
                width={tokens.sizing5}
              />
            )}
          </CollapseIcon>
        </CollapseIconContainer>
      </CollapseItem>

      <List isCollapsed={collapse} tabIndex={-1}>
        {Content}
      </List>
      <Footer
        items={footerItems}
        handleSelect={handleSelect}
        selected={selected}
        isCollapse={collapse}
      />
    </Menu>
  );
};
const DATA_FORMAT = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  icon: PropTypes.node,
  disabled: PropTypes.bool,
  type: PropTypes.oneOf(["section_header", "sub_menu", "footer"]),
};
SideMenu.propTypes = {
  menuData: PropTypes.arrayOf(
    PropTypes.shape({
      ...DATA_FORMAT,
      items: PropTypes.arrayOf(PropTypes.shape({ ...DATA_FORMAT })),
    })
  ),
  selectedId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  onSelectionChange: PropTypes.func,
  onCollapseChange: PropTypes.func,
};
export default SideMenu;

import variables from "../../../tokens/json/variables.json";

export const CheckBoxStyles = {
  all: "unset",
  display: "flex",
  alignItems: "center",

  '&[data-state="checked"]': {
    color: variables.iconIconSecondary,
    fill: variables.iconIconSecondary,
  },

  '&[data-state="indeterminate"]': {
    color: variables.iconIconSecondary,
    fill: variables.iconIconSecondary,
  },

  "&[data-disabled]": {
    fill: variables.iconIconOnColorDisabled,
    color: variables.iconIconOnColorDisabled,
  },

  variants: {
    headerComponent: {
      true: {
        '&[data-state="unchecked"]': {
          color: variables.iconIconPrimary,
          fill: variables.iconIconPrimary,
        },
      },
      false: {
        '&[data-state="unchecked"]': {
          color: variables.iconIconSecondary,
          fill: variables.iconIconSecondary,
        },
      },
    },
  },
};

import tokens from "../../tokens/json/variables.json";

export const CardContainerStyles = {
  boxSizing: "border-box",
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  padding: "16px",
  gap: "16px",
  borderRadius: tokens.borderRadius1,
  minWidth: "384px",
  "&:hover": {
    background: tokens.layerLayerHover01,
  },
  "&:focus": {
    border: `${tokens.borderWidth1} solid  ${tokens.focusFocus}`,
  },
  border: `${tokens.borderWidth1} solid ${tokens.borderBorderSubtle00}`,
  background: tokens.layerLayer02,
  overFlow: "hidden",
  variants: {
    type: {
      default: {
        "&:hover": {
          background: tokens.layerLayer02,
        },
      },
      clickable: {},
      expandable: {
        transition: "height 200ms ease",
        expanded: {
          false: {
            display: "none",
            height: "84px",
          },
          true: {
            display: "flex",
          },
        },
      },
      singleSelect: {},
      multiSelect: {},
    },
    selected: {
      true: {
        border: `${tokens.borderWidth1} solid ${tokens.borderBorderStrong02}`,
        "&:focus": {
          border: `${tokens.borderWidth1} solid ${tokens.borderBorderStrong02}`,
        },
      },
    },
    expanded: {},
    title: {
      false: {
        gap: "0px",
      },
    },
  },
  defaultVariants: {
    type: "default",
  },
};
export const CardHeaderContainerStyles = {
  boxSizing: "border-box",
  gap: tokens.spacing3,
  display: "flex",
  flexDirection: "row",
  padding: "0px",
  flex: "none",
  order: "0",
  flexGrow: "0",
  width: "100%",
  justifyContent: "space-between",
  variants: {
    type: {
      expandable: {
        justifyContent: "flex-start",
      },
    },
  },
};

export const IconContainerStyles = {
  boxSizing: "border-box",
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "center",
  width: tokens.sizing5,
  height: tokens.sizing5,
  fill: tokens.iconIconSecondary,
  color: tokens.iconIconSecondary,
};

export const CardContentContainerStyles = {
  boxSizing: "border-box",
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  padding: "0px",
  gap: tokens.spacing3,
  flex: "none",
  order: "1",
  flexGrow: "0",
};

export const CardTitleStyles = {
  boxSizing: "border-box",
  color: tokens.textTextPrimary,
  fontFamily: tokens.semiBoldBodyShort02FontFamily,
  fontWeight: tokens.semiBoldBodyShort02FontWeight,
  fontSize: tokens.semiBoldBodyShort02FontSize,
  lineHeight: tokens.semiBoldBodyShort02LineHeight,
  letterSpacing: tokens.semiBoldBodyShort02LetterSpacing,
  textDecoration: tokens.semiBoldBodyShort02TextDecoration,
};

export const CardDescriptionStyles = {
  boxSizing: "border-box",
  color: tokens.textTextPrimary,
  fontFamily: tokens.bodyLong01FontFamily,
  fontSize: tokens.bodyLong01FontSize,
  fontWeight: tokens.bodyLong01FontWeight,
  letterSpacing: tokens.bodyLong01LetterSpacing,
  lineHeight: tokens.bodyLong01LineHeight,
  textDecoration: tokens.bodyLong01TextDecoration,
};

export const CardBodyContainerStyles = {
  boxSizing: "border-box",
  flexDirection: "column",
  width: "100%",
  height: 0,
  variants: {
    type: {
      expandable: {
        height: "fit-content",
        paddingLeft: tokens.spacing8,
      },
    },
    expanded: {
      true: {
        height: "fit-content",
      },
      false: {
        display: "none",
      },
    },
  },
};

export const SelectIconContainerStyles = {
  boxSizing: "border-box",
  display: "flex",
  flexDirection: "row",
  justifyContent: "center",
  alignItems: "center",
  padding: "0px",
  height: tokens.sizing5,
  width: tokens.sizing5,
  flex: "none",
  order: "1",
  flexGrow: "0",
  variants: {
    selected: {
      true: {
        color: tokens.iconIconBrand,
        fill: tokens.iconIconBrand,
      },
      false: {
        color: tokens.iconIconSecondary,
        fill: tokens.iconIconSecondary,
      },
    },
  },
};

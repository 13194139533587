import React from "react";
import PropTypes from "prop-types";

import { styled } from "@stitches/react";
import { ProgressContainerStyles } from "./FileUploader.styles";
import ProgressBar from "../ProgressBar/ProgressBar";
import Button from "../Button/Button";
import { CloseIcon } from "../../icons";
import { LabelStyles } from "../ProgressBar/ProgressBar.styles";

const Label = styled("div", LabelStyles);
const ProgressContainer = styled("div", ProgressContainerStyles);

const FileProgress = ({ id, onRemove, ...props }) => {
  // eslint-disable-next-line no-prototype-builtins
  const hasProgress = props.hasOwnProperty("progress");

  return (
    <ProgressContainer key={id} data-testid={`file-${id}`}>
      {hasProgress ? (
        <>
          <ProgressBar width="100%" {...props} />
          <Button color="ghost" onClick={() => onRemove(id)}>
            <CloseIcon />
          </Button>
        </>
      ) : (
        <Label data-testid={`${id}_label`}>{props?.label}</Label>
      )}
    </ProgressContainer>
  );
};

FileProgress.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onRemove: PropTypes.func,
  helperText: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default FileProgress;

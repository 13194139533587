import React from "react";
import PropTypes from "prop-types";
import { styled } from "@stitches/react";
import token from "../../tokens/json/variables.json";

const TextField = styled("textarea", {
  padding: `${token.spacing3} ${token.spacing4} ${token.spacing3} ${token.spacing4}`,
  color: token.textTextPrimary,
  border: "none",
  outline: `${token.borderWidth1} solid ${token.borderBorderSubtle01}`,
  borderRadius: token.borderRadius1,

  fontFamily: token.bodyShort01FontFamily,
  fontSize: token.bodyShort01FontSize,
  fontWeight: token.bodyShort01FontWeight,
  lineHeight: token.bodyShort01LineHeight,
  letterSpacing: token.bodyShort01LetterSpacing,
  paragraphSpacing: token.bodyShort01ParagraphSpacing,
  textDecoration: token.bodyShort01TextDecoration,
  textOverflow: "ellipsis",
  "&::placeholder": {
    color: token.textTextPlaceholder,
  },
  "&:active": {
    padding: `${token.spacing3} ${token.spacing4} ${token.spacing3} ${token.spacing4}`,
    color: token.textTextPrimary,
    outline: `${token.borderWidth2} solid ${token.focusFocus}`,
  },
  "&:focus": {
    padding: `${token.spacing3} ${token.spacing4} ${token.spacing3} ${token.spacing4}`,
    color: token.textTextPrimary,
    outline: `${token.borderWidth2} solid ${token.focusFocus}`,
  },

  variants: {
    disabled: {
      true: {
        color: token.textTextDisabled,
        outline: `${token.borderWidth1} solid ${token.borderBorderDisabled}`,

        pointerEvents: "none",
        cursor: "not-allowed",
        "&::placeholder": {
          color: token.textTextDisabled,
        },
      },
    },
    error: {
      true: {
        color: token.textTextPrimary,
        outline: `${token.borderWidth1} solid ${token.supportSupportError}`,
      },
    },
    resizing: {
      false: {
        resize: "none",
      },
    },
  },

  compoundVariants: [
    {
      disabled: true,
      error: true,
      css: {
        color: token.textTextDisabled,
        outline: `${token.borderWidth1} solid ${token.borderBorderDisabled}`,
        pointerEvents: "none",
        cursor: "not-allowed",
        "&::placeholder": {
          color: token.textTextDisabled,
        },
      },
    },
  ],
});

const Label = styled("div", {
  color: token.textTextSecondary,
  fontFamily: token.labelFontFamily,
  fontSize: token.labelFontSize,
  fontWeight: token.labelFontWeight,
  lineHeight: token.labelLineHeight,
  letterSpacing: token.labelLetterSpacing,
  paragraphSpacing: token.labelParagraphSpacing,
  paddingBottom: token.sizing2,
  variants: {
    disabled: {
      true: {
        color: token.textTextDisabled,
      },
    },
  },
});

const HelperText = styled("div", {
  paddingTop: token.spacing1,
  color: token.textTextSecondary,
  fontFamily: token.labelFontFamily,
  fontSize: token.labelFontSize,
  fontWeight: token.labelFontWeight,
  lineHeight: token.labelLineHeight,
  letterSpacing: token.labelLetterSpacing,
  paragraphSpacing: token.labelParagraphSpacing,
  variants: {
    disabled: {
      true: {
        color: token.textTextDisabled,
      },
    },
    error: {
      true: {
        color: token.textTextError,
      },
    },
  },
  compoundVariants: [
    {
      disabled: true,
      error: true,
      css: {
        color: token.textTextDisabled,
      },
    },
  ],
});

const TextArea = (props) => {
  const { label, helperText, ...otherProps } = props;
  return (
    <>
      <Label {...otherProps} data-testid="textArea-label">
        {label}
      </Label>
      <TextField {...otherProps} data-testid="textArea-input" />
      <HelperText {...otherProps} data-testid="textArea-helper">
        {helperText}
      </HelperText>
    </>
  );
};

TextArea.propTypes = {
  disabled: PropTypes.bool,
  id: PropTypes.string,
  label: PropTypes.string,
  error: PropTypes.bool,
  helperText: PropTypes.string,
  resizing: PropTypes.bool,
  value: PropTypes.string,
  onChange: PropTypes.func,
};

export default TextArea;

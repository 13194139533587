import React from "react";
import PropTypes from "prop-types";
import { styled } from "@stitches/react";
import clsx from "clsx";
import {
  TagContainerStyles,
  LabelContainerStyles,
  RemovableIconContainerStyles,
  RightIconContainerStyles,
  LeftIconContainerStyles,
} from "./Tag.styles";
import CloseIcon from "../../icons/CloseIcon";

const TagContainer = styled("div", TagContainerStyles);
const LabelContainer = styled("div", LabelContainerStyles);
const RightIconContainer = styled("div", RightIconContainerStyles);
const LeftIconContainer = styled("div", LeftIconContainerStyles);

const RemovableIconContainer = styled("div", RemovableIconContainerStyles);
const Tag = (props) => {
  const {
    id,
    variant = "default",
    leftIcon,
    rightIcon,
    label,
    children,
    type = "default",
    size = "small",
    disabled = false,
    onClick,
  } = props;
  const removable = type === "removable";
  const status = type === "status";
  return (
    <TagContainer
      id={id}
      type={type}
      removable={removable}
      status={status}
      data-testid={`tag_${id}`}
      className={variant}
      disabled={disabled}
      size={size}
    >
      {leftIcon ? <LeftIconContainer>{leftIcon}</LeftIconContainer> : null}
      {label || children ? (
        <LabelContainer status={status} disabled={disabled} size={size}>
          {label || children}
        </LabelContainer>
      ) : null}
      {rightIcon ? <RightIconContainer>{rightIcon} </RightIconContainer> : null}
      {removable ? (
        <RemovableIconContainer
          data-testid={`tag_remove_${id}`}
          className={clsx(variant, "hovered", "focused")}
          disabled={disabled}
          onClick={(event) => onClick(event)}
          tabIndex={0}
          size={size}
        >
          <CloseIcon className="closeIcon" />
        </RemovableIconContainer>
      ) : null}
    </TagContainer>
  );
};

Tag.propTypes = {
  id: PropTypes.string,
  leftIcon: PropTypes.node,
  rightIcon: PropTypes.node,
  children: PropTypes.node,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onClick: PropTypes.func,
  variant: PropTypes.oneOf(["info", "error", "warning", "success"]),
  type: PropTypes.oneOf(["default", "status", "removable"]),
  size: PropTypes.oneOf(["small", "medium"]),
  disabled: PropTypes.bool,
};

export default Tag;

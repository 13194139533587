import React from "react";
import { styled } from "@stitches/react";
import PropTypes from "prop-types";
import {
  ActionBarRootStyles,
  ActionBarLabelStyles,
  ActionsContainerStyles,
  CancelButtonStyles,
} from "./ActionBar.styles";

const ActionBarRoot = styled("div", ActionBarRootStyles);
const ActionBarLabel = styled("div", ActionBarLabelStyles);
const ActionsContainer = styled("div", ActionsContainerStyles);
// To be replace with ghost dark button
const CancelButton = styled("div", CancelButtonStyles);

const ActionBar = React.forwardRef(
  (
    { selectedRowIds, CustomActions, toggleAllRowsSelected, ...props },
    forwardedRef
  ) => {
    const handleCancel = () => {
      toggleAllRowsSelected(false);
    };
    return (
      <ActionBarRoot
        visible={selectedRowIds?.length > 0}
        {...props}
        ref={forwardedRef}
        data-testid="Action_Bar"
      >
        <ActionBarLabel data-testid="Selected_Rows">
          {(selectedRowIds || []).length} items selected
        </ActionBarLabel>
        <ActionsContainer>
          {CustomActions && <CustomActions selectedRowIds={selectedRowIds} />}
          <CancelButton data-testid="clearAllRows" onClick={handleCancel}>
            Cancel
          </CancelButton>
        </ActionsContainer>
      </ActionBarRoot>
    );
  }
);

ActionBar.propTypes = {
  selectedRowIds: PropTypes.instanceOf(Array),
  CustomActions: PropTypes.node,
  toggleAllRowsSelected: PropTypes.func,
};

export default ActionBar;

import * as React from "react";
const SvgArrowLeftIcon = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 16 16"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="m9.188 13.281-4.47-4.75c-.124-.156-.218-.343-.218-.53 0-.157.094-.345.219-.5l4.468-4.75c.282-.313.75-.313 1.063-.032.313.281.313.75.031 1.062L6.25 8.031l4.031 4.22c.281.312.281.78-.031 1.062-.313.281-.781.281-1.063-.032Z"
      fill="inherit"
    />
  </svg>
);
export default SvgArrowLeftIcon;

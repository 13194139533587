import React from "react";
import { styled } from "@stitches/react";
import PropTypes from "prop-types";
import * as RadixToast from "@radix-ui/react-toast";
import {
  CircleCheckedIcon,
  CloseIcon,
  ErrorFilledIcon,
  HelpFilledIcon,
  InfoFilledIcon,
  WarningFilledIcon,
} from "../../icons";
import Button from "../Button";
import {
  ViewPortStyles,
  ToastRootStyles,
  ToastIconStyles,
  ToastContentStyles,
  ToastTitleStyles,
  ToastDescriptionStyles,
  ToastActionsContainerStyles,
  ToastActionStyles,
  ToastCloseButtonStyles,
  IconButtonStyles,
} from "./Toast.styles";

const ToastViewport = styled(RadixToast.Viewport, ViewPortStyles);
const ToastRoot = styled(RadixToast.Root, ToastRootStyles);
const ToastIcon = styled("div", ToastIconStyles);
const ToastContent = styled("div", ToastContentStyles);
const ToastTitle = styled(RadixToast.Title, ToastTitleStyles);
const ToastDescription = styled(RadixToast.Description, ToastDescriptionStyles);
const ToastActionsContainer = styled("div", ToastActionsContainerStyles);
const ToastAction = styled(RadixToast.Action, ToastActionStyles);
const ToastClose = styled(RadixToast.Close, ToastCloseButtonStyles);
const IconButton = styled(Button, IconButtonStyles);

const Toast = ({
  id,
  position,
  banner,
  open,
  setOpen,
  onClose,
  title,
  description,
  variant,
  duration,
  showActions,
  primaryActionLabel,
  onPrimaryAction,
  primaryActionProps,
  showSecondaryAction,
  secondaryActionLabel,
  onSecondaryAction,
  secondaryActionProps,
  children,
  ...props
}) => {
  const handleClose = (event) => {
    event.preventDefault();
    if (setOpen) setOpen(false);
    if (onClose) onClose(event);
  };

  const ToastIcons = {
    success: <CircleCheckedIcon height="20px" width="20px" />,
    warning: <WarningFilledIcon height="20px" width="20px" />,
    error: <ErrorFilledIcon height="20px" width="20px" />,
    info: <InfoFilledIcon height="20px" width="20px" />,
    default: <HelpFilledIcon height="20px" width="20px" />,
  };

  return (
    <RadixToast.Provider style={{ all: "unset" }} swipeDirection="right">
      <ToastRoot
        id={`Toast_${id}`}
        data-testid="Toast_Root"
        banner={banner}
        variant={variant}
        open={open}
        onOpenChange={setOpen}
        duration={duration}
        {...props}
      >
        <ToastIcon data-testid="Toast_Icon" variant={variant}>
          {ToastIcons[variant]}
        </ToastIcon>
        <ToastContent>
          <ToastTitle data-testid="Toast_Title">{title}</ToastTitle>
          <ToastDescription data-testid="Toast_Description">
            {description}
          </ToastDescription>
          {children}
          {showActions && (
            <ToastActionsContainer data-testid="Toast_Actions">
              <ToastAction
                data-testid="Toast_Primary_Action"
                altText={primaryActionLabel}
                onClick={onPrimaryAction}
                {...primaryActionProps}
              >
                {primaryActionLabel}
              </ToastAction>
              {showSecondaryAction && (
                <ToastAction
                  data-testid="Toast_Secondary_Action"
                  altText={secondaryActionLabel}
                  onClick={onSecondaryAction}
                  {...secondaryActionProps}
                >
                  {secondaryActionLabel}
                </ToastAction>
              )}
            </ToastActionsContainer>
          )}
        </ToastContent>
        {!banner && (
          <div>
            <ToastClose
              data-testid="Toast_CloseButton"
              onClick={handleClose}
              aria-label="Close"
              asChild
            >
              <IconButton color="ghost" size="small">
                <CloseIcon height="14px" width="14px" />
              </IconButton>
            </ToastClose>
          </div>
        )}
      </ToastRoot>

      <ToastViewport position={position} banner={banner} />
    </RadixToast.Provider>
  );
};

Toast.propTypes = {
  /** Unique identifier for the Toast
   */
  id: PropTypes.string,

  /** Specifies the on-screen position of the Toast.
  
  Available options:
   * `topCentre (default)` , `topRight` , `topLeft`, `center` ,`bottomCentre` ,`bottomRight`, `bottomLeft`
   */
  position: PropTypes.string,

  /** Controls whether the Toast should be displayed as a banner occupying the full width of the screen. If false, it will take up a smaller portion of the screen. */
  banner: PropTypes.bool,

  /** Defines the visual style of the Toast.
   * Available options include:
   */
  variant: PropTypes.oneOf(["default", "success", "info", "warning", "error"]),

  /** Controls the visibility of the Toast. If true, the Toast is displayed. If false, it's hidden. */
  open: PropTypes.bool,

  /**  A function that allows you to programmatically set the open state of the Toast to true or false. */
  setOpen: PropTypes.func,
  /** A callback function that is triggered when the Toast is closed  either manually or automatically due to duration expiration. */
  onClose: PropTypes.func,
  /** The title of the toast. It can be either a string or a React node. */
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  /** The description of the toast. It can be either a string or a React node. It can also extend or wrap to two lines." */
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  /** The time in milliseconds (ms) that the Toast should be displayed automatically if not closed manually. If not provided, Toast will be visible for 5secs */
  duration: PropTypes.number,
  /** Controls whether to display action buttons below the Toast content. */
  showActions: PropTypes.bool,
  /** The label for the primary action button. It should be a React node. */
  primaryActionLabel: PropTypes.node,
  /** The function to be called when the primary action button is clicked. */
  onPrimaryAction: PropTypes.func,
  /** An object of props to be passed to the primary action button.
   */
  primaryActionProps: PropTypes.shape({
    size: PropTypes.string,
    href: PropTypes.string,
    disabled: PropTypes.bool,
    hidden: PropTypes.bool,
    id: PropTypes.string,
  }),
  /** Whether to show the secondary action button. */
  showSecondaryAction: PropTypes.bool,
  /** The label for the secondary action button. It should be a React node */
  secondaryActionLabel: PropTypes.node,
  /** The function to be called when the secondary action button is clicked */
  onSecondaryAction: PropTypes.func,
  /** An object of props to be passed to the secondary action button. */
  secondaryActionProps: PropTypes.shape({
    size: PropTypes.string,
    href: PropTypes.string,
    disabled: PropTypes.bool,
    hidden: PropTypes.bool,
    id: PropTypes.string,
  }),
  /** The content of the toast. It can be any React node. */
  children: PropTypes.node,
};

Toast.defaultProps = {
  variant: "default",
  position: "topCenter",
  banner: false,
  duration: 5000,
};

export default Toast;

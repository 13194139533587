import * as React from "react";
const SvgCopyIcon = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 14 14"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M13.727 1.941 12.059.273a.89.89 0 0 0-.63-.273H6.974c-.985 0-1.75.793-1.75 1.75v7c.027.984.793 1.75 1.777 1.75h5.25c.957 0 1.75-.766 1.75-1.75V2.57a.89.89 0 0 0-.273-.629Zm-1.04 6.809a.45.45 0 0 1-.437.438H6.973a.432.432 0 0 1-.438-.438V1.777c0-.246.192-.437.438-.437h3.5l.027 1.285c0 .492.383.875.875.875h1.285v5.25h.027Zm-5.25 3.5a.45.45 0 0 1-.437.438H1.723a.432.432 0 0 1-.438-.438V5.277a.45.45 0 0 1 .438-.437h2.652V3.5H1.723c-.957 0-1.75.793-1.75 1.75l.027 7C0 13.234.766 14 1.75 14H7c.957 0 1.75-.766 1.75-1.75v-.875H7.437v.875Z"
      fill="#11161D"
    />
  </svg>
);
export default SvgCopyIcon;

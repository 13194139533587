import tokens from "../../tokens/json/variables.json";

export const TagContainerStyles = {
  boxSizing: "border-box",
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  gap: "6px",
  padding: `0px ${tokens.spacing2}`,
  backgroundColor: tokens.tagGrayTagBackground,
  borderRadius: tokens.borderRadius1,
  color: tokens.tagGrayTagText,
  userSelect: "none",
  "&.error": {
    backgroundColor: tokens.tagRedTagBackground,
    color: tokens.tagRedTagText,
  },
  "&.success": {
    backgroundColor: tokens.tagGreenTagBackground,
    color: tokens.tagGreenTagText,
  },
  "&.info": {
    backgroundColor: tokens.tagBlueTagBackground,
    color: tokens.tagBlueTagText,
  },
  "&.warning": {
    backgroundColor: tokens.tagYellowTagBackground,
    color: tokens.tagYellowTagText,
  },
  // parent hover passed  to child elements
  "&.default:hover .hovered": {
    backgroundColor: tokens.tagGrayTagHover,
    borderRadius: tokens.borderRadius1,
  },
  "&.info:hover .hovered": {
    backgroundColor: tokens.tagBlueTagHover,
    borderRadius: tokens.borderRadius1,
  },
  "&.error:hover .hovered": {
    backgroundColor: tokens.tagRedTagHover,
    borderRadius: tokens.borderRadius1,
  },
  "&.warning:hover .hovered": {
    backgroundColor: tokens.tagYellowTagHover,
    borderRadius: tokens.borderRadius1,
  },
  "&.success:hover .hovered": {
    backgroundColor: tokens.tagGreenTagHover,
    borderRadius: tokens.borderRadius1,
  },
  // Parent focus passed to  child
  "&.default:focus .focused": {
    orderRadius: tokens.borderRadius1,
    border: `${tokens.borderWidth1} solid ${tokens.focusFocus}`,
  },
  "&.info:focus .focused": {
    orderRadius: tokens.borderRadius1,
    border: `${tokens.borderWidth1} solid ${tokens.focusFocus}`,
  },
  "&.error:focus .focused": {
    orderRadius: tokens.borderRadius1,
    border: `${tokens.borderWidth1} solid ${tokens.focusFocus}`,
  },
  "&.warning:focus .focused": {
    orderRadius: tokens.borderRadius1,
    border: `${tokens.borderWidth1} solid ${tokens.focusFocus}`,
  },
  "&.success:focus .focused": {
    orderRadius: tokens.borderRadius1,
    border: `${tokens.borderWidth1} solid ${tokens.focusFocus}`,
  },
  variants: {
    status: {
      true: {
        border: `${tokens.borderWidth1} solid ${tokens.borderBorderSubtleSelected01}`,

        "&.error": {
          backgroundColor: tokens.tagRedTagBackground,
          color: tokens.tagRedTagText,
          border: `${tokens.borderWidth1} solid ${tokens.tagRedTagText}`,
        },
        "&.success": {
          backgroundColor: tokens.tagGreenTagBackground,
          border: `${tokens.borderWidth1} solid ${tokens.tagGreenTagText}`,
        },
        "&.info": {
          backgroundColor: tokens.tagBlueTagBackground,
          border: `${tokens.borderWidth1} solid ${tokens.tagBlueTagText}`,
        },
        "&.warning": {
          backgroundColor: tokens.tagYellowTagBackground,
          border: `${tokens.borderWidth1} solid ${tokens.tagYellowTagText}`,
        },
      },
    },
    removable: {
      true: {
        "&.error": { backgroundColor: tokens.tagRedTagBackground },
        "&.success": { backgroundColor: tokens.tagGreenTagBackground },
        "&.info": { backgroundColor: tokens.tagBlueTagBackground },
        "&.warning": { backgroundColor: tokens.tagYellowTagBackground },
        paddingRight: "0px",
      },
    },
    disabled: {
      true: {
        fill: `${tokens.textTextOnColorDisabled} !important`,
        color: `${tokens.textTextOnColorDisabled} !important`,
        border: `${tokens.borderWidth1} solid ${tokens.textTextOnColorDisabled} !important`,
        backgroundColor: "#E0E0E0 !important",
        pointerEvents: "none",
      },
    },
    size: {
      small: {
        height: "18px",
        "& .closeIcon": {
          height: "10px",
          width: "10px",
        },
      },
      medium: {
        height: "24px",
        "& .closeIcon": {
          height: "12px",
          width: "12px",
        },
      },
    },
  },
};

export const LabelContainerStyles = {
  boxSizing: "border-box",
  fill: "inherit",
  textTransform: "none",
  variants: {
    disabled: {
      true: {
        color: "inherit",
      },
    },
    status: {
      true: {
        fontWeight: tokens.fontWeightsSourceSansPro1,
        fontSize: tokens.fontSize0,
        // lineHeight: tokens.lineHeights3,
        letterSpacing: tokens.letterSpacing1,
        textTransform: "uppercase",
      },
    },
    size: {
      small: {
        fontFamily: tokens.labelFontFamily,
        fontSize: tokens.labelFontSize,
        fontWeight: tokens.labelFontWeight,
        letterSpacing: tokens.labelLetterSpacing,
        lineHeight: tokens.labelLineHeight,
        textDecoration: tokens.labelTextDecoration,
        paragraphSpacing: tokens.labelParagraphSpacing,
      },
      medium: {
        fontFamily: tokens.bodyShort01FontFamily,
        fontSize: tokens.bodyShort01FontSize,
        fontWeight: tokens.bodyShort01FontWeight,
        letterSpacing: tokens.bodyShort01LetterSpacing,
        lineHeight: tokens.bodyShort01LineHeight,
        textDecoration: tokens.labelTextDecoration,
        paragraphSpacing: tokens.bodyShort01ParagraphSpacing,
      },
    },
  },
};

export const RemovableIconContainerStyles = {
  boxSizing: "border-box",
  fill: "inherit",
  display: "flex",
  flexDirection: "row",
  justifyContent: "center",
  alignItems: "center",
  padding: tokens.spacing1,
  variants: {
    disabled: {
      true: {
        "&:focus": {
          border: "transparent",
        },
      },
    },
    size: {
      small: {
        height: "18px",
        width: "18px",
      },
      medium: {
        height: "24px",
        width: "24px",
      },
    },
  },
  "&:hover": {
    backgroundColor: tokens.tagGrayTagHover,
    borderRadius: tokens.borderRadius1,
  },
  "&:focus": {
    borderRadius: tokens.borderRadius1,
    border: `${tokens.borderWidth1} solid ${tokens.focusFocus}`,
  },
  "&:focus-visible": {
    outline: "none",
  },
  "&.error": {
    "&:hover": {
      backgroundColor: tokens.tagRedTagHover,
      borderRadius: tokens.borderRadius1,
    },
  },
  "&.success": {
    "&:hover": {
      backgroundColor: tokens.tagGreenTagHover,
      borderRadius: tokens.borderRadius1,
    },
  },
  "&.info": {
    "&:hover": {
      backgroundColor: tokens.tagBlueTagHover,
      borderRadius: tokens.borderRadius1,
    },
  },
  "&.warning": {
    "&:hover": {
      backgroundColor: tokens.tagYellowTagHover,
      borderRadius: tokens.borderRadius1,
    },
  },
};

export const RightIconContainerStyles = {
  minWidth: tokens.sizing25,
  fill: "inherit",
};
export const LeftIconContainerStyles = {
  minWidth: tokens.sizing25,
  fill: "inherit",
};

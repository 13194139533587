/* eslint-disable no-dupe-keys */
import tokens from "../../tokens/json/variables.json";

export const PortalRootStyles = {
  boxSizing: "border-box",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  position: "fixed",
  top: 0,
  right: 0,
  left: 0,
  bottom: 0,
  opacity: 0,
  zIndex: 2,
  pointerEvents: "none",
  variants: {
    visible: {
      true: {
        opacity: 1,
        pointerEvents: "all",
      },
    },
    dimmer: {
      true: {
        backgroundColor: tokens.miscellaneousOverlay,
      },
    },
  },
};

export const PopUpContainerStyles = {
  boxSizing: "border-box",
  position: "relative",
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  padding: 0,
  background: tokens.layerLayer01,
  boxShadow: "0px 0px 2px rgba(0, 0, 0, 0.3), 0px 0px 10px rgba(0, 0, 0, 0.2)", // yet to  be tokenized
  borderRadius: "8px",
  zIndex: 2,
  variants: {
    size: {
      extraSmall: {
        height: "auto",
        width: "28.75rem",
        maxHeight: "48%",
      },
      small: {
        width: "43.25rem",
        height: "auto",
        maxHeight: "72%",
      },
      medium: {
        width: "57.563rem",
        height: "auto",
        maxHeight: "84%",
      },
      large: {
        width: "86.375rem",
        height: "auto",
        maxHeight: "96%",
      },
    },
  },
};
export const PopUpHeaderStyles = {
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  padding: tokens.spacing6,
  width: "-moz-available",
  width: "-webkit-fill-available",
  gap: tokens.spacing2,
};

export const PopUpTextContainerStyles = {
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "flex-start",
  gap: tokens.spacing2,
  width: "inherit",
  variants: {
    size: {
      extraSmall: {
        height: "auto",
        width: "28.75rem",
        maxHeight: "48%",
      },
      small: {
        width: "43.188rem",
        height: "auto",
        maxHeight: "72%",
      },
      medium: {
        width: "57.563rem",
        height: "auto",
        maxHeight: "84%",
      },
      large: {
        width: "86.375rem",
        height: "auto",
        maxHeight: "96%",
      },
    },
  },
};

export const CloseIconStyles = {
  fill: tokens.iconIconPrimary,
  display: "inline-flex",
};

export const PopUpDescriptionStyles = {
  fontSize: tokens.bodyLong01FontSize,
  fontFamily: tokens.bodyLong01FontFamily,
  fontWeight: tokens.bodyLong01FontWeight,
  lineHeight: tokens.bodyLong01LineHeight,
  letterSpacing: tokens.bodyLong01LetterSpacing,
  textTransform: tokens.bodyLong01TextCase,
  testDecoration: tokens.bodyLong01TextDecoration,
};
export const PopUpTitleStyles = {
  color: tokens.textTextPrimary,
  fontSize: tokens.productiveHeading05FontSize,
  fontFamily: tokens.productiveHeading05FontFamily,
  fontWeight: tokens.productiveHeading05FontWeight,
  lineHeight: tokens.productiveHeading05LineHeight,
  letterSpacing: tokens.productiveHeading05LetterSpacing,
  textTransform: tokens.productiveHeading05TextCase,
  testDecoration: tokens.productiveHeading05TextDecoration,
  overflow: "hidden",
  textOverflow: "ellipsis",
  whiteSpace: "nowrap",
  flex: "auto",
};

export const PopUpBodyStyles = {
  paddingRight: tokens.spacing6,
  paddingLeft: tokens.spacing6,
  paddingBottom: tokens.spacing6,
  width: "-moz-available",
  width: "-webkit-fill-available",
  overflowY: "scroll",
  marginRight: tokens.spacing15,
  "&::-webkit-scrollbar": {
    width: tokens.sizing15,
  },
  "&::-webkit-scrollbar-thumb": {
    backgroundColor: tokens.layerLayerActive01,
  },
  variants: {
    overflow: {
      true: {
        paddingBottom: 0,
      },
    },
  },
};

export const PopUpFooterContainerStyles = {
  display: "flex",
  flexDirection: "row",
  justifyContent: "flex-end",
  alignItems: "center",
  padding: tokens.spacing6,
  width: "-moz-available",
  width: "-webkit-fill-available",
  variants: {
    secondary: {
      true: {
        justifyContent: "space-between",
      },
    },
    overflow: {
      true: {
        boxShadow:
          "0px -2px 8px rgba(0, 0, 0, 0.05), 0px -1px 4px rgba(0, 0, 0, 0.05), 0px -1px 2px rgba(0, 0, 0, 0.05)",
      },
    },
  },
};

export const PrimaryActionContainerStyles = {
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  gap: tokens.spacing4,
};

export const PopUpTitleIconStyles = {
  height: tokens.sizing5,
  width: tokens.sizing5,
};

import variables from "../../../../tokens/json/variables.json";

export const ColumnVisibilityToggleStyles = {
  fontFamily: variables.bodyShort01FontFamily,
  fontWeight: variables.bodyShort01FontWeight,
  lineHeight: variables.bodyShort01LineHeight,
  fontSize: variables.bodyShort01FontSize,
  letterSpacing: variables.bodyShort01LetterSpacing,
  paragraphSpacing: variables.bodyShort01ParagraphSpacing,
  testCase: variables.bodyShort01TextCase,
  textDecoration: variables.bodyShort01TextDecoration,
};

export const ColumnToggleFieldStyles = {
  display: "inline-flex",
  gap: variables.spacing2,
  alignItems: "center",
  cursor: "move",
  opacity: "1",

  variants: {
    isDragging: {
      true: {
        opacity: "0.5",
      },
    },
    isHidden: {
      true: {
        display: "none",
      },
    },
  },
};

export const ToggleFieldsContainerStyles = {
  height: "auto",
  display: "flex",
  flexDirection: "column",
  gap: variables.spacing4,
  paddingBottom: variables.spacing4,
};

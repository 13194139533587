import * as React from "react";
import { useEffect, useState, useMemo } from "react";
import PropTypes from "prop-types";
import { useDatePicker } from "@rehookify/datepicker";
import { styled } from "@stitches/react";
import { isValid, parse, format } from "date-fns";
import {
  ArrowButtonStyles,
  ArrowIconStyles,
  CalendarContainerStyles,
  CalendarHeaderStyles,
  CalendarIconContainerStyles,
  CollapsibleCardStyles,
  DaysContainerStyles,
  EscapeBackgroundStyles,
  InputStyles,
  TimeButtonStyles,
  TimeModalContainerStyles,
  TimeTextContainerStyles,
  TimeTextStyles,
  TodayContainerStyles,
  TodayTextStyles,
  WeekDaysContainerStyles,
  YearMonthButtonStyles,
  YearMonthTextStyles,
  YearsContainerStyles,
  LabelStyles,
} from "./DatePicker.styles";
import CalendarIcon from "../../icons/CalendarIcon";
import { ArrowDownIcon, ArrowLeftIcon, ArrowRightIcon } from "../../icons";
import Button from "../Button/Button";
import TimeIcon from "../../icons/TimeIcon";
import Card from "../Card/Card";
import TimePickerColumn from "./TimePickerColumn";
import Tooltip from "../Tooltip";
import TextInputMask from "./TextInputMask/TextInputMask";

const MINS = [
  "00",
  "01",
  "02",
  "03",
  "04",
  "05",
  "06",
  "07",
  "08",
  "09",
  "10",
  "11",
  "12",
  "13",
  "14",
  "15",
  "16",
  "17",
  "18",
  "19",
  "20",
  "21",
  "22",
  "23",
  "24",
  "25",
  "26",
  "27",
  "28",
  "29",
  "30",
  "31",
  "32",
  "33",
  "34",
  "35",
  "36",
  "37",
  "38",
  "39",
  "40",
  "41",
  "42",
  "43",
  "44",
  "45",
  "46",
  "47",
  "48",
  "49",
  "50",
  "51",
  "52",
  "53",
  "54",
  "55",
  "56",
  "57",
  "58",
  "59",
];
const DATE_FORMAT = {
  hour12: false,
  hour: "2-digit",
  minute: "2-digit",
};

const Container = styled("div", {
  position: "relative",
  display: "flex",
  flexDirection: "column",
});

const InputContainer = styled("div", {
  position: "relative",
  width: "100%",
});
const Input = styled(TextInputMask, InputStyles);
const Label = styled("div", LabelStyles);
const CalendarIconContainer = styled("div", CalendarIconContainerStyles);

const CalendarContainer = styled("div", CalendarContainerStyles);
const CalendarHeader = styled("div", CalendarHeaderStyles);
const WeekDaysContainer = styled("div", WeekDaysContainerStyles);
const DaysContainer = styled("div", DaysContainerStyles);
const TodayContainer = styled("div", TodayContainerStyles);
const ArrowButton = styled(Button, ArrowButtonStyles);
const YearMonthButton = styled("button", YearMonthButtonStyles);
const YearMonthText = styled("div", YearMonthTextStyles);
const ArrowDown = styled(ArrowDownIcon, ArrowIconStyles);
const TodayText = styled("div", TodayTextStyles);
const TimeTextContainer = styled("div", TimeTextContainerStyles);
const TimeText = styled("div", TimeTextStyles);
const TimeButton = styled(Button, TimeButtonStyles);
const YearsContainer = styled("div", YearsContainerStyles);
const CollapsibleCard = styled(Card, CollapsibleCardStyles);
const TimeModalContainer = styled("div", TimeModalContainerStyles);
const EscapeBackground = styled("div", EscapeBackgroundStyles);

const DatePicker = (props) => {
  const [open, setOpen] = useState(false);
  const [view, setView] = useState("normal");
  const [timeModalOpen, setTimeModalOpen] = useState(false);
  const [selectedDates, onDatesChange] = useState([]);
  const [offsetDate, onOffsetChange] = useState(new Date());
  const [hours, setHours] = useState([]);

  DATE_FORMAT.hour12 = props.hourFormat === 12;

  const {
    data: { weekDays, calendars, years, time, months },
    propGetters: {
      monthButton,
      timeButton,
      dayButton,
      addOffset,
      subtractOffset,
    },
  } = useDatePicker({
    selectedDates,
    onDatesChange,
    offsetDate,
    onOffsetChange,
    locale: {
      locale: props?.locale,
      ...DATE_FORMAT,
    },
    years: { numberOfYears: 10, mode: "decade" },
    time: { interval: 1 },
    calendar: { startDay: 1, mode: "static" },
    dates: { mode: props.range ? "range" : "single", selectSameDate: true },
  });

  const parseTime = (timeInput = "") => {
    const [timeString, meridiem] = timeInput.split(/\s+/);
    const [hour, minute] = timeString.split(":");

    const parsedHour = hour?.trim();
    const parsedMinute = minute?.trim();

    const parsedMeridiem = meridiem?.toUpperCase();

    return {
      hour: parsedHour,
      minute: parsedMinute,
      meridiem: parsedMeridiem,
    };
  };

  useEffect(() => {
    setHours([...new Set(time.map((t) => parseTime(t.time).hour))]);
  }, []);

  const getFormattedDate = (dateString) => {
    const dates = dateString.split("-");
    const formattedDates = [];
    let currentFormat = "";
    // single date with 12 hours dd/MM/yyyy, hh:mm a
    if (props.timeEnabled && props.hourFormat === 12) {
      currentFormat =
        props?.format === "date" || props?.typable
          ? "dd/MM/yyyy, hh:mm a"
          : "E MMM dd yyyy, hh:mm a";
    } // single date with 24 hours dd/MM/yyyy, HH:mm
    else if (props.timeEnabled && props.hourFormat === 24) {
      currentFormat =
        props?.format === "date" || props?.typable
          ? "dd/MM/yyyy, HH:mm"
          : "E MMM dd yyyy, HH:mm";
    } // single date without time
    else {
      currentFormat =
        props?.format === "date" || props?.typable
          ? "dd/MM/yyyy"
          : "E MMM dd yyyy";
    }
    try {
      dates.forEach((date) => {
        if (date.trim()) {
          const newFormat = format(
            parse(date.trim(), currentFormat, new Date()),
            props.customFormat
          );
          formattedDates.push(newFormat);
        }
      });
      return formattedDates;
    } catch (e) {
      // eslint-disable-next-line
      console.error(e);
      return [dateString];
    }
  };

  const onDateChange = (e, date) => {
    if (e) {
      e.stopPropagation();
    }

    const myDate = new Date(date);
    let dateString =
      props?.format === "date" || props?.typable
        ? myDate.toLocaleDateString()
        : myDate.toDateString();

    if (props?.timeEnabled) {
      let localTime = myDate.toLocaleTimeString(props?.locale, DATE_FORMAT);
      if (props.hourFormat === 12 && localTime.includes("00:")) {
        localTime = localTime.replace("00:", "12:");
      }
      dateString += `, ${localTime}`;
    }

    if (props?.range) {
      if (selectedDates.length === 0 || selectedDates.length === 2) {
        dateString += " - ";
      } else {
        const firstDateString = props.value.split(" -")[0];
        dateString =
          new Date(firstDateString) <= myDate
            ? props.value + dateString
            : `${dateString} - ${firstDateString}`;
      }
    }
    const formattedDate = getFormattedDate(dateString);
    if (props?.setCustomFormatValue) props?.setCustomFormatValue(formattedDate);
    props.onChange(dateString);
  };

  const onSelectDay = (e, date) => {
    onDateChange(e, date);
    if (props?.range) {
      if (selectedDates.length === 1) setOpen(false);
    } else if (!props?.timeEnabled) setOpen(false);
  };

  const moveOffsetToDay = (e, date) => {
    onOffsetChange(date);
    onSelectDay(e, date);
  };

  const updateDate = (date) => {
    let parseFormat = "dd/MM/yyyy";
    if (props.timeEnabled) {
      if (props.hourFormat === 24) {
        parseFormat = "dd/MM/yyyy, HH:mm";
      } else if (props.hourFormat === 12) {
        parseFormat = "dd/MM/yyyy, hh:mm a";
      }
    }
    const theDate = parse(date, parseFormat, new Date());
    const { onClick, ...attributes } = dayButton(
      {
        $date: theDate,
        day: theDate.getDate(),
        now: true,
        range: "",
        disabled: false,
        selected: false,
        inCurrentMonth: false,
      },
      { onClick: moveOffsetToDay }
    );
    const btn = document.createElement("button");
    Object.keys(attributes).forEach((attribute) =>
      btn.setAttribute(attribute, attributes[attribute])
    );
    btn.addEventListener("click", onClick);

    btn.click();
    btn.remove();
  };

  // currently supporting only single date initial value
  useEffect(() => {
    if (!props?.range && props?.value) {
      updateDate(props?.value);
    }
  }, []);

  const selectedTimeString = time.find((t) => t.selected)?.time ?? "--:--";

  const { year, month, days } = calendars[0];

  const onToggleDatePicker = () => setOpen(!open);

  const onClickModalEscape = () => {
    if (props?.range && selectedDates.length === 1) {
      updateDate(selectedDates[0].toLocaleDateString());
    }
    if (timeModalOpen) setTimeModalOpen(false);
    else setOpen(false);
  };

  const updateTime = (timeObject) => {
    const parsedTime = parseTime(selectedTimeString);

    const hour = timeObject?.hour ?? parsedTime.hour;
    const minute = timeObject?.minute ?? parsedTime.minute;
    const meridiem = timeObject?.meridiem ?? parsedTime.meridiem;

    const updatedTimeString = DATE_FORMAT.hour12
      ? `${hour}:${minute} ${meridiem}`
      : `${hour}:${minute}`;

    const updatedTime = time.find(
      (t) => t.time.toUpperCase().replace(/\s+/g, " ") === updatedTimeString
    );
    const { onClick, ...attributes } = timeButton(updatedTime);
    const btn = document.createElement("button");
    Object.keys(attributes).forEach((attribute) =>
      btn.setAttribute(attribute, attributes[attribute])
    );
    btn.addEventListener("click", onClick);

    btn.click();
    btn.remove();
    onDateChange(null, updatedTime.$date);
  };

  const concatenateYearAndMonth = (monthDate, yearDate) => {
    const firstDate = new Date(monthDate);
    const secondDate = new Date(yearDate);

    const m = firstDate.getMonth();
    const d = firstDate.getDate();
    const y = secondDate.getFullYear();

    return new Date(y, m, d);
  };

  const getCurrentDate = () => {
    const date = new Date();
    date.setHours(0, 0, 0, 0);
    return date;
  };

  const onChangeView = (newView) =>
    setView(view !== "normal" ? "normal" : newView);

  const renderCalendarBody = () => {
    switch (view) {
      case "year":
        return (
          <YearsContainer data-testid="year-container">
            {years.map((dpYear) => (
              <CollapsibleCard
                key={dpYear.$date}
                type="expandable"
                title={dpYear.year}
                expanded={`${dpYear.year}` === year}
                initialHeight={40}
              >
                {months.map((dpMonth) => {
                  const isSelected =
                    `${dpYear.year}` === year && dpMonth.month === month;

                  // eslint-disable-next-line no-param-reassign
                  dpMonth.$date = concatenateYearAndMonth(
                    dpMonth.$date,
                    dpYear.$date
                  );

                  return (
                    <button
                      key={`${dpYear.year}-${dpMonth.$date.toString()}`}
                      type="button"
                      {...monthButton(dpMonth, {
                        onClick: onChangeView,
                      })}
                      disabled={dpMonth.disabled}
                      data-monthselected={isSelected}
                    >
                      {dpMonth.month.slice(0, 3)}
                    </button>
                  );
                })}
              </CollapsibleCard>
            ))}
          </YearsContainer>
        );
      case "normal":
      default:
        return (
          <>
            <WeekDaysContainer>
              {weekDays.map((day) => (
                <div key={`${month}-${day}`}>{day}</div>
              ))}
            </WeekDaysContainer>
            <DaysContainer data-testid="datepicker-daysContainer">
              {days.map((dpDay) => (
                <button
                  key={`${month}-${dpDay.$date.toString()}`}
                  type="button"
                  {...dayButton(dpDay, { onClick: onSelectDay })}
                  disabled={dpDay.disabled}
                  data-dayoutside={!dpDay.inCurrentMonth}
                  data-dayselected={dpDay.selected}
                  data-daytoday={dpDay.now}
                  data-dayinrange={dpDay.range === "in-range"}
                >
                  {dpDay.day}
                </button>
              ))}
            </DaysContainer>
          </>
        );
    }
  };

  const renderTimeModal = () => {
    const { hour, minute, meridiem } = parseTime(selectedTimeString);

    return (
      <>
        <EscapeBackground onClick={onClickModalEscape} />
        <TimeModalContainer
          data-position={props.timePickerPosition}
          data-testid="timepicker"
          data-todayenabled={props?.todayEnabled}
        >
          <TimePickerColumn
            selectedOption={hour}
            onChange={(hr) => updateTime({ hour: hr })}
            options={hours}
            label="Hour"
          />
          <TimePickerColumn
            selectedOption={minute}
            onChange={(min) => updateTime({ minute: min })}
            options={MINS}
            label="Minutes"
          />
          {DATE_FORMAT.hour12 && (
            <TimePickerColumn
              selectedOption={meridiem}
              onChange={(mer) => updateTime({ meridiem: mer })}
              options={["AM", "PM"]}
              label="Meridiem"
            />
          )}
        </TimeModalContainer>
      </>
    );
  };

  const renderCalendarFooter = () =>
    view === "normal" && (
      <>
        {props?.timeEnabled && !props?.range && (
          <TimeTextContainer>
            <Tooltip
              content={selectedDates.length === 0 && "Please select a date"}
            >
              <TimeText
                data-testid="time-text"
                onClick={() =>
                  selectedDates.length === 0
                    ? null
                    : setTimeModalOpen(!timeModalOpen)
                }
              >
                {selectedTimeString.toUpperCase()}
                <TimeButton
                  color="ghost"
                  disabled={selectedDates.length === 0}
                  selected={timeModalOpen}
                  size="small"
                >
                  <TimeIcon />
                </TimeButton>
              </TimeText>
            </Tooltip>
          </TimeTextContainer>
        )}
        {props?.todayEnabled && (
          <TodayContainer>
            <TodayText
              {...dayButton(
                {
                  $date: getCurrentDate(),
                  day: new Date().getDate(),
                  now: true,
                  range: "",
                  disabled: false,
                  selected: false,
                  inCurrentMonth: false,
                },
                { onClick: moveOffsetToDay }
              )}
            >
              Today: {months[new Date().getMonth()].month.slice(0, 3)}{" "}
              {new Date().getDate()} {new Date().getFullYear()}
            </TodayText>
          </TodayContainer>
        )}
      </>
    );

  const renderModal = () => (
    <>
      <EscapeBackground onClick={onClickModalEscape} data-testid="escape" />
      <div style={{ position: "relative", zIndex: 10 }}>
        <CalendarContainer style={{ rowGap: view === "normal" ? 8 : 0 }}>
          <CalendarHeader>
            {view === "normal" ? (
              <ArrowButton color="ghost" {...subtractOffset({ months: 1 })}>
                <ArrowLeftIcon />
              </ArrowButton>
            ) : (
              <ArrowButton color="ghost" {...subtractOffset({ years: 1 })}>
                <ArrowLeftIcon />
              </ArrowButton>
            )}
            <YearMonthButton
              data-testid="year-toggle"
              onClick={() => onChangeView("year")}
            >
              <YearMonthText>
                {month} {year}
              </YearMonthText>
              <ArrowDown data-open={view === "year"} />
            </YearMonthButton>
            {view === "normal" ? (
              <ArrowButton color="ghost" {...addOffset({ months: 1 })}>
                <ArrowRightIcon />
              </ArrowButton>
            ) : (
              <ArrowButton color="ghost" {...addOffset({ years: 1 })}>
                <ArrowRightIcon />
              </ArrowButton>
            )}
          </CalendarHeader>
          {renderCalendarBody()}
          {renderCalendarFooter()}
          {timeModalOpen && renderTimeModal()}
        </CalendarContainer>
      </div>
    </>
  );

  const [isInvalidDate, setIsInvalidDate] = useState("NA");

  const validateDateValues = (dateStr) => {
    let datePattern = /^(\d{2})\/(\d{2})\/(\d{4})$/;

    if (props.timeEnabled) {
      if (props.hourFormat === 12) {
        datePattern = /(\d{2})\/(\d{2})\/(\d{4}), (\d{2}):(\d{2}) (am|pm)/i;
      } else {
        datePattern = /^(\d{2})\/(\d{2})\/(\d{4}), (\d{2}):(\d{2})$/;
      }
    }

    const match = dateStr.match(datePattern);

    if (!match) {
      return false;
    }
    // eslint-disable-next-line
    const [, day, month, year, hours, minutes] = match;

    if (year.length !== 4) {
      return false;
    }

    const parsedMonth = parseInt(month, 10);
    if (parsedMonth < 1 || parsedMonth > 12) {
      return false;
    }

    const parsedDay = parseInt(day, 10);
    const parsedYear = parseInt(year, 10);
    const maxDay = new Date(parsedYear, parsedMonth, 0).getDate();
    if (parsedDay < 1 || parsedDay > maxDay) {
      return false;
    }

    const parsedHours = parseInt(hours, 10) || 0;
    const parsedMinutes = parseInt(minutes, 10) || 0;

    if (props.timeEnabled && (parsedMinutes < 0 || parsedMinutes > 59)) {
      return false;
    }

    if (
      props.timeEnabled &&
      props.hourFormat === 12 &&
      (parsedHours < 1 || parsedHours > 12)
    ) {
      return false;
    }
    if (
      props.timeEnabled &&
      props.hourFormat === 24 &&
      (parsedHours < 0 || parsedHours > 23)
    ) {
      return false;
    }

    const parsedDate = parse(
      dateStr,
      // eslint-disable-next-line
      props.timeEnabled
        ? props.hourFormat === 24
          ? "dd/MM/yyyy, HH:mm"
          : "dd/MM/yyyy, hh:mm a"
        : "dd/MM/yyyy",
      new Date()
    );

    return parsedDate;
  };

  const isValidDateString = (dateString) => {
    let datePattern = /^(\d{2})\/(\d{2})\/(\d{4})$/;
    let parsedDate;
    const rangeDates = [];

    if (props.range) {
      if (props.timeEnabled) {
        if (props.hourFormat === 24)
          datePattern =
            /^(\d{2})\/(\d{2})\/(\d{4}), (\d{2}):(\d{2}) - (\d{2})\/(\d{2})\/(\d{4}), (\d{2}):(\d{2})$/;
        else if (props.hourFormat === 12)
          datePattern =
            /^(\d{2})\/(\d{2})\/(\d{4}), (\d{2}):(\d{2}) (am|pm) - (\d{2})\/(\d{2})\/(\d{4}), (\d{2}):(\d{2}) (am|pm)$/i;
      } else
        datePattern = /^(\d{2})\/(\d{2})\/(\d{4}) - (\d{2})\/(\d{2})\/(\d{4})$/;

      const initialMatch = dateString.match(datePattern);
      if (!initialMatch) {
        return false;
      }

      const dateRange = dateString.split("-").map((date) => date.trim());

      if (dateRange.length !== 2) {
        return false;
      }

      dateRange.forEach((dateStr) => {
        const parsedDateTime = validateDateValues(dateStr);
        rangeDates.push(parsedDateTime);
      });
    } else {
      parsedDate = validateDateValues(dateString);
    }

    return props.range
      ? rangeDates.every((prsdDate) => isValid(prsdDate))
      : isValid(parsedDate);
  };

  const [requiredDateFormat, maskFormat] = useMemo(() => {
    let dateFormat = "dd/MM/yyyy";
    let mask = "99/99/9999";
    if (props.range) {
      if (props.timeEnabled) {
        if (props.hourFormat === 12) {
          dateFormat = "dd/MM/yyyy, hh:mm a - dd/MM/yyyy, hh:mm a";
          mask = "99/99/9999, 99:99 am - 99/99/9999, 99:99 am";
        } else if (props.hourFormat === 24) {
          dateFormat = "dd/MM/yyyy, HH:mm - dd/MM/yyyy, HH:mm";
          mask = "99/99/9999, 99:99 - 99/99/9999, 99:99";
        }
      } else {
        dateFormat = "dd/MM/yyyy - dd/MM/yyyy";
        mask = "99/99/9999 - 99/99/9999";
      }
    } else if (props.timeEnabled) {
      if (props.hourFormat === 12) {
        dateFormat = "dd/MM/yyyy, hh:mm a";
        mask = "99/99/9999, 99:99 am";
      } else if (props.hourFormat === 24) {
        dateFormat = "dd/MM/yyyy, HH:mm";
        mask = "99/99/9999, 99:99";
      }
    }

    return [dateFormat, mask];
  }, [props.range, props.timeEnabled]);

  const handleChange = (e) => {
    props.onChange(e.target.value);
    if (e.target.value.trim()) {
      if (isValidDateString(e.target.value.trim())) {
        setIsInvalidDate(false);
        const dates = e.target.value.trim().split("-");
        dates.forEach((date) => updateDate(date.trim()));
      } else {
        setIsInvalidDate(true);
      }
    }
  };

  return (
    <Container data-testid="datepicker" style={{ zIndex: open ? 3 : 2 }}>
      {props?.label && (
        <Label
          required={props?.required}
          disabled={props?.disabled}
          data-testid="datePicker-label"
        >
          {props?.label}
        </Label>
      )}
      <InputContainer>
        <Input
          type="text"
          placeholder={props?.typable ? requiredDateFormat : props.placeholder}
          value={props?.value}
          onChange={props?.typable && !props.range ? handleChange : () => {}}
          onClick={onToggleDatePicker}
          mask={props?.typable && !props.range ? maskFormat : null}
          error={
            props?.error ||
            (isInvalidDate === "NA" || isValidDateString(props.value)
              ? false
              : isInvalidDate)
          }
          helperText={props?.helperText}
          style={{
            cursor: "pointer",
            paddingTop: 0,
            width: "100%",
            boxSizing: "border-box",
          }}
          disabled={props?.disabled}
        />
        <CalendarIconContainer
          data-testid="datepicker-toggle"
          data-active={open}
          data-disabled={props?.disabled}
          onClick={onToggleDatePicker}
        >
          <CalendarIcon />
        </CalendarIconContainer>
      </InputContainer>
      {open && renderModal()}
    </Container>
  );
};

export default DatePicker;

DatePicker.propTypes = {
  timePickerPosition: PropTypes.oneOf(["up", "down"]),
  todayEnabled: PropTypes.bool,
  timeEnabled: PropTypes.bool,
  placeholder: PropTypes.string,
  hourFormat: PropTypes.oneOf([12, 24]),
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  locale: PropTypes.string,
  format: PropTypes.oneOf(["readable", "date"]),
  value: PropTypes.string,
  typable: PropTypes.bool,
  range: PropTypes.bool,
  label: PropTypes.string,
  helperText: PropTypes.string,
  required: PropTypes.bool,
  setCustomFormatValue: PropTypes.func,
  customFormat: PropTypes.string,
  error: PropTypes.bool,
};

DatePicker.defaultProps = {
  timePickerPosition: "up",
  todayEnabled: false,
  timeEnabled: false,
  placeholder: "Pick a date",
  hourFormat: 24,
  locale: "en-gb",
  format: "date",
  value: "",
  range: false,
  required: false,
  label: "label",
  typable: false,
  customFormat: "yyyy-MM-dd'T'HH:mm:ss.SSS",
};

import React, { useRef, useEffect, useState } from "react";
import * as Checkbox from "@radix-ui/react-checkbox";
import { styled } from "@stitches/react";
import PropTypes from "prop-types";
import { SquareIcon, SquareCheckIcon, SquareMinusIcon } from "../../../icons";
import { CheckBoxStyles } from "./RowSelectionBox.styles";

const StyledCheckBox = styled(Checkbox.Root, CheckBoxStyles);

const RowSelectionBox = React.forwardRef(
  (
    { checked, indeterminate, disabled, headerComponent, ...props },
    forwardedRef
  ) => {
    const defaultRef = useRef();
    const resolvedRef = forwardedRef || defaultRef;

    const [checkedState, setCheckedState] = useState(checked);

    useEffect(() => {
      if (indeterminate) {
        setCheckedState("indeterminate");
      } else if (checked) {
        setCheckedState(true);
      } else {
        setCheckedState(false);
      }
    }, [resolvedRef, indeterminate, checked]);

    return (
      <StyledCheckBox
        checked={checkedState}
        onCheckedChange={(value) =>
          props?.onChange({ target: { checked: value } })
        }
        disabled={disabled}
        headerComponent={headerComponent}
        onClick={(event) => {
          // Stop event propagation to prevent row click
          event.stopPropagation();
        }}
        {...props}
        data-testid="Row_SelectionBox"
        ref={resolvedRef}
      >
        {!checkedState && <SquareIcon height="14px" width="14px" />}
        <Checkbox.Indicator>
          {checkedState === true && (
            <SquareCheckIcon height="14px" width="14px" />
          )}
          {checkedState === "indeterminate" && (
            <SquareMinusIcon height="14px" width="14px" />
          )}
        </Checkbox.Indicator>
      </StyledCheckBox>
    );
  }
);

RowSelectionBox.propTypes = {
  checked: PropTypes.bool,
  indeterminate: PropTypes.bool,
  disabled: PropTypes.bool,
  headerComponent: PropTypes.bool,
  onChange: PropTypes.func,
};

export default RowSelectionBox;

import * as React from "react";
const SvgInfoIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    viewBox="0 0 16 16"
    fill="currentColor"
    {...props}
  >
    <path
      d="M8 0C3.562 0 0 3.594 0 8c0 4.438 3.563 8 8 8 4.406 0 8-3.563 8-8 0-4.406-3.594-8-8-8Zm0 14.5A6.495 6.495 0 0 1 1.5 8c0-3.563 2.906-6.5 6.5-6.5 3.563 0 6.5 2.938 6.5 6.5 0 3.594-2.938 6.5-6.5 6.5Zm1.25-4h-.5V7.75A.76.76 0 0 0 8 7H7a.74.74 0 0 0-.75.75c0 .438.313.75.75.75h.25v2h-.5a.74.74 0 0 0-.75.75c0 .438.313.75.75.75h2.5a.74.74 0 0 0 .75-.75.76.76 0 0 0-.75-.75ZM8 6c.531 0 1-.438 1-1 0-.531-.469-1-1-1-.563 0-1 .469-1 1 0 .563.438 1 1 1Z"
      fill="inherit"
    />
  </svg>
);
export default SvgInfoIcon;

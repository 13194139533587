import React from "react";
import * as Switch from "@radix-ui/react-switch";
import { styled } from "@stitches/react";
import PropTypes from "prop-types";
import Tooltip from "../Tooltip/Tooltip";
import SvgUserIcon from "../../icons/InfoIcon";

import {
  ToggleContainerStyles,
  SwitchRootStyles,
  SwitchThumbStyles,
  FlexStyles,
  LabelStyles,
  ToggleLabelStyles,
  HelperTextStyles,
  RequiredStyles,
  IconStyles,
  FlexCenteredStyles,
} from "./Toggle.styles";

const ToggleContainer = styled("div", ToggleContainerStyles);
const SwitchRoot = styled(Switch.Root, SwitchRootStyles);
const SwitchThumb = styled(Switch.Thumb, SwitchThumbStyles);
const Flex = styled("div", FlexStyles);
const FlexCentered = styled("div", FlexCenteredStyles);
const Label = styled("div", LabelStyles);
const ToggleLabel = styled("div", ToggleLabelStyles);
const HelperText = styled("div", HelperTextStyles);
const Required = styled("div", RequiredStyles);
const InfoTooltip = styled(Tooltip, IconStyles);

const Toggle = (props) => {
  const {
    id,
    name,
    label,
    checked,
    onCheckedChange,
    helperText,
    disabled,
    error,
    readOnly,
    required,
    infoText,
  } = props;

  return (
    <div id={id}>
      {label && (
        <Flex>
          <Label disableStyles={disabled} data-testid="Toggle_Label">
            {label}
          </Label>
          {required && (
            <Required
              data-testid="Toggle_Required_Inline"
              readOnly={readOnly}
              disableStyles={disabled}
            >
              *
            </Required>
          )}
          {infoText && (
            <InfoTooltip content={infoText} disableStyles={disabled}>
              <SvgUserIcon />
            </InfoTooltip>
          )}
        </Flex>
      )}
      <FlexCentered>
        <ToggleContainer
          disableStyles={disabled}
          error={error}
          readOnly={readOnly}
          data-testid="Toggle_Container"
        >
          <SwitchRoot
            id={id}
            disableStyles={disabled}
            disabled={disabled || readOnly}
            checked={checked}
            error={error}
            readOnly={readOnly}
            onCheckedChange={onCheckedChange}
            data-testid="Toggle_Root"
            required={required}
          >
            <SwitchThumb
              disableStyles={disabled}
              disabled={disabled || readOnly}
              error={error}
              readOnly={readOnly}
              data-testid="Toggle_Thumb"
            />
          </SwitchRoot>
        </ToggleContainer>
        {name && (
          <ToggleLabel
            data-testid="Toggle_Title"
            readOnly={readOnly}
            disableStyles={disabled}
          >
            {name}
          </ToggleLabel>
        )}
        {!label && required && (
          <Required
            data-testid="Toggle_Required_Inline"
            readOnly={readOnly}
            disableStyles={disabled}
          >
            *
          </Required>
        )}
      </FlexCentered>
      {helperText && (
        <HelperText
          disableStyles={disabled}
          error={error}
          data-testid="Toggle_Helper"
        >
          {helperText}
        </HelperText>
      )}
    </div>
  );
};

Toggle.propTypes = {
  /** Unique identifier for the toggle, used to associate the toggle with its label for accessibility. */
  id: PropTypes.string,

  /** If true, the toggle is in a disabled state and cannot be interacted with. */
  disabled: PropTypes.bool,

  /** Indicates whether the toggle is displaying an error state. */
  error: PropTypes.bool,

  /** If true, the toggle is in a read-only state and cannot be interacted with, but is not visually disabled. */
  readOnly: PropTypes.bool,

  /** Text label for the toggle. Displayed alongside the toggle switch. */
  label: PropTypes.string,

  /** Additional text provided as helper information, such as an explanation of the toggle's purpose. */
  helperText: PropTypes.string,

  /** Controls the checked state of the toggle. True means the toggle is on/active. */
  checked: PropTypes.bool,

  /** Optional name for the toggle. */
  name: PropTypes.string,

  /** Function called when the checked state of the toggle changes. It receives the new checked value as its argument. */
  onCheckedChange: PropTypes.func,

  /** Text for an informational tooltip that appears when hovering over an information icon next to the toggle. */
  infoText: PropTypes.string,

  /** If true, displays an asterisk (*) to indicate that the toggle is required in a form context. */
  required: PropTypes.bool,
};

export default Toggle;

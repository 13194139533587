import React from "react";
import { styled } from "@stitches/react";
import * as DialogPrimitive from "@radix-ui/react-dialog";
import PropTypes from "prop-types";
import Button from "../Button/Button";

import {
  PanelStyles,
  OverlayStyles,
  CloseButtonStyles,
  IconButtonStyles,
  HeaderStyles,
  HeaderContentStyles,
  BodyStyles,
  TitleStyles,
  DescriptionStyles,
  FooterStyles,
  PrimaryActionContainerStyles,
} from "./SidePanel.styles";

export const Sheet = DialogPrimitive.Root;

export const SheetTitle = styled(DialogPrimitive.Title, TitleStyles);
export const SheetDescription = styled(
  DialogPrimitive.Description,
  DescriptionStyles
);

export const SheetOverlay = styled(DialogPrimitive.Overlay, OverlayStyles);
export const SheetContent = styled(DialogPrimitive.Content, PanelStyles);
export const SheetHeader = styled("div", HeaderStyles);
export const SheetHeaderContent = styled("div", HeaderContentStyles);
export const StyledBody = styled("div", BodyStyles);
export const SheetBody = React.forwardRef(
  ({ children, ...props }, forwardedRef) => {
    return (
      <StyledBody {...props} ref={forwardedRef}>
        {children}
      </StyledBody>
    );
  }
);

SheetBody.propTypes = {
  children: PropTypes.node,
};

export const SheetCloseButton = styled(
  DialogPrimitive.Close,
  CloseButtonStyles
);
export const IconButton = styled(Button, IconButtonStyles);
const Footer = styled("div", FooterStyles);
const PrimaryActionContainer = styled("div", PrimaryActionContainerStyles);
export const SheetFooter = React.forwardRef(
  (
    {
      showSecondaryButton,
      secondaryButtonLabel = "Reset",
      secondaryButtonProps,
      onSecondaryAction,
      cancelButtonLabel = "Cancel",
      cancelButtonProps,
      onCancel,
      acceptButtonLabel = "Accept",
      acceptButtonProps,
      onAccept,
      enabledShadow,
      ...props
    },
    forwardedRef
  ) => {
    return (
      <Footer
        secondary={showSecondaryButton}
        enabledShadow={enabledShadow}
        ref={forwardedRef}
        {...props}
      >
        {showSecondaryButton && (
          <Button
            data-testid="SidePanel_secondary_button"
            color="ghost"
            {...secondaryButtonProps}
            onClick={onSecondaryAction}
          >
            {secondaryButtonLabel}
          </Button>
        )}
        <PrimaryActionContainer>
          <Button
            data-testid="SidePanel_cancel_button"
            {...cancelButtonProps}
            color="secondary"
            onClick={onCancel}
          >
            {cancelButtonLabel}
          </Button>
          <Button
            data-testid="SidePanel_accept_button"
            {...acceptButtonProps}
            onClick={onAccept}
          >
            {acceptButtonLabel}
          </Button>
        </PrimaryActionContainer>
      </Footer>
    );
  }
);

SheetFooter.propTypes = {
  enabledShadow: PropTypes.bool,
  onCancel: PropTypes.func,
  cancelButtonLabel: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  cancelButtonProps: PropTypes.shape({
    color: PropTypes.string,
    size: PropTypes.string,
    disabled: PropTypes.bool,
    hidden: PropTypes.bool,
    id: PropTypes.string,
  }),
  onAccept: PropTypes.func,
  acceptButtonLabel: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  acceptButtonProps: PropTypes.shape({
    color: PropTypes.string,
    size: PropTypes.string,
    disabled: PropTypes.bool,
    hidden: PropTypes.bool,
    id: PropTypes.string,
  }),
  onSecondaryAction: PropTypes.func,
  secondaryButtonLabel: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  secondaryButtonProps: PropTypes.shape({
    color: PropTypes.string,
    size: PropTypes.string,
    disabled: PropTypes.bool,
    hidden: PropTypes.bool,
    id: PropTypes.string,
  }),
  showSecondaryButton: PropTypes.bool,
};

import React from "react";
import { styled } from "@stitches/react";
import PropTypes from "prop-types";
import SectionItem from "./SectionItem";
import { SectionContainerStyles, SectionHeaderStyles } from "./Section.styles";

const SectionContainer = styled("div", SectionContainerStyles);

const SectionHeader = styled("div", SectionHeaderStyles);

const Section = ({
  id,
  sectionItems,
  children,
  isCollapse,
  handleSelect,
  selected,
  setCollapse,
  ...otherProps
}) => {
  return (
    <SectionContainer className={isCollapse && "nospacing"}>
      {!isCollapse ? (
        <SectionHeader id={id} {...otherProps}>
          {children ?? children}
        </SectionHeader>
      ) : null}
      <SectionItem
        sectionItems={sectionItems}
        isCollapse={isCollapse}
        setCollapse={setCollapse}
        handleSelect={handleSelect}
        selected={selected}
      />
    </SectionContainer>
  );
};

Section.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  sectionItems: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      label: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      icon: PropTypes.node,
      disabled: PropTypes.bool,
      type: PropTypes.oneOf(["section_header", "sub_menu", "footer"]),
    })
  ),
  children: PropTypes.node,
  handleSelect: PropTypes.func,
  selected: PropTypes.string,
  isCollapse: PropTypes.bool,
  setCollapse: PropTypes.func,
};
export default Section;

/* eslint-disable no-dupe-keys */
import variables from "../../tokens/json/variables.json";

export const RootStyles = {
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
};

export const TableStyles = {
  width: "-moz-available",
  width: "-webkit-fill-available",
  height: "inherit",
  borderSpacing: "0px",
  transition: "height 0.6s ease",
};

export const TableActionsContainerStyles = {
  display: "grid",
  gap: variables.spacing4,
  marginBottom: "8px",
};

export const SearchFilterWrapperStyle = {
  display: "flex",
  gap: variables.spacing2,
  alighItems: "center",
};

export const ColumnSFStyle = {
  display: "flex",
  flexDirection: "row",
  gap: variables.spacing2,
};

import * as React from "react";

const NoDataSvgArt = (props) => (
  <svg
    width="263"
    height="256"
    viewBox="0 0 263 256"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_12548_17844)">
      <path
        d="M166.494 57.5736H95.8264C94.216 57.5755 92.6721 58.2161 91.5334 59.3549C90.3947 60.4936 89.7541 62.0375 89.7521 63.6479V220.708L88.9422 220.955L71.6062 226.264C70.7846 226.515 69.8972 226.429 69.1387 226.026C68.3803 225.623 67.8128 224.935 67.5609 224.114L15.9941 55.6744C15.7432 54.8527 15.8287 53.9651 16.2319 53.2065C16.6352 52.4479 17.3231 51.8805 18.1445 51.6289L44.8592 43.4488L122.306 19.743L149.021 11.5629C149.427 11.4378 149.855 11.3941 150.278 11.4343C150.702 11.4744 151.113 11.5977 151.489 11.7971C151.864 11.9964 152.197 12.2679 152.468 12.596C152.739 12.9241 152.942 13.3024 153.066 13.7092L166.248 56.7637L166.494 57.5736Z"
        fill="#F1F3F7"
      />
      <path
        d="M181.915 56.7637L166.029 4.87312C165.765 4.00853 165.332 3.20449 164.757 2.50695C164.182 1.80941 163.475 1.23204 162.677 0.80784C161.878 0.383636 161.004 0.120905 160.104 0.0346708C159.204 -0.0515632 158.296 0.0403894 157.432 0.305265L119.872 11.8018L42.4295 35.5118L4.87022 47.0124C3.12541 47.5482 1.66446 48.7544 0.808046 50.3662C-0.0483705 51.978 -0.230285 53.8638 0.302239 55.6096L54.5982 232.95C55.0308 234.359 55.9037 235.593 57.0888 236.47C58.2738 237.346 59.7087 237.82 61.1829 237.822C61.8651 237.822 62.5436 237.72 63.1953 237.518L88.9422 229.638L89.7521 229.387V228.54L88.9422 228.787L62.9565 236.745C61.4164 237.214 59.7529 237.053 58.331 236.298C56.9091 235.543 55.8449 234.254 55.3717 232.715L1.07991 55.3706C0.845482 54.6081 0.763777 53.8068 0.839473 53.0126C0.915169 52.2184 1.14677 51.4469 1.52104 50.7424C1.8953 50.0379 2.40488 49.4141 3.02056 48.9068C3.63625 48.3994 4.34596 48.0185 5.10907 47.7859L42.6684 36.2852L120.111 12.5794L157.671 1.07873C158.25 0.90209 158.851 0.812025 159.456 0.811461C160.755 0.814377 162.019 1.23308 163.062 2.00624C164.106 2.7794 164.874 3.86639 165.255 5.108L181.069 56.7637L181.32 57.5736H182.162L181.915 56.7637Z"
        fill="#52698E"
      />
      <path
        d="M49.6798 51.7613C48.8993 51.7607 48.1395 51.51 47.5119 51.046C46.8843 50.582 46.4219 49.9291 46.1926 49.1831L40.9767 32.146C40.8365 31.6884 40.7879 31.2076 40.8335 30.7311C40.8792 30.2547 41.0183 29.7919 41.2428 29.3692C41.4674 28.9466 41.773 28.5723 42.1422 28.2677C42.5114 27.9631 42.937 27.7343 43.3947 27.5942L114.642 5.78201C115.566 5.49998 116.564 5.59617 117.418 6.04947C118.271 6.50277 118.91 7.27615 119.193 8.19986L124.409 25.2371C124.691 26.1614 124.595 27.1597 124.142 28.013C123.688 28.8664 122.915 29.5051 121.992 29.7891L50.7445 51.6013C50.3995 51.7072 50.0407 51.7611 49.6798 51.7613Z"
        fill="#91A3C0"
      />
      <path
        d="M77.0031 18.2044C81.4761 18.2044 85.1021 14.5784 85.1021 10.1054C85.1021 5.63241 81.4761 2.00635 77.0031 2.00635C72.5301 2.00635 68.9041 5.63241 68.9041 10.1054C68.9041 14.5784 72.5301 18.2044 77.0031 18.2044Z"
        fill="#91A3C0"
      />
      <path
        d="M77.0031 15.2341C79.8355 15.2341 82.1316 12.9379 82.1316 10.1055C82.1316 7.27306 79.8355 4.97693 77.0031 4.97693C74.1706 4.97693 71.8745 7.27306 71.8745 10.1055C71.8745 12.9379 74.1706 15.2341 77.0031 15.2341Z"
        fill="white"
      />
      <path
        d="M244.039 235.752H107.165C106.252 235.751 105.378 235.388 104.732 234.743C104.087 234.098 103.724 233.223 103.723 232.31V68.3048C103.724 67.3922 104.087 66.5173 104.732 65.872C105.377 65.2267 106.252 64.8637 107.165 64.8627H244.039C244.951 64.8637 245.826 65.2267 246.471 65.872C247.117 66.5173 247.48 67.3922 247.481 68.3048V232.31C247.48 233.223 247.117 234.098 246.471 234.743C245.826 235.388 244.951 235.751 244.039 235.752Z"
        fill="#F1F3F7"
      />
      <path
        d="M181.069 56.7637H95.8263C94.0013 56.7663 92.2518 57.4924 90.9613 58.7829C89.6708 60.0733 88.9447 61.8228 88.9421 63.6479V228.787L89.752 228.54V63.6479C89.754 62.0375 90.3946 60.4936 91.5333 59.3549C92.672 58.2161 94.2159 57.5755 95.8263 57.5736H181.32L181.069 56.7637ZM255.377 56.7637H95.8263C94.0013 56.7663 92.2518 57.4924 90.9613 58.7829C89.6708 60.0733 88.9447 61.8228 88.9421 63.6479V249.116C88.9447 250.941 89.6708 252.69 90.9613 253.981C92.2518 255.271 94.0013 255.997 95.8263 256H255.377C257.202 255.997 258.952 255.271 260.242 253.981C261.533 252.69 262.259 250.941 262.262 249.116V63.6479C262.259 61.8228 261.533 60.0733 260.242 58.7829C258.952 57.4924 257.202 56.7663 255.377 56.7637ZM261.452 249.116C261.45 250.726 260.809 252.27 259.67 253.409C258.532 254.548 256.988 255.188 255.377 255.19H95.8263C94.2159 255.188 92.672 254.548 91.5333 253.409C90.3946 252.27 89.754 250.726 89.752 249.116V63.6479C89.754 62.0375 90.3946 60.4936 91.5333 59.3549C92.672 58.2161 94.2159 57.5755 95.8263 57.5736H255.377C256.988 57.5755 258.532 58.2161 259.67 59.3549C260.809 60.4936 261.45 62.0375 261.452 63.6479V249.116Z"
        fill="#52698E"
      />
      <path
        d="M212.857 74.5815H138.346C137.38 74.5804 136.454 74.1961 135.77 73.5128C135.087 72.8296 134.703 71.9032 134.702 70.9369V53.1191C134.703 52.1528 135.087 51.2264 135.77 50.5432C136.454 49.8599 137.38 49.4756 138.346 49.4745H212.857C213.824 49.4756 214.75 49.8599 215.433 50.5432C216.117 51.2264 216.501 52.1528 216.502 53.1191V70.9369C216.501 71.9032 216.117 72.8296 215.433 73.5128C214.75 74.1961 213.824 74.5804 212.857 74.5815Z"
        fill="#91A3C0"
      />
      <path
        d="M175.602 50.6894C180.075 50.6894 183.701 47.0633 183.701 42.5904C183.701 38.1174 180.075 34.4913 175.602 34.4913C171.129 34.4913 167.503 38.1174 167.503 42.5904C167.503 47.0633 171.129 50.6894 175.602 50.6894Z"
        fill="#91A3C0"
      />
      <path
        d="M175.602 47.5234C178.326 47.5234 180.535 45.3148 180.535 42.5903C180.535 39.8658 178.326 37.6572 175.602 37.6572C172.877 37.6572 170.669 39.8658 170.669 42.5903C170.669 45.3148 172.877 47.5234 175.602 47.5234Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_12548_17844">
        <rect width="262.262" height="256" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
export default NoDataSvgArt;

import React, { useState } from "react";
import { styled } from "@stitches/react";
import PropTypes from "prop-types";
import clsx from "clsx";
import SvgArrowDownIcon from "../../../icons/ArrowDownIcon";
import SvgArrowUpIcon from "../../../icons/ArrowUpIcon";
import SubMenuItem from "./SubMenuItem";
import SvgUserIcon from "../../../icons/DealIcon";
import tokens from "../../../tokens/json/variables.json";

import {
  SubMenuTriggerStyles,
  LabelStyles,
  IconContainerStyles,
  SubMenuItemContainerStyles,
} from "./SubMenu.styles";

const SubMenuTrigger = styled("div", SubMenuTriggerStyles);

const Label = styled("div", LabelStyles);
const IconContainer = styled("div", IconContainerStyles);
const SubMenuItemContainer = styled("div", SubMenuItemContainerStyles);

const SubMenu = ({
  id,
  subMenuItems,
  label,
  Icon,
  collapse,
  handleSelect,
  selected,
  expanded,
  setCollapse,
  disabled = false,
}) => {
  const [menuExpand, setMenuExpand] = useState(expanded || false);
  const subMenuItemSelected = subMenuItems.some(
    (item) => item?.id === selected
  );

  const menuItems = subMenuItems?.map((item) => {
    return (
      <SubMenuItem
        key={item?.id}
        disabled={item?.disabled || false}
        {...item}
        selected={selected}
        handleSelect={(event) => handleSelect(event, item?.id)}
      />
    );
  });

  return (
    <SubMenuTrigger
      onClick={(event) => {
        event.stopPropagation();
        setMenuExpand(collapse ? true : !menuExpand);
        setCollapse(false);
      }}
      selected={collapse && subMenuItemSelected}
      data-testid={`subMenu_${id}`}
      className={clsx(menuExpand && "subMenuCollapsed")}
      disabled={disabled}
    >
      <SubMenuItemContainer className={menuExpand ? "selectedSubMenu" : null}>
        <IconContainer>
          {typeof Icon === "object" ? Icon : <SvgUserIcon />}
        </IconContainer>
        <Label className={menuExpand ? "selected" : ""}>{label}</Label>
        {menuExpand ? (
          <SvgArrowUpIcon height={tokens.sizing4} width={tokens.sizing4} />
        ) : (
          <SvgArrowDownIcon height={tokens.sizing4} width={tokens.sizing4} />
        )}
      </SubMenuItemContainer>
      {menuExpand && !collapse ? menuItems : null}
    </SubMenuTrigger>
  );
};

SubMenu.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  subMenuItems: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      label: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      icon: PropTypes.node,
      disabled: PropTypes.bool,
      type: PropTypes.oneOf(["section_header", "sub_menu", "footer"]),
    })
  ),
  disabled: PropTypes.bool,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  Icon: PropTypes.node,
  children: PropTypes.node,
  handleSelect: PropTypes.func,
  selected: PropTypes.string,
  isCollapse: PropTypes.bool,
  collapse: PropTypes.bool,
  expanded: PropTypes.bool,
  setCollapse: PropTypes.func,
};

export default SubMenu;

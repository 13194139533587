import React from "react";
import PropTypes from "prop-types";
import { styled, keyframes } from "@stitches/react";
import * as RadixTooltip from "@radix-ui/react-tooltip";
import token from "../../tokens/json/variables.json";

const scaleIn = keyframes({
  from: { transform: "scale(0.9)", opacity: 0 },
  to: { transform: "scale(1)", opacity: 1 },
});

const scaleOut = keyframes({
  from: { transform: "scale(1)", opacity: 1 },
  to: { transform: "scale(0.9)", opacity: 0 },
});

const StyledContent = styled(RadixTooltip.Content, {
  maxWidth: "288px",
  minWidth: "64px",
  variants: {
    type: {
      inline: {
        padding: `${token.spacing05} ${token.spacing4} ${token.spacing05} ${token.spacing4}`,
        color: token.textTextPrimary,
        borderRadius: token.borderRadius05,
        fontFamily: token.bodyShort01FontFamily,
        fontWeight: token.bodyShort01FontWeight,
        lineHeight: token.bodyShort01LineHeight,
        fontSize: token.bodyShort01FontSize,
        letterSpacing: token.bodyShort01LetterSpacing,
      },
      standard: {
        padding: token.spacing4,
        color: token.textTextPrimary,
        borderRadius: token.borderRadius05,
        fontFamily: token.bodyLong01FontFamily,
        fontWeight: token.bodyLong01FontWeight,
        lineHeight: token.bodyLong01LineHeight,
        fontSize: token.bodyLong01FontSize,
        letterSpacing: token.bodyLong01LetterSpacing,
      },
    },
  },
  backgroundColor: token.white,
  textAlign: "center",
  overflowWrap: "break-word",
  '&[data-state="delayed-open"]': {
    animation: `${scaleIn} 0.2s ease-in-out`,
  },
  '&[data-state="closed"]': {
    animation: `${scaleOut} 0.2s ease-in-out`,
  },
  '&[data-side="top"]': {
    transform: `translateY(-${token.sizing1})`,
  },
  '&[data-side="bottom"]': {
    transform: `translateY(${token.sizing1})`,
  },
  '&[data-side="left"]': {
    transform: `translateX(-${token.sizing1})`,
  },
  '&[data-side="right"]': {
    transform: `translateX(${token.sizing1})`,
  },
  boxShadow: "0px 0px 10px 0px rgba(161,161,161,0.6)",
  defaultVariants: {
    type: "inline",
  },
});

const TooltipArrow = styled(RadixTooltip.Arrow, {
  width: token.sizing2,
  fill: token.iconIconOnColor,
  variants: {
    type: {
      inline: {
        width: token.sizing2,
        height: token.sizing1,
      },
      standard: {
        width: token.sizing3,
        height: token.sizing15,
      },
    },
  },
});

const TooltipContent = React.forwardRef(
  ({ children, type, portal, ...props }, forwardedRef) => {
    const content = (
      <StyledContent type={type} {...props} ref={forwardedRef}>
        {children}
        <TooltipArrow type={type} />
      </StyledContent>
    );
    if (!portal) {
      return content;
    }
    return <RadixTooltip.Portal>{content}</RadixTooltip.Portal>;
  }
);
TooltipContent.propTypes = {
  type: PropTypes.string,
  portal: PropTypes.bool,
  children: PropTypes.node.isRequired,
};

const Tooltip = ({
  type,
  content,
  side,
  align,
  defaultOpen = false,
  portal,
  ...props
}) => {
  return (
    <div>
      {content ? (
        <RadixTooltip.Provider delayDuration={0}>
          <RadixTooltip.Root defaultOpen={defaultOpen}>
            <RadixTooltip.Trigger asChild>
              <div {...props}>{props.children}</div>
            </RadixTooltip.Trigger>
            <TooltipContent
              side={side}
              type={type}
              align={align}
              data-testid="tooltip-content"
              data-side={side}
              portal={portal}
            >
              {content}
            </TooltipContent>
          </RadixTooltip.Root>
        </RadixTooltip.Provider>
      ) : (
        <div>{props.children}</div>
      )}
    </div>
  );
};

Tooltip.propTypes = {
  type: PropTypes.string,
  content: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  align: PropTypes.string,
  side: PropTypes.string,
  defaultOpen: PropTypes.bool,
  id: PropTypes.string,
  children: PropTypes.node.isRequired,
  portal: PropTypes.bool,
};

Tooltip.defaultProps = {
  type: "inline",
  align: "center",
  side: "top",
  portal: true,
};

export default Tooltip;

import * as React from "react";
const SvgFiltersIcon = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 16 16"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M14.955 2a1.045 1.045 0 0 1 .822 1.691L12.4 8.008v3.58a.8.8 0 0 1-1.295.63l-2.322-1.825a.999.999 0 0 1-.383-.788V8.007L5.022 3.692A1.045 1.045 0 0 1 5.845 2h9.111ZM9.6 7.8v1.708l1.6 1.257V7.8c0-.135.045-.265.128-.37l3.31-4.23H6.161l3.31 4.23a.598.598 0 0 1 .128.37ZM4.393 4.185l.46.615H1.361l3.31 4.23a.598.598 0 0 1 .128.37v.907l1.6 1.258V9.4c0-.135.045-.265.127-.37l.83-1.06.243.313v4.104a.8.8 0 0 1-1.295.63l-2.322-1.824a.999.999 0 0 1-.383-.788v-.797L.222 5.29a1.045 1.045 0 0 1 .824-1.69h3.039c.065.21.17.408.308.585Z"
      fill="inherit"
    />
  </svg>
);
export default SvgFiltersIcon;

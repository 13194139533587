import React from "react";
import PropTypes from "prop-types";
import { styled } from "@stitches/react";
import { LabelContainerStyles, LabelStyles } from "./Tab.styles";

const LabelContainer = styled("div", LabelContainerStyles);

const Label = styled("div", LabelStyles);

const Tab = (props) => {
  const { id, label, selected, disabled, onClick, children } = props;
  return (
    <LabelContainer
      id={id}
      data-testid={`tab_${id}`}
      disabled={disabled}
      selected={selected}
      onClick={!disabled && onClick}
    >
      <Label disabled={disabled} selected={selected}>
        {label || children}
      </Label>
    </LabelContainer>
  );
};

Tab.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  selected: PropTypes.bool,
  disabled: PropTypes.bool,
  children: PropTypes.node,
  onClick: PropTypes.func,
};
export default Tab;

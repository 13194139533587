import React from "react";
import { styled } from "@stitches/react";
import PropTypes from "prop-types";
import Row from "../Row/Row";

import { TableBodyStyles } from "./Content.styles";
import { SIZE_VARIENTS } from "../Constants";

const StyledTableBody = styled("tbody", TableBodyStyles);

const Content = React.forwardRef(
  (
    {
      page,
      prepareRow,
      size,
      RowActions,
      onRowClick,
      selectedRowIndex,
      toggleAllRowsSelected,
      ...props
    },
    forwardedRef
  ) => {
    const handleRowClick = (event, row) => {
      if (onRowClick && typeof onRowClick === "function") {
        toggleAllRowsSelected(false);
        onRowClick(event, row);
      }
    };
    return (
      <StyledTableBody
        size={size}
        {...props}
        ref={forwardedRef}
        data-testid="Table_Body"
      >
        {page?.map((row) => {
          prepareRow(row);

          return (
            <Row
              row={row}
              size={size}
              {...row.getRowProps()}
              RowActions={RowActions}
              onClick={(e) => handleRowClick(e, row)}
              selectedRowIndex={selectedRowIndex}
            />
          );
        })}
      </StyledTableBody>
    );
  }
);

Content.propTypes = {
  page: PropTypes.instanceOf(Array),
  prepareRow: PropTypes.func,
  size: PropTypes.oneOf(SIZE_VARIENTS),
  RowActions: PropTypes.node,
  onRowClick: PropTypes.func,
  selectedRowIndex: PropTypes.number,
  toggleAllRowsSelected: PropTypes.func,
};

export default Content;

import variables from "../../../tokens/json/variables.json";

export const TableFootStyles = {
  background: variables.layerLayer02,
  boxSizing: "border-box",
  height: variables.sizing16,
};

export const TableRowStyles = {};

export const PaginatorRootStyles = {
  boxSizing: "content-box",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  textAlign: "center",
  height: "40px",
  padding: "11px 10px",
  gap: "48px",
  borderTop: `${variables.borderWidth1} solid ${variables.borderBorderSubtle00}`,
  borderRadius: `0px`,

  "& .leftSection": {
    display: "inline-flex",
    gap: "8px",
    height: "100%",
    alignItems: "center",
  },

  "& .rightSection": {
    display: "inline-flex",
    gap: "8px",
    height: "100%",
    alignItems: "center",
  },

  "& .paginatorSelect": {
    padding: "0px 12px",
    border: "none",
    color: variables.gray70,

    // typography
    fontFamily: variables.bodyShort01FontFamily,
    fontWeight: variables.bodyShort01FontWeight,
    lineHeight: variables.bodyShort01LineHeight,
    fontSize: variables.bodyShort01FontSize,
    letterSpacing: variables.bodyShort01LetterSpacing,
    paragraphSpacing: variables.bodyShort01ParagraphSpacing,
    textCase: variables.bodyShort01TextCase,
    textDecoration: variables.bodyShort01TextDecoration,
  },
};

export const EmptyPlaceHolderStyles = {
  width: "128px",
};

import variables from "../../tokens/json/variables.json";

export const ViewPortStyles = {
  position: "fixed",
  margin: 0,
  display: "flex",
  flexDirection: "column",
  width: "354px",
  variants: {
    banner: {
      true: {
        position: "relative",
        width: "100%",
      },
    },
    position: {
      topLeft: {
        top: 0,
        left: 0,
      },
      topCenter: {
        top: 0,
        left: "50%",
        transform: "translateX(-50%)",
      },
      topRight: {
        top: 0,
        right: 0,
      },
      bottomLeft: {
        bottom: 0,
        left: 0,
      },
      bottomCenter: {
        bottom: 0,
        left: "50%",
        transform: "translateX(-50%)",
      },
      bottomRight: {
        bottom: 0,
        right: 0,
      },
      center: {
        top: "50%",
        left: "50%",
        transform: "translate(-50% -50%)",
      },
    },
  },
};

export const ToastRootStyles = {
  display: "inline-flex",
  gap: variables.spacing4,
  // justifyContent: "space-between",
  padding: `${variables.spacing4} ${variables.spacing5}`,
  margin: variables.spacing5,
  borderRadius: variables.borderRadius1,
  // boxShadow: `${variables.modal0X} ${variables.modal0Y} ${variables.modal0Blur} ${variables.modal0Spread} ${variables.modal0Color} ${variables.modal0Type}`,
  boxShadow: "0px 0px 2px rgba(0, 0, 0, 0.3), 0px 0px 10px rgba(0, 0, 0, 0.2)",
  variants: {
    banner: {
      true: {
        margin: 0,
        boxShadow: "none",
        border: `${variables.borderWidth1} solid ${variables.borderBorderSubtle01}`,
      },
    },
    variant: {
      error: {
        backgroundColor: variables.tagRedTagBackground,
        color: variables.tagRedTagText,
      },
      success: {
        backgroundColor: variables.tagGreenTagBackground,
        color: variables.tagGreenTagText,
      },
      info: {
        backgroundColor: variables.tagBlueTagBackground,
        color: variables.tagBlueTagText,
      },
      warning: {
        backgroundColor: variables.tagYellowTagBackground,
        color: variables.tagYellowTagText,
      },
      default: {
        backgroundColor: variables.tagGrayTagBackground,
        color: variables.tagGrayTagText,
      },
    },
  },
  listStyle: "none",
};

export const ToastIconStyles = {
  variants: {
    variant: {
      error: {
        fill: variables.tagRedTagText,
        color: variables.tagRedTagText,
      },
      success: {
        fill: variables.tagGreenTagText,
        color: variables.tagGreenTagText,
      },
      info: {
        fill: variables.tagBlueTagText,
        color: variables.tagBlueTagText,
      },
      warning: {
        fill: variables.supportSupportWarning,
        color: variables.supportSupportWarning,
      },
      default: {
        fill: variables.textTextSecondary,
        color: variables.textTextSecondary,
      },
    },
  },
};

export const ToastContentStyles = {
  display: "grid",
  gap: variables.spacing3,
};

export const ToastActionsContainerStyles = {
  display: "inline-flex",
  gap: variables.spacing4,
  flex: 1,
};

export const ToastActionStyles = {
  all: "unset",
  cursor: "pointer",
  color: variables.linkLinkPrimary,

  fontFamily: variables.semiBoldBodyShort01FontFamily,
  fontWeight: variables.semiBoldBodyShort01FontWeight,
  lineHeight: variables.semiBoldBodyShort01LineHeight,
  fontSize: variables.semiBoldBodyShort01FontSize,
  letterSpacing: variables.semiBoldBodyShort01LetterSpacing,
  paragraphSpacing: variables.semiBoldBodyShort01ParagraphSpacing,
  testCase: variables.semiBoldBodyShort01TextCase,
  textDecoration: variables.semiBoldBodyShort01TextDecoration,
};

export const ToastTitleStyles = {
  color: variables.textTextPrimary,

  fontFamily: variables.semiBoldBodyShort02FontFamily,
  fontWeight: variables.semiBoldBodyShort02FontWeight,
  lineHeight: variables.semiBoldBodyShort02LineHeight,
  fontSize: variables.semiBoldBodyShort02FontSize,
  letterSpacing: variables.semiBoldBodyShort02LetterSpacing,
  paragraphSpacing: variables.semiBoldBodyShort02ParagraphSpacing,
  testCase: variables.semiBoldBodyShort02TextCase,
  textDecoration: variables.semiBoldBodyShort02TextDecoration,
};

export const ToastDescriptionStyles = {
  color: variables.textTextPrimary,

  fontFamily: variables.bodyLong01FontFamily,
  fontWeight: variables.bodyLong01FontWeight,
  lineHeight: variables.bodyLong01LineHeight,
  fontSize: variables.bodyLong01FontSize,
  letterSpacing: variables.bodyLong01LetterSpacing,
  paragraphSpacing: variables.bodyLong01ParagraphSpacing,
  testCase: variables.bodyLong01TextCase,
  textDecoration: variables.bodyLong01TextDecoration,
};

export const ToastCloseButtonStyles = {
  width: variables.sizing6,
  height: variables.sizing6,
};

export const IconButtonStyles = {
  padding: 0,
  width: variables.sizing6,
  height: variables.sizing6,
  color: variables.iconIconPrimary,
};

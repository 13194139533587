import React, { useRef } from "react";
import * as DialogPrimitive from "@radix-ui/react-dialog";
import PropTypes from "prop-types";
import { CloseIcon } from "../../icons";
import useIsOverflow from "../../customHooks/overFlow";
import {
  Sheet,
  SheetOverlay,
  SheetContent,
  SheetHeader,
  SheetHeaderContent,
  SheetTitle,
  SheetDescription,
  SheetCloseButton,
  IconButton,
  SheetBody,
  SheetFooter,
} from "./Sheet";
import useEscapeKey from "../../customHooks/useEscapeKey";

const SidePanel = ({
  id,
  open,
  setOpen,
  onClose,
  title,
  description,
  modal,
  type,
  showFooter,
  showSecondaryButton,
  secondaryButtonLabel,
  secondaryButtonProps,
  onSecondaryAction,
  cancelButtonLabel,
  cancelButtonProps,
  onCancel,
  acceptButtonLabel,
  acceptButtonProps,
  onAccept,
  children,
  ...props
}) => {
  const bodyRef = useRef();
  const hasOverflow = useIsOverflow(bodyRef);
  const handleClose = (event) => {
    event.preventDefault();
    if (setOpen) setOpen(false);
    if (onClose) onClose(event);
  };
  useEscapeKey(handleClose);

  return (
    <Sheet open={open} modal={modal}>
      <DialogPrimitive.Portal>
        <SheetOverlay />
        <SheetContent
          id={`SidePanel_${id}`}
          data-testid="SidePanel"
          type={type}
          {...props}
        >
          <SheetHeader data-testid="SidePanel_Header">
            <SheetHeaderContent>
              {title && (
                <SheetTitle data-testid="SidePanel_Title">{title}</SheetTitle>
              )}
              {description && (
                <SheetDescription data-testid="SidePanel_Description">
                  {description}
                </SheetDescription>
              )}
            </SheetHeaderContent>
            <SheetCloseButton
              asChild
              onClick={handleClose}
              data-testid="SidePanel_CloseButton"
            >
              <IconButton color="ghost" size="small">
                <CloseIcon height="14px" width="14px" />
              </IconButton>
            </SheetCloseButton>
          </SheetHeader>
          <SheetBody ref={bodyRef} data-testid="SidePanel_Body">
            {children}
          </SheetBody>
          {showFooter && (
            <SheetFooter
              data-testid="SidePanel_Footer"
              enabledShadow={!!hasOverflow}
              showSecondaryButton={showSecondaryButton}
              secondaryButtonLabel={secondaryButtonLabel}
              secondaryButtonProps={secondaryButtonProps}
              onSecondaryAction={onSecondaryAction}
              cancelButtonLabel={cancelButtonLabel}
              cancelButtonProps={cancelButtonProps}
              onCancel={onCancel}
              acceptButtonLabel={acceptButtonLabel}
              acceptButtonProps={acceptButtonProps}
              onAccept={onAccept}
            />
          )}
        </SheetContent>
      </DialogPrimitive.Portal>
    </Sheet>
  );
};

SidePanel.propTypes = {
  id: PropTypes.string,
  children: PropTypes.node,
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  modal: PropTypes.bool,
  type: PropTypes.oneOf(["white", "gray"]),
  title: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  description: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  showFooter: PropTypes.bool,
  onClose: PropTypes.func,
  cancelButtonLabel: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  cancelButtonProps: PropTypes.shape({
    color: PropTypes.string,
    size: PropTypes.string,
    disabled: PropTypes.bool,
    hidden: PropTypes.bool,
    id: PropTypes.string,
  }),
  acceptButtonLabel: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  acceptButtonProps: PropTypes.shape({
    color: PropTypes.string,
    size: PropTypes.string,
    disabled: PropTypes.bool,
    hidden: PropTypes.bool,
    id: PropTypes.string,
  }),
  secondaryButtonLabel: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  secondaryButtonProps: PropTypes.shape({
    color: PropTypes.string,
    size: PropTypes.string,
    disabled: PropTypes.bool,
    hidden: PropTypes.bool,
    id: PropTypes.string,
  }),
  onAccept: PropTypes.func,
  onCancel: PropTypes.func,
  onSecondaryAction: PropTypes.func,
  showSecondaryButton: PropTypes.bool,
};

SidePanel.defaultProps = {
  modal: true,
  type: "white",
};

export default SidePanel;

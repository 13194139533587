import React, { useState, useRef } from "react";
import PropTypes from "prop-types";
import {
  Label,
  InputArea,
  TagsContainer,
  CustomInput,
  HelperText,
} from "./InputTags.style";
import Tag from "../Tag";
import Tooltip from "../Tooltip";

const InputTags = (props) => {
  const {
    tags,
    handleAddTag,
    handleRemoveTag,
    helperText,
    label,
    placeholder,
    inputWidth,
    inputHeight,
    labelStyles,
    handleError,
    disabled,
    error,
    resizable,
    multiline,
    id,
    labelId,
    inputAreaId,
    helperTextId,
    tagsContainerId,
    ...rest
  } = props;
  const inputRef = useRef(null);
  const isLength = Array.isArray(tags) && tags.length;
  const [input, setInput] = useState("");

  const handleInputAreaClick = () => {
    inputRef.current.select();
  };

  const initiateAddTag = () => {
    const tagName = input.trim();
    if (tagName) {
      handleAddTag(tagName, setInput);
    }
  };

  const handleKeyPress = (event) => {
    switch (event.code) {
      case "Enter":
        initiateAddTag(event.code);
        break;
      case "Backspace":
        if (!input && tags.length) handleRemoveTag(tags[tags.length - 1]);
        break;
      default:
    }
  };

  const handleInputChange = (event) => {
    const { value } = event.target;
    setInput(value);
    if (error) handleError(true);
  };

  return (
    <div
      id={id}
      data-testid={id}
      className="InputTagsContainer"
      disabled={disabled}
      {...rest}
    >
      <Label
        id={labelId}
        data-testid={labelId}
        style={labelStyles || null}
        disabled={disabled}
      >
        {label}
      </Label>
      <InputArea
        tabIndex={0}
        id={inputAreaId}
        data-testid={inputAreaId}
        onClick={handleInputAreaClick}
        onBlur={initiateAddTag}
        onFocus={handleInputAreaClick}
        disabled={disabled}
        style={{
          width: inputWidth || "max-content",
          height: inputHeight || "max-content",
        }}
        error={error}
        resizable={resizable}
        multiline={multiline}
      >
        <TagsContainer id={tagsContainerId} data-testid={tagsContainerId}>
          {isLength
            ? tags?.map((tag) => (
                <Tooltip
                  id={`${tag.label}_Tooltip`}
                  data-testid={`${tag.label}_Tooltip`}
                  key={tag.label}
                  content={tag?.tooltipMessage || null}
                  side="bottom"
                >
                  <Tag
                    id={`${tag.label}_Tag`}
                    data-testid={`${tag.label}_Tag`}
                    disabled={disabled}
                    {...tag}
                    onClick={() => handleRemoveTag(tag)}
                  />
                </Tooltip>
              ))
            : null}
        </TagsContainer>
        <CustomInput
          id="InputTagsCustomInputId"
          data-testid="InputTagsCustomInputId"
          tabIndex="-1"
          ref={inputRef}
          type="text"
          placeholder={placeholder}
          value={input}
          disabled={disabled}
          onChange={handleInputChange}
          onKeyDown={handleKeyPress}
        />
      </InputArea>
      <HelperText
        id={helperTextId}
        data-testid={helperTextId}
        disabled={disabled}
        error={error}
      >
        {helperText}
      </HelperText>
    </div>
  );
};

InputTags.propTypes = {
  id: PropTypes.string,
  labelId: PropTypes.string,
  inputAreaId: PropTypes.string,
  helperTextId: PropTypes.string,
  tagsContainerId: PropTypes.string,
  tags: PropTypes.instanceOf(Array),
  handleAddTag: PropTypes.func,
  handleRemoveTag: PropTypes.func,
  helperText: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  inputWidth: PropTypes.string,
  inputHeight: PropTypes.string,
  labelStyles: PropTypes.oneOfType([PropTypes.object]),
  error: PropTypes.bool,
  handleError: PropTypes.func,
  resizable: PropTypes.bool,
  disabled: PropTypes.bool,
  multiline: PropTypes.bool,
};

InputTags.defaultProps = {
  tags: [],
  disabled: false,
  resizable: true,
  error: false,
  multiline: true,
  inputWidth: "527px",
  inputHeight: "78px",
  label: "Add Label text",
  placeholder: "Add placeholder text",
  helperText: "Add HelperText",
  id: "inputTagsId",
  labelId: "inputTagsLabelId",
  inputAreaId: "inputTagsInputAreaId",
  helperTextId: "inputTagsHelperTextId",
  tagsContainerId: "inputTagsTagsContainerId",
};

export default InputTags;
